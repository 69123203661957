/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';

const KnMediaIcon = () => (
  <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8431 0H1.75217C0.791302 0 0 0.791302 0 1.75217V10.1174C0 11.0782 0.791302 11.8695 1.75217 11.8695H16.8431C17.804 11.8695 18.5953 11.0782 18.5953 10.1174V1.75217C18.5953 0.791302 17.804 0 16.8431 0ZM11.9823 6.10433L7.68661 8.59128C7.57357 8.6478 7.40401 8.59128 7.40401 8.42172V3.44782C7.40401 3.33477 7.57357 3.22173 7.68661 3.27825L11.9823 5.7652C12.0953 5.87825 12.0953 5.99129 11.9823 6.10433Z" fill="#0A87A0" />
    <path d="M21.5908 1.24484L19.443 2.60136C19.1604 2.77093 19.0474 3.05353 19.0474 3.39266V8.47961C19.0474 8.81874 19.2169 9.10134 19.443 9.27091L21.5908 10.6274C22.1561 10.9666 22.8343 10.5144 22.8343 9.83613V2.03615C22.8908 1.30136 22.1561 0.905713 21.5908 1.24484Z" fill="#0A87A0" />
  </svg>
);

export default KnMediaIcon;
