/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { styled } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ready, create, color, Container, percent, useTheme,
} from '@amcharts/amcharts4/core';
import {
  XYChart, XYCursor, ColumnSeries, ValueAxis, CategoryAxis,
} from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { translate } from 'i18n/i18n';
import palette from 'styles/colors';
import { TOPLINE_METRICS_BUCKETS_COUNT, TOPLINE_METRICS_BUCKET_SIZE } from 'Constants';

const labelsColor = color(palette.brownishGrey);
const labelsFontSize = 11;
const columnsColor = color(palette.linkWater);
const lastColumnColor = color(palette.slateGrey);
const lastColumnLabelColor = color(palette.brightPurple);
const lastColumnLabelSuffix = '\u2022';
const tooltipBackgroundColor = color(palette.midnight);
const tooltipLabelColor = color(palette.white.white2);
const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

/**
 * Adapter function which returns either the default color for an amcharts object
 * OR a contrast color if the dataSelector value the object references meets the conditions
 * defined here.
 * (In short: we want to show different color for last column / label)
 *
 * @param {string} defaultColor The default color set for fill or stroke of the object.
 * @param {string} contrastColor The contrast color.
 * @param {object} target The amcharts object the adapter is defined for (label, column, etc).
 */
const getColorByBucket = (defaultColor, contrastColor, target) => {
  if (target.dataItem) {
    /** Label and column objects hold the category value in different fields,
     * so let's check both of them
     */
    const bucketIndex = target.dataItem.category || Number(target.dataItem.categories.categoryX);
    if (bucketIndex === TOPLINE_METRICS_BUCKETS_COUNT) {
      return contrastColor;
    }
  }
  return defaultColor;
};

/**
 * Adapter function to return a label for chart columns,
 * based on bucket index
 *
 * @param {string} text The default label text
 * @param {string} target The amcharts object the adapter is defined for.
 */
const getLabelTextForBucket = (text, target) => {
  if (target.dataItem) {
    const bucketIndex = target.dataItem.category;
    if (bucketIndex === TOPLINE_METRICS_BUCKETS_COUNT) {
      /** Label for last bucket */
      return `${lastColumnLabelSuffix} ${translate('PATIENT_RECORD.metricsChartBucketLabels.lastBucket', { count: TOPLINE_METRICS_BUCKET_SIZE.days })}`;
    }
    /** Label for any other bucket */
    const reversedIndex = TOPLINE_METRICS_BUCKETS_COUNT - bucketIndex;
    const labelStartDay = (reversedIndex * TOPLINE_METRICS_BUCKET_SIZE.days) + 1;
    const labelEndDay = (reversedIndex + 1) * TOPLINE_METRICS_BUCKET_SIZE.days;
    return translate('PATIENT_RECORD.metricsChartBucketLabels.prevBucket', { startCount: labelStartDay, endCount: labelEndDay });
  }
  /** Should never get here */
  return text;
};

/**
 * Amcharts dimensions are relative to the chart container.
 * Define actual sizes here.
 */
const KnChartContainer = styled('div')({
  height: 80,
  width: 306,

  /**
   * Needed as the pixels are antialised by default and because of
   * this the border around the chart it's rendered blurry.
   * This does pixel rounding and produces sharp edges. We revert the
   * shapeRendering property on columns and tooltip to default value auto,
   * so we should have no visual defects.
   */
  '& g > g:last-child': {
    shapeRendering: 'auto', // crispEdges
  },
  /**
   * The designs require a border around the columns only. Without a way to configure
   * this, the border is added through CSS.
   * This targets the svg rect child where the columns are displayed and adds it.
   * Note: we set the stroke width to 1px and the stroke opacity because of crispEdges property.
   */
  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
});

const createChartContainer = (chartId) => {
  const container = create(chartId, Container);
  container.layout = 'grid';
  container.fixedWidthGrid = false;
  container.width = percent(100);
  container.height = 80;
  container.fontFamily = 'DIN Next LT W01 Regular';
  return container;
};

const createChart = (container, data) => {
  const chart = container.createChild(XYChart);
  chart.width = percent(100);
  chart.height = percent(100);
  chart.paddingTop = 4;
  chart.paddingLeft = 1;
  chart.paddingRight = 1;
  chart.cursor = new XYCursor();

  chart.data = data;

  /** On horizontal axis we show the time bucekts */
  const bucketAxis = chart.xAxes.push(new CategoryAxis());
  bucketAxis.renderer.grid.template.disabled = true;
  bucketAxis.renderer.labels.template.fontSize = labelsFontSize;
  bucketAxis.renderer.labels.template.fill = labelsColor;
  bucketAxis.renderer.minGridDistance = 50;
  bucketAxis.cursorTooltipEnabled = false;
  bucketAxis.dataFields.category = 'bucketIndex';

  /** On vertical axis we show no grid values. */
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.min = 0;
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.renderer.baseGrid.disabled = true;
  valueAxis.renderer.labels.template.disabled = true;
  valueAxis.cursorTooltipEnabled = false;

  /**
   * The chart is column based.
   * The horizontal axis uses `bucketIndex` property as data source.
   * The vertical axis uses `value` property as data source.
   */
  const series = chart.series.push(new ColumnSeries());
  series.dataFields.categoryX = 'bucketIndex';
  series.dataFields.valueY = 'value';

  /** Define the tooltip text and style. */
  series.adapter.add('tooltipText', (text, target) => {
    const { dataContext } = target.tooltipDataItem;
    return dataContext && dataContext.value === 1
      ? translate('PATIENT_RECORD.metricsChartTooltip')
      : translate('PATIENT_RECORD.metricsChartTooltip_plural');
  });
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = tooltipBackgroundColor;
  series.tooltip.background.filters.clear();
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.label.paddingTop = 10;
  series.tooltip.label.paddingBottom = 10;
  series.tooltip.label.fontSize = 18;
  series.tooltip.label.fill = tooltipLabelColor;
  series.tooltip.background.shapeRendering = 'auto';

  /** Define columns background and border colors. */
  series.columns.template.fill = columnsColor;
  series.columns.template.stroke = columnsColor;
  series.columns.template.shapeRendering = 'auto';

  /** Define color adapters. */
  series.columns.template.adapter.add('fill', (fill, target) => getColorByBucket(fill, lastColumnColor, target));
  series.columns.template.adapter.add('stroke', (stroke, target) => getColorByBucket(stroke, lastColumnColor, target));

  bucketAxis.renderer.labels.template.adapter.add('fill', (fill, target) => getColorByBucket(fill, lastColumnLabelColor, target));
  bucketAxis.renderer.labels.template.adapter.add('text', getLabelTextForBucket);
};

const KnMetricsChart = ({ chartId, data }) => {
  const chartContainer = useRef(null);

  useTheme(am4themesAnimated);
  useEffect(() => {
    ready(() => {
      chartContainer.current = createChartContainer(chartId);
      createChart(chartContainer.current, data);
    });

    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartContainer.current) {
        chartContainer.current.dispose();
      }
    };
  }, [data, chartId]);

  return (
    <KnChartContainer id={chartId} />
  );
};

KnMetricsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    bucketIndex: PropTypes.number,
    value: PropTypes.number,
  })).isRequired,
  chartId: PropTypes.string.isRequired,
};

export default KnMetricsChart;
