/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useEffect, useState,
} from 'react';
import { Box, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import KnCopyIcon from 'components/icons/CopyIcon';
import { translate } from 'i18n/i18n';
import patientActions from 'redux/actions/patientActions';
import { downloadPDF, withKeyNamespace } from 'utils/utils';
import { get } from 'lodash';
import KnButton from 'components/Button';
import calendarActions from 'redux/actions/calendarActions';
import KnPageContent from '../../../components/Content';
import { KnPatientEmrRecordBox } from '../styles';
import logo from '../../../assets/images/nileLogo84x48.png';
import {
  KnCopyText,
  KnCursorBox, KnHTMLTextArea, KnLogo, StyledBox, StyledPre,
} from './PatientEmrExport.styles';
import useConsentText from './useConsentText';

/**
 * Page used for exporig emr report
 */
const i18nKey = withKeyNamespace('PATIENT_RECORD');
const ConsentExport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [copied, setCopied] = useState(false);
  const { finalText, patientText } = useConsentText();
  const consent = useSelector((state) => state.calendar.consent);

  useEffect(() => {
    if (params) {
      dispatch(patientActions.fetchPatientInfo(params.patientId));
      dispatch(calendarActions.getPatientConsent(params.patientId));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const accountType = get(consent, 'data.patientUser.accountType');
    if (accountType === 'caregiver') {
      dispatch(patientActions.fetchPatientInfo(get(consent, 'data.patientUser.appOwnerId')));
    }
  }, [dispatch, consent]);

  const onCopyText = () => {
    const textToCopy = `${patientText}\n${document.getElementById('consentText').textContent}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 10000);
    });
  };

  return (
    <KnPageContent>
      <KnPatientEmrRecordBox mb={2} padding={3} pb={0} id="divToPrint">
        <Box display="flex" height="calc(100vh - 20px)" minHeight="890px">
          <Box flex="3 1 0">
            <Box position="relative">
              <div>
                <StyledPre>{patientText}</StyledPre>
                <KnHTMLTextArea dangerouslySetInnerHTML={{ __html: finalText }} style={{ lineHeight: '18px' }} />
              </div>
              <div style={{ display: 'none' }}>
                <div id="reportPDF" style={{ width: 1000 }}>
                  <>
                    <div style={{ textAlign: 'right' }}><KnLogo src={logo} alt="" /></div>
                    <StyledPre>{patientText}</StyledPre>
                  </>
                  <StyledBox>
                    <StyledPre id="consentText" dangerouslySetInnerHTML={{ __html: finalText }} />
                  </StyledBox>
                </div>
              </div>
              <Box display="flex" mt={2} mb={0} justifyContent="space-between">
                <KnCursorBox display="flex" alignItems="center" onClick={onCopyText}>
                  <KnCopyIcon />
                  <Typography component={KnCopyText} data-testid="copy-report-button">
                    {translate(i18nKey(`patientReports.${copied ? 'copied' : 'copyText'}`))}
                  </Typography>
                </KnCursorBox>

                <Box display="flex" alignItems="center">
                  <KnButton
                    data-testid="download-pdf-button"
                    onClick={() => downloadPDF(
                      { before: '.beforeClass', after: '.afterClass', avoid: ['p'] },
                      'consent',
                    )}
                  >
                    {translate(i18nKey('patientReports.downloadPDF'))}
                  </KnButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </KnPatientEmrRecordBox>
    </KnPageContent>
  );
};

export default ConsentExport;
