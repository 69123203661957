/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { appInsights } from 'appInsights';
import { KnSectionHeader } from 'components/Typography';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import KnButton from 'components/Button';
import { translate } from 'i18n/i18n';
import { APP_PAGE_URLS, REGIMEN_STATUS, TRACKING_EVENTS } from 'Constants';
import { useSelector } from 'react-redux';
import { withKeyNamespace, useIsUserHospitalStaff } from 'utils/utils';
import KnPatientRecordBox from './styles';

const i18nKey = withKeyNamespace('ASSIGN_REGIMEN');


const KnAddRegiment = ({ patientType, admin }) => {
  const { data: patientInfo } = useSelector((state) => state.patientRecord.patientInfo);
  const firstName = (_.get(patientInfo, 'firstName')) ? `${_.get(patientInfo, 'firstName')}'s` : '';
  const title = translate(i18nKey('patientMedicationRegimen'), { name: firstName || '' });
  const {
    data: thresholds,
  } = useSelector((state) => state.patientRecord.thresholds);
  const userIsHospitalStaff = useIsUserHospitalStaff();


  const onAssignRegimen = useCallback(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.clickOnAssignMedication });
  }, []);

  const onSetThreshold = useCallback(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.clickSetThreshold });
  }, []);

  const {
    data: regimens,
  } = useSelector((state) => state.patientRecord.regimens);


  /** Currently assigned regimen (started or not) */
  const assignedRegimen = useMemo(() => {
    const currentRegimens = _.filter(
      regimens,
      (regimen) => (
        (regimen.status !== REGIMEN_STATUS.deactivated)
        && (regimen.status !== REGIMEN_STATUS.completed)
        && (regimen.status !== REGIMEN_STATUS.updated)
      ),
    );
    return _.first(currentRegimens);
  }, [regimens]);


  const canSetThreshold = useMemo(() => {
    const patientHasThresholds = !_.isEmpty(thresholds);
    return userIsHospitalStaff && !patientHasThresholds;
  }, [userIsHospitalStaff, thresholds]);

  const canAssignNewRegimen = useMemo(() => {
    const patientHasAssignedRegimen = _.find(regimens, (regimen) => (
      regimen.status === REGIMEN_STATUS.notStarted
      || regimen.status === REGIMEN_STATUS.active
    ));
    return userIsHospitalStaff && !patientHasAssignedRegimen;
  }, [userIsHospitalStaff, regimens]);

  const AssignRegimenButton = useMemo(() => (
    <KnButton
      disabled={admin}
      route={APP_PAGE_URLS.assignRegimen.replace(':patientId', _.get(patientInfo, 'patientId'))}
      onClick={onAssignRegimen}
      routeParams={{ patientName: _.get(patientInfo, 'firstName'), patientType }}
      data-testid="assign-regimen-button"
    >
      <span>
        <big>+</big>
        {' '}
        {translate('PATIENT_RECORD.patientSettings.assignRegimenButton')}
      </span>
    </KnButton>
  ), [admin, onAssignRegimen, patientInfo, patientType]);

  const SetThresholdButton = useMemo(() => (
    <KnButton
      route={APP_PAGE_URLS.threshold.replace(':patientId', _.get(patientInfo, 'patientId'))}
      onClick={onSetThreshold}
      disabled={admin}
      routeParams={{
        activeRegimen: assignedRegimen,
        patientName: _.get(patientInfo, 'firstName'),
      }}
      data-testid="set-threshold-button"
    >
      <span>
        <big>+</big>
        {' '}
        {translate('PATIENT_RECORD.patientSettings.setThresholdButton')}
      </span>
    </KnButton>
  ), [patientInfo, onSetThreshold, admin, assignedRegimen]);
  if ((!canAssignNewRegimen && !canSetThreshold)
    || (!_.isArray(regimens) && !_.isArray(thresholds))) {
    return null;
  }
  if (patientType && !canAssignNewRegimen && !!_.isArray(regimens)) {
    return null;
  }

  return (
    <KnPatientRecordBox extrapadding="true" mb={3} data-testid="medication-regimen-wrapper">
      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h6" component={KnSectionHeader}>
          {title}
        </Typography>
      </Box>
      <Box display="inline-box">
        <>
          <Box mr={3}>
            {!patientType && (regimens && canAssignNewRegimen) ? AssignRegimenButton : null}
            {patientType && (canAssignNewRegimen) ? AssignRegimenButton : null}
          </Box>

          {(regimens && canSetThreshold && thresholds) ? SetThresholdButton : null}
        </>
      </Box>
    </KnPatientRecordBox>
  );
};

KnAddRegiment.defaultProps = {
  patientType: false,
  admin: false,
};

KnAddRegiment.propTypes = {
  patientType: PropTypes.bool,
  admin: PropTypes.bool,
};

export default KnAddRegiment;
