/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _, {
  ceil, isArray, isEmpty, omit, size, sum,
} from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { translate } from 'i18n/i18n';
import * as html2pdf from 'html2pdf.js';
import { useSelector } from 'react-redux';
import Big from 'big.js';
import {
  HCP_USER_ROLES,
  HCP_USER_STATUS,
  MEDICATIONS_WITH_STRENGTH,
  MEDICATION_STRENGTH_TYPE,
  REGIMEN_STATUS,
  TITRATION_MAX_FREQUENCY,
} from 'Constants';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import { addDays } from 'date-fns';

const useHasUserRole = (roles) => {
  const [hasUserRole, setHasUserRole] = useState(false);
  const currentUserStore = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const userRoleId = _.get(currentUserStore, 'role.id');
    if (userRoleId) {
      setHasUserRole(_.includes(roles, userRoleId));
    }
  }, [currentUserStore, roles]);

  return hasUserRole;
};

const useIsUserState = (status) => {
  /** isUserState to be `undefined` until we get the current user record to check status */
  const [isUserState, setIsUserState] = useState(undefined);
  const currentUserStore = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (currentUserStore.initialized) {
      const userStatus = _.get(currentUserStore, 'status');
      if (userStatus !== undefined) {
        // We've managed to get user record successfully
        setIsUserState(userStatus === status);
      }
    }
  }, [currentUserStore, status]);

  return isUserState;
};

/**
 * Utility function to show 'Undisclosed' when a value is not given
 * @param {string} value Given value
 * @returns {string} `value` if not null or empty, 'Undisclosed' otherwise
 * */
export const undisclosed = (value, isInvite = false) => (
  value || (isInvite ? translate('GENERAL.inactive') : translate('GENERAL.undisclosed'))
);

/**
 * Uppercases the first letter of a string,
 * and lowercases the rest of it
 * @param {string} value The string to format
 */
export const capFirstCharOnly = (value) => value && value.charAt(0).toUpperCase()
    + value.slice(1).toLowerCase();

/**
  * Trim spaces from the beginning of a string
  * @param {string} value
  * @return {string} the `value` without any leading space
  */
export const leftTrim = (value) => {
  if (!value) return value;
  return value.replace(/^\s+/g, '');
};

/**
 * When date from server comes in ISO 8601 format (YYYY-MM-DD), eg. date of birth,
 * if used as-is with Date(), the computed date will be relative to the users time zone.
 * Use this helper method, if you want to convert it into time zone independent date
 * This will turn YYYY-MM-DD into YYYY,MM,DD and then create the Date object
 * @param {string} value Date in YYYY-MM-DD syntax
 * @returns {Date} Time zone independent date object
 */
export const serverDateToGMT = (value) => {
  if (value.includes('T')) {
    return new Date(value.split('T')[0].replace(/-/g, '/'));
  }
  return new Date(value.replace(/-/g, '/'));
};

/**
 * Helper function to compose full name of a user in the format '{lastName}, {firstName}'.
 *
 * @param {string} firstName
 * @param {string} lastName
 */
export const composeFullName = (firstName, lastName) => (
  (lastName && firstName)
    ? `${lastName}, ${firstName}`
    : ''
);

/**
 * Helper function to compose the medication label with both name and active ingredient.
 *
 * @param {string} name
 * @param {string} activeIngredient
 */
export const composeMedicationLabel = (medication) => (
  `${medication.activeIngredient} (${medication.name})`
);

/**
 * Attaches tablet strength to the end of the medication label,
 *
 * @param {object} medication
 * @param {number} medicationStrength
 * @param {string} unit
 * @returns {string} Medication label followed by strength in paranthesis
 */
export const composeMedicationLabelWithStrength = (medication, medicationStrength, unit) => {
  const medicationLabel = composeMedicationLabel(medication);
  if (medicationStrength) {
    return `${medicationLabel} (${translate('TITRATIONS.titrationBuilder.doseWithUnit', { dose: medicationStrength, unit })})`;
  }
  return medicationLabel;
};

/**
 * Filtering algorithm for a medication, used when searching in the medications dropdown
 *
 * @param {object} medication Medication object, as in the reference data
 * @param {string} searchTerm Search term to match
 */
export const medicationSearchMatch = (medication, searchTerm) => {
  const lowerSearchTerm = leftTrim(searchTerm).toLowerCase();
  const fullMedicationLabel = composeMedicationLabel(medication).toLowerCase();
  return medication.name.toLowerCase().indexOf(lowerSearchTerm) === 0
    || medication.activeIngredient.toLowerCase().indexOf(lowerSearchTerm) === 0
    || fullMedicationLabel.indexOf(lowerSearchTerm) === 0;
};

/**
 * Helper function to prepend a child key path to its parent namespace.
 * Used for translation keys so we don't have to repeat the entire path.
 *
 * Example usage:
 * const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');
 * {translate(i18nKey('title'))} will use the message from 'TITRATIONS.titrationBuilder.title'
 *
 * @param {string} namespace
 */
export const withKeyNamespace = (namespace) => (child) => `${namespace}.${child}`;

/**
 * Custom hook which stores the previous value of a given prop, state or
 * other calculated value.
 * Source and usage:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param {any} value Value to be watched for previous values.
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * React hook for isUserPhysician flag
 */
export const useIsUserPhysician = useHasUserRole.bind(null, [HCP_USER_ROLES.physician]);

/**
 * React hook for isUserNurse flag
 */
export const useIsUserNurse = useHasUserRole.bind(null, [HCP_USER_ROLES.nurse]);

/**
 * React hook for isUserHospitalStaff flag
 */
export const useIsUserHospitalStaff = useHasUserRole.bind(
  null,
  [HCP_USER_ROLES.physician, HCP_USER_ROLES.nurse],
);

/**
 * Helper function which pads a number with zero on front if that
 * number is a digit.
 *
 * @param {number} value The number to pe padded if is a digit.
 * @returns {string} The number as a string.
 */
export const padIfDigit = (value) => _.padStart(value, 2, '0');

/**
 * React hook for isUserPending flag
 * 'true' if current hcp user has not yet been approved into the system
 */
export const useIsUserPending = useIsUserState.bind(null, HCP_USER_STATUS.pending);

/**
 * React hook for isUserActive flag
 */
export const useIsUserActive = useIsUserState.bind(null, HCP_USER_STATUS.active);

/**
 * Calculates how many tablets are needed
 * from a med with given medicationStrength, to get the dosage
 *
 * @param {string} dosage Dosage value, as typed in
 * @param {number} medicationStrength Strength of the med
 * @returns {number} Number if tablets, or `undefined` if cannot be calculated
 */
export const calculateTabletCount = (dosage, medicationStrength, titrationState) => {
  const dosageValue = parseFloat(dosage);
  if (
    !isEmpty(titrationState.availableDoses)
    && titrationState.medicationType === _.lowerCase(MEDICATION_STRENGTH_TYPE.suspension)
    && !medicationStrength
    && dosageValue > 0) {
    return Number((dosageValue / titrationState.availableDoses[0]).toFixed(2));
  }
  if (!medicationStrength) {
    return null;
  }
  if (!medicationStrength || !dosageValue || dosageValue < 0) {
    return 0;
  }
  const tabletCount = Number((dosageValue / medicationStrength).toFixed(2));
  return tabletCount;
};

/**
 * @param {number} tabletCount
 * @returns {string} tablet count with translated suffix (eg. 'tablets'),
 *  or empty string, if no count given
 */
export const tabletCountDisplay = (tabletCount, medicationType, medicationUnit = '') => {
  let lable = '';
  const pill = _.lowerCase(MEDICATION_STRENGTH_TYPE.pill);
  const suspension = _.lowerCase(MEDICATION_STRENGTH_TYPE.suspension);
  if (!tabletCount) return null;
  if (medicationUnit === 'cc') {
    lable = 'cc';
  } else if (medicationType === suspension) {
    lable = 'mL';
  } else if (medicationType === pill) {
    lable = tabletCount > 1 ? `${pill}s` : pill;
  }
  return translate('TITRATIONS.titrationBuilder.tabletCount', { count: tabletCount, suspension: lable });
};

/**
 * Checks if given unit allows medication strength
 * @param {string} unit
 * @returns {boolean}
 */
export const unitAllowsStrength = (unit) => MEDICATIONS_WITH_STRENGTH.indexOf(unit) > -1;

/**
 * Quantities can be floating numbers,
 * we use big.js to fix errors we might have with floating number arithmetics
 * (eg. 0.03 + 0.03 + 0.03 + 0.01 = 0.0999999999,
 * or 2.55 * 100 = 254.99999999999997)
 *
 * @param {array} quantities List of floating numbers representing
 * individual dosage quantities. some may be null
 * @returns {number} sum of quantities
 */
export const computeTotalQuantity = (quantities) => {
  let total = Big(0);
  quantities.forEach((quantity) => {
    if (Number(quantity)) {
      total = total.plus(quantity);
    }
  });
  return total.toNumber();
};

/**
 * Add completed and ongoing flag in dosages
 * @param {Array} medications
 * @param {Object} parentTitration
 * @param {Date} startedAt
 * @returns
 */
export const getCompletedDosages = (
  medications, parentTitration, startedAt, completed, assignedRegimen = { medications: [] },
) => {
  let medics = medications;
  const completedDosages = [];
  const parentActivatedDate = parentTitration
    ? new Date(parentTitration.activatedAt) : undefined;
  const complete = startedAt || parentActivatedDate;
  if (complete) {
    medics = [];
    const date1 = complete;
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    medics = medications.map((meds) => {
      let dose = [];
      const offset = meds.offset ? meds.offset : 0;
      let duration = diffDays - offset;
      const durationCompleted = diffDays - offset;
      let dosageDuration = 0;
      dose = meds.dosages.map((dosage) => {
        if (duration >= dosage.duration || duration + 1 >= dosage.duration) {
          duration -= dosage.duration;
          completedDosages.push(dosage.keyId);
          return { ...dosage, complete: true };
        }
        const ongoing = Boolean((dosageDuration <= durationCompleted) && startedAt);

        duration -= dosage.duration;
        dosageDuration += dosage.duration;
        return {
          ...dosage,
          complete: false,
          ongoing,
          endDate: ongoing ? addDays(new Date(startedAt), dosageDuration - 1) : null,
        };
      });
      if (completed) {
        if (assignedRegimen.medications
          && assignedRegimen.medications.find((m) => m.id === meds.id)) {
          dose = dose.filter((d) => !d.complete || d.new === true);
        }
      }
      return { ...meds, dosages: dose, fromPrev: true };
    });
  }
  return { medics, complete, completedDosages };
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
*/
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'web';
};


export const getCurrentRegiment = (regimens, notStarted) => {
  const currentRegimens = _.filter(
    regimens,
    (regimen) => {
      if (!notStarted) return regimen.status === REGIMEN_STATUS.active;
      return [
        REGIMEN_STATUS.active,
        REGIMEN_STATUS.notStarted,
        REGIMEN_STATUS.updated,
      ].includes(regimen.status);
    },
  );
  return _.first(currentRegimens);
};

export const toDataURL = (url) => new Promise((resolve) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.setRequestHeader('Cache-Control', 'no-cache');
  xhr.responseType = 'blob';
  xhr.send();
});

export const downloadPDF = (pagebreak, fileName) => {
  const element = document.querySelector('#reportPDF');
  const opt = {
    margin: 0.5,
    filename: `${fileName || 'report'}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
    pagebreak: pagebreak || { avoid: ['p'] },
  };

  html2pdf().set(opt).from(element).save();
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};


export const detectIEEdge = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return Boolean(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10));
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return Boolean(parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10));
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge => return version number
    return Boolean(parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10));
  }

  // other browser
  return false;
};


export const dosageTimes = refDataService.getList(REFERENCE_DATA_TYPES.dosageTimePeriod);

export const makeTimeItem = (id, index) => ({
  index,
  timePeriod: {
    id,
    value: refDataService.getValue(
      REFERENCE_DATA_TYPES.dosageTimePeriod,
      id,
    ),
  },
});

export const makeTimeItems = (list) => _.map(list, makeTimeItem);

export const preFillTimesOnSelection = (value) => {
  let times;
  const FREQUENCIES = _.range(1, TITRATION_MAX_FREQUENCY + 1);
  switch (value) {
    case 1:
      times = makeTimeItems([FREQUENCIES[0]]);
      break;
    case 2:
      times = makeTimeItems([FREQUENCIES[0], FREQUENCIES[2]]);
      break;
    case 3:
      times = makeTimeItems([FREQUENCIES[0], FREQUENCIES[1], FREQUENCIES[2]]);
      break;
    case 4:
      times = makeTimeItems(FREQUENCIES);
      break;
    default:
      break;
  }
  return times;
};

export const getTitrationBuilderDuration = (builderState, index) => {
  if (builderState.maintenanceDose && index === builderState.doses - 1) {
    return 365;
  }
  return 7;
};


export const numberWithCommas = (x) => x.toLocaleString('en-US');

export const mapSmartTitrationToState = (response) => ({
  startDose: response.startDose,
  targetDose: response.targetDose,
  doses: response.doses,
  unit: response.unit,
  frequency: isArray(response.frequency) ? size(response.frequency) : response.frequency,
  isChild: response.patientType === 1,
  maintenanceDose: false,
  weight: response.patientType === 1 ? response.weight : 0,
  offset: response.offset,
  medicationType: response.medicationType === 1 ? 'pill' : 'suspension',
  medicationUnit: response.medicationUnit,
  availableDose: response.availableDose,
  availableDoses: response.availableDoses,
});

export const mapSmartTitrationToParams = (state) => (omit({
  medicationUnit: state.unit,
  startingDose: state.startDose,
  targetDose: state.targetDose,
  dosesNumber: state.doses,
  patientType: state.isChild ? 1 : 2,
  frequency: state.frequency,
  weight: state.isChild ? state.weight : null,
  availableDose: state.availableDose || null,
}, ['maintenanceDose', 'isChild']));

export const getPatientType = (isChild) => (isChild ? 1 : 2);


export const titrationMedsMigrate = (medications) => medications.map((med) => ({
  ...med,
  dosages: med.dosages.map((d) => ({
    ...d,
    hasCustomDosage: true,
    quantity: d.hasCustomDosage ? d.quantity : ceil(sum(d.individualQuantities), 2),
  })),
}));
