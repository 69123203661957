/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';
import KnButton from 'components/Button';
import palette from 'styles/colors';

const buttonIconColor = palette.aquaBlue;

const KnStyledButton = withStyles({
  label: {
    display: 'contents',
  },
})(KnButton);

const KnIconButton = ({
  Icon, iconColor, tooltip, tooltipText, ...rest
}) => (
  <KnStyledButton
    variant="text"
    size="small"
    {...rest}
  >
    {tooltip ? (
      <Tooltip title={tooltipText} placement="top" arrow>
        <span>
          <Icon color={iconColor} />
        </span>
      </Tooltip>
    ) : <Icon color={iconColor} />}
  </KnStyledButton>
);

KnIconButton.propTypes = {
  Icon: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

KnIconButton.defaultProps = {
  iconColor: buttonIconColor,
  tooltip: false,
  tooltipText: '',
};

export default KnIconButton;
