/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { combineReducers } from 'redux';
import ACTION_TYPES from 'redux/actionTypes';
import appReducer from './appReducer';
import loginReducer from './loginReducer';
import healthSystemReducer from './healthSystemReducer';
import registerReducer from './registerReducer';
import patientsListReducer from './patientsListReducer';
import patientRecordReducer from './patientRecordReducer';
import userReducer from './userReducer';
import userRoleReducer from './userRoleReducer';
import faqReducer from './faqReducer';
import titrationsListReducer from './titrationsListReducer';
import thresholdsReducer from './thresholdsReducer';
import surveysListReducer from './surveysListReducer';
import calendarReducer from './calendarReducer';
import titrationBuilder from './titrationBuilder';

/** Combining all reducers into one to form the global state of our app */
const globalReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  healthSystem: healthSystemReducer,
  register: registerReducer,
  patientsList: patientsListReducer,
  patientRecord: patientRecordReducer,
  user: userReducer,
  userRole: userRoleReducer,
  faq: faqReducer,
  titrationsList: titrationsListReducer,
  thresholds: thresholdsReducer,
  surveysList: surveysListReducer,
  calendar: calendarReducer,
  titrationBuilder,
});

const rootReducer = (state, action) => {
  /**
   * When a user is logged out, we call the globalReducer
   * with undefined state which will reset all our reducers
   * to their initial state, leaving no cached data.
   */
  if (action.type === ACTION_TYPES.LOGGED_OUT) {
    return globalReducer(undefined, action);
  }

  return globalReducer(state, action);
};

export default rootReducer;
