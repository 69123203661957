/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KnHamburgerIcon from 'components/icons/HamburgerIcon';
import { KnContrastTextField } from 'components/TextField';
import palette from 'styles/colors';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Typography, withStyles, ExpansionPanelSummary } from '@material-ui/core';

const reorderDosageIcon = palette.secondary;

export const KnTitrationBuilderFieldsBox = styled(Box)({
  '& > *': {
    width: 398,
  },
});

export const KnTitrationNameInput = styled(KnContrastTextField)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

export const KnMedicationFieldsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(1),

  '& > *': {
    width: 105,
    marginRight: theme.spacing(1),
    paddingBottom: 0,
  },

  '& > :first-child': {
    width: 145,
  },

  '& > :nth-child(3)': {
    width: 150,
  },
}));

export const KnTimesFieldsBox = styled(KnMedicationFieldsBox)({
  '& > .MuiBox-root': {
    width: 111,
  },
});

export const KnTimesDosagesBox = styled(Box)(({ theme }) => ({
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

export const KnCustomDosageFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  position: 'absolute',
  top: -27,
  right: 0,
  justifyContent: 'flex-end',
  '& .MuiCheckbox-colorSecondary': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(0.5),
  },
}));

export const KnReorderDosageIcon = styled(KnHamburgerIcon)({
  position: 'absolute',
  top: 16,
  right: 16,
  fill: reorderDosageIcon,
});

/** Styles for the Titrations (list) page */
export const KnTitrationNameContainer = styled('div')({
  width: 230,
});

export const KnTitrationMedsContainer = styled('div')(({ theme }) => ({
  maxWidth: 462,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: theme.spacing(3),
  '& *:last-child': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const KnTitrationActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  '& :not(:last-child)': {
    marginRight: theme.spacing(4),
  },
}));

export const KnTitrationSubtleText = styled('span')({
  color: palette.plainGrey,
});

export const KnTitrationMedsText = styled('span')(({ theme }) => ({
  color: palette.black.black3,
  whiteSpace: 'nowrap',
  paddingRight: theme.spacing(2),
}));

export const KnTitrationExpansionPanelSummary = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 3, 4),
    borderBottomWidth: 0,

  },
  expandIcon: {
    alignSelf: 'baseline',
    marginTop: theme.spacing(1),

  },
}))(ExpansionPanelSummary);

export const KnTitrationExpansionPanelDetails = withStyles((theme) => ({
  root: {
    borderBottomColor: (props) => props.color,
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(0.5),
    padding: theme.spacing(0, 4, 2, 4),
  },
}))(ExpansionPanelDetails);


export const KnTitrationORText = styled(Typography)(({ theme }) => ({
  width: 70,
  fontSize: 20,
  margin: `${theme.spacing(2)}px 0`,
  textAlign: 'center',
  textTransform: 'capitalize',
}));

export const KnMedicationIndexBox = styled(Box)({
  position: 'absolute',
  top: 25,
  left: 15,
  width: 40,
  textAlign: 'center',
});

export const KnTitrationIndexBorder = styled('span')(({ color }) => ({
  width: '100%',
  height: 10,
  background: `${color}`,
  borderRadius: 5,
}));

export const KnMedicationPanelSummary = styled(ExpansionPanelSummary)({
  border: 'none',
  '& .MuiExpansionPanelSummary-expandIcon': {
    position: 'absolute',
    top: 15,
    right: 31,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});
