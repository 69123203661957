/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import KnPatientRecordBox from 'features/patient/styles';
import PropTypes, { bool, func } from 'prop-types';
import palette from 'styles/colors';

const { plainGrey, sealBlue, greenBlue } = palette;
const black = palette.black.black1;

const KnPatientRecordBoxUpdate = styled(KnPatientRecordBox)({
  height: '150px',
  margin: '24px 0 24px 1px',
  padding: '18px 32.3px 22px 32px',
  border: 'solid 10px #ffffff',
  backgroundColor: sealBlue,
});

const KnBox = styled(Box)({
  transform: 'scale(1.5)',
  position: 'absolute',
  right: '13%',
  marginTop: '65px',
});

const divStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.2',
  letterSpacing: '0.5px',
  textAlign: 'left',
  color: black,
};

const KnDivStyle = styled('div')({
  ...divStyle,
});

const spanStyle = {
  ...divStyle,
  fontSize: '17px',
  fontWeight: 'normal',
  lineHeight: '1.29',
  letterSpacing: 'normal',
  color: greenBlue,
};

const KnSpan = styled('span')({
  ...spanStyle,
  marginLeft: '-140px',
  marginTop: '35px',
});

const KnSpan1 = styled('span')({
  ...spanStyle,
  marginLeft: '-409px',
  marginTop: '55px',
});

const KnSpan2 = styled('span')({
  ...spanStyle,
  marginLeft: '-618px',
  marginTop: '75px',
});

const SpanStyle = styled('span')({
  ...spanStyle,
  width: '105px',
  height: '17.1px',
  flexGrow: 0,
  margin: '0 0 0 -3.7px',
  fontSize: '10px',
  color: plainGrey,
});

const KnDisclaimer = (props) => {
  const { showDisclaimer, setShowDisclaimer } = props;
  const { t: translate } = useTranslation();

  return (
    !showDisclaimer ? (
      <KnPatientRecordBoxUpdate
        flexDirection="row"
        display="flex"
        mb={2}
      >
        <>
          <KnDivStyle>
            {translate('TITRATIONS.disclaimer')}
            :
          </KnDivStyle>
          <Box display="flex" mb={2}>
            <KnSpan>
              1-
              {' '}
              {translate('TITRATIONS.disclaimerFollowUp.first')}

            </KnSpan>
            <KnSpan1>
              2-
              {' '}
              {translate('TITRATIONS.disclaimerFollowUp.second')}

            </KnSpan1>
            <KnSpan2>
              3-
              {' '}
              {translate('TITRATIONS.disclaimerFollowUp.third')}
            </KnSpan2>
            <KnBox>
              <Checkbox
                style={{ color: plainGrey }}
                checked={showDisclaimer}
                onChange={(e, v) => {
                  setShowDisclaimer(v);
                }}
                inputProps={{ 'data-testid': `custom-dosage-checkbox-${1 + 1}` }}
              />
              <SpanStyle>
                {translate('TITRATIONS.disclaimerDontShow')}
              </SpanStyle>
            </KnBox>
          </Box>
        </>

      </KnPatientRecordBoxUpdate>
    ) : null

  );
};

KnDisclaimer.defaultProps = {
  showDisclaimer: bool,
  setShowDisclaimer: func,
};

KnDisclaimer.propTypes = {
  showDisclaimer: PropTypes.bool,
  setShowDisclaimer: PropTypes.func,
};

export default KnDisclaimer;
