/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';

const KnSwitcherIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 20V0H4.44444V20H0ZM6.66667 20V0H11.1111V20H6.66667ZM13.3333 20V0H17.7778V20H13.3333Z" fill="#6CB7C6" />
  </svg>
);

export default KnSwitcherIcon;
