/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import KnPageContent, { KnBrightBox } from 'components/Content';
import { KnBoldSectionHeader } from 'components/Typography';

const AppRedirectPage = () => (
  <KnPageContent>
    <Typography gutterBottom variant="h4" component={KnBoldSectionHeader} />
    <KnBrightBox display="flex" flexDirection="column" flex="1" p={2} />
  </KnPageContent>
);

export default AppRedirectPage;
