import React, { useState } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, IconButton, styled, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { KnSectionHeader, KnSmallLabel } from '../../components/Typography';
import { KnMetricsTitle, KnMetricsSmallLabel } from './styles';
import { withKeyNamespace } from '../../utils/utils';
import palette from '../../styles/colors';
import KnButton from '../../components/Button';
import KnPlayIcon from '../../components/icons/PlayIcon';
import KnLink from '../../components/Link';
import KnMediaIcon from '../../components/icons/MediaIcon';

const i18nKey = withKeyNamespace('PATIENT_RECORD.patientMedia');

const KnLinkDialog = styled(KnLink)({
  fontSize: 12,
  textDecoration: 'none',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
});

const KnMediaBox = styled(Box)({
  boxShadow: '4px 4px 15px 0 rgb(89 89 89 / 12%)',
});

const KnMedia = styled('div')(({ url, isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 118,
  width: '100%',
  backgroundImage: `url(${url})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  border: `2px solid ${isActive ? palette.primary : 'transparent'}`,
}));

const KnSelectedBox = styled(Box)(() => ({
  width: 520,
  margin: '0 auto',
}));

const StyledDivider = styled(Divider)(() => ({
  marginTop: 20,
}));

const KnMediaLabel = ({ title, date }) => (
  <Typography component={KnSmallLabel}>
    <b style={{ textTransform: 'capitalize' }}>{title}</b>
    {date && `: ${format(Date.parse(date), 'MM/dd/yyyy')}`}
  </Typography>
);

KnMediaLabel.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

const KnMediaItem = ({
  handleSelectVideo, thumbnailUrl, seizureDate, isActive,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Box display="inline-block" mb={1} mx={1} width="23%">
      <KnMedia url={thumbnailUrl} isActive={isActive}>
        <IconButton onClick={handleSelectVideo}>
          <KnPlayIcon />
        </IconButton>
      </KnMedia>
      <KnMediaLabel title={translate(i18nKey('seizureTitle'))} date={seizureDate} />
    </Box>
  );
};

KnMediaItem.propTypes = {
  handleSelectVideo: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  seizureDate: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const KnMediaSelected = ({ videoUrl, seizureDate }) => {
  const { t: translate } = useTranslation();
  return (
    <KnSelectedBox>
      <Box boxShadow={4} display="inline-flex" width={520} borderRadius={5} overflow="hidden" bgcolor={palette.black.black2}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls width="100%" height={286} autoPlay>
          <source src={videoUrl} />
        </video>
      </Box>
      <KnMediaLabel title={translate(i18nKey('seizureTitle'))} date={seizureDate} />
    </KnSelectedBox>
  );
};

KnMediaSelected.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  seizureDate: PropTypes.string.isRequired,
};

const KnMediaDialog = ({ open, onClose }) => {
  const { attachments } = useSelector((state) => state.patientRecord);
  const { t: translate } = useTranslation();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle>
        {translate(i18nKey('title'))}
        <StyledDivider />
      </DialogTitle>
      <Box px={3} pb={2}>
        <KnMediaSelected
          key={attachments[selectedVideoIndex].id}
          {...attachments[selectedVideoIndex]}
        />
        <StyledDivider />
      </Box>
      <DialogContent>
        <Box>
          {attachments.map((item, index) => (
            <KnMediaItem
              {...item}
              key={item.id}
              isActive={selectedVideoIndex === index}
              handleSelectVideo={() => setSelectedVideoIndex(index)}
            />
          ))}
        </Box>
      </DialogContent>
      <Box px={3}><Divider /></Box>
      <DialogActions>
        <KnButton onClick={onClose} style={{ width: 350, margin: '0 auto' }}>
          Close
        </KnButton>
      </DialogActions>
    </Dialog>
  );
};

KnMediaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const KnPatientMedia = () => {
  const { t: translate } = useTranslation();
  const { attachments } = useSelector((state) => state.patientRecord);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => setIsDialogOpen(true);

  return (
    <Box my={2}>
      <Typography variant="h6" component={KnSectionHeader}>
        {translate(i18nKey('title'))}
      </Typography>
      <KnMediaBox p={2} mt={2} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography component={KnMetricsTitle}>{translate(i18nKey('title'))}</Typography>
          </Box>
          <Box>
            <KnLinkDialog onClick={handleOpenDialog}>{translate(i18nKey('mediaLibrary'))}</KnLinkDialog>
            {isDialogOpen && (
              <KnMediaDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography component={KnMetricsSmallLabel} style={{ marginRight: 6 }}>
            {`${translate(i18nKey('recent'))}: `}
          </Typography>
          <KnLinkDialog onClick={handleOpenDialog}>
            <span style={{ marginRight: 10, textTransform: 'capitalize' }}>
              {attachments[0].seizureDate ? translate(i18nKey('seizure'), {
                date: format(new Date(attachments[0].seizureDate), 'MM/dd/yyyy'),
              }) : translate(i18nKey('seizureTitle'))}
            </span>
            <KnMediaIcon />
          </KnLinkDialog>
        </Box>
      </KnMediaBox>
    </Box>
  );
};

export default KnPatientMedia;
