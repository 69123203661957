import { filter } from 'lodash';
import { format, isAfter, isBefore } from 'date-fns';
import { REGIMEN_STATUS } from 'Constants';

const filterPastRegiment = (regimens, dateRange) => filter(
  regimens,
  (regimen) => {
    const isPastMedication = (
      regimen.status === REGIMEN_STATUS.deactivated
    || regimen.status === REGIMEN_STATUS.completed
    || regimen.status === REGIMEN_STATUS.updated
    );

    if (!dateRange) {
      return isPastMedication;
    }

    return (
      isPastMedication && (
        (
          dateRange && isBefore(new Date(regimen.titrationUpdateDate ? format(new Date(regimen.titrationUpdateDate), 'MM/dd/yyyy') : regimen.deactivatedAt || regimen.completedAt), new Date(dateRange.rangeEnd))
        && isAfter(new Date(regimen.startedAt), new Date(dateRange.rangeStart))
        ) || (
          dateRange && !regimen.startedAt && isBefore(new Date(regimen.assignedAt),
            new Date(dateRange.rangeEnd)) && isAfter(
            new Date(regimen.assignedAt), new Date(dateRange.rangeStart),
          )))
    );
  },
);

export default filterPastRegiment;
