/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import KnIconWrapper from './styles';

const KnUnlinkIcon = (props) => {
  const {
    color,
    ...rest
  } = props;

  return (
    <KnIconWrapper fontSize={16}>
      <SvgIcon
        fontSize="inherit"
        viewBox="0 -1 15 14"
        fill="none"
        {...rest}
      >
        <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M2.3325 3.39L0 1.0575L1.0575 0L13.6125 12.555L12.555 13.6125L9.5475 10.605H8.25V9.3075L6.5475 7.605H4.5V6.105H5.0475L3.495 4.5525C2.3325 4.68 1.425 5.6625 1.425 6.855C1.425 8.1375 2.4675 9.18 3.75 9.18H6.75V10.605H3.75C1.68 10.605 0 8.925 0 6.855C0 5.2875 0.9675 3.945 2.3325 3.39ZM11.2498 3.10638H8.2498V4.53138H11.2498C12.5323 4.53138 13.5748 5.57388 13.5748 6.85638C13.5748 7.80888 12.9973 8.63388 12.1723 8.98638L13.2223 10.0364C14.2873 9.37638 14.9998 8.19888 14.9998 6.85638C14.9998 4.78638 13.3198 3.10638 11.2498 3.10638ZM9.29258 6.10445L10.5001 7.31195V6.10445H9.29258Z" />
      </SvgIcon>
    </KnIconWrapper>
  );
};

KnUnlinkIcon.propTypes = {
  color: PropTypes.string,
};

KnUnlinkIcon.defaultProps = {
  color: '#666',
};

export default KnUnlinkIcon;
