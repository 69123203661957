/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import KnDisplayContent from 'components/DisplayContent';
import loginActions from 'redux/actions/loginActions';
import KnValidatedTextField from 'components/ValidatedTextField';
import { styled } from '@material-ui/core/styles';
import mobilePhoneLogo from '../../assets/images/04062021_text_message_140px.svg';

const initialMFAInfo = {
  confirmationCode: '',
};

const KnInLine = styled('div')({
  display: 'flex',
});

/**
 * UI Component for handling MFA code verification step
 */
const MFAStep = ({
  submitDisabled, stepsData, onReset, onSuccess,
}) => {
  const { t: translate } = useTranslation();
  const { control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: initialMFAInfo,
    reValidateMode: 'onChange',
  });
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const object = translate('REGISTER.cannotFindCode_array_phone', { returnObjects: true });
    setItems(Object.values(object));
  }, [translate, setItems]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the confirmSignIn call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(loginActions.confirmSignIn(
      stepsData.signInResult,
      formData.confirmationCode,
    ))
      .then((response) => {
        if (!response.error) {
          /** Sign in confirmation was successful */
          onSuccess(response);
        }
      });
  };

  const onCancelLogin = useCallback(() => {
    history.push('/');
    onReset();
  }, [onReset, history]);

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('REGISTER.verificationTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={2} fontSize={17}>
          <Trans
            i18nKey="LOGIN.confirmationCodeHint" // optional -> fallbacks to defaults if not provided
            values={{ number: stepsData.phone }}
            components={<u>univers</u>}
          />
        </Box>
        <KnInLine>
          <img src={mobilePhoneLogo} width="84" height="55" alt="" />
          <KnValidatedTextField
            Component={KnTextField}
            autoFocus
            name="confirmationCode"
            control={control}
            errors={errors}
            defaultValue=""
            required
            trimSpaces
            maxLength={256}
            autoComplete="off"
          />
        </KnInLine>
        <Box marginBottom={2}>
          <KnDisplayContent label={translate('REGISTER.cannotFindCode')} items={items} mailed="mobile" />
        </Box>
        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            style={{ fontSize: '20px' }}
            data-testid="cancel-login-link"
            onClick={onCancelLogin}
          >
            {translate('GENERAL.cancelButton')}
          </KnButton>
          <KnButton
            disabled={submitDisabled}
            data-testid="login-button"
          >
            {translate('LOGIN.submitButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

MFAStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  stepsData: PropTypes.shape().isRequired,
  onReset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MFAStep;
