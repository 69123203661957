import React from 'react';
import { Box } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PatientTextCopy from './PatientTextCopy';

const PatientDetails = (props) => {
  const { t: translate } = useTranslation();
  const { appointment } = props;
  return (
    <Box pt={3}>
      <PatientTextCopy
        value={get(appointment, 'extendedProps.email')}
        label={translate('ADMIN_CALENDAR.patientEmil')}
      />
      <PatientTextCopy
        value={get(appointment, 'extendedProps.additionalEmail')}
        label={translate('ADMIN_CALENDAR.alternateEmail')}
      />
      <PatientTextCopy
        value={get(appointment, 'extendedProps.phoneNumber')}
        label={translate('ADMIN_CALENDAR.phone')}
      />
    </Box>
  );
};

PatientDetails.propTypes = {
  appointment: PropTypes.shape(),
};

PatientDetails.defaultProps = {
  appointment: {},
};

export default PatientDetails;
