/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Box from '@material-ui/core/Box';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import KnButton from 'components/Button';
import { getStatusColor } from 'features/patient/RegimenStatus';
import { TITRATIONS_CONTEXT, MedicationColors } from 'Constants';
import palette from 'styles/colors';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { KnBoldSectionHeader } from 'components/Typography';
import KnTitrationInfo from './item-components/TitrationItemInfo';
import {
  KnTitrationExpansionPanelSummary,
  KnTitrationExpansionPanelDetails,
} from './styles';
import KnCollaspedTable from './CollaspedTable';
import { KnMedicationPanelSummaryUpdate } from '../../features/patient/styles';

const expandIconColor = palette.primary;
const { plainGrey } = palette;

const KnMedicationIndexBoxUpdate = styled(Box)({
  position: 'absolute',
  top: 25,
  left: 15,
  width: 40,
  textAlign: 'center',
});

const KnTitrationIndexBorderUpdate = styled('span')(({ color }) => ({
  width: '100%',
  height: 10,
  background: `${color}`,
  borderRadius: 5,
}));

const KnCollaspedTableViewUpdate = styled(Typography)({
  marginLeft: '65px',
  marginTop: '-5px',
  color: plainGrey,
});

/**
 * Generic component, used to show one titration item
 * Uses KnTitrationInfo and KnMedicationInfo components to show the titration
 */
const KnTitrationItem = (props) => {
  const {
    titrationData,
    context,
    actions,
    alwaysExpanded,
  } = props;
  const { t: translate } = useTranslation();

  const [expand, setExpand] = useState(false);

  /** This will add the 'expanded' prop to the ExpansionPanel,
   * if alwaysExpanded is set to `true`
   */
  const expandedProp = { expanded: alwaysExpanded || undefined };

  const KnMedicationIndexBoxUpdateCh = (medication, index) => (
    <>
      <KnMedicationIndexBoxUpdate style={{ left: '-10px' }} display="flex" flexDirection="column" color={MedicationColors[index]}>
        <Typography variant="h5" component={KnBoldSectionHeader}>
          {index + 1}
        </Typography>
        <KnTitrationIndexBorderUpdate color={MedicationColors[index]} />
      </KnMedicationIndexBoxUpdate>
      <Box display="flex" flexDirection="column" pt={2} pl={8} pr={6}>
        <Typography variant="h6" component={KnBoldSectionHeader}>
          {`${medication.activeIngredient} (${medication.name})`}
        </Typography>
      </Box>
    </>
  );

  return (
    <Box mb={1.5}>
      <ExpansionPanel
        square
        elevation={10}
        {...expandedProp}
        data-testid="titration-item"
        onChange={(event, expanded) => setExpand(expanded)}
      >
        <KnTitrationExpansionPanelSummary
          expandIcon={!alwaysExpanded && <KnArrowDownIcon color={expandIconColor} />}
        >
          <KnTitrationInfo
            context={context}
            titration={titrationData}
            actions={actions}
            expanded={expand}
          />
        </KnTitrationExpansionPanelSummary>
        <KnTitrationExpansionPanelDetails color={getStatusColor(titrationData.status)}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {titrationData.medications.map((medication, index) => (
                <div key={medication.id}>
                  {/* <ExpansionPanel
                    defaultExpanded={false}
                    key={medication.id}
                  > */}
                  <KnMedicationPanelSummaryUpdate
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    {KnMedicationIndexBoxUpdateCh(medication, index)}

                    <KnCollaspedTableViewUpdate />
                  </KnMedicationPanelSummaryUpdate>
                  {/* <ExpansionPanelDetails> */}
                  <Box>
                    <Box pt={2}>
                      <KnCollaspedTable medication={medication} />
                    </Box>
                  </Box>
                  {/* </ExpansionPanelDetails> */}
                  {/* </ExpansionPanel> */}

                </div>
              ))}
            </Box>
            {context === TITRATIONS_CONTEXT.assignPreset
              && (
                <Box alignSelf="flex-end" paddingBottom={2.5}>
                  <KnButton
                    data-testid="assign-preset-button"
                    type="button"
                    onClick={() => actions.onAssignPreset(titrationData)}
                  >
                    {translate('ASSIGN_REGIMEN.assignPresetButton')}
                  </KnButton>
                </Box>
              )}
          </Box>
        </KnTitrationExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

KnTitrationItem.propTypes = {
  titrationData: PropTypes.shape().isRequired,
  context: PropTypes.oneOf(
    [TITRATIONS_CONTEXT.assignPreset, TITRATIONS_CONTEXT.titrationsManagement,
      TITRATIONS_CONTEXT.assignedRegimen, TITRATIONS_CONTEXT.pastRegimen],
  ).isRequired,
  actions: PropTypes.shape(),
  alwaysExpanded: PropTypes.bool,
};

KnTitrationItem.defaultProps = {
  actions: {},
  alwaysExpanded: false,
};

export default KnTitrationItem;
