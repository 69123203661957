/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnDeteriorationIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#D44420" d="M3 9l1.058 1.057L8.25 5.872V15h1.5V5.872l4.185 4.193L15 9 9 3 3 9z" />
  </SvgIcon>
);

export default KnDeteriorationIcon;
