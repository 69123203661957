/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import _ from 'lodash';
import {
  addDays, format, subDays, differenceInDays,
} from 'date-fns';
import React, {
  useRef, useLayoutEffect, useMemo, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, create, Label, percent, useTheme,
} from '@amcharts/amcharts4/core';
import {
  XYChart, DateAxis,
} from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { useTranslation } from 'react-i18next';
import { getCompletedDosages, withKeyNamespace } from 'utils/utils';
import {
  GANTT_CHART_DEFAULAT_TIME_RANGE, GANTT_CHART_ZOOM_TIME_RANGE, MedicationColors, REGIMEN_STATUS,
} from 'Constants';
import { KnSubtleText } from 'components/Typography';
import palette from 'styles/colors';
import { KnGanntChartDateRange, KnGanttChartContainer, styleChartScroll } from './styles';
import {
  commonData,
  commonCofig,
  addRange,
  generateDosages,
  getActiveMedIds,
  getPatientAcceptanceData,
} from './GanttDayChart';
import { getCombinedTimeRange } from '../../../utils/metrics';

const MEDICATION_CONST = 1000;
const DATE_FORMAT = 'MM/dd/yyyy';

const i18nKey = withKeyNamespace('ASSIGN_REGIMEN');

const resetDate = (date) => {
  const d = new Date(format(new Date(date), DATE_FORMAT));
  d.setHours(0, 0, 0, 0);
  return d;
};

const GanttCalendarChart = ({
  chartId, titration, pastRegimens, assignedRegimen,
  isEdit, deletedMedications, hasDateSelector, selectedRange,
}) => {
  const chartRef = useRef(null);
  let dateAxis;
  const previousTitrationIds = _.get(titration, 'parentTitrations', []);
  const previousTitration = pastRegimens
    .filter((p) => previousTitrationIds.includes(p.id))
    .reverse();

  const pTitration = previousTitration.find((r) => r.id === titration.parentTitrationId);
  const { completedDosages } = getCompletedDosages(
    titration.medications, pTitration, titration.startedAt, true, assignedRegimen,
  );
  const [completedDosageKeys] = useState(completedDosages);
  const { t: translate } = useTranslation();
  const [graphData, setGraphData] = React.useState([]);
  const [categoryAxis, setCategoryAxis] = React.useState(null);
  const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
  let startedAt = _.get(previousTitration[0], 'activatedAt') || _.get(previousTitration[0], 'startedAt') || _.get(titration, 'startedAt');
  startedAt = subDays(startedAt, 0);
  const formatDate = (date) => format(date, DATE_FORMAT);

  /**
     * Generate chart data for given titration.
     * @param {Object} t
     * @param {String} color
     * @returns
     */
  const getChartData = useCallback((t, color) => {
    const medications = _.get(t, 'medications', []);
    const startedAtDate = resetDate(_.get(t, 'startedAt') || addDays(_.get(t, 'assignedAt'), 1));

    // on first time edit show the completed dosages in grey.
    let editOffset = 0;
    if (_.get(t, 'startedAt') && !color && isEdit) {
      editOffset = differenceInDays(resetDate(new Date()), resetDate(new Date(_.get(t, 'startedAt')))) + 1;
    }

    let titrationUpdateDate = resetDate(_.get(t, 'patientStartedTitrationOn', addDays(new Date(), 0)));
    if (t.status !== REGIMEN_STATUS.active) {
      titrationUpdateDate = resetDate(_.get(t, 'titrationUpdateDate', addDays(new Date(), 1)));
    }

    const chartData = _.flatten(medications.map((medication) => {
      let startDate = addDays(startedAtDate, (medication.offset || 0) + editOffset);
      let dosages;
      dosages = _.compact(medication.dosages.map((d, dosageIndex) => {
        if (!color && isEdit && (d.complete || completedDosageKeys.includes(d.keyId)) && !d.new) {
          return null;
        }
        let daysDiff = 0;
        let duration = d.duration ? d.duration : 1;
        let data = {};
        if (color) {
          daysDiff = differenceInDays(new Date(titrationUpdateDate), new Date(startDate)) + 1;
          duration = duration > daysDiff ? daysDiff : duration;
        }
        if (!color || (color && daysDiff > 0)) {
          data = {
            from: formatDate(resetDate(startDate)),
            to: formatDate(resetDate(addDays(startDate, duration))),
            ...commonData(medication, d, medication.index, dosageIndex, true, color),
            ...getPatientAcceptanceData(d, medication.unit, startDate),
            id: medication.id + MEDICATION_CONST,
            medId: medication.id + MEDICATION_CONST,
            index: medication.index,
            dosageKey: d.keyId,
          };
          if (color) {
            data.color = color;
          }
        }
        startDate = addDays(startDate, d.duration);
        return data.from ? data : null;
      }));

      dosages = generateDosages(dosages);
      return dosages;
    }));
    return chartData;
  }, [completedDosageKeys, isEdit]);

  /**
   * Generate Gantt chart data
   */
  const ganttChartData = useMemo(() => {
    // Parent titration dosages data
    const data = _.flatten(
      previousTitration.map((t) => getChartData(t, palette.paleGrey.paleGrey6)),
    );
    // on first time edit show the completed dosages in grey
    let currentTitrationCompleted = [];
    if (isEdit && _.keys(assignedRegimen).length) {
      currentTitrationCompleted = getChartData(assignedRegimen, palette.paleGrey.paleGrey6);
    }
    let currentTitrationData = [];
    // filter out the completed dosages.
    if (titration.status === REGIMEN_STATUS.active && isEdit) {
      const parentTitration = previousTitration.find((r) => r.id === titration.parentTitrationId);
      const { medics } = getCompletedDosages(
        titration.medications, parentTitration, titration.startedAt, true, assignedRegimen,
      );
      currentTitrationData = getChartData({ ...titration, medications: medics });
    } else {
      currentTitrationData = getChartData(titration);
    }

    // total titration dosages data
    const dosagesData = [...data, ...currentTitrationCompleted, ...currentTitrationData];

    const medicationIds = _.uniq(dosagesData.map((d) => d.id));
    const currentMedIds = _.get(titration, 'medications', []).map((m) => m.id + MEDICATION_CONST);

    const dosageGroup = _.groupBy(dosagesData, 'id');
    let currentMeds = [];
    let completedAndDeletedMeds = [];
    _.each(medicationIds, (mId) => {
      const meds = dosageGroup[mId];
      if (meds.every((m) => m.color === palette.paleGrey.paleGrey6)
        && !currentMedIds.includes(mId)
      ) {
        completedAndDeletedMeds = _.concat(completedAndDeletedMeds, meds);
      } else {
        currentMeds = _.concat(currentMeds, meds);
      }
    });

    // arrange base on current titration medications.
    const currentDosageGroup = _.groupBy(currentMeds, 'id');
    currentMeds = [];
    _.each(currentMedIds, (mId) => {
      const meds = currentDosageGroup[mId];
      currentMeds = _.concat(currentMeds, meds);
    });

    const currentDosages = _.compact(_.concat(currentMeds, completedAndDeletedMeds));

    // Add colors to medication base on there index.
    currentDosages.forEach((d) => {
      if (!d.color) {
        _.set(d, 'color', MedicationColors[currentMedIds.indexOf(d.id)]);
        _.set(d, 'labelColor', MedicationColors[currentMedIds.indexOf(d.id)]);
      } else if (currentMedIds.includes(d.id)) {
        _.set(d, 'labelColor', MedicationColors[currentMedIds.indexOf(d.id)]);
      } else {
        _.set(d, 'labelColor', palette.paleGrey.paleGrey6);
        _.set(d, 'toolTipHTML', `<strong style="color:#252525;">DELETED/INACTIVE</strong><br/> ${d.toolTipHTML}`);
      }
    });

    // Arrange deleted medication base on last deleted at bottom
    const currDosages = currentDosages.filter(
      (d) => !deletedMedications.includes(d.id) || currentMedIds.includes(d.id),
    );
    const inactiveDosages = currentDosages.filter(
      (d) => deletedMedications.includes(d.id - MEDICATION_CONST) && !currentMedIds.includes(d.id),
    );

    let delDosages = [];
    deletedMedications.forEach((d) => {
      if (!currentMedIds.includes(d + MEDICATION_CONST)) {
        delDosages = [
          ...delDosages,
          ...inactiveDosages.filter((d1) => d1.id - MEDICATION_CONST === d)];
      }
    });
    return [...currDosages, ...delDosages];
  }, [previousTitration, titration, assignedRegimen, isEdit, deletedMedications, getChartData]);

  // Assign theme to amchart
  useTheme(am4themesAnimated);

  const onRangeChangeEnded = useCallback(({ target }) => {
    const minZoomed = new Date(target.minZoomed);
    const maxZoomed = new Date(target.maxZoomed);
    setDateRange({
      startDate: minZoomed,
      endDate: maxZoomed,
    });
  }, [setDateRange]);

  const createChart = () => {
    const chart = create(chartId, XYChart);
    chart.dateFormatter.inputDateFormat = DATE_FORMAT;

    chart.data = ganttChartData;

    dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.dateFormatter.dateFormat = DATE_FORMAT;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.baseInterval = { count: 1, timeUnit: 'day' };
    dateAxis.strictMinMax = true;
    dateAxis.keepSelection = true;
    dateAxis.start = 0;
    dateAxis.end = 0.07;
    dateAxis.title.text = 'Days';
    dateAxis.title.fontWeight = 'bold';

    // eslint-disable-next-line max-len
    const { series1, categoryAxis: axis } = commonCofig(chart, ganttChartData, true, titration, hasDateSelector);
    setCategoryAxis(axis);

    series1.dataFields.openDateX = 'from';
    series1.dataFields.dateX = 'to';
    series1.dataFields.categoryY = 'id';

    chartRef.current = hasDateSelector ? styleChartScroll(chart) : chart;

    /** Helper function for zooming with range change updates */
    const zoomToRange = (rangeStart, rangeEnd) => {
      dateAxis.zoomToDates(
        rangeStart,
        rangeEnd,
        true,
        true,
      );
      onRangeChangeEnded({
        target: {
          minZoomed: rangeStart,
          maxZoomed: rangeEnd,
        },
      });
    };

    if (hasDateSelector) {
      const date = new Date(startedAt);
      date.setHours(0, 0, 0, 0);
      dateAxis.min = subDays(date, 0).getTime();
      dateAxis.max = addDays(startedAt, GANTT_CHART_DEFAULAT_TIME_RANGE.days).getTime();

      // listen to update date range
      chart.events.on('ready', () => {
        const start = startedAt;
        const end = addDays(startedAt, GANTT_CHART_ZOOM_TIME_RANGE.days);
        zoomToRange(start, end);
        dateAxis.events.on('rangechangeended', onRangeChangeEnded);
      });

      chart.zoomOutButton.events.on('hit', () => {
        const start = startedAt;
        const end = addDays(startedAt, GANTT_CHART_ZOOM_TIME_RANGE.days);
        zoomToRange(start, end);
      });
    } else {
      const { startDate, endDate } = getCombinedTimeRange();
      dateAxis.min = startDate.getTime();
      dateAxis.max = endDate.getTime();
      dateAxis.rangeChangeDuration = 0;

      chart.paddingTop = 0;
      chart.paddingLeft = 20;

      const topLabelContainer = chart.createChild(Container);
      topLabelContainer.layout = 'absolute';
      topLabelContainer.toBack();
      topLabelContainer.width = percent(100);

      const topLabel = topLabelContainer.createChild(Label);
      topLabel.text = translate('TITRATIONS.titrationBuilder.medication');
      topLabel.x = 32;
      topLabel.marginBottom = 5;
      topLabel.fontFamily = 'DIN Next LT W01 Regular';
      topLabel.fill = palette.brownishGrey;
      topLabel.fontSize = 12;
      topLabel.fontWeight = 500;
    }

    chart.dateAxes = dateAxis;
  };

  useLayoutEffect(() => {
    if (!chartRef.current) {
      createChart();
    }

    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasDateSelector) {
      chartRef.current.dateAxes.zoomToDates(selectedRange.startDay, selectedRange.endDay);
    }
  }, [hasDateSelector, selectedRange.startDay, selectedRange.endDay]);

  useLayoutEffect(() => {
    if (!_.isEqual(ganttChartData, graphData)) {
      chartRef.current.data = ganttChartData;
      chartRef.current.validateData();
      chartRef.current.series.each((series) => {
        series.appear();
      });
      setGraphData(ganttChartData);
      if (categoryAxis) {
        categoryAxis.renderer.labels.template.adapter.add('textOutput', (text, target) => {
          const data = getActiveMedIds(ganttChartData, titration);
          /* eslint-disable no-underscore-dangle */
          const currentItem = target.dataItem && target.dataItem._dataContext;
          if (data.indexOf(currentItem.medId) > -1) {
            return (data.indexOf(currentItem.medId) + 1).toString();
          }
          return '-';
        });
      }
      if (dateAxis && titration.status) {
        _.each([...previousTitration, titration], ((t) => {
          if (t.status !== REGIMEN_STATUS.active) {
            if (t.titrationUpdateDate && t.status === REGIMEN_STATUS.updated) {
              const date = new Date(t.titrationUpdateDate);
              date.setHours(23, 59, 59, 999);
              addRange(dateAxis, translate('TITRATIONS.titrationBuilder.regimenUpdate',
                { date: format(date, 'MMMM, do') }), date, undefined, true);
            } else if (t.parentTitrationId && t.status === REGIMEN_STATUS.notStarted) {
              const date = new Date(t.startedAt || t.assignedAt);
              date.setHours(23, 59, 59, 999);
              addRange(dateAxis, translate('TITRATIONS.titrationBuilder.pendingApproval'), date, undefined, false);
            }
          } else if (isEdit) {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            addRange(dateAxis, translate('TITRATIONS.titrationBuilder.pendingApproval'), date, undefined, false);
          }
        }));
      }
    }
  }, [
    ganttChartData, graphData, setGraphData, titration,
    dateAxis, previousTitration, translate, categoryAxis, isEdit,
  ]);

  return (
    <>
      {hasDateSelector && (
        <KnGanntChartDateRange component={KnSubtleText}>
          {translate(
            i18nKey('selectedRange'),
            dateRange,
          )}
        </KnGanntChartDateRange>
      )}
      <KnGanttChartContainer id={chartId} datalength={_.keys(_.groupBy(ganttChartData, 'name')).length} />
    </>
  );
};

GanttCalendarChart.defaultProps = {
  pastRegimens: [],
  isEdit: false,
  assignedRegimen: {},
  deletedMedications: [],
  hasDateSelector: true,
  selectedRange: {},
};

GanttCalendarChart.propTypes = {
  titration: PropTypes.shape().isRequired,
  chartId: PropTypes.string.isRequired,
  pastRegimens: PropTypes.arrayOf(PropTypes.shape()),
  isEdit: PropTypes.bool,
  assignedRegimen: PropTypes.shape(),
  deletedMedications: PropTypes.arrayOf(PropTypes.shape()),
  hasDateSelector: PropTypes.bool,
  selectedRange: PropTypes.shape(),
};

export default GanttCalendarChart;
