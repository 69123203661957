/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';

export default {
  MuiCheckbox: {
    root: {
      color: palette.white.white4,

      '& .MuiSvgIcon-root': {
        fontSize: 14,

        '&.MuiSvgIcon-fontSizeSmall': {
          fontSize: 12,
        },
      },
    },

    colorSecondary: {
      '&.Mui-checked': {
        color: palette.slateGrey,
      },
    },
  },

  MuiFormControlLabel: {
    root: {
      width: 'auto',
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
  },
};
