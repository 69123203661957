import ACTION_TYPES from '../actionTypes';
import apiRequest from './apiActions';
import { API_REQUEST_METHODS, API_URLS } from '../../Constants';

const fetchSurveysList = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.SURVEY_LIST_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.SURVEY_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.SURVEY_LIST_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(API_REQUEST_METHODS.GET, API_URLS.surveys, null, { patientId }),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const sortSurveysList = (sortKey) => ({
  type: ACTION_TYPES.SURVEY_LIST_SORT,
  sortKey,
});

const assignSurvey = (patientId, data) => {
  const request = () => ({ type: ACTION_TYPES.SURVEY_ASSIGN_REQUEST });
  const success = () => ({ type: ACTION_TYPES.SURVEY_ASSIGN_SUCCESS });
  const failure = () => ({ type: ACTION_TYPES.SURVEY_ASSIGN_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      await dispatch(apiRequest(API_REQUEST_METHODS.POST, API_URLS.assignSurvey.replace(':patientId', patientId), data));
      dispatch(success());
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

export default {
  fetchSurveysList,
  sortSurveysList,
  assignSurvey,
};
