/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import ACTION_TYPES from 'redux/actionTypes';
import {
  API_URLS, API_REQUEST_METHODS, API_REQUEST_ERROR_CODES,
} from 'Constants';
import titrationMappings from './mappingFunctions/titrationMappings';
import appActions from './appActions';
import apiRequest from './apiActions';

const TITRATIONS_DEFAULT_SORT_KEY = 'lastUpdated';
const TITRATIONS_DEFAULT_SORT_ORDER = 'desc';

/**
 * Action to fetch the titrations list
 *
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchTitrations = (silent = true) => {
  const request = () => ({ type: ACTION_TYPES.TITRATION_LIST_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.TITRATION_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.TITRATION_LIST_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.titrations,
          null,
          null,
          { silent },
        ),
      );

      const titrations = response.data.map(titrationMappings.mapServerTitrationToLocal);
      dispatch(success(_.orderBy(
        titrations,
        [TITRATIONS_DEFAULT_SORT_KEY],
        [TITRATIONS_DEFAULT_SORT_ORDER],
      )));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/**
 * Action to delete a titration preset.
 *
 * @param {number} linkId The ID value of the titration do delete.
 */
const deleteTitration = (titrationId) => {
  const success = () => ({ type: ACTION_TYPES.TITRATION_DELETED, titrationId });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          `${API_URLS.titrations}/${titrationId}`,
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('TITRATIONS.ERROR_MESSAGES.titrationDeleteError'));
    }
  };
};

/**
 * Action to edit or create new titration preset.
 *
 * @param {any} data The titration data.
 * @param {number} [titrationId] Id of the preset, set only when this is an edit (update) action
 */
const saveTitration = (data, titrationId) => {
  const success = () => ({ type: ACTION_TYPES.TITRATION_CREATED });

  return (dispatch) => {
    const payload = titrationMappings.mapLocalTitrationToServer(data);

    const url = titrationId ? `${API_URLS.titrations}/${titrationId}` : API_URLS.titrations;
    return dispatch(
      apiRequest(
        titrationId ? API_REQUEST_METHODS.PUT : API_REQUEST_METHODS.POST,
        url,
        payload,
      ),
    ).then(() => {
      dispatch(success());
    }).catch((errorCode) => {
      /** Error for existing preset name will be handled by the titration builder itself */
      if (errorCode !== API_REQUEST_ERROR_CODES.TITRATION_PRESET_EXISTS) {
        dispatch(appActions.appPushNotification('TITRATIONS.ERROR_MESSAGES.titrationSaveError'));
      }
      return Promise.reject(errorCode);
    });
  };
};

export default {
  fetchTitrations,
  deleteTitration,
  saveTitration,
};
