/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

// import _ from 'lodash';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withKeyNamespace } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import palette from 'styles/colors';
import {
  Box, Button, Divider, styled, Typography, withStyles,
} from '@material-ui/core';
import { KnSectionHeader } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import patientActions from 'redux/actions/patientActions';
import KnEyeCloseIcon from 'components/icons/EyeCloseIcon';
import KnEyeIcon from '../../components/icons/EyeIcon';
import { KnMetricsTitle, KnWidgetBox } from './styles';

const { darkGrey, primary } = palette;

const i18nKey = withKeyNamespace('PATIENT_RECORD');

const LinkButton = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 500,
    padding: '0 8px',
    '&:hover': {
      background: 'none',
    },
  },
})(Button);

const KnTypography = styled(Typography)({
  fontSize: '14px',
  color: darkGrey,
});
const KnTypographyTitle = styled(Typography)({
  fontSize: '16px',
  color: darkGrey,
});

const KnUl = styled('ul')({
  color: darkGrey,
  margin: 0,
  paddingLeft: 20,
  maxHeight: 150,
  overflow: 'hidden',
  overflowY: 'scroll',
});

const KnLiLink = styled('li')({
  color: primary,
  cursor: 'pointer',
});

const KnTypographyLink = styled(Typography)({
  fontSize: '14px',
  cursor: 'pointer',
});

const KnSourceTitle = styled(Typography)({
  fontSize: '12px',
});

const KnLi = styled('li')({
  color: darkGrey,
});

const KnPatientRefractory = ({ onSetSurveyDetails, survey, disableButton }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { patientRefractory = {} } = useSelector((state) => state.patientRecord);

  const openOYCCare = useCallback(() => {
    onSetSurveyDetails(survey, true);
  }, [onSetSurveyDetails, survey]);

  const reviewStatusDisplay = (status) => {
    if (disableButton) return null;

    let buttonColor = 'inherit';
    let newStatus = 'unreviewed';
    let isCloseIcon = true;

    if (status === 'unreviewed') {
      buttonColor = 'primary';
      newStatus = 'reviewed';
      isCloseIcon = false;
    }

    const handleClick = (e) => {
      e.stopPropagation();
      dispatch(patientActions.setPatientReviewStatus(
        patientRefractory.id, newStatus,
      )).then(dispatch(patientActions.getPatientRefractory(patientRefractory.patientId)));
    };
    const iconStyle = { minWidth: 12, maxWidth: 12 };
    return (
      <LinkButton color={buttonColor} data-testid="review-status" onClick={handleClick}>
        {isCloseIcon
          ? <KnEyeCloseIcon style={iconStyle} />
          : <KnEyeIcon style={iconStyle} color={palette.primary} />}
        <span style={{
          marginLeft: 8,
          fontSize: 10,
          fontWeight: 'normal',
          textTransform: 'capitalize',
        }}
        >
          {status}
        </span>
      </LinkButton>
    );
  };
  const reasons = JSON.parse(patientRefractory.reasons || '{}');
  const { oycReport } = reasons;
  // eslint-disable-next-line no-prototype-builtins
  const hasSeizure = reasons.hasOwnProperty('userSeizureCount');
  const hasMeds = (reasons.userMeds || []).length > 0;
  const hasReason = oycReport || hasSeizure || hasMeds;

  return (
    <>
      <Divider />
      <Box pt={2} mb={2}>
        <Box>
          <Typography variant="h6" style={{ fontSize: '17px' }} component={KnSectionHeader}>
            {translate(i18nKey('patientRefractory.title'))}
          </Typography>
          <KnWidgetBox pl={1} mt={2} display="block">

            <Box pt={1} mb={2} pb={hasReason ? 0 : 1} display="flex" justifyContent="space-between" alignItems="center">
              <KnSourceTitle display="block" component={KnMetricsTitle}>
                {translate(i18nKey('patientRefractory.determination'))}
              </KnSourceTitle>
              {reviewStatusDisplay(patientRefractory.status)}
            </Box>
            {
              hasMeds && (
                <Box pb={2}>
                  <KnTypographyTitle color={darkGrey}>{translate(i18nKey('patientRefractory.medicationTitle'))}</KnTypographyTitle>
                  <KnUl>
                    {(reasons.userMeds || []).map((m) => (
                      <KnLi>
                        <KnTypography color={darkGrey}>
                          {m.medication}
                          { m.type === 'hcp' ? `(${translate(i18nKey('patientRefractory.doctor'))})` : `(${translate(i18nKey('patientRefractory.patient'))})` }
                        </KnTypography>
                      </KnLi>
                    ))}
                  </KnUl>
                </Box>
              )
            }
            {
              hasSeizure && (
                <Box pb={2}>
                  <KnTypographyTitle color={darkGrey}>{translate(i18nKey('patientRefractory.seizures'))}</KnTypographyTitle>
                  <KnUl>
                    <KnLi>
                      <KnTypography color={darkGrey}>
                        {translate(i18nKey('patientRefractory.seizureCount'), { count: reasons.userSeizureCount })}
                      </KnTypography>
                    </KnLi>
                  </KnUl>
                </Box>
              )
            }
            {
              oycReport && (
                <Box pb={2} mb={2}>
                  <KnTypographyTitle color={darkGrey}>
                    {translate(i18nKey('patientRefractory.survey'))}
                  </KnTypographyTitle>
                  <KnUl>
                    <KnLiLink onClick={openOYCCare}>
                      <KnTypographyLink color="primary">
                        Optimize Your Care
                      </KnTypographyLink>
                    </KnLiLink>
                  </KnUl>
                </Box>
              )
            }
          </KnWidgetBox>
        </Box>
      </Box>
    </>
  );
};

KnPatientRefractory.propTypes = {
  onSetSurveyDetails: PropTypes.func.isRequired,
  survey: PropTypes.oneOfType(Object).isRequired,
  disableButton: PropTypes.bool,
};

KnPatientRefractory.defaultProps = {
  disableButton: false,
};

export default KnPatientRefractory;
