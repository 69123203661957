/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnFlagIcon = (props) => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" {...props}>
    <path d="M13.0689 0.147883C9.03514 7.67737 5.05526 -4.04697 1.02189 1.86885V0.52434C1.02189 -0.17478 0 -0.17478 0 0.52434V18.7026C0 19.4017 1.02189 19.4017 1.02189 18.7026V9.77497C5.0556 3.85897 9.03549 15.5833 13.0689 8.054V0.201883V0.147883Z" fill="#0A87A0" />
  </svg>

);

export default KnFlagIcon;
