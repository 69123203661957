/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnBoldSectionHeader, KnSubtitleText } from 'components/Typography';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { KnMedicationIndexBox, KnTitrationIndexBorder } from './titrations/styles';

const sheetBackgroundColor = palette.white.white2;

const KnMedicationSheetBox = styled(Box)({
  backgroundColor: sheetBackgroundColor,
  position: 'relative',
});

const KnMedicationBoxSubtitle = styled(Box)({
  width: 395,
});

/**
 * Component wrapper for displaying a bright white box with
 * a section header and other optional elements: a subtitle or
 * a header action button or link.
 */
const KnMedicationSheet = (props) => {
  const {
    children,
    title,
    subTitle,
    ActionComponent,
    index,
    dosages,
    hideIndexAndTitle,
    color,
  } = props;

  return (
    <KnMedicationSheetBox display="flex" flexDirection="column" mb={2}>
      {!hideIndexAndTitle && (
        <KnMedicationIndexBox display="flex" flexDirection="column" color={color}>
          <Typography variant="h5" component={KnBoldSectionHeader}>
            {index + 1}
          </Typography>
          <KnTitrationIndexBorder color={color} />
        </KnMedicationIndexBox>
      )}
      <Box display="flex" flexDirection="column" pt={hideIndexAndTitle ? 0 : 3} pl={10} pr={6}>
        <Box>
          {!hideIndexAndTitle && (
          <Typography variant="h6" component={KnBoldSectionHeader}>
            {title}
          </Typography>
          )}

          {subTitle && (
          <KnMedicationBoxSubtitle>
            <Typography component={KnSubtitleText}>
              {subTitle}
            </Typography>
          </KnMedicationBoxSubtitle>
          )}
        </Box>
        {ActionComponent}
        {children}
      </Box>
      <Box ml={3} mr={3}>
        {dosages}
      </Box>
    </KnMedicationSheetBox>
  );
};

KnMedicationSheet.propTypes = {
  /** Header title */
  title: PropTypes.string,
  /** Header subtitle  */
  subTitle: PropTypes.string,
  /** Header action link or button */
  ActionComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** The content to be dispalyed in the box */
  children: PropTypes.node,
  /** Medication index */
  index: PropTypes.number,
  dosages: PropTypes.shape(),
  hideIndexAndTitle: PropTypes.bool,
  color: PropTypes.string,
};

KnMedicationSheet.defaultProps = {
  subTitle: null,
  ActionComponent: null,
  children: null,
  dosages: null,
  hideIndexAndTitle: true,
  index: 0,
  title: '',
  color: '#000',
};

export default KnMedicationSheet;
