/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from '../colors';

const paperBackgroundColor = palette.white.white1;

export default {
  MuiPaper: {
    /**
     * Note: Material-UI Paper component is also used
     * with other components (eg. Card).
     */
    root: {
      backgroundColor: paperBackgroundColor,
    },
    rounded: {
      borderRadius: 2,
    },
  },
};
