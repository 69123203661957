/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnDeleteIcon = ({ color, ...rest }) => (
  <SvgIcon
    viewBox="-4 -2 24 24"
    fill="none"
    {...rest}
  >
    <path fill={color} d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM14 1h-3.5l-1-1h-5l-1 1H0v2h14V1z" />
  </SvgIcon>
);

KnDeleteIcon.defaultProps = {
  color: '#a9abb5',
};

KnDeleteIcon.propTypes = {
  color: PropTypes.string,
};

export default KnDeleteIcon;
