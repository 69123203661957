import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { styled } from '@material-ui/core/styles';
import { MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';
import palette from '../../styles/colors';
import { KnActionLink } from '../../components/Link';
import KnSwitcherIcon from '../../components/icons/SwitcherIcon';
import { KnSubtitleText } from '../../components/Typography';

const KnCheckbox = styled(Checkbox)({
  transform: 'scale(1.3)',
  color: palette.primary,
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: palette.white.white2,
  width: 190,
});

const StyledButton = styled(KnActionLink)(({ isOpen }) => ({
  width: 190,
  justifyContent: 'space-evenly',
  display: 'flex',
  fontWeight: 'normal',
  backgroundColor: isOpen ? palette.white.white1 : 'inherit',
}));

export default function KnTableColumnsSwitcher({ columns, selected, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleItem = (id) => {
    const newSelected = new Set(selected);
    if (newSelected.has(id)) newSelected.delete(id);
    else newSelected.add(id);
    onSelect(newSelected);
  };

  return (
    <>
      <StyledButton isOpen={open} data-testid="column-switcher-button" onClick={handleClick}>
        <KnSwitcherIcon />
        <KnSubtitleText>Show / hide columns</KnSubtitleText>
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuList>
          {columns.map(({ id, title }, index) => (
            <StyledMenuItem key={id} data-testid={`column-switcher-option-${index + 1}`} onClick={() => handleToggleItem(id)}>
              <KnCheckbox checked={selected.has(id)} color="primary" />
              {title}
            </StyledMenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

KnTableColumnsSwitcher.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape(),
};

KnTableColumnsSwitcher.defaultProps = {
  selected: [],
};
