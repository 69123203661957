/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

const palette = {
  primary: '#0a87a0',
  secondary: '#6cb7c6',
  // 10% of the primary
  oprimary: 'rgb(10,135,160,0.1)',
  error: {
    main: '#d44420',
  },
  white: {
    white1: '#f7f7f7',
    white2: '#fff',
    white3: '#d9d9d9',
    white4: '#dadada',
    white5: '#f9f9f9',
  },
  black: {
    black1: '#252525',
    black2: '#000',
    black3: '#262626',
  },
  coolGrey: {
    coolGrey1: '#a6a8b3',
    coolGrey2: '#a9abb5',
  },
  paleGrey: {
    paleGrey1: '#f9f9fb',
    paleGrey2: '#eaeaed',
    paleGrey3: '#f4f5f6',
    paleGrey4: '#7c7c7c',
    paleGrey5: '#a8a8a8',
    paleGrey6: '#cecece',
    paleGrey7: '#929292',
    paleGrey8: '#F4F4F5',
    paleGrey9: '#71717A',
    paleGrey10: '#E9E9E9',
  },
  lightGrey: '#595959',
  lightColor: 'rgb(234, 234, 237)',
  darkGrey: '#515151',
  slateGrey: '#54586c',
  plainGrey: '#666666',
  brownishGrey: '#666',
  gunmetal: '#4d5358',
  green: {
    dark: '#219653',
    dark1: '#00B934',
    light: '#d3eadd',
    lighter: '#4ab5bc',
    lightest: '#b1f0e8',
    royal: '#0d817a',
    light1: '#00bf4c',
    light2: '#CEE7EC',
  },
  copper: '#d44420',
  linkWater: '#d4d5da',
  midnight: '#222838',
  silver: {
    silver1: '#eaebec',
    silver2: '#d4d5da',
    silver3: '#d3d3d3',
  },
  red: {
    light: '#f6dad2',
  },
  lemonYellow: '#edff10',
  blue: {
    blue1: '#1097f8',
    blue2: '#4ddfff',
    blue3: '#00AEF9',
  },
  brightBlue: '#0072b6',
  lightBlue: '#66aad3',
  extraLightBlue: '#b3d5e9',
  blurBlue: '#EBF5F3',
  sealBlue: '#e6f3f5',
  greenBlue: '#053c46',
  aquaBlue: '#9dcfd9',
  bostonBlue: '#3b9fb3',
  teal: '#008080',

  brightPurple: '#333cab',
  lightPurple: '#858acd',
  extraLightPurple: '#c2c5e6',

  brightOrange: '#e78102',
  lightOrange: '#f1b367',
  extraLightOrange: '#f8d9b3',
  paleOrange: '#fbecd9',
  steel: '#7f8291',
  darkPeach: '#db846f',
  veryLightBrown: '#e0b5ab',
  purpel: '#d691ee',
};

export default palette;
