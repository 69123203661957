/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import { APP_PAGE_URLS, DOMAIN_FORMAT } from 'Constants';
import KnValidatedTextField from 'components/ValidatedTextField';
import KnPageContent from 'components/Content';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Auth } from 'aws-amplify';
import BackArrowIcon from 'components/icons/BackArrowIcon';
import { useHistory } from 'react-router-dom';
import healthSystemActions from 'redux/actions/healthSystemActions';
import { flatten } from 'lodash';
import appActions from 'redux/actions/appActions';

const initialSignInInfo = {
  email: '',
};

const SignInSso = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    data: HSProviders,
  } = useSelector((state) => state.healthSystem);

  const {
    handleSubmit, errors, control,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialSignInInfo,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch(healthSystemActions.fetch());
  }, [dispatch]);

  const providers = useMemo(
    () => flatten(HSProviders.map((h) => h.healthSystemSso)), [HSProviders],
  );

  const handleBack = () => {
    history.push(APP_PAGE_URLS.login);
  };

  const onSubmit = (formData, e) => {
    e.preventDefault();
    const provider = providers.find((p) => (p.domain || '').toLowerCase() === (formData.domain || '').toLowerCase());
    if (provider) {
      Auth.federatedSignIn({ provider: provider.idpName });
      return true;
    }
    dispatch(appActions.appPushNotification('LOGIN.ERROR_MESSAGES.noProviderError'));
    setTimeout(() => dispatch(appActions.appPopNotification()), 5000);
    return false;
  };

  return (
    <KnPageContent dark>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
            {translate('LOGIN.ssoTitle')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <KnValidatedTextField
              Component={KnTextField}
              name="domain"
              control={control}
              errors={errors}
              defaultValue=""
              format={DOMAIN_FORMAT}
              required
              trimSpaces
              maxLength={128}
              placeholder={translate('LOGIN.ssoInputPlaceholder')}
            />
            <Box
              pt={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <KnButton
                data-testid="sso-login-button"
                style={{ width: '100%' }}
              >
                {translate('LOGIN.ssoSubmitButton')}
              </KnButton>
            </Box>
            <Box
              pt={3}
              display="flex"
              alignItems="center"
              justifyContent="start"
              onClick={handleBack}
              style={{ cursor: 'pointer' }}
            >
              <BackArrowIcon />
              <Typography variant="h6" color="primary" style={{ fontSize: 16, marginLeft: 8 }}>
                {translate('LOGIN.back')}
              </Typography>
            </Box>
          </form>
        </CardContent>
      </Card>
    </KnPageContent>
  );
};

export default SignInSso;
