/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import patientActions from 'redux/actions/patientActions';
import KnButton from 'components/Button';
import PropTypes from 'prop-types';
import KnDateRangePicker from 'components/DateRangePicker';
import { format, subDays } from 'date-fns';
import { useDispatch } from 'react-redux';
import { API_DATE_FORMAT } from 'Constants';
import BackArrowIcon from 'components/icons/BackArrowIcon';
import KnCopyIcon from 'components/icons/CopyIcon';
import calendarActions from 'redux/actions/calendarActions';
import * as html2pdf from 'html2pdf.js';
import { KnBoldSectionHeader, KnSubtleText } from 'components/Typography';
import { translate } from 'i18n/i18n';
import { downloadPDF, withKeyNamespace } from 'utils/utils';
import _ from 'lodash';
import KnPageContent from '../../../components/Content';
import { KnPatientEmrRecordBox } from '../styles';

import useRegimenText from './useRegimenText';
import useSideEffectText from './useSideEffectText';
import useSeizureText from './useSeizureText';

import logo from '../../../assets/images/nileLogo84x48.png';
import usePatientText from './usePatientText';
import useSurveyText from './useSurveyText';
import useMedicalHistory from './useMedicalHistory';
import {
  KnBackBox, KnBoldHeader, KnChartCounts, KnCheckbox, KnCopyText,
  KnCursorBox, KnFilterBox, KnLogo, KnTextareaAutosize, StyledPre,
} from './PatientEmrExport.styles';


/**
 * Page used for exporig emr report
 */
const i18nKey = withKeyNamespace('PATIENT_RECORD');

const MIN_DATE = subDays(new Date(), 365);
const MAX_DATE = subDays(new Date(), 0);
const EMR_START_RANGE = subDays(new Date(), 90);
const EMR_END_RANGE = new Date();

const PatientEmrExport = ({ location, isMedicalHistoryPage }) => {
  const isMedicalHistory = _.get(location, 'state.isMedicalHistory') || isMedicalHistoryPage;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [dateRange, setDateRange] = useState({
    rangeStart: EMR_START_RANGE, rangeEnd: EMR_END_RANGE,
  });
  const [filters, setFilters] = useState({
    seizure: true,
    sideEffects: true,
    currentRegimen: true,
    patientNotes: true,
    pastMeds: false,
    surveys: false,
    medicalHistory: isMedicalHistory,
  });
  const [copied, setCopied] = useState(false);
  const {
    currentMedsText: regimenText, pastMedsText,
  } = useRegimenText({ showTitle: true, extraSpace: true, dateRange });
  const sideEffectText = useSideEffectText(filters.patientNotes);
  const sizureText = useSeizureText(filters.patientNotes);
  const patientText = usePatientText(isMedicalHistory);
  const surveyText = useSurveyText();
  const medicalHistoryText = useMedicalHistory();

  useEffect(() => {
    if (params) {
      dispatch(patientActions.fetchMedicationRegimenData(params.patientId));
      dispatch(patientActions.getMedicalHistoryStatus(params.patientId));

      if (isMedicalHistory) {
        dispatch(calendarActions.updateMedicalHistoryFlag(params.patientId));
      }
    }
  }, [dispatch, params, isMedicalHistory]);

  useEffect(() => {
    if (params) {
      dispatch(patientActions.getEMRExportData(
        params.patientId,
        {
          startDate: format(dateRange.rangeStart, API_DATE_FORMAT),
          endDate: format(dateRange.rangeEnd, API_DATE_FORMAT),
          seizure: !isMedicalHistory,
          sideEffects: !isMedicalHistory,
          surveys: !isMedicalHistory,
          medicalHistory: true,
        },
      ));
    }
  }, [dispatch, params, dateRange, isMedicalHistory]);

  const downloadJPEG = () => {
    html2pdf().from(document.querySelector('#reportPDF')).outputImg()
      .then((img) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = img.src;
        downloadLink.download = 'report.jpeg';
        downloadLink.click();
      });
  };

  const finalText = useMemo(() => {
    let text = '';
    if (!isMedicalHistory) {
      text += translate(i18nKey('patientReports.dateRangeText'), {
        startDate: dateRange.rangeStart,
        endDate: dateRange.rangeEnd,
      });
      text += '\n';
      if (filters.seizure) {
        text += sizureText;
      }
      if (filters.sideEffects) {
        text += sideEffectText;
      }

      if (filters.currentRegimen && regimenText && regimenText !== 'undefined') {
        text += regimenText;
      }

      if (filters.pastMeds) {
        text += pastMedsText;
      }
      if (filters.surveys) {
        text += surveyText;
      }
    }

    if (filters.medicalHistory) {
      text += medicalHistoryText;
    }

    return text;
  }, [
    regimenText, medicalHistoryText, sideEffectText, sizureText,
    dateRange, filters, pastMedsText, surveyText, isMedicalHistory,
  ]);

  const getTextInt = useCallback((str) => translate(i18nKey(`patientReports.${str}`)), []);

  const filterOptions = useMemo(() => ([
    {
      type: 'seizure',
      text: getTextInt('seizure'),
    },
    {
      type: 'sideEffects',
      text: getTextInt('sideEffect'),
    },
    {
      type: 'currentRegimen',
      text: getTextInt('regimen'),
    },
    {
      type: 'pastMeds',
      text: getTextInt('pastMeds'),
    },
    {
      type: 'surveys',
      text: getTextInt('surveys'),
    },
    {
      type: 'patientNotes',
      text: getTextInt('notes'),
    },
    {
      type: 'medicalHistory',
      text: getTextInt('medicalHistory'),
    }]), [getTextInt]);

  const onCopyText = () => {
    let textToCopy = finalText;
    if (isMedicalHistoryPage) {
      textToCopy = `${patientText}\n${finalText}`;
    }
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 10000);
    });
  };

  const handleRangeChange = (range) => {
    if (range[0] && range[1]) {
      setDateRange({
        rangeStart: range[0],
        rangeEnd: range[1],
      });
    }
  };

  const handleChange = (option, checked) => {
    setFilters({ ...filters, [`${option.type}`]: checked });
  };

  const goBack = () => {
    history.push(`/patient/${params.patientId}`,
      {
        isNeverLinked: _.get(location, 'state.isNeverLinked', ''),
      });
  };

  return (
    <KnPageContent>
      {!isMedicalHistoryPage && (
        <KnBackBox display="flex" aligItem="center" onClick={goBack}>
          <BackArrowIcon />
          <Typography component={KnCopyText} data-testid="back-to-patient-record">{translate(i18nKey('patientReports.back'))}</Typography>
        </KnBackBox>
      )}
      <KnPatientEmrRecordBox mb={2} padding={3} pb={6} id="divToPrint">
        {!isMedicalHistory && (
          <Typography variant="h6" component={KnBoldSectionHeader} data-testid="report-page-title">{translate(i18nKey('patientReports.emrExport'))}</Typography>
        )}
        <Box display="flex" mt={2} height="calc(100vh - 20px)" minHeight={isMedicalHistoryPage ? '890px' : '805px'}>
          {!isMedicalHistory && (
            <Box flex="1 1 0" mr={4}>
              <Typography variant="subtitle1" component={KnBoldHeader} data-testid="date-range-subtitle">
                {translate(i18nKey('patientReports.dateRange'))}
              </Typography>
              <Box>
                <KnDateRangePicker
                  value={[dateRange.rangeStart, dateRange.rangeEnd]}
                  onChange={handleRangeChange}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  width={368}
                  showIcon
                />
                <KnFilterBox mt={5}>
                  {filterOptions.map((option, index) => (
                    <Box mb={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <KnCheckbox
                              color="primary"
                              onChange={(e, c) => handleChange(option, c)}
                              defaultChecked={!['surveys', 'pastMeds', 'medicalHistory'].includes(option.type)}
                            />
                          )}
                          label={option.text}
                          data-testid={`report-filter-option-${index}`}
                        />
                      </FormGroup>
                    </Box>
                  ))}
                </KnFilterBox>
              </Box>
            </Box>
          )}
          <Box flex="3 1 0">
            {!isMedicalHistoryPage && (
              <>
                <Typography variant="h6" component={KnBoldHeader} data-testid="medical-history-subtitle">
                  {translate(i18nKey('patientReports.patientMedicalHistory'))}
                </Typography>
                <Typography variant="subtitle1" component={KnBoldHeader} data-testid="preview-subtitle">
                  {translate(i18nKey('patientReports.preview'))}
                </Typography>
              </>
            )}
            <Box position="relative">
              <div>
                {isMedicalHistoryPage && <StyledPre>{patientText}</StyledPre>}
                <KnTextareaAutosize value={finalText} disabled />
              </div>
              <div style={{ display: 'none' }}>
                <div id="reportPDF">
                  {(!isMedicalHistory || isMedicalHistoryPage) && (
                    <>
                      <div style={{ textAlign: 'right' }}><KnLogo src={logo} alt="" /></div>
                      <StyledPre>{patientText}</StyledPre>
                    </>
                  )}
                  <StyledPre>{finalText.split('\n').map((text) => (<p style={{ margin: 0 }}>{text}</p>))}</StyledPre>
                </div>
              </div>
              <KnChartCounts component={KnSubtleText} data-testid="characters-count">
                {(finalText || '').trim().length}
                {' '}
                {translate(i18nKey('patientReports.characters'))}
              </KnChartCounts>
              <Box display="flex" mt={2} mb={0} justifyContent="space-between">
                <KnCursorBox display="flex" alignItems="center" onClick={onCopyText}>
                  <KnCopyIcon />
                  <Typography component={KnCopyText} data-testid="copy-report-button">
                    {translate(i18nKey(`patientReports.${copied ? 'copied' : 'copyText'}`))}
                  </Typography>
                </KnCursorBox>

                <Box display="flex" alignItems="center">
                  {!isMedicalHistoryPage && (
                    <KnButton
                      data-testid="download-pdf-button"
                      onClick={downloadJPEG}
                      style={{ marginRight: 13 }}
                    >
                      {translate(i18nKey('patientReports.downloadJPEG'))}
                    </KnButton>
                  )}
                  <KnButton
                    data-testid="download-pdf-button"
                    onClick={() => downloadPDF()}
                  >
                    {translate(i18nKey('patientReports.downloadPDF'))}
                  </KnButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </KnPatientEmrRecordBox>
    </KnPageContent>
  );
};

PatientEmrExport.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      isNeverLinked: PropTypes.bool,
    }),
  }),
  isMedicalHistoryPage: PropTypes.bool.isRequired,
};

PatientEmrExport.defaultProps = {
  location: {
    state: {
      isNeverLinked: false,
      isMedicalHistory: false,
      isMedicalHistoryPage: false,
    },
  },
};

export default PatientEmrExport;
