
/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KeyIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.03102 1.4269C10.4309 2.82677 10.8023 4.86954 10.1415 6.60735L16.7017 13.1675L17 16L13.2576 15.3591L13.1011 13.6371L11.379 13.4805L11.2224 11.7585L9.5004 11.6019L7.32346 9.42494C5.58553 10.0857 3.54276 9.71434 2.143 8.31445C0.240934 6.41238 0.240934 3.3285 2.143 1.42655C4.04507 -0.475517 7.12895 -0.475517 9.0309 1.42655L9.03102 1.4269ZM5.43056 2.83575C4.91184 2.31703 4.07081 2.31703 3.55193 2.83575C3.03321 3.35447 3.03321 4.1955 3.55193 4.71437C4.07065 5.23309 4.91168 5.23309 5.43056 4.71437C5.94927 4.19566 5.94927 3.35462 5.43056 2.83575Z" fill="#0A87A0" />
  </svg>
);

export default KeyIcon;
