/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';

const KnExportIcon = ({ style }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...style}>
    <g clipPath="url(#clip0_886_18421)">
      <path d="M13.0852 10.1168V11.7184C13.0852 12.4256 12.834 13.0336 12.3252 13.5344C11.8244 14.0448 11.2148 14.296 10.5092 14.296H3.07076C2.36356 14.296 1.75556 14.0448 1.24516 13.5344C0.744364 13.0336 0.493164 12.424 0.493164 11.7184V4.2816C0.493164 3.5744 0.744364 2.9664 1.24516 2.4656C1.75556 1.9552 2.36356 1.7056 3.07076 1.7056H9.37156C9.52356 1.7056 9.64836 1.8224 9.64836 1.992V2.5648C9.64836 2.7344 9.52356 2.8512 9.37156 2.8512H3.07076C2.67716 2.8512 2.33636 2.9952 2.05956 3.272C1.78276 3.5488 1.63876 3.8896 1.63876 4.2832V11.72C1.63876 12.1136 1.78276 12.4544 2.05956 12.7312C2.33636 13.008 2.67716 13.152 3.07076 13.152H10.5076C10.9012 13.152 11.242 13.008 11.5188 12.7312C11.7956 12.4544 11.9396 12.1136 11.9396 11.72V10.1168C11.9396 9.94721 12.0564 9.84001 12.226 9.84001H12.7988C12.9684 9.83841 13.0852 9.94721 13.0852 10.1168ZM16.066 5.60641L13.1396 8.52321C12.9076 8.77441 12.5316 8.57761 12.5316 8.27201V6.81281H11.2436C10.8676 6.81281 10.3748 6.83041 9.75716 6.85761C9.13956 6.88481 8.53156 6.99201 7.92196 7.17121C7.32196 7.35041 6.83876 7.55521 6.47236 7.78881C6.11396 8.02081 5.86436 8.25441 5.71236 8.48641C5.56836 8.71041 5.44356 9.01441 5.32676 9.39041C5.20996 9.76641 5.15716 10.1424 5.15716 10.5264C5.15716 10.7328 5.16676 10.9744 5.18436 11.2336C5.18436 11.2784 5.21156 11.448 5.21156 11.52C5.21156 11.6272 5.13956 11.7168 5.03236 11.7168C4.96036 11.7168 4.90756 11.6816 4.87076 11.6176C4.80836 11.5376 4.70916 11.2864 4.65636 11.1888C4.17316 10.1056 4.02116 8.99681 4.20836 7.86881C4.30756 7.08961 4.70916 6.33761 5.42596 5.62241C6.58756 4.4688 8.52996 3.888 11.242 3.888H12.53V2.4208C12.53 2.1072 12.906 1.928 13.138 2.1616L16.0644 5.08801C16.2084 5.23841 16.2084 5.46241 16.066 5.60641Z" fill="#0A87A0" />
    </g>
    <defs>
      <clipPath id="clip0_886_18421">
        <rect width="16" height="16" fill="white" transform="translate(0.333252)" />
      </clipPath>
    </defs>
  </svg>
);

KnExportIcon.defaultProps = {
  style: {},
};

KnExportIcon.propTypes = {
  style: PropTypes.oneOfType(Object),
};

export default KnExportIcon;
