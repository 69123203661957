/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import loginActions from 'redux/actions/loginActions';
import { withKeyNamespace } from 'utils/utils';

const i18nKey = withKeyNamespace('PENDING_APPROVAL');

const KnUserPendingCard = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const onBackToLogin = useCallback(() => {
    dispatch(loginActions.logout());
  }, [dispatch]);

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h6" component={KnBoldSectionHeader}>
          {translate(i18nKey('title'))}
        </Typography>
        <Box mt={3} mb={5}>
          <Typography paragraph>
            {translate(i18nKey('content'), currentUser)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
        >
          <KnButton
            variant="text"
            type="button"
            onClick={onBackToLogin}
            data-testid="login-link-button"
          >
            {translate(i18nKey('backToLogin'))}
          </KnButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default KnUserPendingCard;
