import {
  Box,
  styled,
  TextareaAutosize,
  Typography,
  Checkbox,
} from '@material-ui/core';
import palette from 'styles/colors';
import { KnBoldSectionHeader, KnHighlightText } from 'components/Typography';

const { darkGrey, paleGrey } = palette;
const REPORT_BG_COLOR = '#F8F8F8';

export const KnTextareaAutosizePDF = styled(TextareaAutosize)(({ theme }) => ({
  outline: 'none',
  border: 0,
  width: '100%',
  resize: 'none',
  padding: theme.spacing(2),
  background: REPORT_BG_COLOR,
  pageBreakBefore: 'always',
  marginBottom: theme.spacing(2),
}));

export const KnTextareaAutosize = styled(KnTextareaAutosizePDF)({
  height: '685px !important',
  overflowY: 'scroll !important',
  marginBottom: 0,
});

export const KnHTMLTextArea = styled('div')(({ theme }) => ({
  outline: 'none',
  border: 0,
  width: '100%',
  resize: 'none',
  padding: theme.spacing(2),
  background: REPORT_BG_COLOR,
  pageBreakBefore: 'always',
  height: '685px !important',
  overflowY: 'scroll !important',
  marginBottom: 0,
}));


export const KnInsuranceHTMLArea = styled(KnHTMLTextArea)({
  height: '645px !important',
});

export const KnBoldHeader = styled(KnBoldSectionHeader)({
  marginBottom: 12,
});

export const KnCopyText = styled(KnHighlightText)({
  fontSize: 16,
  fontWeight: 700,
  marginLeft: 11,
});

export const KnChartCounts = styled(Typography)({
  position: 'absolute',
  bottom: 68,
  minWidth: 100,
  right: 0,
  padding: '5px 9px',
  background: paleGrey.paleGrey10,
});

export const KnCursorBox = styled(Box)({
  cursor: 'pointer',
});

export const KnBackBox = styled(KnCursorBox)({
  marginTop: -27,
  marginBottom: 27,
});

export const KnFilterBox = styled(Box)({
  paddingLeft: 5,
});

export const KnCheckbox = styled(Checkbox)({
  transform: 'scale(1.9)',
  color: palette.primary,
  marginRight: 8,
});

export const KnLogo = styled('img')({
  width: 120,
  marginBottom: 20,
});

export const StyledPre = styled('pre')({
  backgroundColor: REPORT_BG_COLOR,
  borderRadius: 5,
  padding: 10,
  color: darkGrey,
});


export const StyledBox = styled(Box)({
  backgroundColor: REPORT_BG_COLOR,
});
