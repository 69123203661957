/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

/**
 * this file would be imported wherever we would want to track events
 */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();
ai.trackPageView();

export const setInsightsContext = (userId, healthSystem) => {
  ai.context.user.id = userId;
  ai.context.user.accountId = healthSystem;
};

/**
 * withAITracking is exported for wrapping in HoC
 */
export default (Component) => withAITracking(reactPlugin, Component);

/**
 * appInsights variable will be used for logging / tracking events
 */
export const { appInsights } = ai;
