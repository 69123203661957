/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import axios from 'axios';
import ACTION_TYPES from 'redux/actionTypes';
import { API_URLS } from 'Constants';
import appActions from './appActions';

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL,
});

const mapServerHealthSystemToLocal = (data) => data.map(
  (hs) => ({
    id: hs.id,
    name: hs.name,
    healthSystemSso: hs.healthSystemSso,
  }),
);

/**
 * Health systems action creator: get the list of supported health systems.
 */
const fetch = () => {
  const request = () => ({ type: ACTION_TYPES.HEALTH_SYSTEM_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.HEALTH_SYSTEM_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.HEALTH_SYSTEM_ERROR });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await instance.get(API_URLS.healthSystem);
      dispatch(success(mapServerHealthSystemToLocal(response.data)));
    } catch (error) {
      dispatch(failure());
      dispatch(appActions.appPushNotification('REGISTER.ERROR_MESSAGES.dataLoadError'));
    }
  };
};

export { instance };

export default { fetch };
