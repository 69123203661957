/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const BackArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 11H7.83L11.41 7.41L10 6L4 12L10 18L11.41 16.59L7.83 13H20V11Z" fill="#0A87A0" />
  </svg>
);


export default BackArrowIcon;
