/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import {
  API_URLS, API_REQUEST_METHODS,
} from 'Constants';
import apiRequest from './apiActions';
import appActions from './appActions';

/**
 * Action to fetch the medication target dose stats.
 *
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchMedicationStat = (medicationId, isChild, unit) => async (dispatch) => {
  try {
    const response = await dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.medicationStats.replace(':medicationId', medicationId),
        null,
        {
          patientType: isChild ? 'child' : 'adult',
          medicationUnit: unit,
        },
        { silent: false },
      ),
    );
    return response.data;
  } catch (errorCode) {
    return {};
  }
};

/**
 * Action to fetch the smart titration for medication.
 *
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchDefaultSmartTitration = (medicationId, params) => async (dispatch) => {
  try {
    const response = await dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.medicationSTDefault.replace(':medicationId', medicationId),
        null,
        params,
        { silent: false },
      ),
    );
    return response.data;
  } catch (errorCode) {
    dispatch(appActions.appPushNotification('TITRATION_BUILDER.ERROR_MESSAGES.smartTitrationNotFound'));
    setTimeout(() => {
      dispatch(appActions.appPopNotification());
    }, 5000);
    return { error: true };
  }
};

/**
 * Action to fetch the smart titration for medication.
 *
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchCustomSmartTitration = (medicationId, params) => async (dispatch) => {
  try {
    const response = await dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.medicationSTCustom.replace(':medicationId', medicationId),
        null,
        params,
        { silent: false },
      ),
    );
    return response.data;
  } catch (errorCode) {
    dispatch(appActions.appPushNotification('TITRATION_BUILDER.ERROR_MESSAGES.smartTitrationNotFound'));
    setTimeout(() => {
      dispatch(appActions.appPopNotification());
    }, 5000);
    return { error: true };
  }
};

export default {
  fetchMedicationStat,
  fetchDefaultSmartTitration,
  fetchCustomSmartTitration,
};
