/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useCallback, useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import useCognitoUser from 'utils/cognitoUser';
import {
  getMobileOperatingSystem,
  useIsUserPending,
  useIsUserActive,
} from 'utils/utils';
import initI18n from 'i18n/i18n';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import theme from 'styles/theme';
import appActions from 'redux/actions/appActions';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS, NILE_APP_LINKS } from 'Constants';
import KnLayout from 'components/Layout';
import LoginPage from 'features/login/LoginPage';
import RegisterPage from 'features/register/RegisterPage';
import HomePage from 'features/home/HomePage';
import PatientRecordPage from 'features/patient/PatientRecordPage';
import ForgotPasswordPage from 'features/forgotPassword/ForgotPasswordPage';
import SettingsPage from 'features/account/SettingsPage';
import FAQPage from 'features/faq/FAQPage';
import AppRedirect from 'features/login/AppRedirect';
import TitrationBuilderPage from 'features/titrations/TitrationBuilderPage';
import TitrationsManagementPage from 'features/titrations/TitrationsManagementPage';
import AssignPresetPage from 'features/patient/AssignPresetPage';
import AssignRegimenPage from 'features/patient/AssignRegimenPage';
import ThresholdSetupPage from 'features/patient/ThresholdSetupPage';
import { KnDialogServiceProvider } from 'components/dialog/DialogService';
import { Amplify } from 'aws-amplify';
import { addLicense } from '@amcharts/amcharts4/core';
import LoginSsoPage from 'features/login/LoginSsoPage';
import ConsentExport from 'features/patient/emrExport/ConsentExport';
import InsuranceExport from 'features/patient/emrExport/InsuranceExport';
import EmrExportPage from 'features/patient/emrExport/EmrExportPage';
import AssignSurveyPage from 'features/patient/AssignSurveyPage';
import PatientEmrExport from 'features/patient/emrExport/PatientEmrExport';
import AdminCalendar from 'features/patient/adminCalendar/AdminCalendar';
import SignInSso from 'features/login/SignInSso';

initI18n();

/** Configure Amplify with the CognitoPool settings  */
const cognitoConfig = {
  region: process.env.REACT_APP_COGNITO_REGION, // 'us-east-1',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // 'us-east-1_kHoADViUp',
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  storage: localStorage,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: `${process.env.REACT_APP_HCP_BASE_URL}login-sso`,
    redirectSignOut: `${process.env.REACT_APP_HCP_BASE_URL}`,
    clientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};
Amplify.configure({ Auth: cognitoConfig });

/**
 * Our own HOC component around react Route,
 * used to protect routes
 * Set `shouldBeAuthenticated` to `false`,
 *  to those routes that are accessible when user is not authenticated
 * Set `shouldBeAuthenticated` to `true` (default value),
 *  to those routes that are accessible when user is authenticated
 */
const KnRoute = (props) => {
  const { component: Component, shouldBeAuthenticated, ...rest } = props;
  const cognitoUser = useCognitoUser();
  const isUserPending = useIsUserPending();
  const location = useLocation();
  const currentUserStore = useSelector((state) => state.user.currentUser);

  const { shouldRedirect, redirectPath } = useMemo(() => {
    const authenticated = cognitoUser !== null;
    const homeURL = APP_PAGE_URLS.patientList;
    /* eslint-disable no-shadow */
    const shouldRedirect = shouldBeAuthenticated !== authenticated
      || (isUserPending && location.pathname !== homeURL);
    let redirectPath = authenticated ? homeURL : APP_PAGE_URLS.login;
    if (currentUserStore && currentUserStore.newUser) {
      redirectPath = '/register';
    }
    /* eslint-enable no-shadow */
    return { shouldRedirect, redirectPath };
  }, [cognitoUser, shouldBeAuthenticated, isUserPending, location.pathname, currentUserStore]);

  /**
   * cognitoUser is undefined while we are waiting for
   * the AWS user data. Until then, we'll show no content.
   * OR
   * user is signed in, but isUserPending is undefined,
   * meaning that we're still waiting for GET /users response,
   * to decide wether user is pending or not
   */
  if (
    (currentUserStore && !currentUserStore.newUser) && (cognitoUser === undefined
    || (cognitoUser && isUserPending === undefined))
  ) {
    return null;
  }

  if (currentUserStore && currentUserStore.newUser) {
    return (
      <Route
        {...rest}
        render={({ ...routerProps }) => (<Component {...routerProps} />)}
      />
    );
  }

  /** redirect when needed,
   * if not, just render requested component
   */
  return (
    <Route
      {...rest}
      render={({ ...routerProps }) => (shouldRedirect ? (
        <Redirect
          to={{
            pathname: redirectPath,
            state: {
              redirectPath:
                  location.pathname !== '/' ? location.pathname : '',
            },
          }}
        />
      ) : (
        <Component {...routerProps} />
      ))}
    />
  );
};

KnRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  shouldBeAuthenticated: PropTypes.bool,
};

KnRoute.defaultProps = {
  shouldBeAuthenticated: true,
};

/** Wrapper for KnRoute that sets `shouldBeAuthenticated` to false, for convinience */
const KnAnonymousRoute = (props) => (
  <KnRoute {...props} shouldBeAuthenticated={false} />
);

const Main = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cognitoUser = useCognitoUser();

  useEffect(() => {
    addLicense('CH236683054');
  }, []);

  const onIdle = useCallback(() => {
    /** On inactivity, if the user is logged in, trigger a logout. */
    if (cognitoUser) {
      dispatch(loginActions.logout());
    }
  }, [dispatch, cognitoUser]);

  const redirectToAppPage = useCallback(() => {
    const device = getMobileOperatingSystem();
    window.location.href = NILE_APP_LINKS[device] || NILE_APP_LINKS.web;
    return null;
  }, []);

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_USER_INACTIVITY_TIMEOUT),
    onIdle,
    debounce: 500,
  });

  useEffect(() => {
    /**
     * Fetch BE API version number
     */
    dispatch(appActions.fetchApiVersion());
  }, [dispatch]);

  useEffect(() => {
    /**
     * Listen to React Router's history change event and
     * dispatch an action to remove any application error
     * notification, because it becomes obsolete when
     * leaving the page.
     */
    const unsubscribe = history.listen(() => {
      dispatch(appActions.appPopNotification());
    });

    return unsubscribe;
  }, [dispatch, history]);

  return (
    <Box display="flex" flex="1">
      <Switch>
        <KnAnonymousRoute
          exact
          path={APP_PAGE_URLS.login}
          component={LoginPage}
        />
        <KnAnonymousRoute
          path={APP_PAGE_URLS.register}
          component={RegisterPage}
        />
        <KnAnonymousRoute
          path={APP_PAGE_URLS.loginSso}
          component={LoginSsoPage}
        />
        <KnAnonymousRoute
          path={APP_PAGE_URLS.ssoRedirect}
          component={SignInSso}
        />
        <KnAnonymousRoute
          path={APP_PAGE_URLS.appRedirect}
          component={AppRedirect}
        />
        <KnAnonymousRoute
          path={APP_PAGE_URLS.forgotPassword}
          component={ForgotPasswordPage}
        />
        <KnAnonymousRoute
          path={[
            APP_PAGE_URLS.emailAppLink,
            APP_PAGE_URLS.registerAppLink,
            APP_PAGE_URLS.careGiverInvite,
          ]}
          component={redirectToAppPage}
        />
        <KnRoute path={APP_PAGE_URLS.patientEMRExport} component={PatientEmrExport} />
        <KnRoute path={APP_PAGE_URLS.emrExport} component={EmrExportPage} />
        <KnRoute path={APP_PAGE_URLS.consentExport} component={ConsentExport} />
        <KnRoute path={APP_PAGE_URLS.insuranceExport} component={InsuranceExport} />
        <KnRoute path={APP_PAGE_URLS.adminCalendar} component={AdminCalendar} />
        <KnRoute path={APP_PAGE_URLS.assignSurvey} component={AssignSurveyPage} />

        <KnRoute path={APP_PAGE_URLS.patientList} component={HomePage} />
        <KnRoute
          path={APP_PAGE_URLS.patientRecord}
          component={PatientRecordPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.invitedPatientRecord}
          component={PatientRecordPage}
        />
        <KnRoute path={APP_PAGE_URLS.settings} component={SettingsPage} />
        <KnRoute path={APP_PAGE_URLS.faq} component={FAQPage} />
        <KnRoute
          path={APP_PAGE_URLS.newTitration}
          component={TitrationBuilderPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.editTitration}
          component={TitrationBuilderPage}
        />

        <KnRoute
          path={APP_PAGE_URLS.titrationsList}
          component={TitrationsManagementPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.assignPreset}
          component={AssignPresetPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.assignRegimen}
          component={AssignRegimenPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.editAssignedRegimen}
          component={AssignRegimenPage}
        />
        <KnRoute
          path={APP_PAGE_URLS.threshold}
          component={ThresholdSetupPage}
        />

        <KnRoute
          path={APP_PAGE_URLS.assignSurvey}
          component={AssignSurveyPage}
        />

        <Redirect to={APP_PAGE_URLS.login} />
      </Switch>
    </Box>
  );
};

function App() {
  const isUserActive = useIsUserActive();
  const { isPro } = useSelector((state) => state.user.currentUser);
  const cognitoUser = useCognitoUser();
  const showSubscriptionBanner = isUserActive && isPro === false && cognitoUser;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <KnLayout showSubscriptionBanner={showSubscriptionBanner}>
          <KnDialogServiceProvider>
            <Main />
          </KnDialogServiceProvider>
        </KnLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
