/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import KnLink from './Link';

export const KnErrorWrapper = styled(Box)(({ centered }) => ({
  display: centered ? 'flex' : 'block',
  justifyContent: centered ? 'center' : 'left',
  paddingTop: centered ? 7 : 0,

  '& .MuiLink-button': {
    verticalAlign: 'top',
  },
}));

/**
 * Component displaying given message,
 * when `error` flag is true, with the option to trigger an action
 * through a link in the message text. (eg. retry link)
 * Used for showing error messages on server call fails, with the retry option
 *
 */
const KnErrorMessage = (props) => {
  const {
    error, messageKey, onRetry, centered,
    ...rest
  } = props;
  if (error) {
    return (
      <KnErrorWrapper centered={centered} {...rest}>
        <Trans
          i18nKey={messageKey}
          components={[
            <KnLink variant="body2" onClick={onRetry} />,
          ]}
        />
      </KnErrorWrapper>
    );
  }
  return '';
};

KnErrorMessage.propTypes = {
  messageKey: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  onRetry: PropTypes.func,
  centered: PropTypes.bool,
};

KnErrorMessage.defaultProps = {
  onRetry: () => {},
  centered: true,
};

export default KnErrorMessage;
