/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnCloseIcon = ({ color, ...rest }) => (
  <SvgIcon {...rest}>
    <path fill={color} d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </SvgIcon>
);

KnCloseIcon.defaultProps = {
  color: '#252525',
};

KnCloseIcon.propTypes = {
  color: PropTypes.string,
};

export default KnCloseIcon;
