/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import patientActions from 'redux/actions/patientActions';
import {
  Box, styled, CircularProgress,
} from '@material-ui/core';
import KnCloseIcon from 'components/icons/CloseIcon';
import KnIconButton from 'components/IconButton';
import palette from 'styles/colors';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import KnArrowUpIcon from 'components/icons/ArrowUpIcon';
import { patientFilterReportCol, patientFilterReport, MATCH_TIME_STRING } from 'Constants';
import { KnSubtleText } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const closeIconColor = palette.secondary;
const {
  darkGrey, expandIconColor, primary, white,
} = palette;
const KnTh = styled('th')({
  width: '95px',
  color: darkGrey,
  fontWeight: 'bold',
  position: 'sticky',
  overflowY: 'auto',
  backgroundColor: white.white1,
  top: '0px',
  paddingLeft: '15px',
});
const KnBox = styled(Box)({
  overflowY: 'scroll',
});
const KnIconButtonStyled = styled(KnIconButton)({
  position: 'absolute',
  top: '4px',
  right: '0px',
});
const LoaderBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
});

const KnTr = styled('tr')({
  background: palette.paleGrey.paleGrey2,
  textAlign: 'left',
  height: 32,
});

const KnTable = styled('table')({
  paddingLeft: '5px',
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  borderSpacing: '0px 5px',
  '-webkit-border-vertical-spacing': 10,
  '-moz-border-vertical-spacing': 10,
  tableLayout: 'fixed',
});

const KnTd = styled('td')(({ expand }) => ({
  width: 100,
  overflow: 'hidden',
  whiteSpace: expand ? 'normal' : 'nowrap',
  textOverflow: 'ellipsis',
  paddingLeft: '15px',
}));

const KnIconTd = styled('td')(({ expand }) => ({
  width: 100,
  overflow: 'hidden',
  whiteSpace: expand ? 'normal' : 'nowrap',
  textOverflow: 'ellipsis',
}));

const KnReportDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 1135,
    height: 735,
  },
});

const KnPatientDialogReport = ({
  open,
  onClose,
  inputData,
}) => {
  const {
    seizuresMetrics,
    symptomsMetrics,
  } = useSelector((state) => state.patientRecord);
  const [inputArray, setInputArray] = useState([]);
  const { t: translate } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [lable, setLable] = useState(30);
  const dispatch = useDispatch();
  const { patientId } = inputData;

  const getSideEffectDuration = useCallback((durationInHour) => {
    let durationString = '';
    if (durationInHour) {
      durationString += Math.floor(durationInHour / 24);
      if (durationString !== '0') durationString += durationString > 1 ? translate('THRESHOLD.seizure.suffix.sday_plural') : translate('THRESHOLD.seizure.suffix.sday');
      else durationString = '';
      const hourString = (durationInHour % 24);
      if (hourString !== 0) durationString += hourString > 1 ? translate('THRESHOLD.seizure.suffix.hour_plural', { count: hourString }) : translate('THRESHOLD.seizure.suffix.sday', { count: hourString });
      return durationString;
    }
    return 'N/A';
  }, [translate]);

  const tConvert = (timestr) => {
    let time = timestr;
    try { time = time.split(':').slice(0, 2).join(':'); } catch (error) {
      // Nothing
    }
    time = time.toString().match(MATCH_TIME_STRING) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  };

  const defineEvents = useCallback((events) => {
    const eventArray = [];
    events.forEach((e) => {
      const {
        duration,
        lastedMoreThanADay,
        sideEffect,
        durationInHour,
        timePeriod,
        startTimestamp,
        eventType,
        triggers,
      } = e;
      const seizureDuration = (duration) ? duration.value : 'N/A';
      const sideEffectDurationString = getSideEffectDuration(durationInHour);
      const seizureTime = (timePeriod) ? timePeriod.value : 'N/A';
      const sideEffectTime = (startTimestamp) ? tConvert(new Date(startTimestamp).toTimeString().split(' ')[0]) : 'N/A';
      const sideEffecteventDetail = (sideEffect) ? sideEffect.value : 'N/A';
      const sideeffectDuration = (lastedMoreThanADay) ? sideEffectDurationString : translate('THRESHOLD.symptoms.lastedLessThanADay');
      const startDate = `${e.date.getMonth() + 1}/${e.date.getDate()}/${e.date.getFullYear()}`;
      const Time = (!sideEffect) ? seizureTime : sideEffectTime;
      const Duration = (!sideEffect) ? seizureDuration : sideeffectDuration;
      const type = (!sideEffect) ? translate('THRESHOLD.seizure.text') : translate('THRESHOLD.symptoms.text');
      const seizureType = eventType === 'aura' ? translate('THRESHOLD.seizure.aura') : 'N/A';
      const eventDetail = (!sideEffect) ? seizureType : sideEffecteventDetail;
      const trigger = (triggers) ? triggers && triggers.join(', ') : 'N/A';

      const notes = (e.notes) ? e.notes : 'N/A';
      const dataArrayObject = {
        date: startDate,
        time: Time,
        duration: Duration,
        type,
        Detail: eventDetail,
        Triggers: trigger,
        Notes: notes,
        expand: false,
      };
      eventArray.push(dataArrayObject);
    });
    setInputArray(eventArray);
  }, [translate, getSideEffectDuration]);

  useEffect(() => {
    let events = [];
    if (seizuresMetrics.data) events = [...events, ...seizuresMetrics.data];
    if (symptomsMetrics.data) events = [...events, ...symptomsMetrics.data];
    events.sort((a, b) => b.date.getTime() - a.date.getTime());
    if (lable === 30) {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() - lable));
      events = events.filter((item) => item.date.getTime() >= endDate.getTime()
        && item.date.getTime() <= startDate.getTime());
    }
    defineEvents(events);
  }, [seizuresMetrics.data, symptomsMetrics.data, defineEvents, lable]);

  const filterSelected = useCallback((e) => {
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(startDate.getDate() - e));
    const promiseSeizure = new Promise((resolve) => {
      dispatch(patientActions.fetchTopLineSeizureData(
        patientId,
        endDate,
        startDate,
      )).then(() => resolve(true)).catch(() => resolve(false));
    });
    const promiseSideeffect = new Promise((resolve) => {
      dispatch(patientActions.fetchTopLineSymptomData(
        patientId,
        endDate,
        startDate,
      )).then(() => resolve(true)).catch(() => resolve(false));
    });
    Promise.all([promiseSeizure, promiseSideeffect]).then(() => {
      setLoader(false);
      setLable(e);
    });
  }, [dispatch, patientId]);

  const handleClick = (index) => {
    setInputArray(inputArray.map((i, inputIndex) => {
      if (inputIndex === index) {
        return {
          ...i,
          expand: !i.expand,
        };
      }
      return i;
    }));
  };

  if (open) {
    return (
      <KnReportDialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        {loader && <LoaderBox><CircularProgress /></LoaderBox>}
        <Box display="flex" color={primary} pt="10px" pl="20px" pb="20px">
          {patientFilterReport.map((e) => (
            <>
              <Box
                width="7%"
                style={{ cursor: 'pointer', fontWeight: (e === lable) ? 'bold' : 100, paddingLeft: '15px' }}
                onClick={() => {
                  setLoader(true);
                  setTimeout(() => { filterSelected(e); }, [1000]);
                }}
              >
                {e}
                {' '}
                Days
              </Box>
            </>
          ))}
          <KnIconButtonStyled
            style={{
            }}
            data-testid="delete-close-button"
            Icon={KnCloseIcon}
            tooltip
            tooltipText={translate('ICON_TOOLTIP.close')}
            iconColor={closeIconColor}
            onClick={onClose}
          />
        </Box>
        <KnBox display="flex" pl="18px" pr="10px">
          <KnTable>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                {patientFilterReportCol.map((evn) => (
                  <KnTh>{evn}</KnTh>
                ))}
                <KnTh style={{ width: 20 }} />
              </tr>
            </thead>
            <tbody>
              {(!inputArray.length && !loader) && (
                <tr>
                  <Box display="flex" pt={7} style={{ marginLeft: '290%', width: '200%' }}>
                    <Typography component={KnSubtleText} variant="body2" data-testid="empty-titrations-list">
                      {translate('THRESHOLD.emptyListSymptoms')}
                    </Typography>
                  </Box>
                </tr>
              )}
              {inputArray.map((tab, index) => (
                <KnTr key={tab.id} onClick={() => handleClick(index)}>
                  <KnTd>
                    {tab.date}
                  </KnTd>
                  <KnTd>
                    {tab.time}
                  </KnTd>
                  <KnTd>
                    {tab.duration}
                  </KnTd>
                  <KnTd>
                    {tab.type}
                  </KnTd>
                  <KnTd>
                    {tab.Detail}
                  </KnTd>
                  <KnTd style={{ maxWidth: '100px' }} expand={tab.expand}>
                    {tab.Triggers}
                  </KnTd>
                  <KnTd style={{ maxWidth: '100px' }} expand={tab.expand}>
                    {tab.Notes}
                  </KnTd>
                  <KnIconTd>
                    {tab.expand
                      ? <KnArrowUpIcon color={expandIconColor} />
                      : <KnArrowDownIcon color={expandIconColor} />}
                  </KnIconTd>
                </KnTr>
              ))}
            </tbody>
          </KnTable>
        </KnBox>
      </KnReportDialog>
    );
  }
  return null;
};

KnPatientDialogReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputData: PropTypes.shape({
    medicationStrength: PropTypes.number,
    medicationTypeUnit: PropTypes.string,
    patientId: PropTypes.string,
  }),
};

KnPatientDialogReport.defaultProps = {
  inputData: {
    medicationStrength: undefined,
    medicationTypeUnit: undefined,
    patientId: undefined,
  },
};

export default KnPatientDialogReport;
