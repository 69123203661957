/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { KnBrightBox } from 'components/Content';
import { styled } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from 'styles/colors';

const elevatedBoxShadow = palette.lightGrey;

export const boxShadow = `4px 4px 15px 0 ${fade(elevatedBoxShadow, 0.12)}`;

export const KnElevatedBrightBox = styled(KnBrightBox)({
  boxShadow,
});

export const KnElevatedColorBox = styled(KnElevatedBrightBox)(({ theme, borderColor }) => ({
  borderBottomColor: borderColor,
  borderBottomStyle: 'solid',
  borderBottomWidth: theme.spacing(0.5),
}));
