/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';

const KnPlayIcon = () => (

  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 0.833008C7.18004 0.833008 0.833374 7.17967 0.833374 14.9997C0.833374 22.8197 7.18004 29.1663 15 29.1663C22.82 29.1663 29.1667 22.8197 29.1667 14.9997C29.1667 7.17967 22.82 0.833008 15 0.833008ZM12.1667 21.3747V8.62467L20.6667 14.9997L12.1667 21.3747Z" fill="white" />
  </svg>

);

export default KnPlayIcon;
