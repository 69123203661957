/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';

const KnEyeCloseIcon = ({ style }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" style={style}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.34001 1.57997L2.28001 0.633301L14.1 12.4666L13.16 13.4066L10.8733 11.12C9.98001 11.4666 9.01334 11.6666 8 11.6666C4.66667 11.6666 1.82001 9.5933 0.666672 6.66663C1.18001 5.3533 2.04001 4.21997 3.12667 3.36663L1.34001 1.57997ZM11.3333 6.66663C11.3333 4.82663 9.84001 3.3333 8.00001 3.3333C7.66001 3.3333 7.33334 3.39997 7.02001 3.4933L5.57334 2.04663C6.34001 1.79997 7.15334 1.66663 8.00001 1.66663C11.3333 1.66663 14.18 3.73997 15.3333 6.65997C14.8733 7.8333 14.14 8.85997 13.2133 9.67997L11.1733 7.63997C11.2667 7.3333 11.3333 7.00663 11.3333 6.66663ZM8 9.99997C6.16 9.99997 4.66667 8.50663 4.66667 6.66663C4.66667 6.1533 4.78667 5.66663 4.99334 5.23997L6.04 6.28663C6.02 6.40663 6 6.5333 6 6.66663C6 7.7733 6.89334 8.66663 8 8.66663C8.13334 8.66663 8.25334 8.64663 8.38 8.61997L9.42667 9.66663C8.99334 9.87997 8.51334 9.99997 8 9.99997ZM9.98001 6.44663C9.88001 5.5133 9.14667 4.78663 8.22001 4.68663L9.98001 6.44663Z" fill="#666666" />
  </svg>
);

KnEyeCloseIcon.defaultProps = {
  style: {},
};

KnEyeCloseIcon.propTypes = {
  style: PropTypes.oneOfType(Object),
};

export default KnEyeCloseIcon;
