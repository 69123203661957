/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import format from 'date-fns/format';
import { appInsights } from 'appInsights';
import axios from 'axios';
import ACTION_TYPES from 'redux/actionTypes';
import {
  PATIENT_LINKS_STATUS,
  API_URLS, API_REQUEST_METHODS, API_DATE_FORMAT,
  THRESHOLD_EVENT_TYPES,
  TRACKING_EVENTS,
} from 'Constants';
import patientMappings from './mappingFunctions/patientMappings';
import titrationMappings from './mappingFunctions/titrationMappings';
import thresholdsMappings from './mappingFunctions/thresholdsMappings';
import appActions from './appActions';
import apiRequest from './apiActions';

const REGIMENS_DEFAULT_SORT_KEY = 'assignedAt';
const REGIMENS_DEFAULT_SORT_ORDER = 'desc';

/**
 * Action to request patient seizure data given a date range.
 *
 * @param {string} patientId The patient id.
 * @param {Date} startDate The start date of the interested time range.
 * @param {Date} endDate The end date of the interested time range.
 * @param {object} actions Object with request, success and error action types to be dispatched.
 */
const requestSeizureData = (patientId, startDate, endDate, actions) => {
  const request = () => ({ type: actions.request });
  const success = (data) => ({ type: actions.success, data });
  const failure = () => ({ type: actions.error });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSeizures.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSeizuresToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to request patient symptoms data given a date range.
 *
 * @param {string} patientId The patient id.
 * @param {Date} startDate The start date of the interested time range.
 * @param {Date} endDate The end date of the interested time range.
 * @param {object} actions Object with request, success and error actions to be dispatched.
 */
const requestSymptomData = (patientId, startDate, endDate, actions) => {
  const request = () => ({ type: actions.request });
  const success = (data) => ({ type: actions.success, data });
  const failure = () => ({ type: actions.error });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSymptoms.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSymptomsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to fetch the patients list:
 * unverified, accepted or unlinked patients
 *
 * @param {string} status Patient list status.
 * @param {number} physicianFilter Id of the physician to filter by (=0 for 'all')
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchPatients = (status, silent = true) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_LIST_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_LIST_SUCCESS, status, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_LIST_ERROR, status });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          patientMappings.mapStatusToPatientLinksUrl(status),
          null,
          null,
          { silent },
        ),
      );

      const mapData = patientMappings.mapDataToPatientLinksUrl(status, response.data);
      dispatch(success(mapData));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/**
 * Action to accept or reject a patient link.
 *
 * @param {number} linkId The ID value of the patient link to verify.
 * @param {boolean} accepted Whether the patient was accepted or rejected.
 */
const verifyPatient = (linkId, accepted) => {
  const success = () => (
    accepted
      ? { type: ACTION_TYPES.PATIENT_ACCEPTED, linkId }
      : { type: ACTION_TYPES.PATIENT_REJECTED, linkId }
  );

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PATCH,
          `${API_URLS.patientLinks}/${linkId}`,
          {
            status: accepted ? PATIENT_LINKS_STATUS.accepted : PATIENT_LINKS_STATUS.rejected,
          },
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification(
        accepted
          ? 'HOME.ERROR_MESSAGES.patientAcceptError'
          : 'HOME.ERROR_MESSAGES.patientRejectError',
      ));
    }
  };
};

/**
 * Action to sort the verified patients list.
 * Sort is done in the patient reducer.
 *
 * @param {string} sortKey The field (key) by which the patients list should be sorted
 * if it is the same as the one by which is currently sorted, it will reverse the order
 */
const sortPatients = (sortKey, listType) => ({
  type: ACTION_TYPES.PATIENT_LIST_SORT,
  sortKey,
  listType,
});

/**
 * Action to set data of a patient into the redux store
 * @param {object} data Patient information
 */
const setPatientInfo = (data) => ({
  type: ACTION_TYPES.PATIENT_SET_INFO,
  data,
});

/**
 * Action to fetch basic patient information
 * @param {number} patientId
 */
const fetchPatientInfo = (patientId) => {
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_SET_INFO, data });
  const failure = (errorCode) => ({ type: ACTION_TYPES.PATIENT_ERROR, error: errorCode });

  return async (dispatch) => {
    dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_ERROR });
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientInfo.replace(':patientId', patientId),
        ),
      );
      dispatch(success(patientMappings.mapServerPatientInfoToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/**
 * Action to fetch basic invited patient information
 * @param {number} patientId
 */
const fetchInvitedPatientInfo = (patientId) => {
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_INVITE_SET_INFO, data });
  const failure = (errorCode) => ({ type: ACTION_TYPES.PATIENT_ERROR, error: errorCode });

  return async (dispatch) => {
    dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_ERROR });
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.invitedPatientInfo.replace(':patientId', patientId),
        ),
      );
      dispatch(success(patientMappings.mapServerPatientInfoToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/**
 * Action to fetch basic invited patient information
 * @param {number} patientId
 */
const fetchVerifiedPatientInfo = (patientId) => async (dispatch) => {
  try {
    const response = await dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.verifiedPatientInfo.replace(':patientId', patientId),
      ),
    );
    return response.data;
  } catch (errorCode) {
    return {};
  }
};

/**
 * Action to fetch basic invited patient information
 * @param {number} patientId
 */
const fetchNeverLinkedPatientInfo = (patientId) => {
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_INVITE_SET_INFO, data });
  const failure = (errorCode) => ({ type: ACTION_TYPES.PATIENT_ERROR, error: errorCode });

  return async (dispatch) => {
    dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_ERROR });
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.neverLinkedPatientInfo.replace(':patientId', patientId),
        ),
      );
      dispatch(success(patientMappings.mapServerPatientInfoToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/** Action to fetch patient seizure top line metrics data. */
const fetchTopLineSeizureData = (patientId, startDate, endDate) => requestSeizureData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_SUCCESS,
    request: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_REQUEST,
    error: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_ERROR,
  },
);

/** Action to fetch long term patient seizure metrics data. */
const fetchLongTermSeizureData = (patientId, startDate, endDate) => requestSeizureData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_SUCCESS,
    request: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_REQUEST,
    error: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_ERROR,
  },
);

/** Action to fetch patient symptoms top line metrics data. */
const fetchTopLineSymptomData = (patientId, startDate, endDate) => requestSymptomData(
  patientId,
  startDate,
  endDate,
  {
    request: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_REQUEST,
    success: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_SUCCESS,
    error: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_ERROR,
  },
);

/** Action to fetch long term patient symptoms metrics data. */
const fetchLongTermSymptomData = (patientId, startDate, endDate) => requestSymptomData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_SUCCESS,
    request: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_REQUEST,
    error: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_ERROR,
  },
);

/** Action to fetch long term patient medication adherence metrics data. */
const fetchLongTermMedicationAdherenceData = (patientId, startDate, endDate, status) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_REQUEST });
  const success = (data) => ({
    type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_SUCCESS,
    data,
  });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientMedicationAdherence.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
            status,
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientMedicationAdherenceToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchLatestSurveyResults = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_SURVEYS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSurveys.replace(':patientId', patientId),
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSurveyResultsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchSurveyResults = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_SURVEYS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSurvey.replace(':patientId', patientId),
        ),
      );

      dispatch(success(patientMappings.mapServerPatientIDSurveyResultsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchAllSurveyData = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_ALL_SURVEYS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_ALL_SURVEYS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_ALL_SURVEYS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.surveys,
          null,
          { patientId },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientAllSurveyResultsToLocal(response.data)));
      return patientMappings.mapServerPatientAllSurveyResultsToLocal(response.data);
    } catch (errorCode) {
      dispatch(failure());
      return [];
    }
  };
};

const deactivateSurveyAssignment = (surveyId) => {
  const success = () => ({
    type: ACTION_TYPES.PATIENT_ALL_SURVEYS_DEACTIVATED,
    data: { surveyId },
  });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          API_URLS.deactivateSurvey
            .replace(':surveyId', surveyId),
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.surveyNotFound'));
    }
  };
};

const callPdfFunction = (surveyId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_ALL_SURVEYS_REQUEST });
  // const success = (data) => ({ type: ACTION_TYPES.PATIENT_ALL_SURVEYS_SUCCESS, data });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.surveyPdf
            .replace(':surveyId', surveyId),
          null,
          {},
          {},
          'arraybuffer',
        ),
      );
      const url = await window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(url, '_blank');
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.surveyNotFound'));
    }
  };
};
/**
 * Action to assign or edit medication regimen to a patient
 *
 * @param {object} medications Medication regimen to be assigned to the patient
 * @param {number} patientId Id of the patient
 * @param {number} [regimenId] Id of the regimen, set only when this is an edit (update) action
 */
const assignRegimen = (medications, patientId, regimenId, note, hcpNote) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_REGIMEN_ASSIGNED });

  const url = regimenId
    ? API_URLS.patientRegimen.replace(':patientId', patientId).replace(':regimenId', regimenId)
    : API_URLS.patientRegimens.replace(':patientId', patientId);
  return (dispatch) => {
    const payload = {
      medications: titrationMappings.mapLocalMedicationsToServer(medications),
      note,
      hcpNote,
    };

    return dispatch(
      apiRequest(
        regimenId ? API_REQUEST_METHODS.PUT : API_REQUEST_METHODS.POST,
        url,
        payload,
      ),
    ).then(() => {
      appInsights.trackEvent({ name: TRACKING_EVENTS.successfullyAssignedMedication });
      dispatch(success());
    }).catch((errorCode) => {
      dispatch(appActions.appPushNotification('ASSIGN_REGIMEN.ERROR_MESSAGES.assignRegimenError'));
      return Promise.reject(errorCode);
    });
  };
};

/**
 * Action to assign or edit medication regimen to a patient
 *
 * @param {object} medications Medication regimen to be assigned to the patient
 * @param {number} patientId Id of the patient
 * @param {number} [regimenId] Id of the regimen, set only when this is an edit (update) action
 */
const assignInvitedRegimen = (medications, patientId, regimenId, note, hcpNote) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_REGIMEN_ASSIGNED });

  const uri = regimenId
    ? API_URLS.invitedPatientRegimen.replace(':patientId', patientId).replace(':regimenId', regimenId)
    : API_URLS.invitedPatientRegimens.replace(':patientId', patientId);
  return (dispatch) => {
    const payloads = {
      medications: titrationMappings.mapLocalMedicationsToServer(medications),
      note,
      hcpNote,
    };

    return dispatch(
      apiRequest(
        regimenId ? API_REQUEST_METHODS.PUT : API_REQUEST_METHODS.POST,
        uri,
        payloads,
      ),
    ).then(() => {
      appInsights.trackEvent({ name: TRACKING_EVENTS.successfullyAssignedMedication });
      dispatch(success());
    }).catch((errorCode) => {
      dispatch(appActions.appPushNotification('ASSIGN_REGIMEN.ERROR_MESSAGES.assignRegimenError'));
      return Promise.reject(errorCode);
    });
  };
};

/**
 * Action to fetch (current and past) medication regimens of a patient
 *
 * @param {number} patientId
 */
const fetchMedicationRegimenData = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientRegimens.replace(':patientId', patientId),
        ),
      );
      const regimens = response.data.map(titrationMappings.mapServerTitrationToLocal);
      dispatch(success(_.orderBy(
        regimens,
        [REGIMENS_DEFAULT_SORT_KEY],
        [REGIMENS_DEFAULT_SORT_ORDER],
      )));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};


/**
 * Action to fetch medical history status of patient
 *
 * @param {number} patientId
 */
const getMedicalHistoryStatus = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_MEDICAL_HISTORY_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_MEDICAL_HISTORY_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_MEDICAL_HISTORY_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.medicalHistory.replace(':patientId', patientId),
          null,
          {},
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to fetch medical history of a patient
 *
 * @param {number} patientId
 * @param {object} params
 */
const getEMRExportData = (patientId, params) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_EMR_REPORT_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_EMR_REPORT_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_EMR_REPORT_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.emrReport.replace(':patientId', patientId),
          null,
          params,
          { silent: false },
        ),
      );
      if (response.data.patientSeizures) {
        response.data.patientSeizures = patientMappings.mapServerPatientSeizuresToLocal(
          response.data.patientSeizures,
        );
      }
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};


/**
 * Action to fetch (current and past) medication regimens of a patient
 *
 * @param {number} patientId
 */
const fetchMedicationRegimenDataInvitedPatient = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.invitedPatientRegimens.replace(':patientId', patientId),
        ),
      );
      const regimens = response.data.map(titrationMappings.mapServerTitrationToLocal);
      dispatch(success(_.orderBy(
        regimens,
        [REGIMENS_DEFAULT_SORT_KEY],
        [REGIMENS_DEFAULT_SORT_ORDER],
      )));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to deactivate a medication regimen of a patient.
 *
 * @param {string} patientId The id of the patient to deactivate the medication regimen
 * @param {string} regimenId The id of the medication regimen to be deactivated
 */
const deactivateMedicationRegimen = (patientId, regimenId) => {
  const success = () => ({
    type: ACTION_TYPES.PATIENT_MEDICATION_REGIMEN_DEACTIVATED,
    data: { regimenId },
  });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          API_URLS.patientRegimen
            .replace(':patientId', patientId)
            .replace(':regimenId', regimenId),
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.deactivateMedicationRegimenError'));
    }
  };
};

/**
 * Action to deactivate a medication regimen of a invited patient.
 *
 * @param {string} patientId The id of the patient to deactivate the medication regimen
 * @param {string} regimenId The id of the medication regimen to be deactivated
 */
const deactivateMedicationRegimenInvitedPatient = (patientId, regimenId) => {
  const success = () => ({
    type: ACTION_TYPES.PATIENT_MEDICATION_REGIMEN_DEACTIVATED,
    data: { regimenId },
  });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          API_URLS.invitedPatientRegimen
            .replace(':patientId', patientId)
            .replace(':regimenId', regimenId),
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.deactivateMedicationRegimenError'));
    }
  };
};

/**
 * Action to set seizure and side-effects thresholds for a patient.
 *
 * @param {string} patientId The id of the patient to set the thresholds for.
 * @param {object} data Data object with seizures and side-effects thresholds.
 */
const setThresholds = (patientId, data) => {
  const payload = thresholdsMappings.mapLocalThresholdsToServer(data);
  return async (dispatch) => (
    dispatch(
      apiRequest(
        API_REQUEST_METHODS.PUT,
        API_URLS.patientThresholds.replace(':patientId', patientId),
        payload,
      ),
    ).catch((errorCode) => {
      dispatch(appActions.appPushNotification('THRESHOLD.ERROR_MESSAGES.saveThresholdError'));
      return Promise.reject(errorCode);
    })
  );
};


/**
 * Action to edit invited patient data.
 *
 * @param {string} patientId The id of the invited patient.
 * @param {object} data Data object.
 */
const editInvitedPatientDialog = (patientId, data) => async (dispatch) => (
  dispatch(
    apiRequest(
      API_REQUEST_METHODS.PATCH,
      API_URLS.invitedPatientInfo.replace(':patientId', patientId),
      {
        ...data,
      },
    ),
  ).catch((errorCode) => {
    dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.editUnlinkPatientError'));
    return Promise.reject(errorCode);
  })
);

/**
 * Action to fetch (seizure and side effects) thresholds set for a patient
 *
 * @param {number} patientId
 */
const fetchThresholds = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientThresholds.replace(':patientId', patientId),
        ),
      );
      const thresholds = thresholdsMappings.mapServerThresholdsToLocal(response.data);
      dispatch(success(thresholds));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchLatestThresholdEvents = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientEvents.replace(':patientId', patientId),
          null,
          {
            eventType: [THRESHOLD_EVENT_TYPES.seizure, THRESHOLD_EVENT_TYPES.side_effect],
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientEventsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to mark an exceeded threshold as seen.
 *
 * @param {string} thresholdId The threshold ID associated with the event.
 */
const markThresholdAsSeen = (thresholdId) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_SEEN_SUCCESS, thresholdId });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PUT,
          API_URLS.patientThresholdSeen.replace(':thresholdId', thresholdId),
          {
            seen: true,
          },
        ),
      );

      return dispatch(success());
    } catch (errorCode) {
      return Promise.reject(errorCode);
    }
  };
};

/**
 * Action to unlink a patient from his HCP.
 *
 * @param {number} linkId The link ID associated with the patient.
 */
const unlinkPatient = (linkId) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_UNLINK_SUCCESS, linkId });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PUT,
          API_URLS.patientUnlink.replace(':linkId', linkId),
          {
            value: true,
          },
        ),
      );

      return dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.unlinkPatientError'));
      return Promise.reject(errorCode);
    }
  };
};

/**
 * Action to get a patient refractory.
 *
 * @param {string} patientId The ID associated with the patient.
 */
const getPatientRefractory = (patientId) => async (dispatch) => {
  try {
    const response = await dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.patientRefractoryStatus.replace(':linkId', patientId),
      ),
    );
    return dispatch({
      type: ACTION_TYPES.PATIENT_REFRACTORY_SUCCESS,
      data: _.get(response, 'data'),
    });
  } catch (errorCode) {
    dispatch({
      type: ACTION_TYPES.PATIENT_REFRACTORY_FAILURE,
    });
    return [];
  }
};

const clearPatientRefractory = () => ({
  type: ACTION_TYPES.PATIENT_CLEAR_REFRACATORY,
});

const refractoryTableUpdateSuccess = (linkId, newStatus) => ({
  type: ACTION_TYPES.PATIENT_REVIEW_STATUS_SUCCESS,
  linkId,
  newStatus,
});

/**
 * Action to unlink a patient from his HCP.
 *
 * @param {string} linkId The ID associated with the patient.
 * @param {string} newStatus The new review status.
 */
const setPatientReviewStatus = (
  linkId, newStatus,
) => async (dispatch) => {
  try {
    await dispatch(
      apiRequest(
        API_REQUEST_METHODS.PATCH,
        API_URLS.patientRefractoryStatus.replace(':linkId', linkId),
        {
          status: newStatus,
        },
        null,
        { silent: true },
      ),
    );
    return Promise.resolve();
  } catch (errorCode) {
    return Promise.reject(errorCode);
  }
};

const getPatientAttachments = (patientId) => {
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_ATTACHMENTS_SUCCESS, data });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientAttachments.replace(':patientId', patientId),
          null,
        ),
      );

      return dispatch(success(response.data));
    } catch (errorCode) {
      return Promise.reject(errorCode);
    }
  };
};

const checkUserRegisration = (payload) => async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}${API_URLS.userRegistrationCheck}`,
      payload,
    );
    return response.data;
  } catch (errorCode) {
    return {};
  }
};

export default {
  fetchPatients,
  verifyPatient,
  unlinkPatient,
  sortPatients,
  fetchPatientInfo,
  fetchInvitedPatientInfo,
  fetchNeverLinkedPatientInfo,
  setPatientInfo,
  fetchTopLineSeizureData,
  fetchLongTermSeizureData,
  fetchTopLineSymptomData,
  fetchLongTermSymptomData,
  fetchLongTermMedicationAdherenceData,
  fetchLatestSurveyResults,
  fetchLatestThresholdEvents,
  assignRegimen,
  assignInvitedRegimen,
  fetchMedicationRegimenData,
  fetchMedicationRegimenDataInvitedPatient,
  deactivateMedicationRegimen,
  deactivateMedicationRegimenInvitedPatient,
  setThresholds,
  editInvitedPatientDialog,
  fetchThresholds,
  fetchSurveyResults,
  fetchAllSurveyData,
  deactivateSurveyAssignment,
  callPdfFunction,
  markThresholdAsSeen,
  setPatientReviewStatus,
  getPatientRefractory,
  clearPatientRefractory,
  refractoryTableUpdateSuccess,
  getPatientAttachments,
  getEMRExportData,
  getMedicalHistoryStatus,
  checkUserRegisration,
  fetchVerifiedPatientInfo,
};
