/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { withKeyNamespace } from 'utils/utils';
import { useHistory } from 'react-router-dom';
import palette from 'styles/colors';
import {
  Box, Divider, styled, Typography, useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { API_DATE_FORMAT } from 'Constants';
import { KnMetricsTitle, KnWidgetBox } from './styles';
import { SLOT_STATUS } from './adminCalendar/CalendarConstant';

const timeZone = 'America/Los_Angeles';

const i18nKey = withKeyNamespace('ADMIN_CALENDAR');
const { blue3 } = palette.blue;
const { dark1 } = palette.green;

const KnSourceTitle = styled(Typography)({
  fontSize: 12,
});

const KnAppointmentStatus = styled(Typography)(({ status }) => ({
  fontSize: 14,
  color: status === SLOT_STATUS.BOOKED ? dark1 : blue3,
  marginRight: 5,
}));

const KnAppointmentDate = styled(Typography)({
  fontSize: 14,
  color: palette.black.black1,
});

const KnCalenarBox = styled(KnWidgetBox)({
  cursor: 'pointer',
});

const KnCalendarWidget = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:1490px)');

  const patientAppointment = useSelector((state) => get(state, 'calendar.patientAppointment.data'));

  const handleClick = () => {
    history.push({
      pathname: '/appointmentCalendar',
      state: { date: format(new Date(patientAppointment.endDate), API_DATE_FORMAT) },
    });
  };

  const pstZoneConvert = (startDate) => utcToZonedTime(new Date(startDate).toISOString(), timeZone);

  return (
    <>
      <Divider />
      <KnCalenarBox pl={1} mt={3} mb={3} display="block" onClick={handleClick}>
        <Box pt={1.5} mb={2} pb={3}>
          <KnSourceTitle display="block" component={KnMetricsTitle}>
            {translate(i18nKey('widget.title'))}
          </KnSourceTitle>
          <Box display={matches ? 'flex' : 'block'} mt={0.2}>
            <KnAppointmentStatus component={KnMetricsTitle} status={patientAppointment.status}>
              {translate(i18nKey(`widget.status.${patientAppointment.status}`))}
            </KnAppointmentStatus>
            <KnAppointmentDate>
              {format(pstZoneConvert(patientAppointment.startDate), 'p').split(' ')[0]}
              {' '}
              -
              {' '}
              {format(pstZoneConvert(patientAppointment.endDate), 'p').toLowerCase()}
              ,
              {!matches && <br />}
              {' '}
              {format(pstZoneConvert(patientAppointment.startDate), 'cccc, LLLL, d, yyyy')}
            </KnAppointmentDate>
          </Box>
        </Box>
      </KnCalenarBox>
    </>
  );
};

export default KnCalendarWidget;
