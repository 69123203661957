/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { REGIMEN_STATUS } from 'Constants';

const defaultRegimenPanelBorderColor = palette.secondary;

/**
 * Returns text color for regimen status, based on status,
 * Note: also used to get regimen card (bottom) color
 * @param {string} status
 */
export const getStatusColor = (status, reached) => {
  switch (status) {
    case REGIMEN_STATUS.notStarted:
      return palette.error.main;
    case REGIMEN_STATUS.active:
      return reached ? palette.green.dark : palette.brightOrange;
    default:
      /** default colour for a titration card */
      return defaultRegimenPanelBorderColor;
  }
};

const KnStatusWrapper = styled('div')(({ theme, status, reached }) => ({
  alignSelf: 'center',
  padding: theme.spacing(0.5, 3),
  fontSize: 14,
  fontWeight: 500,
  color: getStatusColor(status, reached),
}));

/**
 * Displays the a regimen status,
 *  by translating it, and setting proper colors for it
 * @param {string} status Status of a regimen, to be displayed
 */
const KnRegimenStatus = ({ status, reachedGoal, startedAt }) => {
  const { t: translate } = useTranslation();
  const translationStatusKey = reachedGoal ? 'reachedGoal' : status;

  const getStatus = () => {
    if (status === 'active' && !reachedGoal) {
      return translate(
        `PATIENT_RECORD.medicationRegimen.STATUS_VALUES.${status}`,
        { date: startedAt },
      );
    }
    return translate(
      'PATIENT_RECORD.medicationRegimen.status',
      { status: translate(`PATIENT_RECORD.medicationRegimen.STATUS_VALUES.${translationStatusKey}`) },
    );
  };

  return (
    <Typography status={status} reached="true" component={KnStatusWrapper} data-testid="regimen-status">
      {getStatus()}
    </Typography>
  );
};

KnRegimenStatus.defaultProps = {
  startedAt: null,
};

KnRegimenStatus.propTypes = {
  status: PropTypes.oneOf([
    REGIMEN_STATUS.notStarted,
    REGIMEN_STATUS.active,
    REGIMEN_STATUS.completed,
  ]).isRequired,
  reachedGoal: PropTypes.bool.isRequired,
  startedAt: PropTypes.instanceOf(Date),
};

export default KnRegimenStatus;
