import { subDays } from 'date-fns';
import React from 'react';
import { styled, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { KnColoredText } from '../../components/Typography';
import { REGIMEN_STATUS_COLORS, REGIMEN_STATUS_LIST } from '../../Constants';
import { translate } from '../../i18n/i18n';
import KnEyeCloseIcon from '../../components/icons/EyeCloseIcon';
import KnEyeIcon from '../../components/icons/EyeIcon';
import palette from '../../styles/colors';
import { KnTextFieldBase } from '../../components/TextField';

const redColor = palette.copper;

export const LinkButton = withStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 30,
    fontSize: 16,
    fontWeight: 500,
  },
})(Button);

export const KnSearchInput = styled(KnTextFieldBase)({
  '& .MuiFilledInput-root': {
    backgroundColor: palette.white.white1,
  },
  width: 458,
});

const regimenStatusDisplay = (latestRegimenStatus) => (
  <KnColoredText color={REGIMEN_STATUS_COLORS[latestRegimenStatus]}>
    {REGIMEN_STATUS_LIST[latestRegimenStatus]}
  </KnColoredText>
);

const thresholdMetDisplay = (rowData) => {
  if (rowData.thresholdMet) {
    return (
      <KnColoredText color={redColor}>
        {translate('GENERAL.date', { date: rowData.thresholdMet })}
      </KnColoredText>
    );
  }
  return '-';
};

const statusDisplay = (isDeleted) => {
  if (isDeleted) {
    return (
      <KnColoredText color={redColor}>
        {translate('HOME.accountDeleted')}
      </KnColoredText>
    );
  }
  return 'Unlinked';
};

const dateInvitedDisplay = (rowData) => {
  const dateInvitedFormatted = translate('GENERAL.date', { date: rowData.dateInvited });
  return subDays(new Date(), 3) > rowData.dateInvited
    ? <KnColoredText color={redColor}>{dateInvitedFormatted}</KnColoredText>
    : dateInvitedFormatted;
};

const reviewStatusDisplay = (status, linkId, onButtonClick) => {
  let buttonColor = 'inherit';
  let newStatus = 'unreviewed';
  let isCloseIcon = true;

  if (status === 'unreviewed') {
    buttonColor = 'primary';
    newStatus = 'reviewed';
    isCloseIcon = false;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    onButtonClick(linkId, newStatus);
  };

  return (
    <LinkButton color={buttonColor} data-testid="review-status" onClick={handleClick}>
      {isCloseIcon ? <KnEyeCloseIcon /> : <KnEyeIcon color={palette.primary} />}
      <span style={{ marginLeft: 10, fontWeight: 'normal', textTransform: 'capitalize' }}>{status}</span>
    </LinkButton>
  );
};

export const getTableColumns = (columns, onButtonClick) => {
  const tableColumns = [
    {
      name: translate('HOME.tableHeaders.lastName'),
      valueAccessor: 'lastName',
      sortKey: 'lastName',
    },
    {
      name: translate('HOME.tableHeaders.firstName'),
      valueAccessor: 'firstName',
      sortKey: 'firstName',
    },
    {
      name: translate('GENERAL.dob'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dob }),
      sortKey: 'dob',
      switchable: true,
    },
    {
      name: translate('HOME.tableHeaders.accountNumber'),
      valueAccessor: 'accountNumber',
      sortKey: 'accountNumber',
      switchable: true,
    },
    {
      name: translate('HOME.tableHeaders.emailAddress'),
      valueAccessor: 'email',
      sortKey: 'email',
      switchable: true,
    },
    {
      name: translate('HOME.tableHeaders.physician'),
      valueAccessor: 'physician',
      sortKey: 'physician',
      filterable: true,
    },
    {
      name: translate('HOME.tableHeaders.threshold'),
      valueAccessor: thresholdMetDisplay,
      sortKey: 'latestThresholdDate',
    },
    {
      name: translate('HOME.tableHeaders.dateOfDeactivation'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dateOfDeactivation }),
      sortKey: 'dateOfDeactivation',
    },
    {
      name: translate('HOME.tableHeaders.dateInvited'),
      valueAccessor: dateInvitedDisplay,
      sortKey: 'dateInvited',
    },
    {
      name: translate('HOME.tableHeaders.regimenStatus'),
      valueAccessor: (rowData) => regimenStatusDisplay(rowData.latestRegimenStatus),
      sortKey: 'latestRegimenStatus',
      filterable: true,
    },
    {
      name: translate('HOME.tableHeaders.status'),
      valueAccessor: (rowData) => statusDisplay(rowData.isDeleted),
      sortKey: 'isDeleted',
    },
    {
      name: translate('HOME.tableHeaders.healthSystem'),
      valueAccessor: 'healthSystemName',
      sortKey: 'healthSystemName',
      filterable: true,
      switchable: true,
    },
    {
      name: translate('HOME.tableHeaders.reviewStatus'),
      valueAccessor: (rowData) => reviewStatusDisplay(
        rowData.reviewStatus, rowData.linkId, onButtonClick,
      ),
      sortKey: 'status',
    },
    {
      name: translate('HOME.tableHeaders.lastActive'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.lastActive }),
      sortKey: 'lastActive',
    },
  ];

  return tableColumns.filter((col) => columns.includes(col.sortKey));
};
