/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styled } from '@material-ui/core/styles';
import { KnSubtleText } from 'components/Typography';
import { KnContrastTextField } from 'components/TextField';
import KnSearchIcon from 'components/icons/SearchIcon';
import palette from 'styles/colors';
import KnPlusIcon from 'components/icons/PlusIcon';
import { KnElevatedBrightBox } from 'styles/common';

const resultsSeparatorColor = palette.silver.silver2;
const disabledItemBackground = palette.silver.silver1;

const KnToolbarInput = styled(KnContrastTextField)({
  width: 398,
  paddingBottom: 0,
});

const KnSearchResults = styled(KnElevatedBrightBox)(({ theme }) => ({
  marginTop: theme.spacing(-6),
  maxHeight: 190,
  overflowY: 'auto',
  overflowX: 'hidden',
  zIndex: 999,
  width: '100%',
  position: 'absolute',
}));

const KnSearchResultItem = styled(Box)(({ theme, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1.5),
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: resultsSeparatorColor,
  backgroundColor: disabled ? disabledItemBackground : 'transparent',
  cursor: disabled ? 'default' : 'pointer',
}));

const KnSearchResultText = styled(Typography)({
  flex: 1,
});

const KnSearchContainer = styled(Box)({
  position: 'relative',
});

/**
 * Customizable drop down component with search functionality
 */
const KnSearchDropDown = (props) => {
  const {
    label,
    items,
    isItemDisabled,
    itemDisplay,
    onItemSelect,
    searchMatch,
    error,
    medication,
    medicationIndex,
    disabled,
  } = props;
  const { t: translate } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState(Object.keys(medication).length ? `${_.get(medication, 'activeIngredient')} (${_.get(medication, 'name')})` : '');
  const [searchResults, setSearchResults] = useState([]);

  /** This adds the search icon at the end of the text field. */
  const InputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <KnSearchIcon />
      </InputAdornment>
    ),
  }), []);

  const onInputFocus = useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  const onInputBlur = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const onDropdownSelect = (item, medIndex) => {
    onItemSelect(item, medIndex);
  };

  /**
   * By default,
   * the component will find items with 'value' starting with searchTerm case sensitive
   * @param {*} listItem
   * @param {*} searchTerm
   */
  const defaultSearchMatch = (listItem, term) => _.get(listItem, 'value', '').indexOf(term) === 0;

  const onSearchTermChange = useCallback(({ target: { value } }) => {
    setSearchTerm(value);
    /** Apply filter on the items list
     * If there's a custom search filter given, use it,
     *  otherwise go with the default matcher
     */
    const filteredList = _.filter(
      items,
      (item) => {
        if (searchMatch) {
          return searchMatch(item, value);
        }
        return defaultSearchMatch(item, value);
      },
    );
    setSearchResults(filteredList);
  }, [items, searchMatch]);

  return (
    <KnSearchContainer>
      <KnToolbarInput
        disabled={disabled}
        value={searchTerm}
        label={label}
        InputProps={InputProps}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={onSearchTermChange}
        error={(!focused || !searchTerm.trim()) ? error : {}}
        data-testid="search-dropdown-input-field"
      />
      {focused && searchTerm.trim() && (
        <KnSearchResults>
          {searchResults.map((item, index) => (
            <KnSearchResultItem
              key={item.id}
              data-testid={`search-dropdown-option-${index + 1}`}
              onMouseDown={() => onDropdownSelect(item, medicationIndex)}
              disabled={isItemDisabled(item)}
            >
              <KnSearchResultText variant="body2" component={KnSubtleText}>
                {itemDisplay(item)}
              </KnSearchResultText>
              <KnPlusIcon />
            </KnSearchResultItem>
          ))}
          {searchResults.length === 0 && (
            <KnSearchResultItem>
              <KnSearchResultText variant="body2" component={KnSubtleText}>
                {translate('GENERAL.noSearchResults')}
              </KnSearchResultText>
            </KnSearchResultItem>
          )}
        </KnSearchResults>
      )}
    </KnSearchContainer>
  );
};

KnSearchDropDown.propTypes = {
  /** Placeholder and label for the drop down input field */
  label: PropTypes.string.isRequired,
  /**
   * Array of objects for the dropdown
   * - objects can have any fields, only the unique `id` field is required
   * - `value` field will be used for default item display and when searching,
   * except when custom `itemDisplay` and `searchMatch` are given
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string,
  })).isRequired,
  /** Function that takes an item object, and returns `true` if item should be disabled */
  isItemDisabled: PropTypes.func,
  /** Function to be applied on item object to get custom display */
  itemDisplay: PropTypes.func,
  /** Callback function for item click */
  onItemSelect: PropTypes.func,
  /** Function for custom search functionality,
   * takes item object and input value, and returns `true` if item should be listed */
  searchMatch: PropTypes.func,
  /** Error object for error under the dropdown */
  error: PropTypes.shape({
    type: PropTypes.string,
    string: PropTypes.string,
  }),
  medication: PropTypes.shape(),
  medicationIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

KnSearchDropDown.defaultProps = {
  isItemDisabled: () => false,
  itemDisplay: (item) => item.value || '',
  onItemSelect: () => {},
  searchMatch: null,
  error: {},
  medicationIndex: 0,
  medication: {},
  disabled: false,
};

export default KnSearchDropDown;
