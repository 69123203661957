/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnLink from 'components/Link';
import KnButton from 'components/Button';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS, EMAIL_FORMAT } from 'Constants';
import KnValidatedTextField from 'components/ValidatedTextField';

const initialSignInInfo = {
  email: '',
  password: '',
};

const KnForgotPasswordText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
});

const KnForgotPasswordHint = ({ children }) => (
  <KnForgotPasswordText color="textSecondary">{children}</KnForgotPasswordText>
);

const SignInStep = ({ submitDisabled, onSuccess }) => {
  const { t: translate } = useTranslation();
  const {
    handleSubmit, errors, control,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialSignInInfo,
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the signIn call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(loginActions.signIn(formData))
      .then((response) => {
        if (!response.error) {
          /** Sign in was successful,
           * send response object to step 2 of the authentication flow */
          onSuccess({ signInResult: response });
        }
      });
  };

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('LOGIN.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <KnValidatedTextField
          Component={KnTextField}
          name="email"
          control={control}
          errors={errors}
          defaultValue=""
          format={EMAIL_FORMAT}
          required
          trimSpaces
          maxLength={128}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="password"
          type="password"
          control={control}
          errors={errors}
          defaultValue=""
          required
          maxLength={256}
        />
        <Trans
          parent={KnForgotPasswordHint}
          i18nKey="LOGIN.forgotPassword"
          components={[
            <KnLink
              color="textSecondary"
              route={APP_PAGE_URLS.forgotPassword}
              data-testid="forgot-password-link"
            />,
          ]}
        />
        <Box
          pt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            route={APP_PAGE_URLS.register}
            data-testid="register-link"
          >
            {translate('LOGIN.registerButton')}
          </KnButton>
          <KnButton
            disabled={submitDisabled}
            data-testid="login-button"
          >
            {translate('LOGIN.submitButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

SignInStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

KnForgotPasswordHint.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignInStep;
