import _, { get } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import patientActions from 'redux/actions/patientActions';
import calendarActions from 'redux/actions/calendarActions';
import {
  APP_PAGE_URLS,
  METRICS_TYPE, TOPLINE_METRICS_BUCKET_SIZE, TOPLINE_METRICS_BUCKETS_COUNT,
} from 'Constants';
import Typography from '@material-ui/core/Typography';
import { getMetricsTimeRange } from 'utils/metrics';
import { KnSectionHeader, KnCardsSubtitle, KnHighlightText } from 'components/Typography';
import KnReportIcon from 'components/icons/ReportIcon';
import KnErrorMessage from 'components/ErrorMessage';
import KnLink, { KnActionLink } from 'components/Link';
import { useIsUserHospitalStaff, withKeyNamespace } from 'utils/utils';
import useDialog from 'components/dialog/DialogService';
import { styled } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import KnMetricsCard from './MetricsCard';
import KnPatientAchievementsReports from './PatientAchievementsReports';
import { KnPatientReportsBox } from './styles';
import KnPatientDialog from './DialogPatientReport';
import KnSurveyList from './SurveyList';
import KnSurveyDialog from './SurveyDialog';
import KnPatientRefractory from './PatientRefractory';
import KnPatientMedia from './PatientMedia';
import KnExportIcon from '../../components/icons/ExportIcon';
import KnCalendarWidget from './CalendarWidget';

const i18nKey = withKeyNamespace('PATIENT_RECORD');
const TOPLINE_METRICS_PERIOD = TOPLINE_METRICS_BUCKET_SIZE.days * TOPLINE_METRICS_BUCKETS_COUNT;

const KnActionLinkStyled = styled(KnActionLink)({
  marginTop: '-30px', paddingLeft: '90%',
});

const KnLinkAssignSurvey = styled(KnLink)({
  fontSize: 12, textDecoration: 'none',
});

const KnExportTitle = styled(KnHighlightText)({
  fontSize: 14,
  fontWeight: 500,
  marginLeft: 12,
});

const KnMedicalHistoryStatus = styled(Typography)({
  fontSize: 14,
  color: palette.paleGrey.paleGrey4,
  fontWeight: 500,
  marginLeft: 6,
});

const KnExportBox = styled(Box)({
  cursor: 'pointer',
});

/**
 * Component for displaying patient top line metrics
 * @param {string} patientId Id of the patient
 */
const KnPatientReports = ({ patientId, patientType, isNeverLinked }) => {
  const {
    seizuresMetrics,
    symptomsMetrics,
    patientInfo,
    surveyResults,
    allsurveyResults,
    thresholdEvents,
    patientRefractory,
    attachments,
    medicalHistoryStatus,
  } = useSelector((state) => state.patientRecord);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const dialog = useDialog();
  /** `metricsTimeRange` will end with 'today' date of the patient
   * We will be sending the endDate down to the KnMetricsCard,
   * so that it will be aware of the end date of the chart
    */
  const [metricsTimeRange, setMetricsTimeRange] = useState();
  const [surveys, setSuveys] = useState([]);

  const timeZone = get(patientInfo, 'data.timeZone');
  const { isAdmin, patientAppointment } = useSelector((state) => ({
    isAdmin: state.user.currentUser.isAdmin,
    patientAppointment: get(state, 'calendar.patientAppointment.data'),
  }));

  useEffect(() => {
    dispatch(patientActions.fetchSurveyResults(patientId));
    dispatch(patientActions.fetchAllSurveyData(patientId));
    dispatch(patientActions.getPatientRefractory(patientId));
    dispatch(patientActions.getPatientAttachments(patientId));
    dispatch(patientActions.getMedicalHistoryStatus(patientId));
    dispatch(calendarActions.getPatientUpcomingAppointment(patientId));
  }, [dispatch, patientId]);

  function findUpcomingDate(surveyDates, status) {
    for (let index = 0; index < surveyDates.length; index += 1) {
      const element = surveyDates[index];
      if (new Date(element).getTime() >= new Date().getTime() && status !== 'deactivated') {
        return new Date(element);
      }
    }
    return '';
  }

  const elementObject = useCallback((element) => {
    const { assignedSurvey = null, takenSurveys = [], assignedSurveys } = element;
    let surveyID;
    let finalData;
    const details = (takenSurveys.length > 0) || assignedSurveys.length;
    const takenSurvey = _.sortBy(takenSurveys, ['id']);
    const latestSurveyTaken = takenSurvey[takenSurvey.length - 1];
    const score = (latestSurveyTaken && latestSurveyTaken.score !== null) ? `${latestSurveyTaken.score} out of ${element.totalScore}` : 'N/A';
    const completedDate = (latestSurveyTaken)
      ? new Date(latestSurveyTaken.takenDate) : new Date();
    const surveyDates = (element.assignedSurvey) ? element.assignedSurvey.surveyDates : [];
    const status = element.assignedSurvey && element.assignedSurvey.status;
    const upcomingSurvey = findUpcomingDate(surveyDates, status);
    const object = (element.assignedSurvey) ? { ...element.assignedSurvey } : {};
    const active = (element.isActive) ? !!assignedSurvey : false;
    const takenSurveyLength = takenSurvey.length;
    if (assignedSurvey) {
      surveyID = assignedSurvey.id;
      finalData = {
        ...object, ...element, active, surveyID, patientId,
      };
    } else {
      const latestAssignedSurvey = _.sortBy(assignedSurveys, ['id']);
      const obj = latestAssignedSurvey[latestAssignedSurvey.length - 1];
      surveyID = (obj) && obj.id;
      finalData = {
        ...obj, ...element, active, surveyID, patientId,
      };
    }

    return {
      title: element.title,
      taken: (element.takenSurveys.length > 0),
      recurring: !!(upcomingSurvey),
      score,
      completedDate,
      upcomingSurvey,
      finalData,
      details,
      takenSurveyLength,
      assignedSurveys,
    };
  }, [patientId]);

  const createSurveyList = useCallback((data1) => {
    if (data1) {
      const survey = [];
      for (let index = 0; index < data1.length; index += 1) {
        const element = data1[index];
        const object = elementObject(element);
        const { details } = object;

        if (details) {
          survey.push(object);
        }
      }
      setSuveys(survey);
    }
  }, [elementObject]);

  useEffect(() => {
    const data1 = (allsurveyResults) ? allsurveyResults.data : [];
    createSurveyList(data1);
  }, [allsurveyResults, createSurveyList, patientId]);

  useEffect(() => {
    /** We need patient timezone loaded, to decide the time range for the symptoms metrics */
    if (timeZone) {
      /** Note: Currently, patient timezone is not considered for seizures metrics.
       * I expect this to change in the future.
       */
      const { startDate, endDate } = getMetricsTimeRange({
        timezone: timeZone,
        offset: TOPLINE_METRICS_PERIOD,
      });
      setMetricsTimeRange({ startDate, endDate });
      if (!patientType) {
        dispatch(patientActions.fetchTopLineSeizureData(
          patientId,
          startDate,
          endDate,
        ));

        dispatch(patientActions.fetchTopLineSymptomData(
          patientId,
          startDate,
          endDate,
        ));
      }
    }
  }, [dispatch, patientId, timeZone, patientType]);

  useEffect(() => {
    if (!patientType) {
      // dispatch(patientActions.fetchLatestSurveyResults(patientId));
      dispatch(patientActions.fetchLatestThresholdEvents(patientId));
    }
  }, [dispatch, patientId, patientType]);

  /** Retry handlers for error cases */
  const redoFetchSeizures = useCallback(() => {
    if (!patientType) {
      dispatch(patientActions.fetchTopLineSeizureData(
        patientId,
        metricsTimeRange.startDate,
        metricsTimeRange.endDate,
      ));
    }
  }, [dispatch, patientId, metricsTimeRange, patientType]);

  const redoFetchSymptoms = useCallback(() => {
    if (!patientType) {
      dispatch(patientActions.fetchTopLineSymptomData(
        patientId,
        metricsTimeRange.startDate,
        metricsTimeRange.endDate,
      ));
    }
  }, [dispatch, patientId, metricsTimeRange, patientType]);

  const redoFetchSurveyResults = useCallback(() => {
    if (!patientType) dispatch(patientActions.fetchLatestSurveyResults(patientId));
  }, [dispatch, patientId, patientType]);

  const redoFetchThresholdEvents = useCallback(() => {
    if (!patientType) dispatch(patientActions.fetchLatestThresholdEvents(patientId));
  }, [dispatch, patientId, patientType]);

  const onSetStrengthClick = useCallback(() => {
    dialog({
      customDialog: KnPatientDialog,
      inputData: {
        patientId, // medicationStrength, medicationType, medicationTypeUnit
      },
    });
  }, [dialog, patientId]);

  const reopenDialog = useCallback((patientid) => new Promise((resolve) => {
    dispatch(patientActions.fetchAllSurveyData(patientid)).then((e) => {
      resolve({ obj: e, check: true });
    }).catch(() => resolve({ obj: [], check: false }));
  }), [dispatch]);

  const endSurveyAssignment = useCallback((surveyID, patientid, surveyId) => {
    dispatch(patientActions.deactivateSurveyAssignment(surveyID));
    dispatch(patientActions.fetchSurveyResults(patientId));
    setTimeout(async () => {
      const { obj, check } = await reopenDialog(patientid);
      if (check) {
        const element = _.find(obj, { surveyId });
        const survey = elementObject(element);
        dialog({
          customDialog: KnSurveyDialog,
          inputData: {
            patientId, survey, endSurveyAssignment,
          },
        });
      }
    }, 1000);
  }, [dialog, dispatch, elementObject, patientId, reopenDialog]);

  const onSetSurveyDetails = useCallback((survey, hideSchedule) => {
    dialog({
      customDialog: KnSurveyDialog,
      inputData: {
        patientId, survey, endSurveyAssignment, hideSchedule,
      },
    });
  }, [dialog, patientId, endSurveyAssignment]);

  const handleExportClick = useCallback((isMedicalHistory) => {
    history.push(
      APP_PAGE_URLS.patientEMRExport.replace(':patientId', patientId),
      {
        isNeverLinked,
        isMedicalHistory,
      },
    );
  }, [history, patientId, isNeverLinked]);

  const canAssignSurvey = useIsUserHospitalStaff();

  const handleSeenClick = useCallback((id) => {
    dispatch(patientActions.markThresholdAsSeen(id));
  }, [dispatch]);

  return (
    <KnPatientReportsBox mr={2} mb={2}>
      <Box>
        <Typography variant="h6" style={{ fontSize: '17px' }} component={KnSectionHeader}>
          {translate(i18nKey('patientReports.title'))}
        </Typography>
        <Box>
          <KnActionLinkStyled
            LhsIcon={KnReportIcon}
            tooltip
            tooltipText={translate('ICON_TOOLTIP.report')}
            onClick={() => onSetStrengthClick()}
          />
        </Box>
        <Typography component={KnCardsSubtitle}>
          {translate(i18nKey('patientReports.subtitle'), { count: TOPLINE_METRICS_PERIOD })}
        </Typography>
      </Box>

      {!patientType && (
        <>
          <KnExportBox mb={3} mt={2} display="flex" alignItems="center" onClick={() => handleExportClick(false)}>
            <KnExportIcon />
            <Typography component={KnExportTitle} data-testid="emr-report-link">
              {translate(i18nKey('patientReports.emrExport'))}
            </Typography>
          </KnExportBox>
          <KnExportBox mb={2} display="flex" alignItems="center" onClick={() => handleExportClick(true)}>
            <KnExportIcon />
            <Typography component={KnExportTitle} data-testid="medical-history-report-link">
              {translate(i18nKey('patientReports.medicalHistoryReport'))}
            </Typography>
            {medicalHistoryStatus.data !== null && (
              <KnMedicalHistoryStatus>
                {translate(
                  i18nKey(`patientReports.medicalHistoryStatus.${medicalHistoryStatus.data.status || 'false'}`),
                )}
              </KnMedicalHistoryStatus>
            )}
          </KnExportBox>
        </>
      )}

      {isAdmin && patientAppointment && <KnCalendarWidget />}

      {!_.isEmpty(patientRefractory) && (
        <KnPatientRefractory
          disableButton={isNeverLinked}
          onSetSurveyDetails={onSetSurveyDetails}
          survey={surveys.filter((s) => s.finalData.surveyId === 'OYC-1')[0]}
        />
      )}

      {seizuresMetrics.data && metricsTimeRange
        && (
          <KnMetricsCard
            data={seizuresMetrics.data}
            type={METRICS_TYPE.seizure}
            timeRangeEnd={metricsTimeRange.endDate}
          />
        )}

      <KnErrorMessage
        error={seizuresMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.seizuresFetchError')}
        onRetry={redoFetchSeizures}
        centered={false}
        mb={2}
      />

      {symptomsMetrics.data && metricsTimeRange
        && (
          <KnMetricsCard
            data={symptomsMetrics.data}
            type={METRICS_TYPE.symptom}
            timeRangeEnd={metricsTimeRange.endDate}
          />
        )}

      <KnErrorMessage
        error={symptomsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.symptomsFetchError')}
        onRetry={redoFetchSymptoms}
        centered={false}
        mb={2}
      />

      <KnPatientAchievementsReports
        events={thresholdEvents.data}
        handleSeenClick={handleSeenClick}
      />
      {!patientType && (
        <KnErrorMessage
          error={surveyResults.error}
          messageKey={i18nKey('ERROR_MESSAGES.surveyResultsFetchError')}
          onRetry={redoFetchSurveyResults}
          centered={false}
          mb={2}
          data-testid="survey-results-fetch-error"
        />
      )}
      <KnErrorMessage
        error={thresholdEvents.error}
        messageKey={i18nKey('ERROR_MESSAGES.eventsFetchError')}
        onRetry={redoFetchThresholdEvents}
        centered={false}
        mb={2}
        data-testid="threshold-met-fetch-error"
      />


      {!patientType && (
        <>
          <Divider style={{ marginBottom: '14px', marginTop: '35px' }} />
          <Box mb={3}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" style={{ fontSize: '17px' }} component={KnSectionHeader}>
                {translate(i18nKey('surveyInsights.patientSurvey'))}
              </Typography>
              {canAssignSurvey && !isNeverLinked && (
              <KnLinkAssignSurvey route={APP_PAGE_URLS.assignSurvey.replace(':patientId', patientId)}>
                {translate(i18nKey('surveyInsights.assignSurvey'))}
              </KnLinkAssignSurvey>
              )}
            </Box>
            <KnSurveyList
              surveys={surveys.filter((s) => s.finalData.surveyId !== 'OYC-1')}
              surveyResults={surveyResults.data}
              onSetSurveyDetails={onSetSurveyDetails}
            />
          </Box>
          <Divider />
        </>
      )}
      {attachments.length > 0 && <KnPatientMedia />}
    </KnPatientReportsBox>
  );
};

KnPatientReports.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientType: PropTypes.bool.isRequired,
  isNeverLinked: PropTypes.bool,
};

KnPatientReports.defaultProps = {
  isNeverLinked: false,
};

export default KnPatientReports;
