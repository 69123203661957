/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, DialogContentText, styled } from '@material-ui/core';
import KnButton from 'components/Button';
import { withKeyNamespace } from 'utils/utils';
import { KnContrastTextField } from 'components/TextField';
import { NAME_FORMAT } from 'Constants';
import { format } from 'date-fns';
import KnDatePicker from '../../components/DatePicker';
import { formatValidator } from '../../utils/customFieldValidators';

const i18nKey = withKeyNamespace('PATIENT_RECORD.editInvitedPatientDialog');

const KnButtonsWrappers = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > *:first-child': {
    marginBottom: theme.spacing(1),
  },
}));

const KnDialogInvitePatientEditInfo = ({
  open,
  onSubmit,
  onClose,
  inputData,
}) => {
  const { t: translate } = useTranslation();
  const { firstName, lastName, dob } = inputData;
  const [fields, setFields] = useState({ firstName, lastName, dob: new Date(dob).toString() === 'Invalid Date' ? null : dob });
  const handleFieldChange = (field, value) => setFields({ ...fields, [field]: value });

  const handleNameChange = (field, value) => {
    if (value.length === 0) handleFieldChange(field, value);
    if (formatValidator(value, NAME_FORMAT, false) && value.length <= 30) {
      handleFieldChange(field, value);
    }
  };

  const handleSubmit = () => {
    const data = { ...fields };
    if (data.dob) data.dob = format(data.dob, 'yyyy-MM-dd');
    onSubmit(data);
  };

  const disableSave = useMemo(() => {
    const initalData = (firstName || lastName || dob) !== 'N/A';
    const manipulatedData = [!!fields.firstName, !!fields.lastName, !!fields.dob];
    if (initalData && manipulatedData.every((s) => s === false)) {
      return !initalData;
    }

    return !manipulatedData.some((s) => s === true)
    || (fields.lastName || '').length === 1
    || (fields.firstName || '').length === 1;
  }, [firstName, lastName, dob, fields]);

  if (open) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        <DialogTitle data-testid="dialog-title" onClose={onClose}>
          <Box display="flex" justifyContent="space-between">
            {translate(i18nKey('title'))}
          </Box>
        </DialogTitle>
        <DialogContent data-testid="dialog-content">
          <Box style={{ flexDirection: 'column' }}>
            <KnContrastTextField
              value={fields.lastName}
              onChange={(e) => handleNameChange('lastName', e.target.value)}
              label={translate(i18nKey('lastName'))}
              error={(fields.lastName || '').length === 1 ? { type: 'error', message: 'Last Name should be greater than 1 character' } : {}}
            />
            <KnContrastTextField
              value={fields.firstName}
              onChange={(e) => handleNameChange('firstName', e.target.value)}
              label={translate(i18nKey('firstName'))}
              error={(fields.firstName || '').length === 1 ? { type: 'error', message: 'First Name should be greater than 1 character' } : {}}
            />
            <KnDatePicker
              value={fields.dob}
              onChange={(value) => handleFieldChange('dob', value)}
              label={translate(i18nKey('dob'))}
              allowSameDateSelection
              disableFuture
            />
            <DialogContentText data-testid="dialog-content-text">
              {translate(i18nKey('message'))}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions data-testid="dialog-actions">
          <KnButtonsWrappers style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <KnButton
              variant="text"
              onClick={onClose}
              data-testid="dialog-reset-button"
            >
              {translate(i18nKey('cancel'))}
            </KnButton>
            <KnButton
              onClick={handleSubmit}
              disabled={disableSave}
              data-testid="dialog-submit-button"
            >
              {translate(i18nKey('save'))}
            </KnButton>
          </KnButtonsWrappers>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

KnDialogInvitePatientEditInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
  }),
};

KnDialogInvitePatientEditInfo.defaultProps = {
  inputData: {
    firstName: undefined,
    lastName: undefined,
    dob: null,
  },
};

export default KnDialogInvitePatientEditInfo;
