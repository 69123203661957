import { format } from 'date-fns';
import {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const useSurveyText = () => {
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();
  const emrReport = useSelector((state) => state.patientRecord.emrReport);
  const { patientSurveys = [] } = emrReport.data || {};
  const surveyTitle = `${translate('PATIENT_RECORD.patientReports.surveyEMR.title')}`;

  const defaultText = useMemo(() => {
    let text = '\n';
    text += surveyTitle;
    text += '\n';

    if (patientSurveys && !patientSurveys.length) {
      return `\n${surveyTitle}\n`;
    }

    patientSurveys.forEach((survey) => {
      text += `\n  - ${survey.surveyName} (${survey.surveyId}), ${format(new Date(survey.takenDate), 'MM/dd/yyyy, p')},`;
      if (survey.totalScore) {
        text += `\n    ${translate('PATIENT_RECORD.patientReports.surveyEMR.score', {
          score: survey.score,
          totalScore: survey.totalScore,
        })}`;
      }
      text += '\n';
    });
    return text;
  }, [
    translate, patientSurveys, surveyTitle,
  ]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText]);

  return inputText;
};

export default useSurveyText;
