/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

/**
 * useCognitoUser is a react hook that tracks the authenticated user
 */
const useCognitoUser = () => {
  /**
   * We initialize the user with undefined, meaning we are
   * waiting for the AWS Auth data.
   */
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    let didCancel = false;
    const updateUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (!didCancel) {
          setUser(currentUser);
        }
      } catch (error) {
        if (!didCancel) {
          setUser(null);
        }
      }
    };
    Hub.listen('auth', updateUser); /** listen for login/signup events */

    /** we are not using async to wait for updateUser
     * so there will be a flash of page where the user is assumed not to be logged in
     */
    updateUser(); /** check manually the first time because we won't get a Hub event */
    return () => {
      didCancel = true;
      Hub.remove('auth', updateUser); /** cleanup */
    };
  }, []);

  return user;
};

export default useCognitoUser;
