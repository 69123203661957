/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnEditPatientInfoIcon = () => (
  <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 11.751v3.092h3.124l9.216-9.119-3.125-3.092L0 11.752zM14.757 3.333a.815.815 0 0 0 0-1.162L12.807.24a.836.836 0 0 0-1.175 0L10.107 1.75l3.125 3.092 1.525-1.509z" fill="#0A87A0" />
  </svg>
);

export default KnEditPatientInfoIcon;
