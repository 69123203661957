/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import {
  SEIZURE_TYPES,
  SEIZURES_COLORS,
  SYMPTOM_SEVERITY_COLORS,
  ADHERENCE_COLORS,
  SYMPTOM_TYPES,
  ADHERENCE_TYPES,
  REGIMEN_ADHERENCE_TYPES,
} from 'Constants';
import palette from 'styles/colors';
import KnDiamondIcon from 'components/icons/DiamondIcon';
import { withKeyNamespace } from 'utils/utils';
import { KnLegendText, KnMetricsMediumLabel } from '../styles';

const i18nKey = withKeyNamespace('PATIENT_RECORD');

const expandIconColor = palette.primary;
const filterBorder = palette.paleGrey.paleGrey2;
const filterBoxShadow = palette.paleGrey.paleGrey3;

const KnMedicationPanelSummaryUpdate = styled(ExpansionPanelSummary)({
  border: 'none',
  '& .MuiExpansionPanelSummary-expandIcon': {
    position: 'absolute',
    top: 15,
    right: 31,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});

const KnFilterWrapper = styled(Box)({
  width: '24%',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: filterBorder,
  boxShadow: `0 1px 1px 0 ${fade(filterBoxShadow, 0.15)}`,
  marginBottom: 8,
  marginRight: 8,
  alignItems: 'center',
  position: 'relative',
});

const KnTypeColorIndicator = styled('div')(({ color, theme }) => ({
  width: 14,
  height: theme.spacing(3),
  backgroundColor: color,
  position: 'absolute',
  top: 1,
  right: 1,
}));

// const getSymptomCircleSize = (type) => {
//   switch (type) {
//     case SYMPTOM_TYPES.severe:
//       return 17;
//     case 'title':
//     case SYMPTOM_TYPES.medium:
//       return 14;
//     default:
//       return 8;
//   }
// };

const KnSymptomColorIndicator = styled('div')(({ color, type, inline }) => ({
  width: 14,
  height: 14,
  borderRadius: 14,
  backgroundColor: color,
  marginBottom: type === 'title' ? -2 : 0,
  display: inline === 'true' ? 'inline-block' : 'block',
}));


const KnAdherenceColorIndicator = styled('div')(({ color, inline, type }) => ({
  width: 14,
  height: 14,
  borderRadius: 14,
  backgroundColor: color,
  marginTop: type === 'title' ? 10 : 0,
  display: inline === 'true' ? 'inline-block' : 'block',
}));

const KnEventText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  lineHeight: `${theme.spacing(4)}px`,
  fontSize: 15,
  color: palette.lightGrey,
  display: 'inline',
}));

const KnMetricsLegendWrapper = styled(Box)({
  width: 'calc(100vw - 500px)px',
});

const KnInsightsMetricsFilter = (props) => {
  const {
    type, count, category,
    onSelected, children, labelKey, flexDirection, ...rest
  } = props;
  const [checked, setChecked] = useState(false);
  const manuallyUnchecked = useRef(REGIMEN_ADHERENCE_TYPES.includes(type));

  useEffect(() => {
    /** We will make no updates on the filter if the user unchecked it explicitly. */
    if (manuallyUnchecked.current) return;

    /** A filter is checked/unchecked based on count only if the user hasn't unchecked it. */
    if (checked !== !!count) {
      setChecked(!!count);
    }
  }, [checked, count]);

  const onCheckChanged = useCallback(({ target }) => {
    /** Logical flag to mark if a user has explicitly unchecked a filter. */
    manuallyUnchecked.current = !target.checked;
    onSelected(type, target.checked);
    setChecked(target.checked);
  }, [onSelected, type]);

  return (
    <KnFilterWrapper display="flex" flexDirection="row" p={1} {...rest}>
      <Box display="flex" flex="1" alignItems="center">
        <FormControlLabel
          disabled={!count}
          control={(
            <Checkbox
              size="small"
              name="filter"
              checked={checked}
              onChange={onCheckChanged}
              style={{
                transform: 'scale(1.3)',
              }}
            />
          )}
          label={(
            <Trans
              parent={KnLegendText}
              i18nKey={labelKey}
              components={[<div />]}
            />
          )}
        />
      </Box>

      <Box display="flex" flexDirection={flexDirection || 'column'} alignItems="center">
        {children}
      </Box>
    </KnFilterWrapper>
  );
};

const KnSeizuresLegend = ({ counts, onSelected }) => (
  <Box display="flex" flexDirection="row" flexWrap="wrap">
    {Object.values(SEIZURE_TYPES).map((type, index) => (
      <KnInsightsMetricsFilter
        key={type}
        type={type}
        count={counts[type]}
        onSelected={onSelected}
        labelKey={`PATIENT_RECORD.seizureInsights.chartLegend.${type}`}
      >
        <KnTypeColorIndicator color={SEIZURES_COLORS[index].hex} />
        <Box mt={2} style={{ marginRight: -3 }}><KnLegendText>{counts[type]}</KnLegendText></Box>
      </KnInsightsMetricsFilter>
    ))}
  </Box>
);

const KnSymptomsLegend = ({ counts, onSelected }) => (
  <Box display="flex" flexWrap="wrap" style={{ width: '100%' }}>
    {Object.values(SYMPTOM_TYPES).map((type) => (
      <KnInsightsMetricsFilter
        category="symptom"
        key={type}
        type={type}
        count={counts[type]}
        onSelected={onSelected}
        flexDirection="row"
        labelKey={`PATIENT_RECORD.sideEffectsInsights.severities.${type}`}
      >
        <Box mr={1}><KnLegendText>{counts[type]}</KnLegendText></Box>
        <KnSymptomColorIndicator type={type} color={SYMPTOM_SEVERITY_COLORS[type].hex} />
      </KnInsightsMetricsFilter>
    ))}
  </Box>
);

const KnMedicationsAdherenceLegend = ({ counts, onSelected }) => {
  const IconMap = {
    [ADHERENCE_TYPES.started]: <KnDiamondIcon />,
    [ADHERENCE_TYPES.updated]: <KnDiamondIcon color={palette.paleGrey.paleGrey5} />,
  };
  return (
    <Box display="flex" flexWrap="wrap" style={{ width: '100%' }}>
      {Object.values(ADHERENCE_TYPES).map((type) => (
        <KnInsightsMetricsFilter
          category="adherence"
          key={type}
          type={type}
          count={counts[type]}
          onSelected={onSelected}
          flexDirection="row"
          labelKey={`PATIENT_RECORD.medicationsInsights.chartLegend.${type}`}
        >
          <Box mr={1}><KnLegendText>{counts[type]}</KnLegendText></Box>
          {IconMap[type] ? (
            <>{IconMap[type]}</>
          ) : (
            <KnAdherenceColorIndicator type={type} color={ADHERENCE_COLORS[type].hex} />
          )}
        </KnInsightsMetricsFilter>
      ))}
    </Box>
  );
};

const KnInsightsMetricsLegend = (props) => {
  const { t: translate } = useTranslation();
  const {
    seizuresCounts,
    onLegendFilterSelected,
    onLegendSymptomsFilterSelected,
    onLegendRegimenFilterSelected,
    hideSymptomsLegend,
    hideMedicationsLegend,
    symptomsCounts,
    adheranceCounts,
  } = props;

  return (
    <KnMetricsLegendWrapper display="flex" flexDirection="column">
      <ExpansionPanel>
        <KnMedicationPanelSummaryUpdate expandIcon={<KnArrowDownIcon color={expandIconColor} data-testid="panel-expand-icon" />}>
          <Box display="flex" pt={1} pl={2} pb={1} pr={2}>
            <Typography variant="h6">{translate(i18nKey('seizureMetrics.title'))}</Typography>
            <KnEventText variant="subtitle1">
              {`${_.sum(Object.values(seizuresCounts))} events`}
            </KnEventText>
          </Box>
        </KnMedicationPanelSummaryUpdate>

        <ExpansionPanelDetails>
          <Box display="flex" pt={1} pl={2} pb={1} pr={2}>
            <KnSeizuresLegend counts={seizuresCounts} onSelected={onLegendFilterSelected} />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {!hideSymptomsLegend && (
        <ExpansionPanel>
          <KnMedicationPanelSummaryUpdate expandIcon={<KnArrowDownIcon color={expandIconColor} data-testid="panel-expand-icon" />}>
            <Box display="flex" flexDirection="column" pt={1} pl={2} pb={1} pr={2}>
              <Typography variant="h6">
                {translate(i18nKey('symptomMetrics.title'))}
                <KnEventText variant="subtitle1">
                  {`${_.sum(Object.values(symptomsCounts))} events`}
                </KnEventText>
              </Typography>
              <Typography variant="h1" component={KnMetricsMediumLabel}>
                {translate(i18nKey('sideEffectsInsights.legendHint'))}
              </Typography>
            </Box>
          </KnMedicationPanelSummaryUpdate>

          <ExpansionPanelDetails>
            <Box display="flex" pt={1} pl={2} pb={1} pr={2}>
              <KnSymptomsLegend
                counts={symptomsCounts}
                onSelected={onLegendSymptomsFilterSelected}
              />
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {!hideMedicationsLegend && (
        <ExpansionPanel>
          <KnMedicationPanelSummaryUpdate expandIcon={<KnArrowDownIcon color={expandIconColor} data-testid="panel-expand-icon" />}>
            <Box display="flex" flexDirection="column" pt={1} pl={2} pb={1} pr={2}>
              <Typography variant="h6">
                {translate(i18nKey('medicationsInsights.chartLegend.title'))}
                <KnEventText variant="subtitle1">
                  {`${_.sum(Object.values(adheranceCounts))} events`}
                </KnEventText>
              </Typography>
              <Typography variant="h1" component={KnMetricsMediumLabel}>
                {translate(i18nKey('medicationsInsights.legendHint'))}
              </Typography>
            </Box>
          </KnMedicationPanelSummaryUpdate>

          <ExpansionPanelDetails>
            <Box display="flex" pt={1} pl={2} pb={1} pr={2}>
              <KnMedicationsAdherenceLegend
                counts={adheranceCounts}
                onSelected={onLegendRegimenFilterSelected}
              />
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </KnMetricsLegendWrapper>
  );
};

KnSeizuresLegend.propTypes = {
  counts: PropTypes.shape({}).isRequired,
  onSelected: PropTypes.func.isRequired,
};

KnSymptomsLegend.propTypes = {
  counts: PropTypes.shape({}).isRequired,
  onSelected: PropTypes.func.isRequired,
};

KnMedicationsAdherenceLegend.propTypes = {
  counts: PropTypes.shape({}).isRequired,
  onSelected: PropTypes.func.isRequired,
};

KnInsightsMetricsFilter.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  labelKey: PropTypes.string.isRequired,
  flexDirection: PropTypes.string,
  category: PropTypes.string,
};

KnInsightsMetricsFilter.defaultProps = {
  count: 0,
  flexDirection: 'column',
  category: '',
};

KnInsightsMetricsLegend.propTypes = {
  symptomsCounts: PropTypes.shape().isRequired,
  seizuresCounts: PropTypes.shape().isRequired,
  adheranceCounts: PropTypes.shape().isRequired,
  onLegendFilterSelected: PropTypes.func.isRequired,
  onLegendSymptomsFilterSelected: PropTypes.func.isRequired,
  onLegendRegimenFilterSelected: PropTypes.func.isRequired,
  hideSymptomsLegend: PropTypes.bool.isRequired,
  hideMedicationsLegend: PropTypes.bool.isRequired,
};

export default KnInsightsMetricsLegend;
