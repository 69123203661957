/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import axios from 'axios';
import ACTION_TYPES from 'redux/actionTypes';
import { API_URLS } from 'Constants';
import { translate } from 'i18n/i18n';
import appActions from './appActions';

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL,
});

const mapUserRoleToLocal = (roleKey) => ({
  id: roleKey,
  name: translate(`GENERAL.roles.${roleKey}`),
});

const mapServerUserRolesToLocal = (data) => data.map((roleKey) => mapUserRoleToLocal(roleKey));

/**
 * User role action creator: get the list of user roles.
 */
const fetch = () => {
  const request = () => ({ type: ACTION_TYPES.USER_ROLE_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.USER_ROLE_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.USER_ROLE_ERROR });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await instance.get(API_URLS.userRole);
      dispatch(success(mapServerUserRolesToLocal(response.data)));
    } catch (error) {
      dispatch(failure());
      dispatch(appActions.appPushNotification('REGISTER.ERROR_MESSAGES.dataLoadError'));
    }
  };
};

export { instance, mapUserRoleToLocal };

export default { fetch };
