/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import ACTION_TYPES from 'redux/actionTypes';
import { v4 as uuidv4 } from 'uuid';

const initialData = {
  /** 'null' means it's not yet loaded / or error */
  data: null,
  /** usually simply 'true', when there is an error,
   * or error code, if we want to handle specific errors (eg. for patientInfo) */
  error: false,
};

const initialState = {
  openSlots: [],
  error: false,
  appointments: initialData,
  patientAppointment: initialData,
  physicianOpenSlots: [],
  consent: initialData,
  insurance: initialData,
  insuraceStatus: initialData,
  insuraceUpdateStatus: initialData,
  medicalHisUpdateStatus: initialData,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PHYSICIAN_OPEN_SLOT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case ACTION_TYPES.PHYSICIAN_OPEN_SLOT_SUCCESS:
      return {
        ...state,
        openSlots: action.data,
      };
    case ACTION_TYPES.PHYSICIAN_OPEN_SLOT_ERROR:
      return {
        ...state,
        error: true,
      };
    case ACTION_TYPES.PHYSICIAN_OPEN_CALENDAR_SLOT_SUCCESS:
      return {
        ...state,
        physicianOpenSlots: action.data,
      };
    case ACTION_TYPES.PHYSICIAN_APPOINTMENT_REQUEST:
      return {
        ...state,
        appointments: {
          ...initialData,
        },
      };
    case ACTION_TYPES.PHYSICIAN_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: {
          data: action.data.map((a) => ({
            ...a,
            uuid: uuidv4(),
          })),
          error: false,
        },
      };
    case ACTION_TYPES.PHYSICIAN_APPOINTMENT_ERROR:
      return {
        ...state,
        appointments: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_APPOINTMENT_REQUEST:
      return {
        ...state,
        patientAppointment: {
          ...initialData,
        },
      };
    case ACTION_TYPES.PATIENT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        patientAppointment: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_APPOINTMENT_ERROR:
      return {
        ...state,
        patientAppointment: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PHYSICIAN_OPEN_SLOT_CREATE_SUCCESS:
      return {
        ...state,
        openSlots: [...state.openSlots, action.data],
      };
    case ACTION_TYPES.PHYSICIAN_OPEN_SLOT_DELETE_SUCCESS: {
      return {
        ...state,
        openSlots: state.openSlots.filter((slot) => slot.id !== action.data),
      };
    }
    case ACTION_TYPES.PATIENT_CONSENT_REQUEST:
      return {
        ...state,
        consent: {
          ...initialData,
        },
      };
    case ACTION_TYPES.PATIENT_CONSENT_SUCCESS:
      return {
        ...state,
        consent: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_CONSENT_ERROR:
      return {
        ...state,
        consent: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_REQUEST:
      return {
        ...state,
        insurance: {
          ...initialData,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        insurance: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_ERROR:
      return {
        ...state,
        insurance: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_REQUEST:
      return {
        ...state,
        insuraceStatus: {
          data: uuidv4(),
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_SUCCESS:
      return {
        ...state,
        insuraceStatus: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_ERROR:
      return {
        ...state,
        insuraceStatus: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_UPDATED_REQUEST:
      return {
        ...state,
        insuraceUpdateStatus: {
          data: state.insuraceUpdateStatus.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_UPDATED_SUCCESS:
      return {
        ...state,
        insuraceUpdateStatus: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_UPDATED_ERROR:
      return {
        ...state,
        insuraceUpdateStatus: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_MEDICAL_UPDATED_REQUEST:
      return {
        ...state,
        medicalHisUpdateStatus: {
          data: state.medicalHisUpdateStatus.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_MEDICAL_UPDATED_SUCCESS:
      return {
        ...state,
        medicalHisUpdateStatus: {
          data: action.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_MEDICAL_UPDATED_ERROR:
      return {
        ...state,
        medicalHisUpdateStatus: {
          data: null,
          error: true,
        },
      };
    case ACTION_TYPES.PATIENT_INSURANCE_CLEAR_INFO:
      return {
        ...state,
        insuraceUpdateStatus: initialData,
        insuraceStatus: initialData,
        medicalHisUpdateStatus: initialData,
      };
    case ACTION_TYPES.PATIENT_APPOINTMENT_FLAG_VIEWED:
      return {
        ...state,
        appointments: {
          data: (state.appointments.data && state.appointments.data.map((a) => {
            if (a.id === action.data) {
              return {
                ...a,
                flag: false,
              };
            }
            return a;
          })) || [],
          error: false,
        },
      };
    default:
      return state;
  }
};

export default calendarReducer;
