/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import Box from '@material-ui/core/Box';

const ProIcon = () => (
  <Box display="inline-flex" marginLeft={0.5} marginRight={0.5}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.99525 2.78772C6.37651 2.78772 5.88151 3.29398 5.88151 3.90146C5.88151 4.52019 6.38777 5.01519 6.99525 5.01519C7.61398 5.01519 8.10898 4.50893 8.10898 3.90146C8.10898 3.29398 7.60272 2.78772 6.99525 2.78772Z" fill="white" />
      <path d="M11.0226 8.35645C10.9664 8.44644 10.9101 8.52519 10.8314 8.60394C10.5839 8.84019 10.2689 8.98644 9.92011 8.99768C9.16637 9.02018 8.46884 9.30142 7.91761 9.81894L10.5389 13.9702L11.3377 12.1702L13.4189 12.159L11.0226 8.35645Z" fill="white" />
      <path d="M3.15894 8.59271C3.08019 8.51396 3.02394 8.43521 2.96769 8.34521L0.571426 12.1478L2.52892 12.2491L3.45142 13.9591L6.07274 9.80778C5.52147 9.29028 4.824 8.99778 4.07024 8.98652C3.7215 8.97527 3.4065 8.84027 3.15897 8.59278L3.15894 8.59271Z" fill="white" />
      <path d="M10.3252 8.08638C10.4377 7.97388 10.5052 7.82764 10.5052 7.65888C10.5277 6.71388 10.8877 5.85888 11.529 5.17262C11.6415 5.06012 11.6977 4.90262 11.6977 4.74512C11.6977 4.58762 11.6415 4.43012 11.529 4.31762C10.8765 3.63136 10.5277 2.77636 10.5052 1.83136C10.5052 1.67386 10.4377 1.51636 10.3252 1.40386C10.2127 1.29136 10.0665 1.22386 9.89774 1.22386C8.95274 1.20136 8.09775 0.841358 7.41148 0.200122C7.29898 0.087622 7.14148 0.0313721 6.98398 0.0313721C6.82649 0.0313721 6.66899 0.087622 6.55649 0.200122C5.87022 0.852621 5.01522 1.20138 4.07023 1.22386C3.91273 1.22386 3.75523 1.29136 3.64273 1.40386C3.53023 1.51636 3.46273 1.66259 3.46273 1.83136C3.44023 2.77636 3.08023 3.63136 2.43899 4.31762C2.32649 4.43012 2.27024 4.58762 2.27024 4.74512C2.27024 4.90262 2.32649 5.06012 2.43899 5.17262C3.09149 5.85888 3.44025 6.71388 3.46273 7.65888C3.46273 7.81638 3.53023 7.97388 3.64273 8.08638C3.75523 8.19888 3.90146 8.26638 4.07023 8.26638C5.01522 8.28888 5.87022 8.64888 6.55649 9.29011C6.79274 9.50386 7.17522 9.51511 7.41148 9.29011C8.09775 8.63761 8.95274 8.28885 9.89774 8.26638C10.0665 8.26638 10.2127 8.19888 10.3252 8.08638ZM9.13274 6.54512C9.04274 6.09512 8.80648 5.67885 8.45775 5.36385H7.83901L6.99527 5.84759L6.15154 5.36385H5.52154C5.18404 5.67885 4.9478 6.08385 4.8578 6.53385C4.4528 6.05012 4.21654 5.43135 4.21654 4.75635C4.21654 3.21509 5.46528 1.96636 7.00654 1.96636C8.5478 1.96636 9.79653 3.21509 9.79653 4.75635C9.78528 5.44262 9.53774 6.06138 9.13274 6.54512Z" fill="white" />
    </svg>
  </Box>
);

export default ProIcon;
