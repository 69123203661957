/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import ACTION_TYPES from 'redux/actionTypes';
import _ from 'lodash';

const initialState = {
  data: [],
  error: false,
  sortBy: '',
  sortDirection: 'asc',
};

const surveysListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SURVEY_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };

    case ACTION_TYPES.SURVEY_LIST_SUCCESS:
      return {
        ...state,
        data: action.data.filter((item) => item.surveyId !== 'OYC-1'),
      };

    case ACTION_TYPES.SURVEY_LIST_ERROR:
      return {
        ...state,
        error: true,
      };

    case ACTION_TYPES.SURVEY_ASSIGN_ERROR:
      return {
        ...state,
        error: true,
      };

    case ACTION_TYPES.SURVEY_LIST_SORT: {
      const { sortKey: newSortKey } = action;
      const { data, sortBy, sortDirection } = state;
      const newSortDirection = (newSortKey !== sortBy || sortDirection === 'desc') ? 'asc' : 'desc';
      return {
        ...state,
        data: _.orderBy(data, [(survey) => survey[newSortKey]], [newSortDirection]),
        sortBy: newSortKey,
        sortDirection: newSortDirection,
      };
    }

    default:
      return state;
  }
};

export default surveysListReducer;
