/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { DEFAULT_COUNTRY_CODE } from 'Constants';
import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Custom field validator to determine if value is a valid phone number.
 *
 * @param {string} value The phone number to be validated.
 * @returns {boolean} Whether the phone number is valid.
 */
export const phoneNumberValidator = (value) => {
  try {
    const phoneNumber = parsePhoneNumber(value, DEFAULT_COUNTRY_CODE);
    return phoneNumber.isValid()
      && (!process.env.REACT_APP_PHONE_COUNTRY_CODE
        || phoneNumber.country === process.env.REACT_APP_PHONE_COUNTRY_CODE);
  } catch (e) {
    return false;
  }
};

/**
 * Custom field validator to determine if the user has entered
 * only white spaces. The required rule does not invalidate this
 * case. In most of the case, we'll delegate the required error
 * message on this error.
 *
 * @param {string} value The input value to be validated agains white spaces.
 */
export const trimValueEmptyValidator = (value) => value.toString().trim() === '';

/**
 * Generic field format validator
 *  to check if value matches given format (regular expression)
 *
 * Note: This validator can replace some of the
 *  specific validators from above (eg, name, phone, email format validators)
 *
 * @param {string} value The value to be validated
 * @param {RegExp} format Regular expression to match with
 * @param {boolean} [trimSpaces = false] Should trim spaces before validation
 * @returns {boolean} Whether the value is valid.
 */
export const formatValidator = (value, format, trimSpaces = false) => (
  RegExp(format).test(trimSpaces ? value.toString().trim() : value)
);
