/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import KnNotificationBar from 'components/NotificationBar';
import SubscriptionBanner from 'components/SubscriptionBanner';
import KnHeader from './Header';
import withAppInsights from '../appInsights';

const Main = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  minHeight: '100vh',
});

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

// eslint-disable-next-line react/prop-types
const KnLayout = ({ children, showSubscriptionBanner }) => (
  <Main data-testid="kn-main-container">
    {/** Common header (logo and navigation) for all pages */}
    <KnHeader />
    {showSubscriptionBanner && <SubscriptionBanner />}
    {/** Request loading indicator and error message: */}
    <KnNotificationBar />
    {/** Page content */}
    <Content data-testid="kn-content-container">
      {children}
    </Content>
  </Main>
);

KnLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withAppInsights(KnLayout);
