/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { appInsights } from 'appInsights';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnTextField from 'components/TextField';
import KnButton from 'components/Button';
import KnDisplayContent from 'components/DisplayContent';
import registerActions from 'redux/actions/registerActions';
import { APP_PAGE_URLS, TRACKING_EVENTS, ERROR_CODES } from 'Constants';
import KnValidatedTextField from 'components/ValidatedTextField';
import { styled } from '@material-ui/core/styles';
import emailLogo from '../../assets/images/04062021_mail_outline_140px.svg';

const initialVerifyAccountInfo = {
  confirmationCode: '',
};

const KnInLine = styled('div')({
  display: 'flex',
});

const VerifyAccountStep = ({
  submitDisabled, stepsData, onReset, onSuccess,
}) => {
  const { t: translate } = useTranslation();
  const registerStore = useSelector((state) => state.register);
  const {
    control,
    handleSubmit,
    errors,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialVerifyAccountInfo,
    reValidateMode: 'onChange',
  });
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const object = translate('REGISTER.cannotFindCode_array_email', { returnObjects: true });
    setItems(Object.values(object));
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewRegisterVerificationCodePage });
  }, [translate, setItems]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the confirmSignIn call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(registerActions.confirmSignUp(
      stepsData.username,
      formData.confirmationCode,
      stepsData,
    )).then((response) => {
      if (!response.error) {
        /** Confirm sign up was successful, can go to next step */
        appInsights.trackEvent({ name: TRACKING_EVENTS.successfulSignUp });
        onSuccess();
      }
    });
  };

  const onBack = useCallback(() => {
    onReset();
  }, [onReset]);

  const onResend = useCallback(() => {
    dispatch(registerActions.resendSignUp(stepsData.username));
  }, [dispatch, stepsData]);

  const duplicateAccountError = registerStore.error === ERROR_CODES.DUPLICATE_ACCOUNT;

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('REGISTER.verificationTitle')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={2} fontSize={17}>
          <Trans
            i18nKey="REGISTER.verifyAccountHint" // optional -> fallbacks to defaults if not provided
            values={{ mail: stepsData.email }}
            components={<u>univers</u>}
          />
        </Box>
        <KnInLine>
          <img src={emailLogo} width="84" height="55" alt="" />
          <KnValidatedTextField
            Component={KnTextField}
            autoFocus
            name="confirmationCode"
            control={control}
            errors={errors}
            defaultValue=""
            required
            trimSpaces
            maxLength={256}
            disabled={duplicateAccountError}
            autoComplete="off"
          />
        </KnInLine>
        <Box marginBottom={2}>
          <KnButton
            type="button"
            variant="text"
            style={{ fontSize: '20px', marginLeft: '-17px' }}
            route={APP_PAGE_URLS.register}
            data-testid="resend-code-button"
            onClick={onResend}
            disabled={duplicateAccountError}
          >
            {translate('REGISTER.resendCodeButton')}
          </KnButton>
        </Box>

        <Box marginBottom={2}>
          <KnDisplayContent label={translate('REGISTER.cannotFindCode')} items={items} mailed="mail" />
        </Box>

        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            type="button"
            variant="text"
            disabled={submitDisabled}
            style={{ fontSize: '20px' }}
            route={APP_PAGE_URLS.register}
            data-testid="cancel-register-link"
            onClick={onBack}
          >
            {translate('GENERAL.cancelButton')}
          </KnButton>
          <KnButton
            disabled={submitDisabled || duplicateAccountError}
            data-testid="next-button"
          >
            {translate('REGISTER.verifyButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

VerifyAccountStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  stepsData: PropTypes.shape({ username: PropTypes.string, email: PropTypes.string }).isRequired,
  onReset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default VerifyAccountStep;
