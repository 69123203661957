/* eslint-disable jsx-a11y/img-redundant-alt */
/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import {
  get, isEmpty, last, range, times,
} from 'lodash';
import {
  Checkbox, FormHelperText, MenuItem, styled, Tooltip, Typography,
} from '@material-ui/core';
import {
  KnDarkerText,
  KnHighlightTextDosage,
  KnSubtleText,
} from 'components/Typography';
import useDialog from 'components/dialog/DialogService';
import { useDispatch } from 'react-redux';
import { KnContrastTextField } from 'components/TextField';
import {
  BUILDER_INITAL_STATE, MEDICATION_UNITS, TITRATION_DOSAGE_FORMAT, TITRATION_MAX_FREQUENCY,
} from 'Constants';
import titrationBuilderAction from 'redux/actions/titrationBuilderAction';
import { KnCustomDosageFormControlLabel } from 'components/titrations/styles';
import { useForm } from 'react-hook-form';
import { KnActionLink } from 'components/Link';
import { getPatientType, mapSmartTitrationToParams, mapSmartTitrationToState } from 'utils/utils';
import KnLoaderIcon from 'components/icons/LoaderIcon';
import palette from 'styles/colors';
import KnOffsetBoxChange from '../../offsetBox';
import KnTargetDosageInfo from './TargetDosageInfo';
import KnStrengthDialog from '../StrengthDialog';


const FREQUENCIES = range(1, TITRATION_MAX_FREQUENCY + 1);

export const KnCheckbox = styled(Checkbox)(() => ({
  color: palette.primary,
  padding: 0,
  transform: 'scale(1.5)',
}));

const KnContrastTextFieldSmallLabel = styled(KnContrastTextField)({
  '& .MuiInputLabel-root': {
    textTransform: 'inherit !important',
  },
});


export const KnMedicationFieldsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(1),

  '& > *': {
    width: 140,
    marginRight: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const KnCustomDosageFormControlLabelChange = styled(KnCustomDosageFormControlLabel)({
  marginLeft: '10px', position: 'relative', top: 0,
});

/**
 * Component used by the KnTitrationFormMedication,
 * for adding/editing dosages of a medication
 */

const KnTitrationBuilder = ({
  medication,
  medications,
  setMedications,
  dosagesUpdated,
  setDosagesUpdated,
  onTitrationBuilderAddDosage,
  onSmartTitrationAddDosage,
  lastDosageDeleted,
  setLastDosageDeleted,
  isSmartTitration,
  setIsSmartTitration,
  onChangeDosage,
  onMedicationChange,
  medIndex,
  titrationState,
  setTitrationState,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [oldState, setOldState] = useState(BUILDER_INITAL_STATE);
  const [stats, setStats] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const dialog = useDialog();
  const {
    control,
    errors,
    triggerValidation,
    setError,
    clearError,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
    reValidateMode: 'onChange',
  });

  const { medicationStrength, medicationType, medicationTypeUnit } = medication;


  useEffect(() => {
    const lastDosasge = last(medication.dosages);
    setTimeout(() => {
      if (titrationState.maintenanceDose && lastDosasge.duration !== 365) {
        setTitrationState({
          ...titrationState,
          maintenanceDose: false,
        });
      }
    });
  }, [medication, setTitrationState, titrationState]);

  const hasErrorCallback = useCallback((state, update = true) => {
    let noError = true;
    const hasStartDoseError = (
      state.doses > 1 && state.startDose > state.targetDose
    );
    const errorsUpdate = [];
    if (!state.startDose && !hasStartDoseError) {
      errorsUpdate.push({
        key: 'startDose',
        value: 'startDose',
      });
      noError = false;
    }
    if (!state.targetDose && !hasStartDoseError && state.doses > 1) {
      errorsUpdate.push({
        key: 'targetDose',
        value: 'targetDose',
      });
      noError = false;
    }
    if (hasStartDoseError) {
      errorsUpdate.push({
        key: 'targetDose',
        value: 'startDosageGreater',
      });
      noError = false;
    }
    if (state.isChild && !state.weight) {
      errorsUpdate.push({
        key: 'weight',
        value: 'weight',
      });
      noError = false;
    }
    if (!state.frequency) {
      errorsUpdate.push({
        key: 'frequency',
        value: 'frequencyError',
      });
      noError = false;
    }
    if (update && errorsUpdate[0]) {
      [errorsUpdate[0]].forEach((e) => {
        setError(e.key, e.value);
      });
    }
    return noError;
  }, [setError]);

  useEffect(() => {
    if (lastDosageDeleted && titrationState.maintenanceDose) {
      setTitrationState({
        ...titrationState,
        maintenanceDose: false,
      });
      setLastDosageDeleted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDosageDeleted, setLastDosageDeleted]);

  useEffect(() => {
    if (isDirty) {
      clearError();
      hasErrorCallback(titrationState);
    }
  }, [titrationState, clearError, isDirty, hasErrorCallback]);

  const formControls = useMemo(() => ({
    control,
    errors,
    triggerValidation,
    setValue,
  }), [control, errors, triggerValidation, setValue]);

  const onBlur = useCallback((newState, key) => {
    if (newState && hasErrorCallback(newState)) {
      onTitrationBuilderAddDosage(medication.id, newState, false, key);
    }
  }, [hasErrorCallback, medication, onTitrationBuilderAddDosage]);

  const onSmartTitrationBlur = useCallback((newState, smartTitration, key, doNotCheckError) => {
    if (newState && (doNotCheckError ? true : hasErrorCallback(newState))) {
      onSmartTitrationAddDosage(medication.id, smartTitration, key);
    }
  }, [hasErrorCallback, medication, onSmartTitrationAddDosage]);

  const getCustomSmartTitration = useCallback(async (newState) => {
    const params = mapSmartTitrationToParams(newState);
    const response = await dispatch(
      titrationBuilderAction.fetchCustomSmartTitration(medication.id, params),
    );
    if (response.error) {
      return;
    }
    setStats(response.statistics);
    const state = mapSmartTitrationToState(response);
    setTitrationState(state);
    onSmartTitrationBlur(state, response, '', true);
    clearError();
  }, [dispatch, medication, onSmartTitrationBlur, clearError, setTitrationState]);

  const getDefaultSmartTitration = useCallback(async (params, oState = {}) => {
    const response = await dispatch(
      titrationBuilderAction.fetchDefaultSmartTitration(medication.id, params || { patientType: 2, medicationUnit: 'mg' }),
    );
    if (response.error) {
      setIsSmartTitration(isSmartTitration);
      setTitrationState({
        ...titrationState,
        ...oState,
      });
      return;
    }
    onMedicationChange(medication.id, null, {
      medicationStrength: undefined,
      medicationType: undefined,
      medicationTypeUnit: undefined,
    });
    setIsSmartTitration(true);
    const newState = mapSmartTitrationToState(response);
    setTitrationState(newState);
    setStats(response.statistics);
    onSmartTitrationBlur(newState, response, '', true);
    clearError();
  }, [
    dispatch, medication,
    isSmartTitration, onSmartTitrationBlur,
    titrationState, setIsSmartTitration,
    onMedicationChange, clearError, setTitrationState,
  ]);

  const onFieldsChange = useCallback(async (e, key) => {
    setIsDirty(true);
    if (key === 'maintenanceDose') {
      const newState = {
        ...titrationState,
        maintenanceDose: e,
      };
      if (isSmartTitration) {
        onSmartTitrationBlur(newState, {}, key);
        setTitrationState(newState);
      } else {
        setTitrationState(newState);
        onBlur(newState, key);
      }
      return;
    }

    const newState = {
      ...titrationState,
      [key]: key === 'unit' ? e.target.value : parseFloat(e.target.value, 10),
    };

    if (['unit', 'frequency', 'doses'].includes(key)) {
      if (isSmartTitration) {
        if (key === 'unit') {
          getDefaultSmartTitration({
            patientType: getPatientType(titrationState.isChild),
            medicationUnit: newState.unit,
            weight: newState.weight || null,
          }, { unit: titrationState.unit });
        } else if (isEmpty(errors)) {
          getCustomSmartTitration(newState);
        }
      } else {
        onBlur(newState, key === 'unit' ? 'unit' : '');
        setTitrationState(newState);
      }
    }

    setTitrationState(newState);
  }, [
    titrationState, onBlur, isSmartTitration,
    getCustomSmartTitration, onSmartTitrationBlur,
    getDefaultSmartTitration, errors, setTitrationState,
  ]);

  const smartTitrationHandler = useCallback((v) => {
    let newState = {};
    if (v) {
      dialog({
        title: translate('TITRATION_BUILDER.models.EXIT_BUILDER.title'),
        description: translate('TITRATION_BUILDER.models.EXIT_BUILDER.body'),
        submitLabel: translate('TITRATION_BUILDER.models.EXIT_BUILDER.submitButton'),
        closeLabel: translate('GENERAL.cancelButton'),
      }).then(async () => {
        getDefaultSmartTitration();
      });
    } else {
      dialog({
        title: translate('TITRATION_BUILDER.models.EXIT_ASSISTANCE.title'),
        description: translate('TITRATION_BUILDER.models.EXIT_ASSISTANCE.body'),
        submitLabel: translate('TITRATION_BUILDER.models.EXIT_ASSISTANCE.submitButton'),
        closeLabel: translate('GENERAL.cancelButton'),
      }).then(() => {
        newState = BUILDER_INITAL_STATE;
        setTitrationState(newState);
        setIsSmartTitration(v);
        setIsDirty(false);
        clearError();
        onTitrationBuilderAddDosage(medication.id, BUILDER_INITAL_STATE, true);
      });
    }
  }, [
    medication, dialog, translate,
    onTitrationBuilderAddDosage,
    getDefaultSmartTitration,
    setIsSmartTitration,
    clearError,
    setTitrationState,
  ]);

  const onChildSelection = useCallback(async (v) => {
    const newState = {
      ...titrationState,
      isChild: v,
    };
    setTitrationState(newState);
    if (!v) {
      getDefaultSmartTitration();
    }
    hasErrorCallback(newState);
  }, [titrationState, getDefaultSmartTitration, hasErrorCallback, setTitrationState]);

  const handleOffsetChange = useCallback((val) => {
    const newState = {
      ...titrationState,
      offset: val,
    };
    if (!isEmpty(errors)) {
      setTitrationState(newState);
      return;
    }
    getCustomSmartTitration(newState);
  }, [getCustomSmartTitration, titrationState, errors, setTitrationState]);

  const handleFocus = useCallback(() => {
    setOldState(titrationState);
  }, [setOldState, titrationState]);

  const handleInputBlur = useCallback((label) => {
    if (['startDose', 'targetDose', 'weight'].includes(label)
    && (oldState.startDose === titrationState.startDose
    && oldState.targetDose === titrationState.targetDose
    && oldState.weight === titrationState.weight
    )) {
      return;
    }


    if (isSmartTitration && label !== 'weight' && titrationState[label]) {
      if (!isEmpty(errors)) return;
      getCustomSmartTitration(titrationState);
      return;
    }

    if (isSmartTitration && label === 'weight' && titrationState.weight) {
      getDefaultSmartTitration({
        patientType: getPatientType(titrationState.isChild),
        weight: titrationState.weight,
        medicationUnit: titrationState.unit,
      });
      return;
    }
    if (!isSmartTitration) onBlur(titrationState);
  }, [
    titrationState,
    isSmartTitration,
    oldState,
    onBlur,
    errors,
    getCustomSmartTitration,
    getDefaultSmartTitration,
  ]);

  const resetSmartTitration = useCallback(() => {
    dialog({
      title: translate('TITRATION_BUILDER.models.RESET.title'),
      description: translate('TITRATION_BUILDER.models.RESET.body'),
      submitLabel: translate('TITRATION_BUILDER.models.RESET.submitButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      if (titrationState.isChild && titrationState.weight) {
        getDefaultSmartTitration({
          patientType: getPatientType(titrationState.isChild),
          weight: titrationState.weight,
          medicationUnit: titrationState.unit,
        });
        return;
      }
      getDefaultSmartTitration();
    });
  }, [
    dialog, translate,
    titrationState, getDefaultSmartTitration,
  ]);

  const getNumberTextField = (label, disabled) => (
    <KnContrastTextFieldSmallLabel
      disabled={disabled}
      name={label}
      value={titrationState[(label || '').replace('Mg', '')]}
      label={translate(`FIELD_LABELS.${label}`)}
      disableErrorMessage
      onFocus={handleFocus}
      color="secondary"
      type="number"
      onBlur={() => handleInputBlur((label || '').replace('Mg', ''))}
      onWheel={(event) => event.target.blur()}
      defaultValue={0}
      onChange={(e) => onFieldsChange(e, (label || '').replace('Mg', ''))}
      format={TITRATION_DOSAGE_FORMAT}
      error={formControls.errors[label]}
    />
  );

  const builderErrors = useMemo(() => (
    [{
      isError: (get(errors, 'targetDose.type') === 'startDosageGreater'),
      key: 'startDosageGreater',
    },
    {
      isError: (get(errors, 'frequency.type') === 'frequencyError'),
      key: 'frequency',
    },
    {
      isError: (get(errors, 'startDose.type') === 'startDose'),
      key: 'startDose',
    },
    {
      isError: (get(errors, 'targetDose.type') === 'targetDose'),
      key: 'targetDose',
    },
    {
      isError: (get(errors, 'weight.type') === 'weight'),
      key: 'weight',
    }]
  ), [errors]);

  const onSetStrengthClick = useCallback(() => {
    dialog({
      customDialog: KnStrengthDialog,
      inputData: {
        medicationStrength: isSmartTitration ? titrationState.availableDose : medicationStrength,
        medicationType: isSmartTitration ? titrationState.medicationType : medicationType,
        medicationTypeUnit,
        isSmartTitration,
        unit: titrationState.unit || 'mg',
      },
    }).then((updates) => {
      onMedicationChange(medication.id, null, updates);
      const medStrength = updates.medicationStrength;
      if (medStrength) onChangeDosage(medication.id, titrationState.unit);

      const newState = {
        ...titrationState,
        availableDose: medStrength,
      };
      setTitrationState(newState);
      if (isSmartTitration && isEmpty(errors)) {
        getCustomSmartTitration(newState);
      }
    });
  }, [
    dialog, medicationStrength, onChangeDosage,
    onMedicationChange, medicationType, medicationTypeUnit,
    isSmartTitration, medication, titrationState,
    getCustomSmartTitration, errors, setTitrationState,
  ]);

  const showLabel = useMemo(() => (
    !(medicationStrength || titrationState.availableDose) && !isEmpty(titrationState.availableDoses)
  ), [medicationStrength, titrationState]);

  const medicationTypeVar = medicationType ? medicationType.toUpperCase() : medicationType;

  return (
    <>
      <Box display="flex" ml="35px" mt="-30px" mb={showLabel ? '15px' : '25px'}>
        <KnCustomDosageFormControlLabelChange
          size="medium"
          control={(
            <Checkbox
              checked={!!medicationStrength}
              style={{ transform: 'scale(1.3)', marginRight: '3px' }}
              onChange={onSetStrengthClick}
              inputProps={{ 'data-testid': `custom-dosage-checkbox-${medIndex + 1}` }}
            />
          )}
          label={(
            <Typography variant="body2" component={KnSubtleText}>
              {translate('TITRATIONS.titrationBuilder.setStrengthDialog.checkBox')}
              {medicationStrength ? ' - ' : '/'}
              {medicationStrength
                ? (
                  <b>
                    {
                    `${medicationTypeVar} - ${medicationStrength} ${titrationState.unit === 'cc' ? 'mg per cc' : medicationTypeUnit}`
                    }
                  </b>
                )
                : translate('TITRATIONS.titrationBuilder.setStrengthDialog.concentration')}
            </Typography>
          )}
        />
      </Box>
      {showLabel && (
        <Typography style={{ paddingLeft: '54px' }} variant="body2" component={KnSubtleText}>
          Based on available strength/concentration:
          {' '}
          <b>
            {titrationState.availableDoses.join(', ')}
            {' '}
            {translate(
              // eslint-disable-next-line no-nested-ternary
              `TITRATIONS.titrationBuilder.setStrengthDialog.${titrationState.unit === 'cc' ? 'mgPerCC' : titrationState.medicationType === 'pill' ? 'mgPerPill' : 'mgPerMl'}`,
            )}
          </b>
        </Typography>
      )}
      <hr style={{ marginTop: showLabel ? 15 : 25, marginBottom: 30 }} />
      <Box ml={7} mr={7}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" component={KnHighlightTextDosage}>
            {translate('TITRATION_BUILDER.title')}
          </Typography>
          <Box>
            <Tooltip
              title="Autofill the most commonly prescribed dosage for this medication"
              placement="top"
              arrow
              style={{ widht: 100 }}
            >
              <span>
                <KnCheckbox
                  color="primary"
                  style={{
                    marginRight: 8,
                    marginLeft: 16,
                  }}
                  checked={isSmartTitration}
                  onChange={(e, v) => smartTitrationHandler(v)}
                  inputProps={{ 'data-testid': 'smart-titration-checkbox' }}
                />
                <Typography variant="body2" component={KnDarkerText}>
                  Smart Titration
                </Typography>
              </span>
            </Tooltip>
          </Box>
          {isSmartTitration && (
            <KnActionLink
              onClick={resetSmartTitration}
              LhsIcon={KnLoaderIcon}
              style={{ marginLeft: 'auto' }}
              data-testid="reset-smart-titration"
            >
              Reset Smart Titration
            </KnActionLink>
          )}
        </Box>
        <KnMedicationFieldsBox>
          {titrationState.isChild && (
            <>{getNumberTextField('weight')}</>
          )}
          {getNumberTextField(isSmartTitration ? 'startDoseMg' : 'startDose')}
          {getNumberTextField(isSmartTitration ? 'targetDoseMg' : 'targetDose', titrationState.doses === 1)}

          <KnContrastTextField
            select
            value={titrationState.doses}
            label={translate('TITRATION_BUILDER.FIELD_LABELS.doses')}
            onChange={(e) => onFieldsChange(e, 'doses')}
            {...formControls}
          >
            {times(30, (v) => v + 1).map((unitValue, unitIndex) => (
              <MenuItem
                key={unitValue}
                value={unitValue}
                data-testid={`titration-unit-option-${unitIndex + 1}`}
              >
                {unitValue}
              </MenuItem>
            ))}
          </KnContrastTextField>

          <KnContrastTextField
            select
            value={titrationState.unit}
            label={translate('FIELD_LABELS.unit')}
            onChange={(e) => onFieldsChange(e, 'unit')}
            {...formControls}
          >
            {MEDICATION_UNITS.map((unitValue, unitIndex) => (
              <MenuItem
                key={unitValue}
                value={unitValue}
                data-testid={`titration-unit-option-${unitIndex + 1}`}
              >
                {unitValue}
              </MenuItem>
            ))}
          </KnContrastTextField>

          <KnContrastTextField
            select
            value={titrationState.frequency}
            label={translate('FIELD_LABELS.medicationFrequency')}
            onChange={(e) => onFieldsChange(e, 'frequency')}
            disableErrorMessage
            error={formControls.errors.frequency}
          >
            {FREQUENCIES.map((unitValue, unitIndex) => (
              <MenuItem
                key={unitValue}
                value={unitValue}
                data-testid={`titration-unit-option-${unitIndex + 1}`}
              >
                {translate('GENERAL.frequencyTimes', { count: unitValue })}
              </MenuItem>
            ))}
          </KnContrastTextField>

          <KnOffsetBoxChange
            medication={medication}
            medications={medications}
            setMedications={setMedications}
            dosagesUpdated={dosagesUpdated}
            setDosagesUpdated={setDosagesUpdated}
            control={control}
            errors={errors}
            setError={setError}
            clearError={clearError}
            handleOnChange={handleOffsetChange}
          />
        </KnMedicationFieldsBox>

        <FormHelperText
          error
          data-testid="strength-validation-error"
          style={{
            marginTop: '-10px',
            marginLeft: '0px',
          }}
        >
          <Box display="flex" flexDirection="column">
            <Box>
              {
                (get(errors, `offset-add-${medication.id}.type`) === 'valueFormat')
                && translate('FIELD_VALIDATION_MESSAGES.offsetLimit')
              }
            </Box>
            {
              builderErrors.map((e) => (
                <Box>
                  {
                   e.isError && translate(`FIELD_VALIDATION_MESSAGES.titrationBuilder.${e.key}`)
                  }
                </Box>
              ))
            }
          </Box>
        </FormHelperText>
        <Box display="flex" alignItems="center" mt={1} mb={3}>
          <Box display="flex" alignItems="center">
            <KnCheckbox
              color="primary"
              style={{ marginRight: 8 }}
              checked={titrationState.maintenanceDose}
              onChange={(e, v) => onFieldsChange(v, 'maintenanceDose')}
              inputProps={{ 'data-testid': 'maintenance-dosage-checkbox' }}
            />
            <Typography variant="body2" component={KnSubtleText}>
              {translate('TITRATION_BUILDER.maintenanceDose', { count: medication.dosages.length })}
            </Typography>
          </Box>
          {isSmartTitration && (
            <Box display="flex" alignItems="center" ml={3}>
              <KnCheckbox
                color="primary"
                key={titrationState.isChild ? 'checked' : 'unchecked'}
                style={{ marginRight: 8 }}
                checked={titrationState.isChild}
                onChange={(e, v) => onChildSelection(v)}
                inputProps={{ 'data-testid': 'maintenance-dosage-checkbox' }}
              />
              <Typography variant="body2" component={KnSubtleText}>
                Child
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {isSmartTitration && (
        <>
          <div style={{
            height: 0.8, marginTop: 25, marginBottom: 30, background: 'rgba(0, 0, 0, 0.1)',
          }}
          />
          <KnTargetDosageInfo
            chartId={`medicationId-${medication.id}`}
            isChild={titrationState.isChild}
            medicationId={medication.id}
            stats={stats || {}}
            uptodate="https://www.uptodate.com"
          />
          <div style={{
            height: 0.8, marginTop: 25, marginBottom: 30, background: 'rgba(0, 0, 0, 0.1)',
          }}
          />
        </>
      )}
    </>

  );
};

KnTitrationBuilder.propTypes = {
  medication: PropTypes.shape(),
  setMedications: PropTypes.func.isRequired,
  setDosagesUpdated: PropTypes.func.isRequired,
  onTitrationBuilderAddDosage: PropTypes.func.isRequired,
  onSmartTitrationAddDosage: PropTypes.func.isRequired,
  setMaintenanceDose: PropTypes.func.isRequired,
  dosagesUpdated: PropTypes.bool,
  setLastDosageDeleted: PropTypes.func.isRequired,
  lastDosageDeleted: PropTypes.bool.isRequired,
  isSmartTitration: PropTypes.bool.isRequired,
  setIsSmartTitration: PropTypes.func.isRequired,
  medications: PropTypes.arrayOf(PropTypes.shape()),
  onMedicationChange: PropTypes.func.isRequired,
  onChangeDosage: PropTypes.func.isRequired,
  medIndex: PropTypes.number.isRequired,
  setTitrationState: PropTypes.func.isRequired,
  titrationState: PropTypes.shape().isRequired,
};

KnTitrationBuilder.defaultProps = {
  medication: null,
  medications: [],
  dosagesUpdated: false,
};

export default KnTitrationBuilder;
