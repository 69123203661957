import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Popover, Typography,
} from '@material-ui/core';
import { KnActionLink } from 'components/Link';
import KnButton from 'components/Button';
import { capitalizeFirstLetter } from 'utils/utils';
import KnCloseIcon from 'components/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { APP_PAGE_URLS } from 'Constants';
import {
  compact, get, isEmpty, orderBy,
} from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import useTabChange from 'hooks/useTabChange';
import KnClockIcon from 'components/icons/ClockIcon';
import KnFlagIcon from 'components/icons/FlagIcon';
import KnDatePicker from 'components/DatePicker';
import calendarActions from 'redux/actions/calendarActions';
import patientActions from 'redux/actions/patientActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMonths,
  format, isBefore, isSameDay, subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';
import palette from '../../../styles/colors';
import TimeDropDown from './TimeDropdown';
import EventStatus from './EventStatus';
import {
  BoxBottomBorder, BoxErrox, EventBox, EventLegend, EventText, ReportLink,
} from './styles';
import PatientDetails from './PatientDetails';


const timeZone = 'America/Los_Angeles';
const { paleGrey7 } = palette.paleGrey;

const EventDetailsPopover = ({
  anchorEl, handleClose, deleteEvent, appointment, physician, getCalenderData,
}) => {
  const open = Boolean(anchorEl);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [isBackOnActiveTab] = useTabChange();
  const [status, setStatus] = useState(get(appointment, 'extendedProps.status'));
  const [physicianOpenSlots, setPhysicianOpenSlots] = useState([]);
  const [timeSlotValue, setTimeSlotValue] = useState('default');
  const [dateRange, setDateRange] = useState(get(appointment, 'extendedProps.range'));
  const [patientId, setPatientId] = useState(get(appointment, 'extendedProps.patientUserId'));
  const id = open ? 'simple-popover' : undefined;
  const [anchorTimeEl, setTimeAnchorEl] = useState(null);

  const { patientRecord, calendar } = useSelector((state) => state);
  const { medicalHistoryStatus } = patientRecord;
  const {
    physicianOpenSlots: groupSlots, insuraceStatus, insuraceUpdateStatus, medicalHisUpdateStatus,
  } = calendar;


  const hasInsuranceChanged = useMemo(() => {
    const flag = get(insuraceUpdateStatus, 'data.flag');
    const data = get(insuraceUpdateStatus, 'data');
    return data !== null && (data === '' || flag === undefined || flag === true);
  }, [insuraceUpdateStatus]);

  const hasMedicalHistoryChanged = useMemo(() => {
    const flag = get(medicalHisUpdateStatus, 'data.flag');
    const data = get(medicalHisUpdateStatus, 'data');
    return data !== null && (data === '' || flag === undefined || flag === true);
  }, [medicalHisUpdateStatus]);


  useEffect(() => {
    const pId = get(appointment, 'extendedProps.patientUserId');
    if (isBackOnActiveTab && pId) {
      dispatch(calendarActions.getInsuranceUpdateInfo(pId));
      dispatch(calendarActions.getMedicalHisUpdateInfo(pId));
    }
  }, [
    appointment,
    isBackOnActiveTab,
    dispatch,
  ]);

  useEffect(() => {
    const appointmentId = get(appointment, 'extendedProps.slotId');
    const hasNoInsurnace = get(insuraceStatus, 'data') === '';
    if ((!hasInsuranceChanged || hasNoInsurnace)
      && !hasMedicalHistoryChanged
      && isBackOnActiveTab
      && appointmentId
    ) {
      dispatch(calendarActions.markAppointmentFlagViewed(appointmentId));
    }
  }, [
    appointment,
    hasInsuranceChanged,
    hasMedicalHistoryChanged,
    isBackOnActiveTab,
    dispatch,
    getCalenderData,
    insuraceStatus,
  ]);

  useEffect(() => {
    const range = get(appointment, 'extendedProps.range');
    setPhysicianOpenSlots([
      {
        startDate: range.start,
        endDate: range.end,
        id: 'default',
        hcp: {
          firstName: physician.firstName,
          lastName: physician.lastName,
          id: physician.id,
        },
      },
      ...orderBy([...groupSlots.map((g) => ({
        ...g,
        startDate: utcToZonedTime(g.startDate, timeZone),
        endDate: utcToZonedTime(g.endDate, timeZone),
      }))], (d) => new Date(d.startDate))]);
  }, [appointment, groupSlots, physician]);

  useEffect(() => {
    const pId = get(appointment, 'extendedProps.patientUserId');
    const range = get(appointment, 'extendedProps.range');
    const appointmentId = get(appointment, 'extendedProps.slotId');
    setStatus(get(appointment, 'extendedProps.status'));
    setDateRange(range);
    setPatientId(pId);
    if (pId) {
      dispatch(patientActions.getMedicalHistoryStatus(pId));
      dispatch(calendarActions.getInsuranceCompletedInfo(pId));
      dispatch(calendarActions.getInsuranceUpdateInfo(pId));
      dispatch(calendarActions.getMedicalHisUpdateInfo(pId));
    }
    if (range && appointmentId) {
      dispatch(calendarActions.getPhysicanSlotsGroup(appointmentId));
    }
  }, [appointment, patientId, dispatch]);

  const isPastAppintment = useMemo(() => {
    const range = get(appointment, 'extendedProps.range');
    return isBefore(new Date(range.start), utcToZonedTime(new Date(), timeZone));
  }, [appointment]);

  const handleTimeClick = useCallback((event) => {
    if (!isPastAppintment) {
      setTimeAnchorEl(event.currentTarget);
    }
  }, [isPastAppintment]);

  const handleTimeClose = useCallback(() => {
    setTimeAnchorEl(null);
  }, []);

  const timeOptions = useMemo(() => {
    const option = compact(physicianOpenSlots.map((slot) => {
      if (isSameDay(new Date(dateRange.start), new Date(slot.startDate))) {
        return {
          key: format(new Date(slot.startDate), 'p'),
          value: slot.id,
          doctor: `Dr. ${get(slot, 'hcp.firstName')} ${get(slot, 'hcp.lastName')}`,
        };
      }
      return null;
    }));
    return option;
  }, [dateRange, physicianOpenSlots]);

  const handlTimeChange = useCallback((e) => {
    const slot = physicianOpenSlots.find((s) => s.id === e.value);
    setTimeSlotValue(e.value);
    setDateRange({ start: slot.startDate, end: slot.endDate });
    setTimeAnchorEl(null);
  }, [physicianOpenSlots]);

  const handleDateChange = useCallback((date) => {
    const slot = physicianOpenSlots.find(
      (s) => format(date, 'MM/dd/yyyy') === format(new Date(s.startDate), 'MM/dd/yyyy'),
    );
    setTimeSlotValue(slot.id);
    setDateRange({ ...dateRange, start: slot.startDate, end: slot.endDate });
  }, [dateRange, physicianOpenSlots]);

  const handleStatusChange = useCallback((e) => {
    setStatus(e.target.value);
  }, []);

  const handleHistoryClick = useCallback(() => {
    window.open(APP_PAGE_URLS.emrExport.replace(':patientId', patientId), '_blank');
  }, [patientId]);

  const handleInsuranceClick = useCallback(() => {
    window.open(APP_PAGE_URLS.insuranceExport.replace(':patientId', patientId), '_blank');
  }, [patientId]);

  const handleConsentClick = useCallback(() => {
    window.open(APP_PAGE_URLS.consentExport.replace(':patientId', patientId), '_blank');
  }, [patientId]);

  const disableDates = useCallback((date) => !physicianOpenSlots.some(
    (d) => (format(date, 'MM/dd/yyyy') === format(new Date(d.startDate), 'MM/dd/yyyy')),
  ), [physicianOpenSlots]);

  const handlePatientClick = useCallback(() => {
    window.open(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId), '_blank');
  }, [patientId]);

  const updatePaylod = useMemo(() => {
    const payload = {};
    if (timeSlotValue !== 'default') {
      payload.slotGroup = physicianOpenSlots.find((s) => s.id === timeSlotValue).slotGroup;
    }
    if (status !== get(appointment, 'extendedProps.status')) {
      payload.status = status;
    }
    return payload;
  }, [physicianOpenSlots, timeSlotValue, status, appointment]);

  const doctorChanged = useMemo(() => {
    if (timeSlotValue !== 'default') {
      const slot = physicianOpenSlots.find((s) => s.id === timeSlotValue);
      if (physician.id !== slot.hcp.id) {
        return `Dr. ${slot.hcp.firstName} ${slot.hcp.lastName}`;
      }
      return '';
    }
    return '';
  }, [physicianOpenSlots, timeSlotValue, physician]);

  const handleUpdateAppointment = useCallback(async () => {
    const appointmentId = get(appointment, 'extendedProps.slotId');
    const response = await dispatch(calendarActions.updateAppointment(appointmentId, updatePaylod));
    if (response) {
      handleClose(true);
    }
  }, [dispatch, updatePaylod, appointment, handleClose]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={document.getElementById(get(appointment, 'extendedProps.uuid'))}
      onClose={() => handleClose(false)}
      disableEnforceFocus
      disableAutoFocus
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <EventBox status={status}>
        <Box display="flex" alignItem="center" justifyContent="space-between">
          <EventLegend status={status}>{capitalizeFirstLetter(status)}</EventLegend>
          <Box display="flex" alignItems="center">
            {!isPastAppintment && (
              <KnActionLink
                onClick={() => deleteEvent(appointment)}
                LhsIcon={KnDeleteIcon}
                tooltip
                iconColor={paleGrey7}
                tooltipText={translate('ICON_TOOLTIP.deleteEvent')}
                data-testid="delete-event"
              />
            )}
            <KnActionLink
              onClick={() => handleClose(false)}
              LhsIcon={KnCloseIcon}
              tooltip
              iconColor={paleGrey7}
              tooltipText={translate('ICON_TOOLTIP.close')}
              data-testid="close-event"
            />
          </Box>
        </Box>

        <Box
          pt={3}
          pb={3}
          display="flex"
          alignItem="center"
          justifyContent="space-between"
          style={{ position: 'relative' }}
        >
          <EventText>Patient</EventText>
          <EventText isClickAble onClick={handlePatientClick}>{`${get(appointment, 'extendedProps.firstName')} ${get(appointment, 'extendedProps.lastName')}`}</EventText>
        </Box>

        <BoxBottomBorder
          pb={3}
          display="flex"
          alignItem="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItem="center">
            <KnActionLink
              onClick={() => {}}
              LhsIcon={KnClockIcon}
              data-testid="close-event"
              style={{ marginLeft: -4 }}
            />
            {isPastAppintment && dateRange && (
              <EventText>
                {format(new Date(dateRange.start), 'MMM dd, yyyy')}
              </EventText>
            )}
            {dateRange && !isPastAppintment && (
              <KnDatePicker
                label="Date"
                plain
                inputFormat="EEEE, dd MMM"
                value={new Date(dateRange.start)}
                onChange={handleDateChange}
                shouldDisableDate={disableDates}
                maxDate={addMonths(new Date(), 6)}
                minDate={subMonths(new Date(), 6)}

              />
            )}
          </Box>
          <Box display="flex" alignItem="center">
            {dateRange && (
              <>
                <EventText
                  isClickAble={!isPastAppintment}
                  onClick={handleTimeClick}
                >
                  {format(new Date(dateRange.start), 'p').toLowerCase()}
                </EventText>
                <TimeDropDown
                  anchorEl={anchorTimeEl}
                  timeOptions={timeOptions}
                  handleClose={handleTimeClose}
                  handlChange={handlTimeChange}
                />
                <EventText>-</EventText>
                <EventText>
                  {format(new Date(dateRange.end), 'p').toLowerCase()}
                </EventText>
              </>
            )}
          </Box>
          <BoxErrox>{doctorChanged}</BoxErrox>
        </BoxBottomBorder>

        <PatientDetails appointment={appointment} />

        {get(insuraceStatus, 'data.completed', false) === true && (
          <BoxBottomBorder mb={2.5} display="flex" justifyContent="space-between" alignItems="center">
            <ReportLink onClick={handleInsuranceClick} color="primary">{translate('ADMIN_CALENDAR.downloadInsurance')}</ReportLink>
            {hasInsuranceChanged && <KnFlagIcon style={{ marginRight: 8 }} />}
          </BoxBottomBorder>
        )}

        {get(medicalHistoryStatus, 'data.status', false) !== false && (
          <BoxBottomBorder mb={2.5} display="flex" justifyContent="space-between" alignItems="center">
            <ReportLink onClick={handleHistoryClick} color="primary">{translate('ADMIN_CALENDAR.downloadMedHistory')}</ReportLink>
            {hasMedicalHistoryChanged && <KnFlagIcon style={{ marginRight: 8 }} />}
          </BoxBottomBorder>
        )}
        <BoxBottomBorder>
          <ReportLink onClick={handleConsentClick} color="primary">{translate('ADMIN_CALENDAR.downloadCosent')}</ReportLink>
        </BoxBottomBorder>

        <EventStatus
          value={status}
          initialValue={get(appointment, 'extendedProps.status')}
          handleStatusChange={handleStatusChange}
          isPastAppintment={isPastAppintment}
        />

        <Box pt={4} display="flex" alignItems="center" justifyContent="space-between">
          <KnButton
            data-testid="cancel-event-button"
            variant="outline"
            color="primary"
            onClick={handleClose}
          >
            <Typography color="primary">{translate('ADMIN_CALENDAR.cancel')}</Typography>
          </KnButton>

          <KnButton
            data-testid="save-event-button"
            type="button"
            disabled={isEmpty(updatePaylod)}
            onClick={handleUpdateAppointment}
          >
            {translate('ADMIN_CALENDAR.save')}
          </KnButton>
        </Box>

      </EventBox>
    </Popover>
  );
};

EventDetailsPopover.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  getCalenderData: PropTypes.func.isRequired,
  appointment: PropTypes.objectOf(PropTypes.any).isRequired,
  physician: PropTypes.objectOf(PropTypes.any).isRequired,
};

EventDetailsPopover.defaultProps = {
};

export default EventDetailsPopover;
