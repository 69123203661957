import { differenceInDays } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const getSeverity = (severity) => {
  if (severity === 1) return 'Mild';
  if (severity >= 2 && severity <= 2) return 'Moderate';
  return 'Severe';
};

const useSideEffectText = (addNotes) => {
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();
  const emrReport = useSelector((state) => state.patientRecord.emrReport);
  const { patientSideEffects = [] } = emrReport.data || {};


  const sideEffects = useMemo(() => {
    const sideEffectsArray = [];
    patientSideEffects.forEach((s) => {
      const event = sideEffectsArray.find((e) => s.date === e.date
       && s.duration === e.duration && s.endTimestamp === e.endTimestamp
       && s.lastedMoreThanADay && s.notes === e.notes && s.severity === e.severity
       && s.sideEffect.id === e.sideEffect.id);
      if (!event) {
        sideEffectsArray.push(s);
      }
    });
    return sideEffectsArray;
  }, [patientSideEffects]);

  const defaultText = useMemo(() => {
    let text = '\n';
    text += `${translate('PATIENT_RECORD.patientReports.sideEffectEMR.title')}`;
    text += '\n';

    sideEffects.forEach((sideEffect) => {
      const days = differenceInDays(
        new Date(sideEffect.endTimestamp),
        new Date(sideEffect.startTimestamp.split('T')[0]),
      );

      if (sideEffect.lastedMoreThanADay && !isSameDay(
        new Date(sideEffect.date),
        new Date(sideEffect.endTimestamp),
      )) {
        text += '';
      } else {
        text += '\n';
        if (days === 0) {
          text += `-  ${translate('PATIENT_RECORD.patientReports.sideEffectEMR.date', { date: new Date(sideEffect.startTimestamp) })}, `;
          text += `${translate('PATIENT_RECORD.patientReports.sideEffectEMR.lastedLessThanADay')};\n`;
        } else if (days === 1) {
          text += `-  ${translate('PATIENT_RECORD.patientReports.sideEffectEMR.date', { date: new Date(sideEffect.startTimestamp) })}, `;
          text += `${translate('PATIENT_RECORD.patientReports.sideEffectEMR.day')};\n`;
        } else if (days > 1) {
          text += `-  ${translate('PATIENT_RECORD.patientReports.sideEffectEMR.dateRange', {
            start: new Date(sideEffect.startTimestamp), end: new Date(sideEffect.endTimestamp),
          })}, `;
          text += `${translate('PATIENT_RECORD.patientReports.sideEffectEMR.days', { days })};\n`;
        }

        text += `   ${sideEffect.sideEffect.value}, ${sideEffect.severity}-${getSeverity(sideEffect.severity)};\n`;

        if (sideEffect.notes && addNotes) {
          text += `   ${translate('PATIENT_RECORD.patientReports.sideEffectEMR.note')} ${sideEffect.notes};`;
          text += '\n';
        }
      }
    });
    return text;
  }, [
    translate, sideEffects, addNotes,
  ]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText]);

  if (patientSideEffects && !patientSideEffects.length) {
    return `\n${translate('PATIENT_RECORD.patientReports.sideEffectEMR.title')}\n`;
  }

  return inputText;
};

export default useSideEffectText;
