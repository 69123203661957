/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import axios from 'axios';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { API_REQUEST_ERROR_CODES } from 'Constants';
import appActions from './appActions';
import loginActions from './loginActions';

/** Handles all possible api request failure cases
 * It parses the error response and returns Promise.reject with the errorCode.
 *
 */
const handleError = (error, dispatch) => {
  let errorCode;
  if (error.errorCode) {
    /** eg. no token scenario
     * we created the `error` object when calling the handler
     */
    errorCode = error.errorCode;
  } else if (error.response) {
    /** We received an `error.response` object from the server
     * It should have a `data.statusCode` field sent from the BE,
     * as fallback, use the standard `status` field
     */
    errorCode = _.get(error.response, 'data.statusCode', error.response.status);
  } else if (error.message === 'Network Error') {
    errorCode = API_REQUEST_ERROR_CODES.NETWORK_ERROR;
  }
  if (!errorCode) {
    errorCode = API_REQUEST_ERROR_CODES.GENERIC_ERROR;
  }

  /** Auto-logout the user on unauthorized response or no access token error.  */
  if ((errorCode === API_REQUEST_ERROR_CODES.UNAUTHORIZED)
    || (errorCode === API_REQUEST_ERROR_CODES.NO_ACCESS_TOKEN)) {
    dispatch(loginActions.logout());
  }

  return Promise.reject(errorCode);
};

/**
 * Authenticated api request action creator
 * @param {string} method Request method (use API_REQUEST_METHODS)
 * @param {string} url Api endpoint to be called
 * @param {any} data Payload for the call
 * @param {any} [params] Url parameters
 * @param {any} [options] Additional options to the call,
 * @param {string} responseType responseType for type of response
 *  eg: silent = true will not show the top line progress indicator
 * contentful = true, it's a Contentful request, use Contentful base url
 */

let cancelToken;

const apiRequest = (
  method,
  url,
  data,
  params = {},
  options = { silent: false, contentful: false },
  responseType = 'json',
  cancelOnNewRequest = false,
) => async (dispatch) => {
  /** Let's clear notification bar before any api request attempt
   *  Note: this means we remove messages that might have been triggered
   *  by a different action. Also, this also assumes that we want to display
   *  all messages in the top bar.
   *  Revisit & change this approach, if design changes in the future.
   */
  dispatch(appActions.appPopNotification());

  /** Get access token from current session */
  let accessToken;
  try {
    accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  } catch (error) {
    return handleError({ errorCode: API_REQUEST_ERROR_CODES.NO_ACCESS_TOKEN }, dispatch);
  }

  /**
   * On some requests we might not want to show the progress indicator.
   * We're defaulting this to false.
   */
  if (!options.silent) {
    /** Show progress indicator */
    dispatch(appActions.appPushBusy());
  }

  const baseURL = options.contentful
    ? process.env.REACT_APP_CONTENTFUL_BASE_URL : process.env.REACT_APP_BASE_URL;

  if (cancelOnNewRequest) {
    if (cancelToken) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();
  }

  /** Make the API call */
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method,
    baseURL,
    url,
    data,
    params,
    responseType,
    cancelToken: cancelOnNewRequest && cancelToken ? cancelToken.token : null,
  })
    .then(
      (response) => response,
      (error) => handleError(error, dispatch),
    )
    .finally(() => {
      if (!options.silent) {
        dispatch(appActions.appPopBusy());
      }
    });
};

export default apiRequest;
