import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import {
  addMonths, differenceInMinutes, format, isBefore, isValid, lastDayOfMonth, subMonths,
  startOfMonth,
  addDays,
} from 'date-fns';
import KnNextIcon from 'components/icons/NextIcon';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, composeFullName } from 'utils/utils';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import { KnBoldSectionHeader } from 'components/Typography';
import KnPreviousIcon from 'components/icons/PreviousIcon';
import KnFlagIcon from 'components/icons/FlagIcon';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import { KnActionLink } from 'components/Link';
import FullCalendar from '@fullcalendar/react';
import useDialog from 'components/dialog/DialogService';
import dayGridPlugin from '@fullcalendar/daygrid';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import timeGridPlugin from '@fullcalendar/timegrid';
import useSessionStorage, { SESSION_STORAGE_KEYS } from 'utils/sessionStorage';
import { API_DATE_FORMAT, eventBgColors } from 'Constants';
import interactionPlugin from '@fullcalendar/interaction';
import { find, get, map } from 'lodash';
import KnPageContent from '../../../components/Content';
import patientsListActions from '../../../redux/actions/patientsListActions';
import calendarActions from '../../../redux/actions/calendarActions';
import { KnCalenarBox } from '../styles';
import './AdminCalendar.css';
import ThisWeekBtn, {
  CalendarTitle,
  CalendarBox,
  EventTitle,
  EventIcon,
  KnPhysicianTextField,
  TimeZone,
} from './styles';
import EventDetailsPopover from './EventDetailsPopover';
import { SLOT_STATUS } from './CalendarConstant';


const timeZone = 'America/Los_Angeles';

const { paleGrey9, paleGrey7 } = palette.paleGrey;
const { dark1 } = palette.green;
const { blue3 } = palette.blue;
const { black1 } = palette.black;

const SLOT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SS";

const openStyles = {
  backgroundColor: eventBgColors.open,
  textColor: paleGrey7,
  borderColor: 'transparent',
};

const bookedStyles = {
  backgroundColor: eventBgColors.booked,
  textColor: dark1,
  borderColor: 'transparent',
};

const reservedStyles = {
  backgroundColor: eventBgColors.reserved,
  textColor: blue3,
  borderColor: 'transparent',
};

function renderDayHeaderContent(eventInfo) {
  return (
    <span>
      <b style={{ color: paleGrey9, fontSize: 10 }}>{eventInfo.text.split(' ')[0]}</b>
      <br />
      <span style={{ color: black1, fontSize: 22 }}>
        {eventInfo.text.split(' ')[1].split('/')[1]}
      </span>
    </span>
  );
}

const slotLabelContent = (eventInfo) => <b style={{ color: paleGrey9, fontSize: 12 }}>{format(eventInfo.date, 'h a')}</b>;

const AdminCalendar = () => {
  const [initialized, setInitialized] = useState(false);
  const [calendarApi, setCalendarApi] = useState({});
  const [physician, setPhysician] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [storedValue, setStoredValue] = useSessionStorage(SESSION_STORAGE_KEYS.nileNextPhysician);
  const [dates, setDates] = useState({
    start: '', end: '',
  });
  const [currentEvents, setCurrentEvents] = useState([]);
  const calendarRef = React.useRef();
  const { t: translate } = useTranslation();
  const dialog = useDialog();
  const [title, setTitle] = useState(get(calendarApi, 'currentDataManager.data.viewTitle'));

  const { nileNextPhysiciansList } = useSelector((state) => state.patientsList);
  const { openSlots, appointments } = useSelector((state) => state.calendar);
  const { data: appointmentSlots } = appointments;
  const { isAdmin } = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (!isAdmin) {
      history.goBack();
    }
  }, [isAdmin, history]);

  useEffect(() => {
    if (initialized
      && location.state
      && location.state.date
      && isValid(new Date(location.state.date))
    ) {
      calendarApi.gotoDate(new Date(location.state.date));
    }
  }, [location, initialized, calendarApi]);

  const defaultPhysician = useMemo(() => {
    let selectedPhysician;
    if (storedValue && nileNextPhysiciansList) {
      selectedPhysician = nileNextPhysiciansList.find((p) => p.id === storedValue);
      if (selectedPhysician) {
        selectedPhysician = selectedPhysician.id;
        setPhysician(selectedPhysician.id);
      } else {
        selectedPhysician = get(nileNextPhysiciansList, '[0].id');
      }
    } else if (nileNextPhysiciansList && nileNextPhysiciansList.length) {
      selectedPhysician = get(nileNextPhysiciansList, '[0].id');
    }
    setPhysician(selectedPhysician);
    return selectedPhysician;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nileNextPhysiciansList]);

  useEffect(() => {
    setTitle(get(calendarApi, 'currentDataManager.data.viewTitle'));
  }, [setTitle, calendarApi]);

  useEffect(() => {
    if (!initialized && calendarRef.current) {
      setInitialized(true);
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [setInitialized, initialized, calendarRef]);


  useEffect(() => {
    dispatch(patientsListActions.fetchNileNextPhysicians());
  }, [dispatch]);

  const getCalenderData = useCallback(() => {
    if (physician && dates.start && dates.end) {
      dispatch(calendarActions.getPhysicanSlots(physician, {
        startDate: new Date(format(new Date(dates.start), API_DATE_FORMAT)).toISOString(),
        endDate: new Date(format(new Date(dates.end), API_DATE_FORMAT)).toISOString(),
      }));
      dispatch(calendarActions.getPhysicanAppointment(physician, {
        startDate: new Date(format(new Date(dates.start), API_DATE_FORMAT)).toISOString(),
        endDate: new Date(format(new Date(dates.end), API_DATE_FORMAT)).toISOString(),
      }));
    }
  }, [physician, dates, dispatch]);

  useEffect(() => {
    getCalenderData();
  }, [getCalenderData]);

  useEffect(() => {
    if (appointmentSlots) {
      const apps = appointmentSlots.map((o) => ({
        id: o.uuid,
        title: `${capitalizeFirstLetter(o.status)}: ${o.firstName} ${o.lastName}`,
        status: o.status,
        ...o,
        start: format(utcToZonedTime(o.startDate, timeZone), SLOT_FORMAT),
        end: format(utcToZonedTime(o.endDate, timeZone), SLOT_FORMAT),
        range: {
          start: format(utcToZonedTime(o.startDate, timeZone), SLOT_FORMAT),
          end: format(utcToZonedTime(o.endDate, timeZone), SLOT_FORMAT),
        },
        slotId: o.id,
        ...(SLOT_STATUS.RESERVED === o.status ? reservedStyles : bookedStyles),
        isUpdated: o.flag,
      }));
      const slots = openSlots.map((o) => ({
        id: uuidv4(),
        title: 'Open',
        status: 'open',
        ...o,
        start: format(utcToZonedTime(o.startDate, timeZone), SLOT_FORMAT),
        end: format(utcToZonedTime(o.endDate, timeZone), SLOT_FORMAT),
        slotId: o.id,
        ...openStyles,
      }));
      setCurrentEvents([
        ...slots,
        ...apps,
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSlots, openSlots]);

  const nextPrev = useCallback((next) => {
    if (next) {
      calendarApi.next();
    } else {
      calendarApi.prev();
    }

    setTitle(get(calendarApi, 'currentDataManager.data.viewTitle'));
  }, [calendarApi]);

  const jumpToCurrentWeek = useCallback(() => {
    calendarApi.gotoDate(new Date());
    setTitle(get(calendarApi, 'currentDataManager.data.viewTitle'));
  }, [calendarApi]);

  const handleOpenClick = useCallback((clickInfo, eventClick) => {
    // eslint-disable-next-line dot-notation
    const def = clickInfo.event['_def'];
    const { extendedProps = {} } = def;
    const { status } = extendedProps;
    const events = currentEvents.filter((e) => e.id !== def.publicId);

    if ([SLOT_STATUS.OPEN].includes(status)
      && !eventClick
    ) {
      setCurrentEvents(events);
    }
  }, [currentEvents]);

  const deleteOpenSlot = useCallback(async (event) => {
    dispatch(calendarActions.deletePhysicanSlots(get(event, 'event._def.extendedProps.slotId')));
  }, [dispatch]);

  const datesSetEvent = useCallback((e) => {
    if (
      (dates.start ? format(new Date(dates.start), API_DATE_FORMAT) : '')
      !== format(new Date(e.startStr), API_DATE_FORMAT)
    ) {
      setDates({
        start: e.startStr,
        end: e.endStr,
      });
    }
  }, [dates]);

  const createOpenSlot = useCallback((selectInfo) => {
    const calendarApi1 = selectInfo.view.calendar;
    calendarApi1.unselect();
    const startDate = zonedTimeToUtc(selectInfo.start.toISOString(), timeZone);
    const endDate = zonedTimeToUtc(selectInfo.end.toISOString(), timeZone);
    dispatch(calendarActions.createPhysicanSlot({
      hcpUserId: physician,
      startDate,
      endDate,
    }));
  }, [dispatch, physician]);

  const handleEventClick = useCallback((e, event) => {
    setSelectedEvent(event);
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((update) => {
    setAnchorEl(null);
    if (update) {
      getCalenderData();
    }
  }, [getCalenderData]);

  const handleDeleteEvent = useCallback((event) => {
    dialog({
      title: translate('ADMIN_CALENDAR.deleteEventConfirm.title'),
      description: translate('ADMIN_CALENDAR.deleteEventConfirm.content'),
      submitLabel: translate('GENERAL.okButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(async () => {
      // delete event api call
      await dispatch(calendarActions.cancelAppointment(get(event, 'extendedProps.slotId')));
      handleClose(true);
    });
  }, [dialog, translate, dispatch, handleClose]);

  const isPastAppintment = useCallback(
    (startDate) => isBefore(
      utcToZonedTime(new Date(startDate).toISOString(), timeZone),
      utcToZonedTime(new Date(), timeZone),
    ), [],
  );

  const renderEventContent = (eventInfo) => {
    // eslint-disable-next-line dot-notation
    const def = eventInfo.event['_def'];
    const { extendedProps = {} } = def;
    const {
      status, startDate, isUpdated, uuid,
    } = extendedProps;

    if (status === SLOT_STATUS.OPEN) {
      return (
        <EventIcon>
          <b>Open</b>
          {!isPastAppintment(startDate) && (
            <KnActionLink
              onClick={() => deleteOpenSlot(eventInfo)}
              LhsIcon={KnDeleteIcon}
              tooltip
              iconColor={paleGrey7}
              tooltipText={translate('ICON_TOOLTIP.deleteEvent')}
              style={{ float: 'right', marginTop: 7 }}
              data-testid="delete-event"
            />
          )}
        </EventIcon>
      );
    }

    return (
      <>
        <EventTitle id={uuid} onClick={(e) => handleEventClick(e, eventInfo)}>
          <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          >
            <b>{eventInfo.event.title}</b>
          </span>
          <span>{eventInfo.timeText}</span>
          {isUpdated && (
            <span style={{
              position: 'absolute',
              right: 11,
              top: 9,
            }}
            >
              <KnFlagIcon />
            </span>
          )}
        </EventTitle>
      </>
    );
  };

  return (
    <KnPageContent>
      <KnCalenarBox pt={4} pb={6} pl={3} pr={3}>
        <Typography
          variant="h3"
          component={KnBoldSectionHeader}
          style={{ fontSize: 34 }}
          data-testid="calender-page-title"
        >
          {translate('ADMIN_CALENDAR.title')}
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={5}>
          <Box display="flex" alignItems="center">
            <ThisWeekBtn color="primary" onClick={jumpToCurrentWeek}>This Week</ThisWeekBtn>
            <Box display="flex" ml={3} mr={3}>
              <KnActionLink
                style={{ marginRight: 24 }}
                data-testid="previous-button"
                LhsIcon={KnPreviousIcon}
                tooltip
                tooltipText={translate('ICON_TOOLTIP.previous')}
                onClick={() => nextPrev(false)}
              />
              <KnActionLink
                data-testid="next-button"
                LhsIcon={KnNextIcon}
                tooltip
                tooltipText={translate('ICON_TOOLTIP.next')}
                onClick={() => nextPrev(true)}
              />
            </Box>
            <CalendarTitle>{title}</CalendarTitle>
          </Box>
          {nileNextPhysiciansList && (
            <KnPhysicianTextField
              select
              label={translate('HOME.filterByPhysician')}
              data-testid="physician-filter-dropdown"
              onChange={(e) => {
                setPhysician(e.target.value);
                setStoredValue(e.target.value);
              }}
              pb={0}
              defaultValue={defaultPhysician}
              disableErrorMessage
            >
              {map(nileNextPhysiciansList, (p, index) => (
                <MenuItem
                  key={p.id}
                  value={p.id}
                  data-testid={`physician-filter-option-${index + 1}`}
                >
                  {composeFullName(p.firstName, p.lastName)}
                </MenuItem>
              ))}
            </KnPhysicianTextField>
          )}
        </Box>

        <Box mt={3}>
          <CalendarBox>
            <TimeZone>PST</TimeZone>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: '',
                right: '',
              }}
              validRange={{
                start: startOfMonth(subMonths(new Date(), 5)),
                end: addDays(lastDayOfMonth(addMonths(new Date(), 5)), 1),
              }}
              initialView="timeGridWeek"
              editable={false}
              selectable
              droppable={false}
              selectMirror
              allDaySlot={false}
              selectOverlap={false}
              eventMinHeight={35}
              contentHeight="auto"
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              selectAllow={(info) => !isBefore(info.start, utcToZonedTime(new Date(), timeZone))
                && differenceInMinutes(new Date(info.end), new Date(info.start)) <= 30}
              businessHours={{
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
                startTime: '00:00',
                endTime: '24:00',
              }}
              dayMaxEvents
              dragScroll={false}
              titleFormat={{ year: 'numeric', month: 'short' }}
              weekends
              eventContent={renderEventContent}
              select={createOpenSlot}
              eventClick={(e) => handleOpenClick(e, true)}
              eventDisplay="block"
              events={currentEvents}
              selectConstraint="businessHours"
              dayHeaders
              datesSet={datesSetEvent}
              slotLabelContent={slotLabelContent}
              dayHeaderContent={renderDayHeaderContent}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: 'short',
              }}
            />
            {Boolean(anchorEl && nileNextPhysiciansList) && (
              <EventDetailsPopover
                anchorEl={anchorEl}
                handleClose={handleClose}
                deleteEvent={handleDeleteEvent}
                getCalenderData={getCalenderData}
                appointment={get(selectedEvent, 'event._def')}
                physician={find(nileNextPhysiciansList, (p) => p.id === physician)}
              />
            )}
          </CalendarBox>
        </Box>
      </KnCalenarBox>
    </KnPageContent>
  );
};

export default AdminCalendar;
