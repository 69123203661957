/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { KnContrastTextField } from './TextField';
import { withKeyNamespace } from '../utils/utils';
import { translate } from '../i18n/i18n';

const i18nKey = withKeyNamespace('DATEPICKER');

const { black1 } = palette.black;

const KnTextField = styled(TextField)({
  '&:hover': {
    textDecoration: 'underline',
  },
  '& input': {
    fontWeight: 700,
    fontSize: 16,
    color: black1,
  },
  '& .MuiIconButton-label': {
    display: 'none',
  },
});

const KnDatePicker = ({ ...rest }) => {
  const [error, setError] = useState({});
  const handleError = (type) => {
    setError({ type, message: translate(i18nKey('minDateErrorMessage')) });
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setIsOpen((isO) => !isO);
    setAnchorEl(event.currentTarget);
  };

  const openPickerButtonStyles = {
    position: 'absolute',
    left: 0,
    borderRadius: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  let extraProps = {};
  if (rest.plain) {
    extraProps = {
      open: isOpen,
      clearable: true,
      PopperProps: {
        placement: 'bottom-end',
        anchorEl,
      },
    };
  }

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DatePicker
        OpenPickerButtonProps={{
          style: openPickerButtonStyles,
        }}
        renderInput={(props) => (
          <>
            {rest.plain ? (
              <KnTextField
                {...props}
                pb={0}
                helperText=""
                label=""
                required={rest.required}
                onClick={handleClick}
              />
            ) : (
              <KnContrastTextField
                {...props}
                pb={0}
                error={error}
                helperText=""
                required={rest.required}
              />
            )}
          </>
        )}
        inputFormat={rest.inputFormat || 'MMM dd, yyyy'}
        disableHighlightToday
        onError={handleError}
        {...rest}
        onClose={() => setIsOpen(false)}
        {...extraProps}
      />
    </LocalizationProvider>
  );
};

export default KnDatePicker;
