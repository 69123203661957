/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { METRICS_TYPE } from 'Constants';
import { styled } from '@material-ui/core/styles';
import KnMetricsChart from './charts/MetricsChart';
import {
  KnMetricsLabel,
  KnCommonSymptomsWrapper,
  KnSymptomLabel,
  KnSymptomFrequency,
} from './styles';
import palette from '../../styles/colors';

const ScrollableBox = styled(Box)({
  overflowX: 'auto',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: palette.paleGrey.paleGrey6,
  },
  '&::-webkit-scrollbar:horizontal': {
    height: 5,
  },
});

const KnCommonSymptoms = ({ symptoms }) => {
  const { t: translate } = useTranslation();

  return (
    <KnCommonSymptomsWrapper mb={2}>
      <KnMetricsLabel>{translate('PATIENT_RECORD.symptomMetrics.topSymptomsTitle')}</KnMetricsLabel>
      <Box pt={1} pb={1} display="flex" flexDirection="column">
        {symptoms.map(({ type, list }) => {
          /** If there are no symptoms in a month, value will be null. */
          const count = _.sumBy(list, (item) => (item.value || 0));

          return (
            <Box display="flex" key={type}>
              <KnSymptomLabel>{type}</KnSymptomLabel>
              <KnSymptomFrequency>
                {translate('PATIENT_RECORD.symptomMetrics.symptomFrequency', { count })}
              </KnSymptomFrequency>
            </Box>
          );
        })}
      </Box>
    </KnCommonSymptomsWrapper>
  );
};

const KnSeizureDetails = (props) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <KnMetricsLabel>{translate('PATIENT_RECORD.seizureMetrics.chartTitle')}</KnMetricsLabel>
      <ScrollableBox>
        <KnMetricsChart {...props} />
      </ScrollableBox>
    </>
  );
};

const KnSymptomDetails = ({ data, chartId }) => (
  <>
    <KnCommonSymptoms symptoms={data} />
    {data.map((chart) => (
      <Box pb={2} key={`${chartId}-${chart.type}`}>
        {/* TODO: revise this when we have reference data?
            For now use the symtomName value returned by BE. */}
        <KnMetricsLabel>{chart.type}</KnMetricsLabel>
        <ScrollableBox>
          <KnMetricsChart
            chartId={`${chartId}-${chart.type}`}
            data={chart.list}
          />
        </ScrollableBox>
      </Box>
    ))}
  </>
);

/**
 * Displays metrics specific UI.
 */
const KnMetricsDetails = ({ type, aggregatedData }) => {
  const isSeizureMetrics = (type === METRICS_TYPE.seizure);
  const chartId = `${type}-chart`;
  const KnMetricsDetailsVariant = isSeizureMetrics ? KnSeizureDetails : KnSymptomDetails;

  return (
    <KnMetricsDetailsVariant
      chartId={chartId}
      data={aggregatedData}
    />
  );
};

KnMetricsDetails.propTypes = {
  type: PropTypes.oneOf([METRICS_TYPE.seizure, METRICS_TYPE.symptom]).isRequired,
  aggregatedData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

KnSymptomDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  chartId: PropTypes.string.isRequired,
};

KnCommonSymptoms.propTypes = {
  symptoms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default KnMetricsDetails;
