import { v4 as uuidv4 } from 'uuid';
import ACTION_TYPES from '../actionTypes';
import apiRequest from './apiActions';
import { API_REQUEST_METHODS, API_URLS } from '../../Constants';
import appActions from './appActions';

const getPhysicanSlots = (physicianId, params) => {
  const request = () => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_SLOT_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_SLOT_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_SLOT_ERROR });
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.physicianOpenSlots.replace(':physicianId', physicianId),
          null,
          params,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const getPhysicanSlotsGroup = (appointmentId, params) => {
  const request = () => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_CALENDAR_SLOT_REQUEST });
  const success = (data) => ({
    type: ACTION_TYPES.PHYSICIAN_OPEN_CALENDAR_SLOT_SUCCESS,
    data,
  });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_CALENDAR_SLOT_ERROR });
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.openSlotsGroup.replace(':appointmentId', appointmentId),
          null,
          params,
          { silent: false },
        ),
      );
      dispatch(success(response.data.map(
        (s) => ({ ...s, id: uuidv4() }),
      )));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const deletePhysicanSlots = (slotId) => {
  const success = (id) => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_SLOT_DELETE_SUCCESS, data: id });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          API_URLS.deleteSlot.replace(':slotId', slotId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(slotId));
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('ADMIN_CALENDAR.ERROR_MESSAGES.deleteSlot'));
    }
  };
};

const createPhysicanSlot = (body) => {
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIAN_OPEN_SLOT_CREATE_SUCCESS, data });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.POST,
          API_URLS.createSlot,
          body,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('ADMIN_CALENDAR.ERROR_MESSAGES.createSlot'));
    }
  };
};

const getPhysicanAppointment = (physicianId, params) => {
  const request = () => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.physicianAppointment.replace(':physicianId', physicianId),
          null,
          params,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const cancelAppointment = (appointmentId) => {
  const request = () => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_CANCEL_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_CANCEL_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIAN_APPOINTMENT_CANCEL_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.POST,
          API_URLS.cancelAppointment.replace(':appointmentId', appointmentId),
          null,
          {},
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};


const getPatientUpcomingAppointment = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_APPOINTMENT_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_APPOINTMENT_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_APPOINTMENT_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientAppointment.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const getPatientConsent = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_CONSENT_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_CONSENT_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_CONSENT_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.consent.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const getPatientInsurance = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_INSURANCE_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.insurace.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const getInsuranceCompletedInfo = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_COMPLETED_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.insuranceCompleted.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const getInsuranceUpdateInfo = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_UPDATED_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_INSURANCE_UPDATED_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_INSURANCE_UPDATED_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.insuranceUpdate.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const updateInsuranceFlag = (insuranceId) => async (dispatch) => {
  try {
    await dispatch(
      apiRequest(
        API_REQUEST_METHODS.PUT,
        API_URLS.insuranceSeen.replace(':insuranceId', insuranceId),
        null,
        null,
        { silent: false },
      ),
    );
  } catch (errorCode) {
    // log error here
  }
};

const updateAppointment = (appointmentId, payload) => async (dispatch) => {
  try {
    await dispatch(
      apiRequest(
        API_REQUEST_METHODS.PATCH,
        API_URLS.appointment.replace(':appointmentId', appointmentId),
        payload,
        null,
        { silent: false },
      ),
    );
    return true;
  } catch (errorCode) {
    dispatch(appActions.appPushNotification('ADMIN_CALENDAR.ERROR_MESSAGES.updateAppointment'));
    setTimeout(() => {
      dispatch(appActions.appPopNotification());
    }, 3000);
    return false;
  }
};


const getMedicalHisUpdateInfo = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_MEDICAL_UPDATED_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_MEDICAL_UPDATED_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_MEDICAL_UPDATED_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.medicalHistoryUpdate.replace(':patientId', patientId),
          null,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const updateMedicalHistoryFlag = (patientId) => async (dispatch) => {
  try {
    await dispatch(
      apiRequest(
        API_REQUEST_METHODS.PUT,
        API_URLS.medicalHistorySeen.replace(':patientId', patientId),
        null,
        null,
        { silent: false },
      ),
    );
  } catch (errorCode) {
    // log error here
  }
};

const clearInsuranceInfo = () => ({
  type: ACTION_TYPES.PATIENT_INSURANCE_CLEAR_INFO,
});

const markAppointmentFlagViewed = (appointmentId) => ({
  type: ACTION_TYPES.PATIENT_APPOINTMENT_FLAG_VIEWED,
  data: appointmentId,
});

export default {
  getPhysicanSlots,
  deletePhysicanSlots,
  createPhysicanSlot,
  getPhysicanAppointment,
  getPatientUpcomingAppointment,
  cancelAppointment,
  getPatientConsent,
  getPhysicanSlotsGroup,
  updateAppointment,
  getPatientInsurance,
  getInsuranceCompletedInfo,
  getInsuranceUpdateInfo,
  updateInsuranceFlag,
  clearInsuranceInfo,
  getMedicalHisUpdateInfo,
  markAppointmentFlagViewed,
  updateMedicalHistoryFlag,
};
