/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';
import { PATIENTS_LIST_TYPES } from 'Constants';
import { sortBy } from 'lodash';

const DEFAULT_SORT_DIR = {
  verified: 'asc',
  unlinked: 'asc',
  invited: 'asc',
  refractory: 'desc',
  never_linked: 'asc',
};

const DEFAULT_SORT_BY = {
  verified: 'lastName',
  unlinked: 'lastName',
  invited: 'lastName',
  refractory: 'status',
  never_linked: 'lastName',
};

const DEFAULT_LIST_TYPE = PATIENTS_LIST_TYPES.VERIFIED;

// const loggedPhysicianId = JSON.parse(sessionStorage.getItem('loggedPhysicianId'));
const initialState = {
  listType: DEFAULT_LIST_TYPE,
  list: [],
  physiciansList: [],
  nileNextPhysiciansList: null,
  healthSystemsList: [],
  total: 0,
  error: false,
  showInviteToolbar: false,
  params: {
    search: null,
    page: 0,
    limit: 20,
    sortBy: DEFAULT_SORT_BY[DEFAULT_LIST_TYPE],
    sortDir: DEFAULT_SORT_DIR[DEFAULT_LIST_TYPE],
    healthsystem: [],
    physician: [],
    regimenStatus: [],
  },
};

const patientsListReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.PATIENT_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };

    case ACTION_TYPES.PATIENT_LIST_SUCCESS:
      return {
        ...state,
        ...data,
      };

    case ACTION_TYPES.PATIENT_LIST_ERROR:
      return {
        ...state,
        list: [],
        error: true,
      };

    case ACTION_TYPES.PHYSICIANS_LIST_SUCCESS:
      return {
        ...state,
        physiciansList: data,
      };

    case ACTION_TYPES.NILE_NEXT_PHYSICIANS_LIST_SUCCESS:
      return {
        ...state,
        nileNextPhysiciansList: sortBy(data, (d) => d.lastName),
      };
    case ACTION_TYPES.HEALTH_SYSTEM_SUCCESS:
      return {
        ...state,
        healthSystemsList: data,
      };

    case ACTION_TYPES.PATIENT_LIST_SEARCH:
      return {
        ...state,
        params: {
          ...state.params,
          search: action.searchTerm,
          page: 0,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_TYPE:
      return {
        ...state,
        listType: action.listType,
        showInviteToolbar: false,
        list: [],
        params: {
          ...state.params,
          search: null,
          sortBy: DEFAULT_SORT_BY[action.listType],
          sortDir: DEFAULT_SORT_DIR[action.listType],
        },
      };

    case ACTION_TYPES.PATIENT_LIST_PAGE:
      return {
        ...state,
        params: {
          ...state.params,
          page: action.page,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_LIMIT:
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          limit: action.limit,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_SORT:
      return {
        ...state,
        params: {
          ...state.params,
          sortBy: action.sortKey,
          sortDir: (action.sortKey === state.params.sortBy && state.params.sortDir === 'asc') ? 'desc' : 'asc',
          page: 0,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_FILTER_BY_PHYSICIAN:
      return {
        ...state,
        params: {
          ...state.params,
          physician: action.physiciansIds,
          page: 0,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_FILTER_BY_HS:
      return {
        ...state,
        params: {
          ...state.params,
          healthsystem: action.healthSystemIds,
          page: 0,
        },
      };

    case ACTION_TYPES.PATIENT_LIST_FILTER_BY_RS:
      return {
        ...state,
        params: {
          ...state.params,
          regimenStatus: action.regimenStatuses,
          page: 0,
        },
      };

    case ACTION_TYPES.SHOW_INVITE_TOOLBAR: {
      return {
        ...state,
        showInviteToolbar: true,
        listType: PATIENTS_LIST_TYPES.INVITED,
      };
    }

    case ACTION_TYPES.INVITE_PATIENT_SUCCESS: {
      return {
        ...state,
        showInviteToolbar: false,
        params: {
          ...state.params,
          sortBy: 'dateInvited',
          sortDir: 'desc',
          page: 0,
        },
      };
    }

    default:
      return state;
  }
};

export default patientsListReducer;
