/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnBoldSectionHeader, KnSubtitleText } from 'components/Typography';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const sheetBackgroundColor = palette.white.white2;
const headerBoxBorder = palette.silver.silver3;

const KnBrightBox = styled(Box)({
  backgroundColor: sheetBackgroundColor,
});

const KnBrightBoxHeader = styled(Box)({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: headerBoxBorder,
});

const KnBrightBoxSubtitle = styled(Box)({
  width: 395,
});

/**
 * Component wrapper for displaying a bright white box with
 * a section header and other optional elements: a subtitle or
 * a header action button or link.
 */
const KnBrightSheet = (props) => {
  const {
    children,
    title,
    subTitle,
    ActionComponent,
    padding,
  } = props;

  return (
    <KnBrightBox display="flex" flexDirection="column" flex="1">
      <Box display="flex" flex="1" flexDirection="column" pt={padding || 7} pb={padding || 7} pl={padding || 8} pr={padding || 8}>
        {/** Sheet header */}
        <KnBrightBoxHeader display="flex" justifyContent="space-between" alignItems="baseline" pb={3} mb={2.5}>
          {/** Titles */}
          <Box>
            <Typography variant="h5" component={KnBoldSectionHeader}>
              {title}
            </Typography>
            {subTitle && (
              <KnBrightBoxSubtitle>
                <Typography component={KnSubtitleText}>
                  {subTitle}
                </Typography>
              </KnBrightBoxSubtitle>
            )}
          </Box>
          {/** Action link/button on the right */}
          {ActionComponent}
        </KnBrightBoxHeader>
        {/** Content */}
        {children}
      </Box>
    </KnBrightBox>
  );
};

KnBrightSheet.propTypes = {
  /** Header title */
  title: PropTypes.string.isRequired,
  /** Header subtitle  */
  subTitle: PropTypes.string,
  /** Header action link or button */
  ActionComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** The content to be dispalyed in the box */
  children: PropTypes.node,
  padding: PropTypes.number,
};

KnBrightSheet.defaultProps = {
  subTitle: null,
  ActionComponent: null,
  children: null,
  padding: 0,
};

export default KnBrightSheet;
