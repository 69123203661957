import { size } from 'lodash';
import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const medicalHistoryEMR = 'PATIENT_RECORD.patientReports.medicalHistoryEMR';

const useMedicalHistory = () => {
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();
  const emrReport = useSelector((state) => state.patientRecord.emrReport);
  const { medicalHistory = [] } = emrReport.data || {};

  const getQuestionsText = useCallback((questions) => {
    let text = '';
    (questions || []).forEach((question) => {
      if (question.answer) {
        text += `\n  - ${(question.title || '').toUpperCase()}`;
        text += `\n    ${question.answer}`;
      }
    });
    return text;
  }, []);

  const getTextInt = useCallback((str, options) => translate(`${medicalHistoryEMR}.${str}`, options), [translate]);

  const getMedicationText = useCallback((medications, isPast) => {
    let text = '';
    (medications || []).forEach((question, index) => {
      text += `\n  - ${getTextInt('medication', { count: index + 1 })}`;
      text += `\n    ${question.medicationActiveIngredient} - ${question.medicationName}`;

      if (question.duration) {
        text += `\n    ${getTextInt('duration')}`;
        text += `\n    ${question.duration}`;
      }

      if (question.type) {
        text += `\n    ${getTextInt('type')}`;
        text += `\n    ${question.type}`;
      }

      if (question.amount) {
        text += `\n    ${getTextInt('amount')}`;
        text += `\n    ${question.amount}`;
      }

      if (question.frequency) {
        const key = question.frequency > 1 ? 'times_title' : 'times_plural';
        text += `\n    ${getTextInt('frequency')}`;
        text += `\n    ${getTextInt(
          key, { count: question.frequency },
        )}`;
      }

      if (isPast && question.extra) {
        text += `\n    ${getTextInt('stopMeds')}`;
        text += `\n    ${question.extra}`;
      }
    });
    return text;
  }, [getTextInt]);

  const hasQuestions = useCallback(
    (questions) => (questions || []).some((question) => question.answer), [],
  );

  const defaultText = useMemo(() => {
    let text = '\n';
    text += `${getTextInt('title')}\n\n`;

    const seizures = medicalHistory.find((m) => m.title === 'SEIZURES');
    if (seizures && hasQuestions(seizures.questions)) {
      text += `${getTextInt('seizures')}`;
      text += getQuestionsText(seizures.questions);
    }

    const pastMedical = medicalHistory.find((m) => m.title === 'PAST MEDICAL HISTORY');
    if (pastMedical && hasQuestions(pastMedical.questions)) {
      text += `\n\n${getTextInt('pastMedical')}`;
      text += getQuestionsText(pastMedical.questions);
    }

    const currentMedication = medicalHistory.find((m) => m.title === 'CURRENT MEDICATION');
    if (currentMedication && size(currentMedication.medications)) {
      text += `\n\n${getTextInt('currentMedication')}`;
      text += getMedicationText(currentMedication.medications);
    }

    const pastMedication = medicalHistory.find((m) => m.title === 'PAST MEDICATION');
    if (pastMedication && size(pastMedication.medications)) {
      text += `\n\n${getTextInt('pastMedication')}`;
      text += getMedicationText(pastMedication.medications, true);
    }

    const household = medicalHistory.find((m) => m.title === 'DAILY LIVING');
    if (household && hasQuestions(household.questions)) {
      text += `\n\n${getTextInt('household')}`;
      text += getQuestionsText(household.questions);
    }

    const additional = medicalHistory.find((m) => m.title === 'ADDITIONAL RECORDS');
    if (additional && hasQuestions(additional.questions)) {
      text += `\n\n${getTextInt('additional')}`;
      text += getQuestionsText(additional.questions);
    }

    return text;
  }, [
    medicalHistory, getQuestionsText, getMedicationText, getTextInt, hasQuestions,
  ]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText]);

  if (medicalHistory && !medicalHistory.length) {
    return `\n${getTextInt('title')}\n`;
  }

  return inputText;
};

export default useMedicalHistory;
