/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import KnTextField from 'components/TextField';
import {
  formatValidator,
  trimValueEmptyValidator,
} from 'utils/customFieldValidators';

/**
 * Global component for validated text inputs
 * To be used for inputs inside forms with react-hook-form validations
 *
 * Attributes:
 * `name` - Value to be used as:
 *  1) name for the controller
 *  2) for label of the input,
 *     being used as part of the translation key,
*      eg: a name like 'testInput' will result in key of `FIELD_LABELS.testInput`
 *  3) part of data-testid values for input and label
 *     eg: a name like 'testInput' will result in
 *     data-testid of 'test-input-input-field' for the Input, and
 *     data-testid of 'test-input-label-field' for the Label of the input
 *  4) part of the translation key for the validation error text
 *     eg: a name like 'testInput' will result in keys like
 *     'FIELD_VALIDATION_MESSAGES.testInput.required' for required validation
 *     'FIELD_VALIDATION_MESSAGES.testInput.format' for format validation
 *    Note: if these keys will not be added to the translation file,
 *    generic messages will be used for errors
 *  `control` - control object of the form from react-hook-form
 *  `errors` - errors object of the form from react-hook-form
 *
 *  `maxLength` - number, set to impose a max length for the input
 *  `required` - true/false, whether the input should be required or not
 *  `trimSpaces` - true/false, whether trailing/starting spaces should be trimmed before validation
 *  `format` - string, regular expression, for validating the format of the input
 *  `notEqualTo` - string/number, validates whether input is different from this value
 *    (case insensitive, if input is string)
 *  `matchField` - string, name of another field, validates whether
 *      input matches another input's value (case sensitive, only if both non-empty)
 *  `notMatchField` - string, name of another field, validates whether
 *      input differs from another input's value (case sensitive, only if both non-empty)
 *  `rules` - addition validation rules. Use this if you want custom validation rules
 *      to be applies, other than what is covered by above attributes
 *
 *  `dataTestId` - string, set to specify a different data-testid which will overwrite
 *      the data-testid generated using the name attribute
 *  `disableLabel` - if `true` show no label for the field
 *  `disableErrorMessage` - if `true`, no validation error will be displayed under the field
 *
 * @param {*} props
 */
const KnValidatedTextField = (props) => {
  const {
    name,
    maxLength,
    control,
    errors,
    required,
    format,
    notEqualTo,
    matchField,
    autoComplete,
    notMatchField,
    trimSpaces,
    Component,
    disableLabel,
    rules: { validate, ...otherRules },
    dataTestId,
    ...rest
  } = props;
  const { t: translate } = useTranslation();

  const nameInKebab = _.kebabCase(name);
  const genericRequiredErrorMessage = translate('FIELD_VALIDATION_MESSAGES.required');
  const genericFormatErrorMessage = translate('FIELD_VALIDATION_MESSAGES.format');
  const genericNotEqualErrorMessage = translate('FIELD_VALIDATION_MESSAGES.shouldNotEqual');
  const genericMatchErrorMessage = translate('FIELD_VALIDATION_MESSAGES.matchField');
  const genericNotMatchErrorMessage = translate('FIELD_VALIDATION_MESSAGES.shouldNotMatchField');

  /** Save additional rules, on initial props. */
  const customRulesRef = useRef(validate);
  const otherRulesRef = useRef(otherRules);

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const validationRules = useMemo(() => ({
    required: {
      value: required,
      message: translate(`FIELD_VALIDATION_MESSAGES.${name}.required`, genericRequiredErrorMessage),
    },
    ...otherRulesRef.current,
    validate: {
      trimValueEmpty: (value) => (
        required && trimSpaces && trimValueEmptyValidator(value)
          ? translate(`FIELD_VALIDATION_MESSAGES.${name}.required`, genericRequiredErrorMessage)
          : true
      ),
      valueFormat: (value) => (
        format && !formatValidator(value, format, trimSpaces)
          ? translate(`FIELD_VALIDATION_MESSAGES.${name}.format`, genericFormatErrorMessage)
          : true
      ),
      valueNotEqualTo: (value) => (
        notEqualTo
        && (value && ((_.isString(value) && value.toLowerCase() === notEqualTo.toLowerCase())
        || (Number(value) === Number(notEqualTo))))
          ? translate(`FIELD_VALIDATION_MESSAGES.${name}.shouldNotEqual`, genericNotEqualErrorMessage)
          : true
      ),
      matchField: (value) => {
        const otherValue = matchField && control.getValues(matchField);
        if (otherValue && value && otherValue !== value) {
          return translate(`FIELD_VALIDATION_MESSAGES.${name}.matchField`, genericMatchErrorMessage);
        }
        return true;
      },
      notMatchField: (value) => {
        const otherValue = notMatchField && control.getValues(notMatchField);
        if (otherValue && value && otherValue === value) {
          return translate(`FIELD_VALIDATION_MESSAGES.${name}.shouldNotMatchField`, genericNotMatchErrorMessage);
        }
        return true;
      },
      ...customRulesRef.current,
    },
  }), [
    translate, control, format, name, required, trimSpaces, notEqualTo, matchField, notMatchField,
    genericFormatErrorMessage, genericRequiredErrorMessage,
    genericNotEqualErrorMessage, genericMatchErrorMessage, genericNotMatchErrorMessage,
  ]);

  const label = disableLabel ? '' : translate(`FIELD_LABELS.${name}`);

  return (
    <Controller
      as={Component}
      name={name}
      label={label}
      inputProps={{
        'data-testid': dataTestId || `${nameInKebab}-input-field`,
        maxLength,
        autoComplete,
      }}
      InputLabelProps={{
        'data-testid': `${nameInKebab}-input-label`,
      }}
      rules={validationRules}
      error={errors[name]}
      control={control}
      {...rest}
    />
  );
};

KnValidatedTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  format: PropTypes.instanceOf(RegExp),
  trimSpaces: PropTypes.bool,
  notEqualTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  matchField: PropTypes.string,
  autoComplete: PropTypes.string,
  notMatchField: PropTypes.string,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  disableLabel: PropTypes.bool,
  disableErrorMessage: PropTypes.bool,
  rules: PropTypes.shape(),
  dataTestId: PropTypes.string,
};

KnValidatedTextField.defaultProps = {
  maxLength: undefined,
  required: false,
  format: undefined,
  trimSpaces: false,
  Component: KnTextField,
  disableLabel: false,
  disableErrorMessage: false,
  notEqualTo: null,
  matchField: null,
  autoComplete: null,
  notMatchField: null,
  rules: {},
  dataTestId: '',
};

export default KnValidatedTextField;
