import { useEffect, useState } from 'react';

let visibilityChange;
let hidden;

const useTabChange = () => {
  const [isBackOnActiveTab, setIsBackOnActiveTab] = useState(false);

  const handleVisibilityChange = (input) => {
    let customState = input;
    if (customState !== true && customState !== false) {
      customState = !document[hidden];
    }
    setIsBackOnActiveTab(customState);
  };

  useEffect(() => {
    // Opera 12.10 and Firefox 18 and later support
    if (document.hidden !== undefined) {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (document.mozHidden !== undefined) {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
    } else if (document.msHidden !== undefined) {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (document.webkitHidden !== undefined) {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    } else if (document.oHidden !== undefined) {
      hidden = 'oHidden';
      visibilityChange = 'ovisibilitychange';
    }
    window.addEventListener('beforeunload', () => {
      handleVisibilityChange(false);
    }, false);

    document.addEventListener(visibilityChange, () => {
      handleVisibilityChange();
    }, false);
  }, []);

  return [isBackOnActiveTab];
};

export default useTabChange;
