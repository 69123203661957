/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

const KnIconWrapperComponent = ({ fontSize, ...rest }) => <div {...rest} />;

const KnIconWrapper = styled(KnIconWrapperComponent)(({ fontSize }) => ({
  fontSize,
}));

KnIconWrapperComponent.propTypes = {
  fontSize: PropTypes.number,
};

KnIconWrapperComponent.defaultProps = {
  fontSize: 24,
};

export default KnIconWrapper;
