/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import patientActions from 'redux/actions/patientActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnSectionHeader, KnBoldSectionHeader, KnSectionHeaderCapatalize } from 'components/Typography';
import KnTitrationsList from 'components/titrations/TitrationsList';
import KnErrorMessage from 'components/ErrorMessage';
import {
  APP_PAGE_URLS,
  REGIMEN_STATUS, TITRATIONS_CONTEXT,
  MedicationColors,
} from 'Constants';
import { KnElevatedBrightBox, KnElevatedColorBox } from 'styles/common';
import TitrationDateInfo from 'components/titrations/item-components/TitrationDateInfo';
import {
  ExpansionPanel, ExpansionPanelDetails,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import palette from 'styles/colors';
import { KnActionLink } from 'components/Link';
import KnEditIcon from 'components/icons/EditIcon';
import useDialog from 'components/dialog/DialogService';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import { getCompletedDosages, titrationMedsMigrate } from 'utils/utils';
import KnRegimenStatus from './RegimenStatus';
import KnThresholdDetails from './ThresholdDetails';
import GanttDayChart from './charts/GanttDayChart';
import KnPatientRecordBox, { KnTitrationDateText, KnMedicationPanelSummaryUpdate } from './styles';
import KnCollaspedTable from '../../components/titrations/CollaspedTable';
import KnRegimenToText from './RegimenToText';
import GanttCalendarChart from './charts/GanttCalendarChart';

const expandIconColor = palette.primary;
const { plainGrey, paleGrey, white } = palette;

const KnTitrationIndexBorderUpdate = styled('span')(({ color }) => ({
  width: '100%',
  height: 10,
  background: `${color}`,
  borderRadius: 5,
}));

const KnMedicationIndexBoxUpdateCh = styled(Box)({
  position: 'absolute',
  top: 15,
  left: 15,
  width: 40,
  textAlign: 'center',
});


const KnCollaspedTableViewUpdate = styled(Typography)({
  color: plainGrey,
  marginTop: -5,
  textTransform: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const KnEditActionBox = styled(Box)({
  marginLeft: 'auto',
  display: 'inherit',
  '& button:first-child': {
    marginRight: 32,
  },
});

/**
 * Component for displaying patient's medication regimen
 */
const KnMedicationRegimen = ({ patientId, showChart, patientType }) => {
  const { t: translate } = useTranslation();
  const dialog = useDialog();
  const { data: patientInfo } = useSelector((state) => state.patientRecord.patientInfo);
  const { isAdmin: admin } = useSelector((state) => state.user.currentUser);
  const {
    data: regimens,
    error: regimensError,
  } = useSelector((state) => state.patientRecord.regimens);
  const {
    data: thresholds,
    error: thresholdsError,
  } = useSelector((state) => state.patientRecord.thresholds);
  const dispatch = useDispatch();
  const history = useHistory();
  const [notes, setNotes] = useState(false);
  const [noteFlag, setNoteFlag] = useState(true);
  const [hcpNoteFlag, setHcpNoteFlag] = useState(true);

  useEffect(() => {
    if (!patientType) {
      dispatch(patientActions.fetchMedicationRegimenData(patientId));
      dispatch(patientActions.fetchThresholds(patientId));
    }
    if (patientType) {
      dispatch(patientActions.fetchMedicationRegimenDataInvitedPatient(patientId));
    }
  }, [dispatch, patientId, patientType]);

  const redoFetchPatientMedications = useCallback(() => {
    dispatch(patientActions.fetchMedicationRegimenData(patientId));
  }, [dispatch, patientId]);

  const redoFetchPatientThresholds = useCallback(() => {
    dispatch(patientActions.fetchThresholds(patientId));
  }, [dispatch, patientId]);

  const deactivateMedicationRegimen = useCallback(({ id: regimenId }) => {
    dialog({
      title: translate('PATIENT_RECORD.medicationRegimen.confirmDeactivateRegimenDialog.title'),
      description: translate('PATIENT_RECORD.medicationRegimen.confirmDeactivateRegimenDialog.content'),
      submitLabel: translate('GENERAL.okButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      if (patientType) {
        dispatch(patientActions.deactivateMedicationRegimenInvitedPatient(patientId, regimenId));
      } else dispatch(patientActions.deactivateMedicationRegimen(patientId, regimenId));
    });
  }, [dialog, dispatch, patientId, patientType, translate]);

  /** Currently assigned regimen (started or not) */
  const assignedRegimen = useMemo(() => {
    const currentRegimens = _.filter(
      regimens,
      (regimen) => (
        (regimen.status !== REGIMEN_STATUS.deactivated)
        && (regimen.status !== REGIMEN_STATUS.completed)
        && (regimen.status !== REGIMEN_STATUS.updated)
      ),
    );
    if (_.first(currentRegimens)) {
      const { note } = _.first(currentRegimens);
      setNotes(!!note);
    }
    const titration = _.first(currentRegimens);
    if (titration) {
      titration.medications = titrationMedsMigrate(titration.medications);
    }
    return titration;
  }, [regimens]);

  const editMedicationRegimen = useCallback(({
    medications, id: regimenId, assignedAt, startedAt, parentTitrationId, note, hcpNote, status,
  }) => {
    const parentTitration = regimens.find((r) => r.id === parentTitrationId);
    const { medics, complete } = getCompletedDosages(medications, parentTitration, startedAt);
    history.push(
      APP_PAGE_URLS.editAssignedRegimen.replace(':patientId', patientId).replace(':regimenId', regimenId),
      {
        patientName: _.get(patientInfo, 'firstName'),
        medications: medics,
        assignedAt,
        from: complete,
        edit: true,
        note,
        patientType,
        hcpNote,
        status,
      },
    );
  }, [history, patientId, patientInfo, patientType, regimens]);

  const pastRegimens = useMemo(() => _.filter(
    regimens,
    (regimen) => (
      regimen.status === REGIMEN_STATUS.deactivated
      || regimen.status === REGIMEN_STATUS.completed
      || regimen.status === REGIMEN_STATUS.updated
    ),
  ), [regimens]);

  const editThresholds = useCallback(() => {
    history.push(
      APP_PAGE_URLS.threshold.replace(':patientId', patientId),
      {
        activeRegimen: assignedRegimen,
        patientName: _.get(patientInfo, 'firstName'),
        thresholds,
      },
    );
  }, [history, patientId, patientInfo, assignedRegimen, thresholds]);

  const totalDuration = (med) => {
    let duration = 0;
    med.dosages.map((e) => {
      duration += e.duration;
      return e;
    });
    if (duration > 1) return ` ${duration} ${translate('FIELD_LABELS.days')}`;
    return ` ${duration} ${translate('FIELD_LABELS.day')}`;
  };

  const KnMedicationIndexBoxUpdateChange = (medication, index, color, prefix = '', note = '') => (
    <>
      <KnMedicationIndexBoxUpdateCh display="flex" flexDirection="column" color={color}>
        <Typography variant="h5" component={KnBoldSectionHeader}>
          {index}
        </Typography>
        <KnTitrationIndexBorderUpdate color={color} />
      </KnMedicationIndexBoxUpdateCh>
      <Box display="flex" flexDirection="column" pt="5px" pl={8} pr={6}>
        <Typography variant="h6" component={KnBoldSectionHeader}>
          {medication}
          <span style={{ fontWeight: 500 }}>{prefix}</span>
          <KnCollaspedTableViewUpdate>{note}</KnCollaspedTableViewUpdate>
        </Typography>
      </Box>
    </>
  );

  const showNotes = useCallback((note) => <>{note}</>, []);

  const showInteractiveCalendarPanel = (showChart
    && assignedRegimen && assignedRegimen.status
    && assignedRegimen.status === REGIMEN_STATUS.notStarted);

  const { hcpNote } = (assignedRegimen) || {};

  return (
    <>
      <>
        {regimensError && (
          <KnErrorMessage
            error={regimensError}
            messageKey="PATIENT_RECORD.ERROR_MESSAGES.medicationsListError"
            onRetry={redoFetchPatientMedications}
            centered={false}
            pb={3}
            data-testid="regimens-fetch-error"
          />
        )}
        {assignedRegimen && (
          <KnPatientRecordBox mb={3}>
            { /**  Medication Regimen */}
            <Box mb={3} display="flex">
              <Typography variant="h6" component={KnSectionHeader}>
                {translate('ASSIGN_REGIMEN.medicationRegimen')}
              </Typography>
              <Box ml={3} mr={2} mt={1}>
                <TitrationDateInfo
                  titration={assignedRegimen}
                  context={TITRATIONS_CONTEXT.assignedRegimen}
                  component={KnTitrationDateText}
                />
              </Box>
              <Box mt={0.5}>
                <KnRegimenStatus
                  status={assignedRegimen.status}
                  reachedGoal={assignedRegimen.goalDoseReached}
                  startedAt={assignedRegimen.startedAt}
                />
              </Box>
              <KnEditActionBox mt={0.5}>
                <KnActionLink
                  data-testid="edit-titration-button"
                  disabled={admin}
                  LhsIcon={KnEditIcon}
                  onClick={() => editMedicationRegimen(assignedRegimen)}
                  tooltip
                  tooltipText={translate('ICON_TOOLTIP.edit')}
                />
                <KnActionLink
                  data-testid="delete-titration-button"
                  LhsIcon={KnDeleteIcon}
                  tooltip
                  tooltipText={translate('ICON_TOOLTIP.delete')}
                  disabled={admin || assignedRegimen.status !== REGIMEN_STATUS.notStarted}
                  onClick={() => deactivateMedicationRegimen(assignedRegimen)}
                />
              </KnEditActionBox>
            </Box>
            <KnElevatedBrightBox mb={2}>
              {showInteractiveCalendarPanel && (
                <ExpansionPanel defaultExpanded>
                  <KnMedicationPanelSummaryUpdate
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
                  >
                    <Box ml={4} pb={2} pt={2}>
                      <Typography variant="h6" component={KnSectionHeaderCapatalize}>
                        {translate('ASSIGN_REGIMEN.interativeCalender')}
                      </Typography>
                    </Box>
                  </KnMedicationPanelSummaryUpdate>
                  <ExpansionPanelDetails>
                    {!_.size(assignedRegimen.parentTitrations)
                      ? (
                        <GanttDayChart
                          key="gantt-chart-day"
                          chartId="gantt-chart-day"
                          medications={assignedRegimen.medications}
                          debounceUpdate={false}
                        />
                      ) : (
                        <GanttCalendarChart
                          key="gantt-chart-calender"
                          chartId="gantt-chart-calender"
                          titration={assignedRegimen}
                          pastRegimens={pastRegimens}
                        />
                      )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
              {
                assignedRegimen.medications.map((medication, index) => (
                  <ExpansionPanel
                    defaultExpanded={false}
                    key={medication.id}
                  >
                    <KnMedicationPanelSummaryUpdate
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                      expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
                    >
                      {KnMedicationIndexBoxUpdateChange(`${medication.activeIngredient} (${medication.name})`, index + 1, MedicationColors[index], '', `${translate('ASSIGN_REGIMEN.totalDuration')} : ${totalDuration(medication)}`)}
                    </KnMedicationPanelSummaryUpdate>
                    <ExpansionPanelDetails>
                      <Box>
                        <Box pt={2}>
                          <KnCollaspedTable medication={medication} />
                        </Box>
                      </Box>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }
              {notes && (
                <ExpansionPanel
                  defaultExpanded={false}
                  onChange={(event, expanded) => setNoteFlag(!expanded)}
                >
                  <KnMedicationPanelSummaryUpdate
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
                  >
                    {KnMedicationIndexBoxUpdateChange(translate('TITRATIONS.notesToPatient'), 'N', paleGrey.paleGrey5, '', noteFlag ? assignedRegimen.note : '')}
                  </KnMedicationPanelSummaryUpdate>
                  <ExpansionPanelDetails>
                    <Box>
                      <Box pl={8} whiteSpace="pre-wrap">
                        {showNotes(assignedRegimen.note)}
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
              {hcpNote && (
                <ExpansionPanel
                  defaultExpanded={false}
                  onChange={(event, expanded) => setHcpNoteFlag(!expanded)}
                >
                  <KnMedicationPanelSummaryUpdate
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
                  >
                    {KnMedicationIndexBoxUpdateChange(translate('TITRATIONS.notesToDoctor'), 'N', paleGrey.paleGrey5, translate('TITRATIONS.doctorUse'), hcpNoteFlag ? assignedRegimen.hcpNote : '')}
                  </KnMedicationPanelSummaryUpdate>
                  <ExpansionPanelDetails>
                    <Box>
                      <Box pl={8} whiteSpace="pre-wrap">
                        {showNotes(assignedRegimen.hcpNote)}
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </KnElevatedBrightBox>
            <KnRegimenToText />
          </KnPatientRecordBox>
        )}
      </>


      { /** PAST MEDICATIONS */
        pastRegimens.length > 0 && (
          <KnPatientRecordBox mb={3} data-testid="medication-regimen-wrapper">
            <div data-testid="past-regimens-wrapper">
              <Box mb={2}>
                <Typography variant="h6" component={KnSectionHeader}>
                  {patientInfo && translate('PATIENT_RECORD.medicationRegimen.pastTitle')}
                </Typography>
              </Box>
              <KnTitrationsList
                context={TITRATIONS_CONTEXT.pastRegimen}
                titrationsList={pastRegimens}
                onRetry={redoFetchPatientMedications}
              />
            </div>

          </KnPatientRecordBox>
        )
      }
      {
        (!_.isEmpty(thresholds) || regimensError || thresholdsError) && (
          <KnPatientRecordBox>
            <KnElevatedColorBox
              pt={3}
              pb={3}
              pl={3}
              pr={4}
              borderColor={white.white1}
            >
              <KnThresholdDetails
                thresholds={thresholds}
                error={thresholdsError}
                onRetry={redoFetchPatientThresholds}
                onEditClick={editThresholds}
                SetThresholdButton={null}
              />
            </KnElevatedColorBox>
          </KnPatientRecordBox>
        )
      }
    </>
  );
};


KnMedicationRegimen.defaultProps = {
  showChart: true,
  patientType: false,
};

KnMedicationRegimen.propTypes = {
  patientId: PropTypes.string.isRequired,
  showChart: PropTypes.bool,
  patientType: PropTypes.bool,
};

export default KnMedicationRegimen;
