import {
  Collapse,
  Divider,
  IconButton, List, ListItemText,
  Typography,
} from '@material-ui/core';
import KnCollapseIcon from 'components/icons/KnCollapseIcon';
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CheckIcon from 'components/icons/CheckIcon';
import ProIcon from 'components/icons/ProIcon';
import logo from 'assets/images/nileLogo84x48.png';
import EmailIcon from 'components/icons/EmailIcon';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'redux/actions/userActions';
import PhoneIcon from 'components/icons/PhoneIcon';
import { compact, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import palette from 'styles/colors';
import {
  BoxListTitle, BoxTitle, KnListItemIcon, StyledListItem,
  KnContainerBox,
  BoxSubTitle,
  KnStyledBox,
  ProBadge,
  StyledCard,
  KnCardContent,
  NoteText,
  ContactBox,
} from './SubscriptionBanner.styles';

const { black1 } = palette.black;
const { primary } = palette;

const SubscriptionBanner = () => {
  const {
    currentUser,
  } = useSelector((state) => state.user);
  const [initialized, setInitialized] = useState(false);
  const lastSeen = get(currentUser, 'userPreferences.subscriptionBannerSeen');
  const shouldBeOpen = !lastSeen || (new Date() - lastSeen > 2629800000);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const toggleOpen = useCallback(() => {
    const rootElem = document.getElementById('root');
    if (!isOpen) {
      setIsOpen(true);
      rootElem.scrollTop = 0;
      rootElem.style.overflow = 'hidden';
      dispatch(userActions.userPrefernce({
        userPreferences: [{
          key: 'subscriptionBannerSeen',
          value: +new Date(),
        }],
      }));
    } else {
      setIsOpen(false);
      rootElem.style.overflow = 'auto';
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (shouldBeOpen && currentUser && currentUser.initialized && !initialized) {
      setInitialized(true);
      toggleOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const getList = (listDetails) => (
    <>
      <BoxListTitle>{listDetails.listTitle}</BoxListTitle>
      <List>
        {listDetails.list.map((label) => (
          <StyledListItem>
            <KnListItemIcon>
              <CheckIcon fill={primary} width="12px" />
            </KnListItemIcon>
            <ListItemText primary={label} />
          </StyledListItem>
        ))}
      </List>
    </>
  );

  const getHeader = (header) => (
    <>
      <BoxTitle variant="h3"><b>{header.title}</b></BoxTitle>
      <BoxSubTitle>{header.subTitle}</BoxSubTitle>
      <Divider style={{ marginBottom: '10px' }} />
    </>
  );

  return (
    <KnContainerBox open={isOpen}>
      <KnStyledBox>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ cursor: 'pointer' }}
          alignItems="center"
          padding="8px 16px"
          onClick={toggleOpen}
        >
          <small>{translate('PRO_FEATURE.smallTitle')}</small>
          <Box alignItems="center" display="flex">
            <ProBadge>
              <ProIcon />
              {translate('PRO_FEATURE.pro')}
            </ProBadge>
            <IconButton
              style={{ padding: 10, transform: !isOpen ? 'rotate(180deg)' : '', transition: '300ms' }}
            >
              <KnCollapseIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={isOpen}>
          <Divider />
          <Box display="flex">
            <Box display="flex" pt={2} pb={2} width={714} margin="0 auto" flexDirection="column">
              <Box>
                <Typography color={black1}>
                  <b>
                    {translate('PRO_FEATURE.freeVersion')}
                  </b>
                  {translate('PRO_FEATURE.upgradeText')}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" mt={1.5}>
                {['BASIC_ACCESS', 'FULL_ACCESS'].map((type) => (
                  <>
                    <StyledCard variant="outlined" style={{ marginRight: 16 }}>
                      <KnCardContent>
                        {getHeader({
                          title: translate(`PRO_FEATURE.${type}.title`),
                          subTitle: translate(`PRO_FEATURE.${type}.subTitle`),
                        })}
                        {getList({
                          listTitle: translate('PRO_FEATURE.BASIC_ACCESS.listTitle'),
                          list: compact([translate(`PRO_FEATURE.${type}.list.0`), translate(`PRO_FEATURE.${type}.list.1`), type === 'FULL_ACCESS' ? null : translate(`PRO_FEATURE.${type}.list.2`)]),
                        })}

                        {type === 'FULL_ACCESS' && (
                          <>
                            <br />
                            {getList({
                              listTitle: translate('PRO_FEATURE.FULL_ACCESS.listTitle'),
                              list: [translate('PRO_FEATURE.FULL_ACCESS.list.2'), translate('PRO_FEATURE.FULL_ACCESS.list.3'), translate('PRO_FEATURE.FULL_ACCESS.list.4'), translate('PRO_FEATURE.FULL_ACCESS.list.5')],
                            })}
                            <ContactBox>
                              <NoteText>{translate(`PRO_FEATURE.${type}.note`)}</NoteText>
                              <Box display="flex" flexDirection="row" alignItems="baseline">
                                <Box display="flex" flexDirection="column" mr={2}>
                                  <b>
                                    <EmailIcon />
                                    <span style={{ marginLeft: 10 }}>Email us</span>
                                  </b>
                                  <a href="mailto:sales@nile.ai" style={{ color: black1 }}>sales@nile.ai</a>
                                </Box>
                                <Box display="flex" flexDirection="column" ml={2}>
                                  <b>
                                    <PhoneIcon />
                                    <span style={{ marginLeft: 10 }}>Call us</span>
                                  </b>
                                  <a href="tel:+1 (818) 835-1733" style={{ color: black1 }}>+1 (818) 835-1733</a>
                                </Box>
                              </Box>
                            </ContactBox>
                          </>
                        )}
                      </KnCardContent>
                    </StyledCard>
                  </>
                ))}
              </Box>
            </Box>
          </Box>

          <Divider />
          <Box padding="32px" pt={1.2} pb={1.2} display="flex" justifyContent="space-between">
            <img src={logo} width="74" height="45" alt="" />
          </Box>
        </Collapse>
      </KnStyledBox>
    </KnContainerBox>
  );
};

export default SubscriptionBanner;
