/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';

const disabledTextColor = palette.paleGrey.paleGrey1;
const disabledBackgroundColor = palette.coolGrey.coolGrey1;
const primaryTextButtonBackgroundColor = '#E4E5E9';

export default {
  MuiButton: {
    root: {
      borderRadius: 0,
    },

    contained: {
      '&.Mui-disabled': {
        color: disabledTextColor,
        backgroundColor: disabledBackgroundColor,
      },
    },

    sizeLarge: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 27,
      paddingRight: 27,
    },

    label: {
      lineHeight: 1,
    },

    textPrimary: {
      '&:hover': {
        backgroundColor: primaryTextButtonBackgroundColor,
      },
    },
  },

  MuiIconButton: {
    root: {
      padding: 4,
    },
  },
};
