/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KnButton from 'components/Button';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS } from 'Constants';
import { composeFullName } from 'utils/utils';
import palette from 'styles/colors';
import profileLogo from '../assets/images/profile-01.png';

const menuListPositioningProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  getContentAnchorEl: null,
};

const KnUsername = styled('h6')(({ theme }) => ({
  fontWeight: 'normal',
  paddingRight: theme.spacing(2),
}));

const KnMenu = withStyles({
  paper: {
    marginTop: 2,
    width: 280,
  },
})((props) => <Menu {...menuListPositioningProps} {...props} />);

const KnMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 16,
    color: fade(palette.black.black2, 0.87),
    '&:hover': {
      backgroundColor: fade(palette.black.black2, 0.08),
    },
  },
  gutters: {
    padding: theme.spacing(1.5, 2),
  },
}))(MenuItem);

const KnButtonChange = styled(KnButton)({
  '&.MuiButton-sizeLarge': {
    paddingTop: '22px',
    paddingLeft: '27px',
    paddingRight: '27px',
    paddingBottom: '0px',
  },
});

const KnAccountMenu = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElement, setAnchorElement] = useState(null);
  const currentUserStore = useSelector((state) => state.user.currentUser);

  const handleClick = useCallback(({ currentTarget }) => {
    setAnchorElement(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const onLogout = useCallback(() => {
    handleClose();
    dispatch(loginActions.logout());
  }, [dispatch, handleClose]);

  const onAccountSettings = useCallback(() => {
    handleClose();
    history.push(APP_PAGE_URLS.settings);
  }, [handleClose, history]);

  const onFAQ = useCallback(() => {
    handleClose();
    history.push(APP_PAGE_URLS.faq);
  }, [handleClose, history]);

  return (
    <>
      <KnButtonChange variant="text" onMouseEnter={handleClick} data-testid="account-menu-button">
        {currentUserStore.initialized && !currentUserStore.error && (
          <Typography
            data-testid="account-menu-username"
            variant="h6"
            color="textPrimary"
            component={KnUsername}
          >
            {composeFullName(currentUserStore.firstName, currentUserStore.lastName)}
          </Typography>
        )}
        <img src={profileLogo} width="25" height="24" alt={translate('GENERAL.projectName')} style={{ marginBottom: '9px' }} />
      </KnButtonChange>
      <KnMenu
        anchorEl={anchorElement}
        keepMounted
        open={!!anchorElement}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
      >
        <KnMenuItem
          onClick={onAccountSettings}
          data-testid="account-settings-option"
          disabled={currentUserStore.error}
        >
          {translate('ACCOUNT_MENU.account')}
        </KnMenuItem>
        <KnMenuItem
          onClick={onFAQ}
          data-testid="account-faq-option"
        >
          {translate('ACCOUNT_MENU.faq')}
        </KnMenuItem>
        <KnMenuItem
          onClick={onLogout}
          data-testid="account-logout-option"
        >
          {translate('ACCOUNT_MENU.logout')}
        </KnMenuItem>
      </KnMenu>
    </>
  );
};

export default KnAccountMenu;
