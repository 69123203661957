/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import enTranslations from './en.json';
import frTranslations from './fr.json';

const language = 'en';
const fallbackLanguage = 'en';

/**
 * Parses and returns the available date format arguments.
 *
 * @param {string} formatValue A format string value either the formatting
 * value or a stringified array to be parsed.
 */
const getDateFormatArguments = (formatValue) => {
  try {
    const [value, timezone] = JSON.parse(formatValue);
    return { value, timezone };
  } catch (e) {
    return { value: formatValue };
  }
};

/**
 * Translation formatting interpolation callback; useful for dates or number
 * formattings.
 *
 * @param {any} value Tha value interpolated in a translation string
 * @param {string} formatValue A format to apply on the value
 */
const formatHandler = (value, formatValue) => {
  if (value instanceof Date) {
    /** Defensive; in case the date format is wrong, this will prevent the app from crashing. */
    try {
      const formatArgs = getDateFormatArguments(formatValue);
      if (formatArgs.timezone) {
        return formatTz(
          utcToZonedTime(value.toISOString(), formatArgs.timezone),
          formatArgs.value,
          { timezone: formatArgs.timezone },
        );
      }
      return format(value, formatArgs.value);
    } catch (e) {
      return '';
    }
  }
  return value;
};

const initI18n = () => {
  i18n.use(initReactI18next).init({
    lng: language,
    fallbackLng: fallbackLanguage,
    /** we init with resources */
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
    },

    nsSeparator: false,
    keySeparator: '.',

    interpolation: {
      escapeValue: false,
      format: formatHandler,
    },
  });

  return i18n;
};

export const translate = (key, values = {}) => i18n.t(key, values);

export default initI18n;
