/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './reducers';

/** List of Redux middleware to enhance the store with */
const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

/** Add the redux dev tools on top of the middlewares
 * (the store and actions will be visibile in development only).
 */
const devToolsEnhancer = composeWithDevTools(middlewareEnhancer);

/** Create the app store with the root reducer and the store enhancers. */
const store = createStore(rootReducer, devToolsEnhancer);

export default store;
