/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';

const KnEyeIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M20.3378 7.47893C20.3378 8.00902 19.9081 8.43893 19.3778 8.43893H4.59379C4.06342 8.43893 3.63379 8.00902 3.63379 7.47893V6.5828C3.63379 6.05271 4.06342 5.6228 4.59379 5.6228H19.3778C19.9081 5.6228 20.3378 6.05271 20.3378 6.5828V7.47893Z" />
    <path d="M17.4577 12.463C17.4577 12.9934 17.0281 13.423 16.4977 13.423H7.47376C6.94339 13.423 6.51376 12.9934 6.51376 12.463V11.5672C6.51376 11.0368 6.94339 10.6072 7.47376 10.6072H16.4977C17.0281 10.6072 17.4577 11.0368 17.4577 11.5672V12.463Z" />
    <path d="M14.5775 17.4467C14.5775 17.9768 14.1479 18.4067 13.6175 18.4067H10.3536C9.82319 18.4067 9.39355 17.9768 9.39355 17.4467V16.5506C9.39355 16.0205 9.82319 15.5906 10.3536 15.5906H13.6175C14.1479 15.5906 14.5775 16.0205 14.5775 16.5506V17.4467Z" />
  </svg>
);

KnEyeIcon.defaultProps = {
  color: '#666',
};

KnEyeIcon.propTypes = {
  color: PropTypes.string,
};

export default KnEyeIcon;
