/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import faqActions from 'redux/actions/faqActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KnPageContent, { KnBrightBox } from 'components/Content';
import { KnBoldSectionHeader } from 'components/Typography';
import { Collapse, IconButton } from '@material-ui/core';
import KnCollapseIcon from 'components/icons/KnCollapseIcon';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const KnFAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <KnBrightBox mb={2} px={4} py={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" color="primary">
          {question}
        </Typography>
        <IconButton onClick={toggleOpen} style={{ padding: 10, transform: !isOpen ? 'rotate(180deg)' : '', transition: '300ms' }}>
          <KnCollapseIcon />
        </IconButton>
      </Box>
      <Collapse in={isOpen}>
        <Box pt={2}>
          <Typography color="textSecondary">
            {documentToReactComponents(answer)}
          </Typography>
        </Box>
      </Collapse>
    </KnBrightBox>
  );
};

KnFAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.shape().isRequired,
};

const FAQPage = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    error,
    data,
  } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(faqActions.fetchFAQ());
  }, [dispatch]);

  return (
    <KnPageContent>
      <Box alignSelf="center" style={{ maxWidth: 1000 }}>
        <Typography align="center" variant="h4" component={KnBoldSectionHeader}>
          {translate('FAQ.title')}
        </Typography>
        <Box display="flex" flexDirection="column" flex="1" p={2}>
          {error && <p data-testid="faq-error-message">{translate('FAQ.ERROR_MESSAGES.dataLoadingError')}</p>}
          {!error
                && data.map((item) => <KnFAQItem question={item.question} answer={item.answer} />)}
        </Box>
      </Box>
    </KnPageContent>
  );
};

export default FAQPage;
