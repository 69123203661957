/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import palette from '../colors';

const disabledLinkColor = palette.coolGrey.coolGrey1;

export default {
  MuiLink: {
    button: {
      marginLeft: 5,

      '&:disabled': {
        color: disabledLinkColor,
      },
    },
  },
};
