import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import KnButton from 'components/Button';
import { eventBgColors } from 'Constants';
import theme from 'styles/theme';
import { KnOutlineTextField } from 'components/TextField';
import palette from '../../../styles/colors';
import { SLOT_STATUS } from './CalendarConstant';

const { blue3 } = palette.blue;
const { black1 } = palette.black;
const { paleGrey10 } = palette.paleGrey;
const { dark1 } = palette.green;

const ThisWeekBtn = styled(KnButton)({
  width: 85,
  height: 28,
  fontSize: 12,
  fontWeight: 400,
  paddingLeft: 6,
  paddingRight: 6,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
});

export const CalendarTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: 17,
  color: palette.black.black2,
});

export const CalendarBox = styled(Box)({
  padding: 24,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  position: 'relative',
});

export const EventTitle = styled('span')({
  borderLeft: '3px solid',
  height: '100%',
  fontSize: 12,
  cursor: 'pointer',
  paddingLeft: 8,
  display: 'flex',
  flexDirection: 'column',
});

export const EventIcon = styled(EventTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const KnPhysicianTextField = styled(KnOutlineTextField)({
  width: 226,
  paddingBottom: 0,
});

export const TimeZone = styled('b')({
  fontSize: 10,
  position: 'absolute',
  width: 35,
  top: 48,
  overflowWrap: 'break-word',
  left: 35,
  color: palette.paleGrey.paleGrey9,
});


export const EventText = styled(Typography)(({ isClickAble }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: black1,
  '&:hover': {
    textDecoration: isClickAble ? 'underline' : 'none',
    cursor: isClickAble ? 'pointer' : '',
  },
}));


export const EventLegend = styled(Typography)(({ status }) => ({
  fontWeight: 700,
  fontSize: 16,
  width: 83,
  height: 44,
  color: status === SLOT_STATUS.RESERVED ? blue3 : dark1,
  background: status === SLOT_STATUS.RESERVED ? eventBgColors.reserved : eventBgColors.booked,
  padding: '10px 8px',
  textAlign: 'center',
}));


export const EventBox = styled(Box)(({ status }) => ({
  minHeight: 722,
  minWidth: 365,
  background: 'white',
  borderLeft: `2px solid ${status === SLOT_STATUS.BOOKED ? dark1 : blue3}`,
  borderRadius: 5,
  padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
}));

export const ReportLink = styled(Typography)({
  cursor: 'pointer',
});

export const BoxBottomBorder = styled(Box)({
  borderBottom: `1px solid ${paleGrey10}`,
  position: 'relative',
});

export const BoxErrox = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 24,
  color: palette.error.main,
});

export default ThisWeekBtn;
