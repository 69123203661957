/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnArrowDownIcon = ({ color, ...rest }) => (
  <SvgIcon {...rest}>
    <path fill={color} fillOpacity=".54" d="M7 10l5 5 5-5H7z" />
  </SvgIcon>
);

KnArrowDownIcon.defaultProps = {
  color: '#666',
};

KnArrowDownIcon.propTypes = {
  color: PropTypes.string,
};

export default KnArrowDownIcon;
