/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  subDays, isWithinInterval, startOfDay, endOfDay,
} from 'date-fns';
import Box from '@material-ui/core/Box';
import {
  KnStateDeterioratedIcon,
  KnStateImprovedIcon,
  KnStateStableIcon,
} from 'components/icons/PatientStateIcon';
import { METRICS_TYPE, METRICS_TREND_PERIOD } from 'Constants';
import { KnMetricsLabel, KnMetricsExtraSmallLabel } from './styles';

/**
 * Translation key variant for trend indication related text.
 * Will be used in association with other keys to translate
 * messages based on the trend type.
 *
 * @param {bool} isPositive Whether the trend is positive.
 * @param {bool} isNegative Whether the trend is negative.
 */
const getIndicatorTranslationKey = (isPositive, isNegative) => {
  if (isPositive) {
    return 'POSITIVE';
  }
  if (isNegative) {
    return 'NEGATIVE';
  }
  return 'STABLE';
};

/**
 * The trend change explanation text and instances count.
 */
const KnMetricsSummaryTexts = (props) => {
  const { count, translationTextKeyVariant, translationTrendKeyVariant } = props;
  const { t: translate } = useTranslation();

  const subtitle = count
    ? translate(`PATIENT_RECORD.${translationTextKeyVariant}.subtitle`, { count })
    : translate('PATIENT_RECORD.metricsEmptyData');
  const trendExplanationKey = `PATIENT_RECORD.${translationTextKeyVariant}.trendExplanation.${translationTrendKeyVariant}`;

  return (
    <Box pl={1}>
      <KnMetricsLabel>
        {subtitle}
      </KnMetricsLabel>
      {count > 0
      && (
        <KnMetricsExtraSmallLabel>
          {translate(trendExplanationKey)}
        </KnMetricsExtraSmallLabel>
      )}
    </Box>
  );
};

/**
 * Computes the trend of the metrics (improved, deteriorated or stable) based on the raw data
 * it receives and displays the trend visual indicator and the specific metrics summary.
 * Trend is computed by comparing the
 *  metrics of the last METRICS_TREND_PERIOD.days (ending with timeRangeEnd), with the
 *  matrics of the previous METRICS_TREND_PERIOD.days
 */
const KnMetricsSummary = ({ rawData, type, timeRangeEnd }) => {
  /** Let's round up timeRange's end date to the last second,
   * so that the METRICS_TREND_PERIOD ranges (eg 30 day range) would cover full days
   */
  const currentRangeEnd = useMemo(() => endOfDay(timeRangeEnd), [timeRangeEnd]);
  const currentRangeStart = useMemo(
    () => startOfDay(subDays(timeRangeEnd, (METRICS_TREND_PERIOD.days - 1))),
    [timeRangeEnd],
  );
  const previousRangeEnd = useMemo(
    () => subDays(currentRangeEnd, METRICS_TREND_PERIOD.days),
    [currentRangeEnd],
  );
  const previousRangeStart = useMemo(
    () => subDays(currentRangeStart, METRICS_TREND_PERIOD.days),
    [currentRangeStart],
  );

  const currentRangeMetrics = useMemo(() => (
    _.filter(rawData, (metric) => (
      isWithinInterval(metric.date, { start: currentRangeStart, end: currentRangeEnd })
    ))), [currentRangeStart, rawData, currentRangeEnd]);
  const previousRangeMetrics = useMemo(() => (
    _.filter(rawData, (metric) => (
      isWithinInterval(
        metric.date,
        { start: previousRangeStart, end: previousRangeEnd },
      )
    ))), [previousRangeStart, rawData, previousRangeEnd]);
  const isSeizureMetrics = (type === METRICS_TYPE.seizure);
  const isNegativeTrend = (currentRangeMetrics.length > previousRangeMetrics.length);
  const isPositiveTrend = (currentRangeMetrics.length < previousRangeMetrics.length);
  const isStableTrend = !isNegativeTrend && !isPositiveTrend;
  const translationTrendKeyVariant = getIndicatorTranslationKey(isPositiveTrend, isNegativeTrend);
  const translationTextKeyVariant = isSeizureMetrics ? 'seizureMetrics' : 'symptomMetrics';
  return (
    <Box display="flex" pt={1}>
      {isNegativeTrend && <KnStateDeterioratedIcon />}
      {isPositiveTrend && <KnStateImprovedIcon />}
      {isStableTrend && <KnStateStableIcon />}

      <KnMetricsSummaryTexts
        translationTextKeyVariant={translationTextKeyVariant}
        translationTrendKeyVariant={translationTrendKeyVariant}
        count={rawData.length}
      />
    </Box>
  );
};

KnMetricsSummary.propTypes = {
  rawData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.string.isRequired,
  timeRangeEnd: PropTypes.instanceOf(Date).isRequired,
};

KnMetricsSummaryTexts.propTypes = {
  count: PropTypes.number.isRequired,
  translationTrendKeyVariant: PropTypes.string.isRequired,
  translationTextKeyVariant: PropTypes.string.isRequired,
};

export default KnMetricsSummary;
