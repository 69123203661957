/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import KnIconWrapper from './styles';

const DiamondPart = ({ id }) => (
  <defs>
    <filter id={id} filterUnits="userSpaceOnUse">
      <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
    </filter>
  </defs>
);

DiamondPart.propTypes = {
  id: PropTypes.string,
};

DiamondPart.defaultProps = {
  id: '',
};

const KnDiamondIcon = ({ outline, rotate, color }) => (
  <KnIconWrapper fontSize={16}>
    <SvgIcon
      fontSize="inherit"
      viewBox="0 0 28 28"
      x="0px"
      y="0px"
      style={{ enableBackground: 'new 0 0 28 28', transform: rotate ? 'rotate(45deg)' : 'rotate(0deg)' }}
    >
      <DiamondPart id="Adobe_OpacityMaskFilter" />
      <mask maskUnits="userSpaceOnUse" id="mask0_1_">
        <rect x="60.3" y="70.9" fill="#666667" filter="url(#Adobe_OpacityMaskFilter)" width="28.7" height="28.7" />
      </mask>
      <g mask="url(#mask0_1_)" />
      <DiamondPart id="Adobe_OpacityMaskFilter_1_" />
      <mask maskUnits="userSpaceOnUse" id="mask0_2_">
        <rect x="46.1" y="42.3" fill="#666666" filter="url(#Adobe_OpacityMaskFilter_1_)" width="28.7" height="28.7" />
      </mask>
      <g mask="url(#mask0_2_)" />
      <DiamondPart id="Adobe_OpacityMaskFilter_2_" />
      <mask maskUnits="userSpaceOnUse" id="mask0_3_">
        <rect x="42.2" y="38.4" fill="#666666" filter="url(#Adobe_OpacityMaskFilter_2_)" width="36.5" height="36.5" />
      </mask>
      <g mask="url(#mask0_3_)" />
      <g>
        {outline ? (
          <path fill={color || '#00BF4C'} d="M14,27L1,14L14,1l13,13L14,27z M6.5,14l7.5,7.5l7.5-7.5L14,6.5L6.5,14z" />
        ) : (
          <path fill={color || '#00BF4C'} d="M14,27L1,14L14,1l13,13L14,27z" />
        )}
      </g>
    </SvgIcon>
  </KnIconWrapper>
);

KnDiamondIcon.propTypes = {
  outline: PropTypes.bool,
  rotate: PropTypes.bool,
  color: PropTypes.string,
};

KnDiamondIcon.defaultProps = {
  outline: false,
  rotate: false,
  color: null,
};

export default KnDiamondIcon;
