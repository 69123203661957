/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';
import { color } from '@amcharts/amcharts4/core';

export const EMAIL_FORMAT = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
export const DOMAIN_FORMAT = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

/** Password should be minimumn 8 characters long and contain
 * at least one uppercase letter, one number and one special character.
 * Cognito allows any password which contains the following alphanumeric and special characters:
 * A-Za-z0-9^$*.[]{}()?"!@#%&/\,><':;|_~`+=
 */
export const PASSWORD_FORMAT = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`+=]{8,}$/;

/** Regex for name validation */
export const NAME_FORMAT = /^[A-Za-z\u00C0-\u00FF]+([ A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-]+)*$/;

/** By default, we consider phone numbers to be from US */
export const DEFAULT_COUNTRY_CODE = 'US';

/** Regex for titration dosage validation */
/** Also used for tablet stength validation (together with a not 0 check) */
export const TITRATION_DOSAGE_FORMAT = /^[1-9]([.][0-9]{1,2})?$|^[1-9][0-9]{1,2}([.][0-9]{1,2})?$|^[1-9][0-9]{1,3}([.][0-9]{1,2})?$|^10000([.][0]{1,2})?$|^0[.][1-9]{1}?$|^0[.][0-9]{1}[1-9]{1}?$/;

export const TITRATION_STRENGTH_FORMAT = /^[1-9]([.][0-9]{1,2})?$|^[1-9][0-9]{1,2}([.][0-9]{1,2})?$|^[1-9][0-9]{1,3}([.][0-9]{1,2})?$|^10000([.][0]{1,2})?$|^0[.][1-9]{1}?$|^0[.][0-9]{1}[1-9]{1}?$/;

export const TITRATION_OFFSET_FORMAT = /^[^+-]*^(\s*|[1-9][0-9]?|[12][0-9][0-9]|3[0-5][0-9]|36[0-5])$/;

export const TITRATION_DURATION_FORMAT = /^[^+-]*^(\s*|[1-9][0-9]{0,2}|[12]\d\d\d|3[0-5]\d\d|36[0-4]\d|3650)$/;

/** Regex for positive integer numbers (eg. used for threshold seizures inputs) */
export const INTEGER_POSITIVE_VALUE_FORMAT = /^[1-9]\d*$/;

/** Regex for titration preset name validation. */
export const TITRATION_NAME_FORMAT = /^[A-Za-z0-9\u00C0-\u00FF'\-#&\s]+$/;

export const MATCH_TIME_STRING = /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/;

export const MATCH_DATE_STRING = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

/**
 * API URLS paths relative to the base API URL.
 */
export const API_URLS = {
  /** Public endpoints */
  /** Endpoint for getting BE API version number */
  health: 'health',
  /** Endpoint for getting health systems list */
  healthSystem: 'hcp/healthsystem',
  /** Endpoint for getting user role options */
  userRole: 'hcp/hcp-roles',

  /** Authenticated endpoints */

  /** Endpoint for
   * - registering a new HCP user account (POST)
   * - getting current user info (GET)
   * - updating user profile (PATCH) */
  users: 'users',
  ssoUser: 'users/sso',
  /** Endpoint for getting list of HCP users with physician role */
  physicians: 'users/primary',
  preference: 'users/user-preference',

  /** GET Patient links list (status to be attached as url param)
   *  PATCH link accept/reject */
  patientLinks: 'patient-links',
  patientActiveLinks: 'patient-links?status=accepted',
  patientDeactivatedLinks: 'patient-links/deactivated',
  patientInvitedLinks: 'patient-links/invited',
  patientRefractoryLinks: 'patient-refractory',
  patientNeverLinkedLinks: 'patient-links/never-linked',
  patientRefractoryStatus: 'patient-refractory/:linkId',
  /** Endpoint for unlinking a patient (PATCH) */
  patientUnlink: 'patient-links/:linkId/deactivated',
  /** Endpoint for getting patient information */
  patientInfo: 'patients/:patientId',
  /** Endpoint for getting patient seizures data */
  patientSeizures: 'patients/:patientId/seizures',
  /** Endpoint for getting patient symptoms data */
  patientSymptoms: 'patients/:patientId/side-effects',
  /** Endpoint for getting patient medication adherence data */
  patientMedicationAdherence: 'patients/:patientId/medication-adherence',
  /** Endpoint for getting/setting medication regimens */
  patientRegimens: 'patients/:patientId/titrations',
  /** Endpoint for getting/setting medication regimens for invited-patient */
  invitedPatientRegimens: 'invited-patient/:patientId/titrations',
  /** GET, PUT thresholds endpoint */
  patientThresholds: 'patients/:patientId/thresholds',
  /** Endpoint for getting the latest surveys taken by a patient */
  patientSurveys: 'patients/:patientId/surveys',
  /** Endpoint for getting latest events for given types */
  patientEvents: 'patients/:patientId/latest-threshold-events',
  /** Endpoint for setting latest thresholds as seen */
  patientThresholdSeen: 'patients/latest-threshold-event/:thresholdId/seen',
  /** GET side-effect threshold defaults per medication */
  thresholdPresets: 'medication-side-effect-threshold-defaults',
  /** GET patient attachments */
  patientAttachments: 'patients/:patientId/attachment',

  /** Endpoint for editing/deactivating a patient's regimen */
  patientRegimen: 'patients/:patientId/titrations/:regimenId',

  /** Endpoint for editing/deactivating a invited patient's regimen */
  invitedPatientRegimen: 'invited-patient/:patientId/titrations/:regimenId',

  /** GET, POST, DELETE, PUT titrations endpoint */
  titrations: 'titration-presets',

  /** Contentful api */
  faq: 'spaces/:spaceId/environments/:environment/entries?content_type=nileHcpFaq',

  /** POST invite study patient endpoint */
  inviteStudyPatient: 'patient-invites',
  /** Endpoint for getting patient information */
  invitedPatientInfo: 'patient-invites/:patientId',
  verifiedPatientInfo: 'patient-invites/:patientId/verified-patient',
  /** Endpoint for getting never linked patient information */
  neverLinkedPatientInfo: 'patient-links/never-linked/:patientId',

  /** Endpoint for getting surveys list */
  surveys: 'surveys',
  assignSurvey: 'surveys/:patientId/patient',
  patientSurvey: 'surveys/:patientId/patient',
  deactivateSurvey: 'surveys/:surveyId/patient',
  surveyPdf: 'surveys/:surveyId/pdf',
  emrReport: 'patients/:patientId/export-emr',
  medicalHistory: 'patients/:patientId/medical-history-status',
  userRegistrationCheck: 'hcp/check-user-registration',

  /** Endpoint for getting calendar data */
  physicianOpenSlots: 'slots/physician/:physicianId',
  deleteSlot: 'slots/:slotId',
  createSlot: 'slots',
  physicianAppointment: 'appointment/physician/:physicianId',
  cancelAppointment: 'appointment/cancel/:appointmentId',
  patientAppointment: 'appointment/patient/:patientId',
  consent: 'virtual-clinic/consent-terms/patient/:patientId',
  openSlotsGroup: 'slots/group/appointment/:appointmentId',
  appointment: 'appointment/:appointmentId',
  medicationStats: '/smart-titration/statistic/medication/:medicationId',
  medicationSTDefault: '/smart-titration/default/medication/:medicationId',
  medicationSTCustom: '/smart-titration/customize/medication/:medicationId',
  insurace: 'insurance/:patientId',
  insuranceCompleted: 'insurance/:patientId/is-completed',
  insuranceUpdate: 'insurance/flag/:patientId',
  insuranceSeen: 'insurance/view/:insuranceId',
  medicalHistoryUpdate: 'medical-history/flag/:patientId',
  medicalHistorySeen: 'medical-history/view/:patientId',
};

export const APP_PAGE_URLS = {
  login: '/',
  register: '/register',
  registerSso: '/register-sso',
  loginSso: '/login-sso',
  ssoRedirect: '/sso-redirect',
  appRedirect: '/app',
  emailAppLink: '/patient/patient-invites/',
  registerAppLink: '/patient/register',
  careGiverInvite: '/care-giver/invite',
  forgotPassword: '/forgotPassword',
  patientList: '/patients',
  patientRecord: '/patient/:patientId',
  settings: '/settings',
  faq: '/faq',
  titrationsList: '/titrationsList',
  newTitration: '/titration',
  editTitration: '/editTitration/:presetId',
  assignPreset: '/assignPreset/:patientId',
  assignRegimen: '/assignRegimen/:patientId',
  editAssignedRegimen: '/editAssignedRegimen/:patientId/:regimenId',
  threshold: '/threshold/:patientId',
  invitedPatientRecord: '/patient-invites/:patientId',
  assignSurvey: '/patient/:patientId/assignSurvey',
  patientEMRExport: '/patient/:patientId/emrExport',
  emrExport: '/medical-history/:patientId',
  consentExport: '/consent-export/:patientId',
  insuranceExport: '/insurance-export/:patientId',
  adminCalendar: '/appointmentCalendar',
};

export const NAV_ITEMS = {
  PATIENT_LIST: 'PATIENT_LIST',
  TEMPLATES: 'TEMPLATES',
  ADMIN_CALENDAR: 'ADMIN_CALENDAR',
};

export const MAP_URL_TO_NAV = {
  [APP_PAGE_URLS.patientList.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.patientRecord.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.assignPreset.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.assignRegimen.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.editAssignedRegimen.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.threshold.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.invitedPatientRecord.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.assignSurvey.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,
  [APP_PAGE_URLS.patientEMRExport.split('/')[1]]: NAV_ITEMS.PATIENT_LIST,

  [APP_PAGE_URLS.titrationsList.split('/')[1]]: NAV_ITEMS.TEMPLATES,
  [APP_PAGE_URLS.newTitration.split('/')[1]]: NAV_ITEMS.TEMPLATES,
  [APP_PAGE_URLS.editTitration.split('/')[1]]: NAV_ITEMS.TEMPLATES,
  [APP_PAGE_URLS.adminCalendar.split('/')[1]]: NAV_ITEMS.ADMIN_CALENDAR,
};

/**
 * Nile app links
 */
export const NILE_APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=ai.nile.patient.epilepsy.care.seizure.tracker.alert.medications',
  ios: 'https://apps.apple.com/us/app/nile-ai/id1554756443',
  web: 'https://www.nile.ai/app/',
};

/**
 * API request methods
 */
export const API_REQUEST_METHODS = {
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

/**
 * API request error codes
 */
export const API_REQUEST_ERROR_CODES = {
  /** Server sent error codes */

  BAD_REQUEST: 400,

  UNAUTHORIZED: 401,

  /** Error code if the user has not been registered successfully in our users system. */
  USER_NOT_FOUND: 404,

  /** When tring to save a titration preset with a name that already is used by another preset */
  TITRATION_PRESET_EXISTS: 409,

  /** Codes reserved for our app */

  /** This is our last fallback option for error code, might never get to use it */
  GENERIC_ERROR: 1000,
  /** When no token found (eg. user is not logged in) */
  NO_ACCESS_TOKEN: 1001,
  /** No internet connection */
  NETWORK_ERROR: 1002,
};

/**
 * Locally defined error values for different scenarios,
 * usually maps to an error string key in the translation file
 */
export const ERROR_CODES = {
  SIGNIN_ERROR: 'signInError',
  SIGNOUT_ERROR: 'signOutError',
  MFA_ERROR: 'mfaError',
  SIGNUP_ERROR: 'signUpError',
  RESEND_SIGNUP_ERROR: 'resendSignUpError',
  ACCOUNT_VERIFY_ERROR: 'verifyError',
  FORGOT_PASSWORD_ERROR: 'forgotPasswordError',
  CHANGE_PASSWORD_ERROR: 'changePasswordError',
  FORGOT_PASSWORD_RESEND_ERROR: 'forgotPasswordResendError',
  /** Cognito error codes */
  CODE_MISMATCH: 'CodeMismatchException',
  EXPIRED_CODE: 'ExpiredCodeException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  INVALID_PARAMETER: 'InvalidParameterException',
  /** Lambda related errors */
  LAMBDA_EXCEPTION: 'UserLambdaValidationException',
  /** Part of lambda error message, and also maps to a translation key */
  DUPLICATE_ACCOUNT: 'DUPLICATE_ACCOUNT',
};

/**
 * Aws Amplify's MFA types
 * Currently only SMS (TOTP to come later?)
 */
export const MFA_TYPES = {
  SMS: 'SMS_MFA',
};

export const HCP_USER_STATUS = {
  pending: 'pending',
  active: 'active',
};

/**
 * Patient links statuses;
 * (matching BE values)
 */
export const PATIENT_LINKS_STATUS = {
  /** Verified (linked) patients status */
  accepted: 'accepted',
  /** Unverified patients status */
  pending: 'pending',
  /** Status used when making API call to reject a patient */
  rejected: 'rejected',
  /** This status does not map to a BE status,
   * but on the FE side, we work with this one
   */
  unlinked: 'unlinked',
  /** Invited patients status */
  invited: 'invited',
  refractory: 'refractory',
  never_linked: 'never_linked',
};

/**
 * User roles (matching BE values)
 */
export const HCP_USER_ROLES = {
  physician: 'physician',
  nurse: 'nurse',
  staff: 'general_staff',
};

/**
 * Medication regimen statuses (matching BE values)
 */
export const REGIMEN_STATUS = {
  notStarted: 'not_started',
  active: 'active',
  completed: 'completed',
  deactivated: 'deactivated',
  updated: 'updated',
};

export const METRICS_TYPE = {
  seizure: 'seizure',
  symptom: 'symptom',
};

/**
 * Survey types (matching BE values)
 * and used as key in translation file
 */
export const SURVEY_TYPES = {
  anxiety: 'anxiety',
  depression: 'depression',
};

/**
 * Threshold event types
 * - key matching BE values
 * - value used as key in translation file
 */
export const THRESHOLD_EVENT_TYPES = {
  seizure: 'seizure',
  side_effect: 'side_effect',
};

export const TITRATIONS_CONTEXT = {
  /** Titration input contexts */
  newTitration: 'newTitration',
  editTitration: 'editTitration',
  assignPreset: 'assignPreset',
  assignRegimen: 'assignRegimen',
  /** Titration display contexts */
  titrationsManagement: 'titrationsManagement',
  assignedRegimen: 'assignedRegimen',
  pastRegimen: 'pastRegimen',
};

/**
 * Time period for the insights metrics.
 */
export const INSIGHTS_METRICS_PERIOD = {
  days: 365,
};

/**
 * Maximum add medication limit
 */
export const MAX_MEDICATION_LIMIT = 10;

/**
 * Default timerange of the insights metrics.
 */
export const INSIGHTS_METRICS_DEFAULT_TIME_RANGE = {
  days: 30,
};

/** Time period size of a bucket for the top line metrics */
export const TOPLINE_METRICS_BUCKET_SIZE = {
  days: 30,
};

/**
 * Default timerange of the Gantt chart.
 */
export const GANTT_CHART_DEFAULAT_TIME_RANGE = {
  days: 365,
};

/**
 * Default timerange of the Gantt chart.
 */
export const GANTT_CHART_ZOOM_TIME_RANGE = {
  days: 20,
};

/** Number of buckets (columns) for the top line metrics chart
 * Chart will cover a time range of TOPLINE_METRICS_BUCKET_SIZE * TOPLINE_METRICS_BUCKETS_COUNT
*/
export const TOPLINE_METRICS_BUCKETS_COUNT = 3;

/** Time period when calculating trend indicator */
export const METRICS_TREND_PERIOD = TOPLINE_METRICS_BUCKET_SIZE;

/**
 * Default number of common symptoms.
 */
export const TOP_SYMPTOMS_COUNT = 3;

/**
 * Format for date values in API requests
 */
export const API_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Seizures are characterized by convulsions
 * and aware attributes.
 */
export const SEIZURE_TYPES = {
  ca: 'ca',
  cna: 'cna',
  cu: 'cu',
  nca: 'nca',
  ncna: 'ncna',
  ncu: 'ncu',
  ua: 'ua',
  una: 'una',
  ucua: 'ucua',
  aura: 'aura',
};

/**
 * Side Effect categorization.
 */
export const SYMPTOM_TYPES = {
  severe: 'severe',
  medium: 'medium',
  mild: 'mild',
};

/**
 * Medication adherence categories.
 */
export const ADHERENCE_TYPES = {
  missed: 'missed',
  logged: 'logged',
  updated: 'updated',
  started: 'started',
};

export const REGIMEN_TYPES = [ADHERENCE_TYPES.updated, ADHERENCE_TYPES.started];
export const REGIMEN_ADHERENCE_TYPES = [ADHERENCE_TYPES.logged, ADHERENCE_TYPES.missed];

/**
 * The responses for convulsive and loss of consciousness
 * questions from a user when entering a new seizure entry.
 */
export const SEIZURE_USER_RESPONSES = {
  yes: 'yes',
  no: 'no',
  notSure: 'not_sure',
};

/** The maximum number of times per day for a medication. */
export const TITRATION_MAX_FREQUENCY = 4;

/** The maximum severity value of a symptom. */
export const SYMPTOM_MAX_SEVERITY = 5;

/** Units used in Medication Regimens,
 * should be only values accepted by the BE
 */
export const MEDICATION_UNITS = ['mg', 'mL', 'cc'];
export const VERSION_FORMATE_ARRAY = [2, 2, 4];

export const MEDICATION_STRENGTH_TYPE = {
  pill: 'Pill',
  suspension: 'Suspension',
};

export const BUILDER_INITAL_STATE = {
  startDose: 0,
  targetDose: 0,
  doses: 1,
  unit: 'mg',
  frequency: '',
  weight: 0,
  maintenanceDose: false,
  offset: null,
};

/**
 * Default unit for medications
 */
export const DEFAULT_MEDICATION_UNIT = MEDICATION_UNITS[0];

/**
 * List of units (subset of MEDICATION_UNITS),
 *  that allow medication's strength
 */
export const MEDICATIONS_WITH_STRENGTH = [MEDICATION_UNITS[0]];

/** Status of medication adherence as reported by patient. */
export const MEDICATION_ADHERENCE_STATUS = {
  missed: 'missed',
  all: 'all',
};

/**
 * The order of these colors correspond
 * with the order in which the series
 * are added in the chart.
 */
export const SEIZURES_COLORS = [
  color(palette.brightBlue),
  color(palette.lightBlue),
  color(palette.extraLightBlue),
  color(palette.brightPurple),
  color(palette.lightPurple),
  color(palette.extraLightPurple),
  color(palette.brightOrange),
  color(palette.lightOrange),
  color(palette.extraLightOrange),
  color(palette.purpel),
];

/** Symptoms severity color map. */
export const SYMPTOM_SEVERITY_COLORS = {
  mild: color(palette.veryLightBrown),
  medium: color(palette.darkPeach),
  severe: color(palette.copper),
};

/** Symptoms severity color map. */
export const ADHERENCE_COLORS = {
  missed: color(palette.blue.blue1),
  logged: color(palette.blue.blue2),
  updated: color(palette.paleGrey.paleGrey5),
  started: color(palette.green.light1),
};

/** Tracking events for analytics */
export const TRACKING_EVENTS = {
  viewRegisterPage: 'Sign up: Register',
  viewRegisterVerificationCodePage: 'Sign up: Verification code',
  successfulSignUp: 'Sign up: Success',
  viewPatientList: 'Patient List',
  viewPatientRecord: 'Patient Record',
  viewTitrationManager: 'Titration Manager: List',
  viewTitrationBuilder: 'Titration Manager: Builder',
  clickOnAssignMedication: 'Patient Record: Assign',
  successfullyAssignedMedication: 'Patient Record: Assign Medication Success',
  clickSetThreshold: 'Threshold',
  clickThresholdSave: 'Threshold save',
};

/** Base color for medication adherence vizualisations. */
export const ADHERENCE_COLOR = color(palette.blue.blue1);
export const MEDICATION_COLOR = color(palette.green.dark);

/**
 * Tabs are identified by index. Use these named constants
 * to demistify index values. If the tabs order changes,
 * these should be updated also.
 * Note: using atrings for index values, as we persist this in
 *  session storage, where they get stored as strings
 */
export const PATIENTS_LIST_TABS = {
  VERIFIED_PATIENTS: '0',
  UNLINKED_PATIENTS: '1',
  INVITED_PATIENTS: '2',
  REFRACTORY_PATIENTS: '3',
};

/** Patients list tab types */
export const PATIENTS_LIST_TYPES = {
  VERIFIED: 'verified',
  UNLINKED: 'unlinked',
  INVITED: 'invited',
  REFRACTORY: 'refractory',
  NEVER_LINKED: 'never_linked',
};

/** Regimen status list */
export const REGIMEN_STATUS_LIST = {
  active: 'Accepted',
  deactivated: 'Deactivated',
  completed: 'Not assigned',
  not_started: 'Pending Acceptance',
};

export const REGIMEN_STATUS_COLORS = {
  active: palette.brownishGrey,
  deactivated: palette.copper,
  completed: palette.copper,
  not_started: palette.brownishGrey,
};

export const PATIENT_SURVEY_FREQUENCY = {
  one_time: 'One time',
  daily: 'Daily',
  weekly: 'Weekly',
  every_two_weeks: 'Every two weeks',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
};

export const MedicationColors = ['#0066ff', '#ee1ed9', '#39e002', '#00e7f6', '#ff7a00', '#0066ff', '#ee1ed9', '#39e002', '#00e7f6', '#ff7a00'];


export const patientFilterReport = [30, 60, 90, 180];
export const patientFilterReportCol = ['Start Date', 'Time', 'Duration', 'Event Type', 'Event Detail', 'Triggers', 'Notes'];

export const eventBgColors = {
  open: 'rgba(146, 146, 146, 0.1)',
  booked: 'rgba(0, 185, 52, 0.1)',
  reserved: 'rgba(0, 174, 249, 0.1)',
  paleGrey7: palette.paleGrey.paleGrey7,
};
