import _ from 'lodash';
import ACTION_TYPES from '../actionTypes';
import apiRequest from './apiActions';
import { API_REQUEST_METHODS, API_URLS } from '../../Constants';
import patientMappings from './mappingFunctions/patientMappings';
import appActions from './appActions';

const fetch = (params, listType) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_LIST_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_LIST_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          patientMappings.mapStatusToPatientLinksUrl(listType),
          null,
          params,
          { silent: false },
          'json',
          true,
        ),
      );

      const mapData = {
        total: response.data.total,
        list: patientMappings.mapDataToPatientLinksUrl(listType, response.data.list),
      };
      dispatch(success(mapData));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchPhysicians = () => {
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIANS_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIANS_LIST_ERROR });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.physicians,
          null,
          { silent: true },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchNileNextPhysicians = () => {
  const success = (data) => ({ type: ACTION_TYPES.NILE_NEXT_PHYSICIANS_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.NILE_NEXT_PHYSICIANS_LIST_ERROR });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.physicians,
          null,
          { silent: true, isNileNext: true },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchHealthSystems = () => {
  const success = (data) => ({ type: ACTION_TYPES.HEALTH_SYSTEM_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.HEALTH_SYSTEM_ERROR });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          `${process.env.REACT_APP_PUBLIC_BASE_URL}${API_URLS.healthSystem}`,
          null,
          { silent: false },
        ),
      );
      dispatch(success(response.data));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const setListType = (listType) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_TYPE, listType },
);

const search = (searchTerm) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_SEARCH, searchTerm },
);

const sort = (sortKey) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_SORT, sortKey },
);

const filterByPhysician = (physiciansIds) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_FILTER_BY_PHYSICIAN, physiciansIds },
);

const filterByHealthSystem = (healthSystemIds) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_FILTER_BY_HS, healthSystemIds },
);

const filterByRegimenStatus = (regimenStatuses) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_FILTER_BY_RS, regimenStatuses },
);

const setPage = (page) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_PAGE, page },
);

const setLimit = (limit) => async (dispatch) => dispatch(
  { type: ACTION_TYPES.PATIENT_LIST_LIMIT, limit },
);

const setShowInviteToolbar = () => async (dispatch) => dispatch(
  { type: ACTION_TYPES.SHOW_INVITE_TOOLBAR },
);

const invitePatient = (patientEmail, physicianId) => {
  const success = (data) => ({ type: ACTION_TYPES.INVITE_PATIENT_SUCCESS, data });
  // const failure = () => ({ type: ACTION_TYPES.INVITE_PATIENT_ERROR });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PUT,
          API_URLS.inviteStudyPatient,
          { patientEmail, physicianId },
        ),
      );
      if (_.get(response, 'data.existing')) {
        dispatch(appActions.appPushNotification('STUDY_PATIENTS.SUCCESS_MESSAGES.invitePatientDuplicate'));
        dispatch(success(response.data));
      } else {
        dispatch(success(response.data));
      }
    } catch (errorCode) {
      // dispatch(failure(errorCode));
    }
  };
};

const setReviewStatus = (linkId, newStatus) => async (dispatch, getState) => {
  try {
    await dispatch(
      apiRequest(
        API_REQUEST_METHODS.PATCH,
        API_URLS.patientRefractoryStatus.replace(':linkId', linkId),
        {
          status: newStatus,
        },
        null,
        { silent: false },
      ),
    );
    const { params, listType } = getState().patientsList;
    dispatch(fetch(params, listType));
  } catch (errorCode) {
    // test
  }
};

export default {
  fetch,
  fetchPhysicians,
  fetchHealthSystems,
  search,
  sort,
  setListType,
  setPage,
  setLimit,
  filterByPhysician,
  filterByHealthSystem,
  filterByRegimenStatus,
  setShowInviteToolbar,
  invitePatient,
  setReviewStatus,
  fetchNileNextPhysicians,
};
