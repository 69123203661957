/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnCheckOutlineIcon = (props) => {
  const { color, fontSize, ...rest } = props;

  const style = {
    fontSize,
  };

  return (
    <SvgIcon
      height="23"
      width="23"
      viewBox="0 0 23 23"
      fill="none"
      style={style}
      {...rest}
    >
      <path fill={color} fillRule="evenodd" d="M11.5 0C5.152 0 0 5.152 0 11.5S5.152 23 11.5 23 23 17.848 23 11.5 17.848 0 11.5 0zm0 20.7c-5.072 0-9.2-4.128-9.2-9.2 0-5.072 4.128-9.2 9.2-9.2 5.071 0 9.2 4.128 9.2 9.2 0 5.072-4.129 9.2-9.2 9.2zm-2.3-6.704l7.579-7.579L18.4 8.05l-9.2 9.2-4.6-4.6 1.622-1.621L9.2 13.996z" clipRule="evenodd" />
    </SvgIcon>
  );
};

KnCheckOutlineIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

KnCheckOutlineIcon.defaultProps = {
  color: '#666',
  fontSize: 23,
};

export default KnCheckOutlineIcon;
