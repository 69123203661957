
import {
  Card,
  CardContent,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import palette from 'styles/colors';

export const KnContainerBox = styled(Box)(({ open }) => ({
  position: 'absolute',
  width: '100%',
  height: open ? '100vh' : 48,
  zIndex: 1000,
  backgroundColor: open ? 'rgba(0,0,0,0.4)' : 'transparent',
}));

export const KnStyledBox = styled(Box)(() => ({
  top: 65,
  position: 'relative',
  backgroundColor: 'white',
  width: '1000px',
  margin: '0 auto',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}));

export const StyledListItem = styled(ListItem)(() => ({
  borderBottom: 'none',
  padding: 0,
}));

export const ProBadge = styled('span')(() => ({
  background: palette.primary,
  borderRadius: 2,
  width: 155,
  height: 24,
  color: '#F8FBFC',
  fontWeight: 700,
  fontSize: 12,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BoxTitle = styled(Typography)(() => ({
  fontSize: 36,
}));

export const BoxSubTitle = styled(Typography)(() => ({
  fontSize: 17,
  color: palette.black.black1,
  fontWeight: 300,
  paddingBottom: 8,
  opacity: 0.8,
}));

export const BoxListTitle = styled(BoxSubTitle)({
  fontWeight: 700,
  paddingBottom: 0,
});

export const KnCardContent = styled(CardContent)(() => ({
  padding: '8px 16px !important',
  position: 'relative',
  height: '100%',
}));

export const KnListItemIcon = styled(ListItemIcon)({
  minWidth: 25,
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  margin: '8px 0',
});

export const StyledCard = styled(Card)(() => ({
  backgroundColor: 'white',
  boxShadow: '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)',
  alignSelf: 'stretch',
}));

export const ContactBox = styled(Box)({
});
