/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

const initialState = {
  data: null,
  error: false,
};

const titrationBuilder = (state = initialState, action) => {
//   const { data, type } = action;
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default titrationBuilder;
