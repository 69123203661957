/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'redux/actions/userActions';
import { useForm } from 'react-hook-form';
import { styled, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KnButton from 'components/Button';
import { KnSectionHeader, KnSubtleText, KnDarkerText } from 'components/Typography';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import { NAME_FORMAT, VERSION_FORMATE_ARRAY } from 'Constants';
import { withKeyNamespace } from 'utils/utils';
import {
  KnSettingsFieldsBox,
  KnSettingsReadonlyTextFields,
} from './styles';


const i18nKey = withKeyNamespace('PROFILE');

const KnUDIBox = styled(Box)({
  paddingTop: '5%',
});


const KnProfile = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const currentUserStore = useSelector((state) => state.user.currentUser);
  const {
    handleSubmit, errors, control, formState: { dirty, isSubmitting, dirtyFields }, reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: currentUserStore.firstName,
      lastName: currentUserStore.lastName,
    },
    reValidateMode: 'OnBlur',
  });

  useEffect(() => {
    /**
     * This is here for the case when a user refreshes the page.
     * We need to reset the form with the current user's data
     * as soon as we have it, so the default values and formState
     * are updated.
     *
     * However, this code will run also when navigating to this
     * page, without side-effects.
     */
    if (currentUserStore.initialized) {
      reset({
        firstName: currentUserStore.firstName,
        lastName: currentUserStore.lastName,
      });
    }
  }, [
    currentUserStore.firstName,
    currentUserStore.initialized,
    currentUserStore.lastName,
    reset,
  ]);

  const onSubmit = () => {
    const updates = {};
    const values = control.getValues();

    /** Loop over the dirty fields, as we want to only send the modified data. */
    dirtyFields.forEach((field) => {
      updates[field] = values[field].trim();
    });
    return dispatch(userActions.updateProfile(updates)).then(
      () => {
        /** Remove leading/ending spaces from modified inputs,
         * to reflect how they got saved
         */
        reset(updates);
      },
      () => { },
    );
  };

  const appVersion = (str) => {
    const arr = str.split('.');
    let ans = '';
    ans += arr.map((numr, size) => {
      let num = numr.toString();
      while (num.length < VERSION_FORMATE_ARRAY[size]) num = `0${num}`;
      return num;
    });
    return ans.replace(/,/g, '');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Typography variant="h6" component={KnSectionHeader}>
          {translate(i18nKey('sectionTitles.name'))}
        </Typography>
        <Typography variant="body2" component={KnSubtleText}>
          {translate(i18nKey('editableFieldsHint'))}
        </Typography>
        <KnSettingsFieldsBox display="flex" pt={2}>
          <KnValidatedTextField
            Component={KnContrastTextField}
            name="firstName"
            control={control}
            errors={errors}
            maxLength={30}
            required
            trimSpaces
            format={NAME_FORMAT}
            defaultValue={currentUserStore.firstName || ''}
          />

          <KnValidatedTextField
            Component={KnContrastTextField}
            name="lastName"
            control={control}
            errors={errors}
            maxLength={30}
            required
            trimSpaces
            format={NAME_FORMAT}
            defaultValue={currentUserStore.lastName || ''}
          />
        </KnSettingsFieldsBox>
      </Box>

      <Box pb={6}>
        <Typography variant="h6" component={KnSectionHeader}>
          {translate(i18nKey('sectionTitles.contact'))}
        </Typography>

        <KnSettingsFieldsBox display="flex" pt={2}>
          <KnSettingsReadonlyTextFields
            disabled
            inputProps={{ 'data-testid': 'email-input-field' }}
            InputLabelProps={{ 'data-testid': 'email-input-label' }}
            label={translate('GENERAL.email')}
            value={currentUserStore.email || ''}
          />

          <KnSettingsReadonlyTextFields
            disabled
            inputProps={{ 'data-testid': 'phone-number-input-field' }}
            InputLabelProps={{ 'data-testid': 'phone-number-input-label' }}
            label={translate('FIELD_LABELS.phoneNumber')}
            value={currentUserStore.phoneNumber || ''}
          />
        </KnSettingsFieldsBox>
      </Box>

      <Box pb={6}>
        <Typography variant="h6" component={KnSectionHeader}>
          {translate(i18nKey('sectionTitles.userRole'))}
        </Typography>

        <KnSettingsFieldsBox display="flex" pt={2}>
          <KnSettingsReadonlyTextFields
            disabled
            inputProps={{ 'data-testid': 'user-role-input-field' }}
            InputLabelProps={{ 'data-testid': 'user-role-input-label' }}
            label={translate('FIELD_LABELS.userRole')}
            value={_.get(currentUserStore, 'role.name', '')}
          />
        </KnSettingsFieldsBox>
      </Box>

      <Box pb={4}>
        <Typography component={KnDarkerText}>
          {translate(i18nKey('accountNumber'), { accountNumber: currentUserStore.accountNumber })}
        </Typography>
      </Box>

      <KnButton
        data-testid="save-button"
        disabled={!dirty || isSubmitting}
      >
        {translate('GENERAL.save')}
      </KnButton>

      <KnUDIBox>
        <Typography component={KnDarkerText}>
          {translate('ACCOUNT_SETTINGS.UDI')}
          :
          {`(01)00850031821026(8012)${appVersion(process.env.REACT_APP_VERSION || '').split('.').join('').replace(/-/g, '')}`}
        </Typography>
      </KnUDIBox>
    </form>
  );
};

export default KnProfile;
