/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnStableIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#54586C" d="M14.25 9.75H3.75v-1.5h10.5v1.5z" />
  </SvgIcon>
);

export default KnStableIcon;
