/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withKeyNamespace } from 'utils/utils';
import { translate } from 'i18n/i18n';
import { styled } from '@material-ui/core';
import { KnAssignRegimentdBox } from './styles';

const i18nKey = withKeyNamespace('ASSIGN_REGIMEN');

const KnAddRegimentText = styled(Typography)({
  fontWeight: 500,
  textAlign: 'center',
  textTransform: 'uppercase',
  cursor: 'pointer',
});

const KnAddMedicationTx = ({ children, onClick }) => (
  <KnAddRegimentText variant="h4" color="primary" onClick={onClick}>{children}</KnAddRegimentText>
);

KnAddMedicationTx.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.shape(),
};
KnAddMedicationTx.defaultProps = {
  children: null,
};

/**
 * Component for displaying Add medication option
 */
const KnAddMedication = ({ setShowAddMedicaion, showAddMedicaion }) => (
  <KnAssignRegimentdBox mb={7} disabled={showAddMedicaion}>
    <KnAddMedicationTx onClick={() => {
      if (!showAddMedicaion) {
        setShowAddMedicaion(true);
      }
    }}
    >
      <>
        +
        {' '}
        {translate(i18nKey('addMedication'))}
      </>
    </KnAddMedicationTx>
  </KnAssignRegimentdBox>
);

KnAddMedication.propTypes = {
  setShowAddMedicaion: PropTypes.func.isRequired,
  showAddMedicaion: PropTypes.bool.isRequired,
};

export default KnAddMedication;
