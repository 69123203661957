/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';

const KnClockIcon = ({ color, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
    <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#A8A8A8" />
    <path d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="#A8A8A8" />
  </svg>
);

KnClockIcon.defaultProps = {
  color: '#D4D5DA',
};

KnClockIcon.propTypes = {
  color: PropTypes.string,
};

export default KnClockIcon;
