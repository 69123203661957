/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  data: [],
  error: false,
};

const faqReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.FAQ_FETCH_SUCCESS:
    case ACTION_TYPES.FAQ_FETCH_ERROR:
      return {
        ...state,
        data: data || [],
        error: data === undefined,
      };

    default:
      return state;
  }
};

export default faqReducer;
