/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'redux/actions/userActions';
import loginActions from 'redux/actions/loginActions';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KnButton from 'components/Button';
import { KnSectionHeader, KnSubtleText } from 'components/Typography';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import { PASSWORD_FORMAT } from 'Constants';
import useCognitoUser from 'utils/cognitoUser';
import {
  KnSettingsFieldsBox,
} from './styles';

const initialChangePasswordInfo = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const KnPassword = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const currentUserStore = useSelector((state) => state.user.currentUser);
  const {
    handleSubmit, errors, control, triggerValidation,
    formState: { dirty, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialChangePasswordInfo,
    reValidateMode: 'onChange',
  });
  const cognitoUser = useCognitoUser();

  const onPasswordBlur = useCallback(() => {
    /** On password blur, if there is a value in the confirm password,
     * trigger the validation of confirm password field manually,
     * in case a mismatch password error was fixed.
     */
    if (control.getValues('confirmPassword')) {
      triggerValidation('confirmPassword');
    }
  }, [control, triggerValidation]);

  const onCurrentPasswordBlur = useCallback(() => {
    /** On current password blur, if there is a value in the new password,
     * trigger the validation of new password field manually,
     * in case a match with the current password occurs or an error is fixed.
     */
    if (control.getValues('newPassword')) {
      triggerValidation('newPassword');
    }
  }, [control, triggerValidation]);

  const onSubmit = (formData) => {
    dispatch(userActions.changePassword(
      cognitoUser,
      formData.currentPassword,
      formData.newPassword,
    )).then(() => {
      /** Password change was successful, end current session */
      dispatch(loginActions.logout());
    },
    () => {});
  };

  const newPasswordCustomRules = useRef({
    validate: {
      shouldNotEqualCurrent: (value) => (
        (value && control.getValues('currentPassword') && (value.toLowerCase() === control.getValues('currentPassword').toLowerCase()))
          ? translate('FIELD_VALIDATION_MESSAGES.newPassword.shouldNotEqualCurrent')
          : true
      ),
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Typography variant="h6" component={KnSectionHeader}>
          {translate('FORGOT_PASSWORD.changePasswordTitle')}
        </Typography>
        <Typography variant="body2" component={KnSubtleText}>
          {translate('CHANGE_PASSWORD.subtitle')}
        </Typography>
        <KnSettingsFieldsBox pt={2}>
          <KnValidatedTextField
            Component={KnContrastTextField}
            name="currentPassword"
            control={control}
            errors={errors}
            type="password"
            required
            maxLength={256}
            onBlur={onCurrentPasswordBlur}
          />
          <KnValidatedTextField
            Component={KnContrastTextField}
            name="newPassword"
            control={control}
            errors={errors}
            type="password"
            required
            format={PASSWORD_FORMAT}
            notEqualTo={currentUserStore.email}
            rules={newPasswordCustomRules.current}
            maxLength={256}
            onBlur={onPasswordBlur}
          />
          <KnValidatedTextField
            Component={KnContrastTextField}
            name="confirmPassword"
            control={control}
            errors={errors}
            type="password"
            required
            matchField="newPassword"
            maxLength={256}
          />
        </KnSettingsFieldsBox>
      </Box>
      <Box pb={2} mt={-2}>
        <Typography variant="body2" component={KnSubtleText}>
          {translate('REGISTER.passwordHint')}
        </Typography>
      </Box>
      <KnButton
        data-testid="change-password-button"
        disabled={!dirty || isSubmitting}
      >
        {translate('FORGOT_PASSWORD.changePasswordButton')}
      </KnButton>
    </form>
  );
};

export default KnPassword;
