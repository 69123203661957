/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import { useHistory } from 'react-router-dom';
import titrationActions from 'redux/actions/titrationActions';
import useDialog from 'components/dialog/DialogService';
import { useTranslation } from 'react-i18next';
import KnPageContent, { KnBox } from 'components/Content';
import KnBrightSheet from 'components/BrightSheet';
import KnButton from 'components/Button';
import { APP_PAGE_URLS, TITRATIONS_CONTEXT, TRACKING_EVENTS } from 'Constants';
import { titrationMedsMigrate, useIsUserHospitalStaff } from 'utils/utils';
import KnTitrationsList from 'components/titrations/TitrationsList';
import { get } from 'lodash';

const TitrationsManagementPage = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: titrationsList,
    error,
  } = useSelector((state) => state.titrationsList);
  const dialog = useDialog();
  const currentUserData = useSelector((state) => state.user.currentUser);
  const userIsHospitalStaff = useIsUserHospitalStaff();
  const history = useHistory();

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewTitrationManager });
    dispatch(titrationActions.fetchTitrations(false));
  }, [dispatch]);

  const redoFetchTitrationsList = useCallback(() => {
    dispatch(titrationActions.fetchTitrations(false));
  }, [dispatch]);

  const addTitrationButton = userIsHospitalStaff
    && (
      <KnButton
        route={APP_PAGE_URLS.newTitration}
        data-testid="add-titration-button"
      >
        <span>
          <big>+</big>
          {' '}
          {translate('TITRATIONS.addTitrationButtonTemplate')}
        </span>
      </KnButton>
    );

  const editTitration = useCallback((titrationData) => {
    const {
      name: presetName,
      medications: presetMedications,
      id: presetId,
    } = titrationData;

    history.push(
      APP_PAGE_URLS.editTitration.replace(':presetId', presetId),
      {
        presetName,
        presetMedications,
      },
    );
  }, [history]);

  const duplicateTitration = useCallback((titrationData) => {
    const {
      medications: presetMedications,
    } = titrationData;

    history.push(
      APP_PAGE_URLS.newTitration,
      {
        presetMedications,
      },
    );
  }, [history]);

  const deleteTitration = useCallback(({ id: titrationId }) => {
    dialog({
      title: translate('TITRATIONS.titrationDeleteModal.title'),
      description: translate('TITRATIONS.titrationDeleteModal.content'),
      submitLabel: translate('TITRATIONS.titrationDeleteModal.submitButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      dispatch(titrationActions.deleteTitration(titrationId));
    });
  }, [dialog, translate, dispatch]);

  const titrationsListConverted = useMemo(() => (titrationsList || []).map((t) => ({
    ...t,
    medications: titrationMedsMigrate(t.medications),
  })).filter((t) => get(t, 'hcpUser.id') === currentUserData.userId),
  [titrationsList, currentUserData]);

  return (
    <>
      <KnPageContent>
        <KnBrightSheet
          title={translate('TITRATIONS.title')}
          ActionComponent={addTitrationButton}
        >
          <KnTitrationsList
            context={TITRATIONS_CONTEXT.titrationsManagement}
            titrationsList={titrationsListConverted}
            emptyMessageKey="TITRATIONS.emptyListTemplate"
            error={error}
            errorMessageKey="TITRATIONS.ERROR_MESSAGES.titrationsListError"
            onRetry={redoFetchTitrationsList}
            actions={{
              onEditTitration: editTitration,
              onDuplicateTitration: duplicateTitration,
              onDeleteTitration: deleteTitration,
            }}
          />
        </KnBrightSheet>
        <KnBox />
      </KnPageContent>
    </>
  );
};

export default TitrationsManagementPage;
