/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

/**
 * Filters the list of medications from a medication regimen which are marked as dangerous.
 *
 * @param {array} medications The list of medications from the medication regimen of a patient.
 * @param {array} presetMedications The list of dangerous medications with side effect presets.
 * @returns {array} The side effect presets list associated with the given medications.
 */
export const getDangerousMedicationsSymptomPresets = (medications, presetMedications) => {
  const dangerousMeds = [];
  medications.forEach((med) => {
    const medFound = presetMedications.find((item) => (item.medicationId === med.id));
    if (medFound) {
      dangerousMeds.push(medFound);
    }
  });

  return dangerousMeds;
};

/**
 * Flats the threshold symptom presets by minimum severity.
 *
 * @param {array} sideEffectPresets The list of side effect presets for dangerous medications.
 */
export const flatSymptomsPresetsBySeverity = (sideEffectPresets) => {
  const result = [];
  sideEffectPresets.forEach(({ sideEffects }) => {
    sideEffects.forEach((sideEffect) => {
      const item = result.find((symptom) => (symptom.symptomId === sideEffect.symptomId));
      if (item) {
        item.severity = Math.min(item.severity, sideEffect.severity);
      } else {
        result.push(sideEffect);
      }
    });
  });

  return result;
};
