/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import KnIconWrapper from './styles';

const KnEditIcon = (props) => {
  const {
    color,
    ...rest
  } = props;

  return (
    <KnIconWrapper fontSize={16}>
      <SvgIcon
        fontSize="inherit"
        viewBox="0 -3 16 16"
        fill="none"
        {...rest}
      >
        <path fill={color} d="M0 10.292V13h2.708l7.987-7.987-2.708-2.708L0 10.292zM12.789 2.92c.281-.282.281-.737 0-1.019l-1.69-1.69c-.282-.281-.737-.281-1.018 0L8.759 1.533l2.708 2.708 1.322-1.322z" />
      </SvgIcon>
    </KnIconWrapper>
  );
};

KnEditIcon.propTypes = {
  color: PropTypes.string,
};

KnEditIcon.defaultProps = {
  color: '#666',
};

export default KnEditIcon;
