/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import _ from 'lodash';
import React, {
  useRef, useLayoutEffect, useMemo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  create, color, percent, useTheme, Scrollbar, Tooltip, Image,
} from '@amcharts/amcharts4/core';
import {
  XYChart, ColumnSeries, ValueAxis, CategoryAxis, Bullet,
} from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import palette from 'styles/colors';
import { MedicationColors, GANTT_CHART_DEFAULAT_TIME_RANGE, GANTT_CHART_ZOOM_TIME_RANGE } from 'Constants';
import { withKeyNamespace } from 'utils/utils';
import { KnSubtleText } from 'components/Typography';

import { differenceInDays, format } from 'date-fns';
import { translate } from 'i18n/i18n';
import { KnGanttChartContainer, KnGanntChartDateRange, styleChartScroll } from './styles';

import declinedIcon from '../../../assets/images/icon_alert_error_24px.svg';
import acceptedIcon from '../../../assets/images/icon_accepted10px.svg';


const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

const baseOpacity = 0.65;

const bulletChartTooltipStyle = [
  `background-color: ${color(palette.white.white2)}`,
  `color: ${color(palette.black.black1)}`,
  'padding: 8px',
  'min-width: 163px',
].join(';');

/**
 * Common data for Gantt chart
 * @param {Object} medication
 * @param {Object} d
 * @param {Number} index
 * @param {Number} dosageIndex
 * @returns
 */
export const commonData = (medication, d, index, dosageIndex, isCalendar) => ({
  name: isCalendar ? index : index + 1,
  color: isCalendar ? '' : MedicationColors[index],
  dosageIndex: dosageIndex + 1,
  quantity: d.quantity,
  frequency: d.frequency,
  duration: d.duration,
  unit: medication.unit,
  individualQuantities: d.individualQuantities,
  total: _.sumBy((d.individualQuantities || []), (val) => parseFloat(val)),
  medicationName: `${medication.activeIngredient} ${medication.name}`,
  toolTipHTML: `
    <div style="${bulletChartTooltipStyle}">
    <strong>{medicationName}</strong><br />
    ${_.sumBy((d.individualQuantities || [])) ? '<span>Total Daily Dosage {dosageIndex} : <strong>{total} {unit}</strong></span>' : ''}
    <br />
    ${d.frequency && d.duration ? `<span> for <strong>{duration} ${d.duration > 1 ? 'days' : 'day'}</strong></span>` : ''}
    </div>`,
});

export const getPatientAcceptanceData = (d, unit, startDate) => {
  if (!d.patientDosageAcceptanceAt) return null;

  const daysDiff = differenceInDays(new Date(d.patientDosageAcceptanceAt), new Date(startDate));
  const locationX = 1 - daysDiff / d.duration;
  const bulletLocation = Math.max(Math.min(locationX, 1), 0);

  const bulletIcon = d.patientDosageAcceptance ? acceptedIcon : declinedIcon;
  const bulletColor = d.patientDosageAcceptance ? palette.paleGrey.paleGrey7 : palette.error.main;

  const bulletText = `<div style="padding: 5px">
      <strong>${translate(i18nKey(d.patientDosageAcceptance ? 'patientAccepted' : 'patientDeclined'))}</strong></br>
      ${format(new Date(d.patientDosageAcceptanceAt), 'MM/dd')}</br>
      <strong style="text-transform: uppercase">${translate('FIELD_LABELS.dosage')}</strong><br>
      ${_.sum((d.individualQuantities || []))} {unit}
  </div>`;

  return {
    bulletIcon, bulletColor, bulletText, bulletLocation, accepted: !!d.patientDosageAcceptance,
  };
};

export const addRange = (valueAxis, tooltipText, value, dayView, isDisable) => {
  const range = valueAxis.axisRanges.create();
  range.grid.strokeWidth = 6;
  range.grid.strokeOpacity = 1;
  range.grid.strokeLinecap = 'round';
  range.grid.strokeLinejoin = 'round';
  range[dayView ? 'value' : 'date'] = value;
  range.grid.stroke = color(isDisable ? palette.paleGrey.paleGrey5 : palette.bostonBlue);
  range.grid.above = true;
  range.grid.tooltip = new Tooltip();
  range.grid.tooltip.getFillFromObject = false;
  range.grid.tooltip.getStrokeFromObject = false;
  range.grid.tooltipHTML = `<strong>${tooltipText}</strong>`;
  range.grid.tooltip.background.fill = palette.white.white2;
  range.grid.tooltip.label.fill = palette.black.black2;
  range.grid.tooltip.background.stroke = color(isDisable ? '#7c7c7c' : palette.bostonBlue);
  range.grid.tooltip.background.strokeWidth = 5;
  range.grid.interactionsEnabled = true;
  range.grid.isMeasured = true;
};

export const getActiveMedIds = (dosagesData, titration) => {
  const medIds = _.uniq(dosagesData.map((d) => d.id));
  const currentMedIds = _.get(titration, 'medications', []).map((m) => m.id + 1000);
  const dosageGroup = _.groupBy(dosagesData, 'id');
  return _.filter(medIds, (mId) => {
    const meds = dosageGroup[mId];
    if (meds.every((m) => m.color === palette.paleGrey.paleGrey6) && !currentMedIds.includes(mId)) {
      return false;
    }
    return true;
  });
};


const bulletTooltipAdapter = (target, series) => {
  if (target.dataItem) {
    series.tooltip.background.fill = target.dataItem._dataContext.bulletColor;
    return (`
      <div style="${bulletChartTooltipStyle}">
        ${target.dataItem._dataContext.bulletText}
      </div>
    `);
  }
  return null;
};

const tooltipAdapter = (target, series) => {
  if (target.dataItem) {
    series.tooltip.background.fill = target.dataItem._dataContext.color;
    return (`
      <div style="${bulletChartTooltipStyle}">
        ${target.dataItem._dataContext.toolTipHTML}
      </div>
    `);
  }
  return null;
};


/**
 * Common Configuration for Gantt chart
 * @param {Object} chart
 * @param {Array} ganttChartData
 * @returns
 */
// eslint-disable-next-line max-len
export const commonCofig = (chart, ganttChartData, isCalendar, titration, hasDateSelector = true) => {
  chart.paddingRight = hasDateSelector ? 30 : 15;
  chart.hiddenState.properties.opacity = 0;

  chart.data = ganttChartData;

  const categoryAxis = chart.yAxes.push(new CategoryAxis());
  categoryAxis.dataFields.category = isCalendar ? 'id' : 'name';
  categoryAxis.renderer.inversed = true;
  categoryAxis.renderer.labels.template.propertyFields.fill = isCalendar ? 'labelColor' : 'color';
  categoryAxis.renderer.labels.template.fontSize = hasDateSelector ? 22 : 9;
  categoryAxis.renderer.labels.template.fontWeight = 600;
  categoryAxis.renderer.grid.template.location = 0;
  if (categoryAxis && isCalendar) {
    categoryAxis.renderer.labels.template.adapter.add('textOutput', (text, target) => {
      const data = getActiveMedIds(ganttChartData, titration);
      /* eslint-disable no-underscore-dangle */
      const currentItem = target.dataItem && target.dataItem._dataContext;
      if (currentItem && data.indexOf(currentItem.medId) > -1) {
        return (data.indexOf(currentItem.medId) + 1).toString();
      }
      return '-';
    });
  }

  const series1 = chart.series.push(new ColumnSeries());

  series1.columns.template.width = percent(10);

  series1.tooltip.pointerOrientation = 'up';
  series1.tooltip.background.cornerRadius = 0;
  series1.tooltip.label.fontSize = 12;
  series1.tooltip.label.fill = color(palette.black.black1);
  series1.tooltip.label.paddingLeft = 0;
  series1.tooltip.label.paddingRight = 0;
  series1.tooltip.label.paddingBottom = 0;
  series1.tooltip.getFillFromObject = false;
  series1.tooltip.getStrokeFromObject = false;
  series1.tooltip.background.propertyFields.fill = 'color';
  series1.tooltip.background.stroke = color(palette.white.white2);
  series1.tooltip.background.strokeWidth = '2';
  series1.columns.template.stroke = color(palette.white.white2);
  series1.columns.template.fill = color(palette.white.white2);

  series1.columns.template.propertyFields.fill = 'color';
  series1.columns.template.propertyFields.stroke = 'color';
  series1.columns.template.propertyFields.fillOpacity = 'opacity';
  series1.columns.template.propertyFields.strokeOpacity = 'opacity';
  series1.columns.template.adapter.add('tooltipHTML', (html, target) => tooltipAdapter(target, series1));
  series1.columns.template.height = percent(50);

  if (hasDateSelector) {
    chart.scrollbarX = new Scrollbar();
  } else {
    chart.zoomOutButton.disabled = true;
  }

  const bullet = series1.bullets.push(new Bullet());

  bullet.propertyFields.tooltipHTML = 'bulletText';
  bullet.propertyFields.locationX = 'bulletLocation';
  bullet.propertyFields.stroke = 'bulletColor';
  bullet.strokeWidth = 0;
  bullet.adapter.add('tooltipHTML', (html, target) => bulletTooltipAdapter(target, series1));

  function tooltipFill(series) {
    series.columns.template.propertyFields.fill = 'color';
  }

  series1.adapter.add('tooltipHTML', () => tooltipFill(series1));

  series1.columns.template.events.on('over', () => tooltipFill(series1));

  const image = bullet.createChild(Image);
  image.propertyFields.href = 'bulletIcon';
  image.horizontalCenter = 'middle';
  image.verticalCenter = 'middle';
  image.width = 14;
  image.height = 14;

  return { categoryAxis, series1 };
};

export const generateDosages = (values) => {
  const sortedByDosages = _.sortBy(values, (d) => parseInt(
    _.sum(
      _.map(d.individualQuantities, (q) => parseInt(q, 10)),
    ), 10,
  )).reverse();
  let dosages = sortedByDosages.map((d, dosageIndex) => {
    const opacity = (baseOpacity - ((baseOpacity / sortedByDosages.length) * dosageIndex));
    return {
      ...d,
      opacity: opacity < 0.01 ? 0.05 : opacity,
    };
  });
  dosages = _.sortBy(dosages, 'index');
  return dosages;
};

const GanttDayChart = ({
  chartId, medications, showAddMedicaion, debounceUpdate,
}) => {
  const chartRef = useRef(null);
  const [graphData, setGraphData] = React.useState([]);
  const [zoomRange, setZoomRange] = useState({ min: 0, max: 0 });

  const ganttChartData = useMemo(() => {
    const chartData = _.sortBy(_.flatten(medications.map((medication, index) => {
      let offset = medication.offset ? medication.offset + 1 : 1;
      let dosages = medication.dosages.map((d, dosageIndex) => {
        const data = {
          from: offset,
          to: offset + (d.duration ? d.duration : 1),
          ...commonData(medication, d, index, dosageIndex),
        };
        offset += (d.duration ? d.duration : 0);
        return data;
      });

      dosages = generateDosages(dosages);
      if (showAddMedicaion) {
        dosages.push({
          from: 1,
          to: 2,
          name: medications.length + 1,
          color: MedicationColors[medications.length],
          opacity: baseOpacity,
          medicationName: translate(i18nKey('addMedication')),
          dosageIndex: 1,
          toolTipHTML: '<strong>{medicationName}</strong>',
        });
      }
      return dosages;
    })), 'name');

    if (!medications.length) {
      chartData.push({
        from: 1,
        to: 2,
        name: 1,
        color: MedicationColors[0],
        opacity: baseOpacity,
        medicationName: translate(i18nKey('addMedication')),
        dosageIndex: 1,
        toolTipHTML: '<strong>{medicationName}</strong>',
      });
    }
    return chartData;
  }, [medications, showAddMedicaion]);

  useTheme(am4themesAnimated);

  const onRangeChangeEnded = useCallback(({ target }) => {
    setZoomRange({
      min: target.minZoomed,
      max: target.maxZoomed,
    });
  }, [setZoomRange]);

  const createChart = () => {
    const chart = create(chartId, XYChart);

    const valueAxis = chart.xAxes.push(new ValueAxis());
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.strictMinMax = true;
    valueAxis.maxPrecision = 0;
    valueAxis.keepSelection = true;
    valueAxis.start = 0;
    valueAxis.end = 0.07;
    valueAxis.title.text = 'Days';
    valueAxis.title.fontWeight = 'bold';
    valueAxis.max = GANTT_CHART_DEFAULAT_TIME_RANGE.days;
    valueAxis.min = 1;
    valueAxis.formatLabel = (value) => {
      if (value === 0) {
        return '';
      }
      return value;
    };
    const { series1 } = commonCofig(chart, ganttChartData);
    series1.dataFields.openValueX = 'from';
    series1.dataFields.valueX = 'to';
    series1.dataFields.categoryY = 'name';

    // addRange(valueAxis, translate(i18nKey('pendingApproval')), 10, true);

    chartRef.current = styleChartScroll(chart);


    const zoomToRange = (min, max) => {
      valueAxis.zoomToValues(
        min,
        max,
        true,
        true,
      );
      onRangeChangeEnded({
        target: {
          minZoomed: min,
          maxZoomed: max,
        },
      });
    };

    chart.events.on('ready', () => {
      const start = 1;
      const end = GANTT_CHART_ZOOM_TIME_RANGE.days;
      zoomToRange(start, end);
      valueAxis.events.on('rangechangeended', onRangeChangeEnded);
    });

    chart.zoomOutButton.events.on('hit', () => {
      const start = 1;
      const end = GANTT_CHART_ZOOM_TIME_RANGE.days;
      zoomToRange(start, end);
    });
  };

  useLayoutEffect(() => {
    if (!chartRef.current) {
      createChart();
    }
    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // debouce update chart for optimization
  const updateChartDebounce = useCallback(
    _.debounce((data) => {
      chartRef.current.data = data;
      setGraphData(data);
      // try {
      //   chartRef.current.validateData();
      //   chartRef.current.series.each((series) => {
      //     series.appear();
      //   });
      // } catch (e) {
      //   // eslint-disable-next-line no-console
      //   console.log('Error Updating graph', e);
      // }
    }, 1000), [],
  );

  useLayoutEffect(() => {
    if (!_.isEqual(ganttChartData, graphData)) {
      if (debounceUpdate) {
        updateChartDebounce(ganttChartData);
      } else {
        chartRef.current.data = ganttChartData;
      }
    }
  }, [ganttChartData, debounceUpdate, updateChartDebounce, graphData]);

  return (
    <>
      <KnGanntChartDateRange component={KnSubtleText}>
        {translate(
          i18nKey('selectedDaysRange'),
          { daysRange: (zoomRange.max - zoomRange.min) + 1 },
        )}
      </KnGanntChartDateRange>
      <KnGanttChartContainer id={chartId} datalength={_.keys(_.groupBy(graphData, 'name')).length || 1} />
    </>
  );
};

GanttDayChart.defaultProps = {
  showAddMedicaion: false,
  debounceUpdate: true,
};

GanttDayChart.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  chartId: PropTypes.string.isRequired,
  showAddMedicaion: PropTypes.bool,
  debounceUpdate: PropTypes.bool,
};

export default GanttDayChart;
