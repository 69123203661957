/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import titrationActions from 'redux/actions/titrationActions';
import patientActions from 'redux/actions/patientActions';
import { useTranslation } from 'react-i18next';
import KnPageContent from 'components/Content';
import KnBrightSheet from 'components/BrightSheet';
import KnButton from 'components/Button';
import { APP_PAGE_URLS, TITRATIONS_CONTEXT } from 'Constants';
import KnTitrationsList from 'components/titrations/TitrationsList';
import { useIsUserHospitalStaff } from 'utils/utils';
import KnAssignRegimenConfirmationCard from './AssignRegimenConfirmationCard';

const AssignPresetPage = (props) => {
  const { location, match, history } = props;

  /**
   * Default the router parameters in case the user
   * will navigate directly to the titration page.
   */
  const patientId = _.get(match, 'params.patientId', '');
  const patientInfo = _.get(location, 'state.patientInfo', null);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: titrationsList,
    error,
  } = useSelector((state) => state.titrationsList);
  const assignedMedications = useRef([]);
  const [successfulAssign, setSuccessfulAssign] = useState(false);
  const userIsHospitalStaff = useIsUserHospitalStaff();

  useEffect(() => {
    /**
     * This page depends on router parameters data to work as expected.
     * If these are missing, it means the page was accessed directly.
     * Redirect the user to the patient record page which is the
     * starting point of all patient related pages.
     */
    if (!location.state && patientId) {
      history.replace(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
    }
  }, [history, location, match, patientId]);

  useEffect(() => {
    dispatch(titrationActions.fetchTitrations(false));
  }, [dispatch]);

  const redoFetchTitrationsList = useCallback(() => {
    dispatch(titrationActions.fetchTitrations(false));
  }, [dispatch]);

  const assignPreset = useCallback((titrationData) => {
    dispatch(patientActions.assignRegimen(titrationData.medications, patientId))
      .then(
        () => {
          /** We need a copy of the medications to pass to the confirmation card through props. */
          assignedMedications.current = titrationData.medications;
          setSuccessfulAssign(true);
        },
        () => { /** Error notification done in action */ },
      );
  }, [dispatch, patientId]);

  const editBeforeAssignPreset = useCallback(({ medications }) => {
    history.push(
      APP_PAGE_URLS.assignRegimen.replace(':patientId', patientId),
      {
        patientName: _.get(patientInfo, 'firstName'),
        medications,
      },
    );
  }, [history, patientId, patientInfo]);

  const customRegimenButton = userIsHospitalStaff
  && (
    <KnButton
      data-testid="custom-regimen-button"
      route={APP_PAGE_URLS.assignRegimen.replace(':patientId', patientId)}
      routeParams={{ patientName: _.get(patientInfo, 'firstName') }}
    >
      <span>
        <big>+</big>
        {' '}
        {translate('ASSIGN_REGIMEN.customRegimenButton')}
      </span>
    </KnButton>
  );

  return (
    <KnPageContent>
      {!successfulAssign && (
        <KnBrightSheet
          title={translate('ASSIGN_REGIMEN.selectPresetTitle')}
          ActionComponent={customRegimenButton}
          subTitle={translate('ASSIGN_REGIMEN.subtitle')}
        >
          <KnTitrationsList
            context={TITRATIONS_CONTEXT.assignPreset}
            titrationsList={titrationsList}
            emptyMessageKey="TITRATIONS.emptyList"
            error={error}
            errorMessageKey="TITRATIONS.ERROR_MESSAGES.titrationsListError"
            onRetry={redoFetchTitrationsList}
            actions={{ onAssignPreset: assignPreset, onEditTitration: editBeforeAssignPreset }}
          />
        </KnBrightSheet>
      )}

      {successfulAssign && (
        <KnAssignRegimenConfirmationCard
          patientName={_.get(patientInfo, 'firstName')}
          patientId={patientId}
          medications={assignedMedications.current}
        />
      )}
    </KnPageContent>
  );
};

AssignPresetPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientInfo: PropTypes.shape().isRequired,
    }),
  }).isRequired,
};

export default AssignPresetPage;
