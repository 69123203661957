/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  busyCount: 0,
  notification: null,
  /** BE API version number */
  apiVersion: '-',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP_PUSH_BUSY:
      return {
        ...state,
        busyCount: state.busyCount + 1,
      };

    case ACTION_TYPES.APP_POP_BUSY:
      /**
       * We should never have a pop busy call without a previous
       * push busy call to end up with negative busyCount, but let's
       * be defensive.
       */
      return {
        ...state,
        busyCount: Math.max(state.busyCount - 1, 0),
      };

    case ACTION_TYPES.APP_PUSH_NOTIFICATION:
      return {
        ...state,
        notification: {
          messageKey: action.messageKey,
          messageRoute: action.messageRoute,
        },
      };

    case ACTION_TYPES.APP_POP_NOTIFICATION:
      return state.notification
        ? {
          ...state,
          notification: null,
        }
        : state;

    case ACTION_TYPES.APP_SET_API_VERSION:
      return {
        ...state,
        apiVersion: action.version,
      };

    default:
      return state;
  }
};

export { initialState };

export default appReducer;
