/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KnButton from 'components/Button';
import { useIsUserHospitalStaff, withKeyNamespace } from 'utils/utils';
import {
  ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Box, styled,
} from '@material-ui/core';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import palette from 'styles/colors';
import { format } from 'date-fns';
import Divider from '@material-ui/core/Divider';
import useDialog from 'components/dialog/DialogService';
import KnCheckIcon from 'components/icons/CheckIcon';
import patientActions from 'redux/actions/patientActions';
import { useDispatch } from 'react-redux';

const i18nKey = withKeyNamespace('SURVEY_ENGINE');

const expandIconColor = palette.primary;
const { bostonBlue, primary, green } = palette;

const KnButtonChange = styled(KnButton)({
  width: '35%',
});

const KnTypography = styled(Typography)({
  marginLeft: '100px',
  fontSize: '15px',
});

const KnTypography1 = styled(KnTypography)({
  marginLeft: '20px',
  marginTop: '-12px',
});

const KnDivider = styled(Divider)({
  marginTop: '16px',
  marginBottom: '15px',
});

const KnButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',

  '& > *:first-child': {
    marginBottom: theme.spacing(1),
  },
}));

const KnMedicationPanelSummaryUpdate = styled(ExpansionPanelSummary)({
  border: 'none',
  '& .MuiExpansionPanelSummary-expandIcon': {
    position: 'absolute',
    top: 15,
    right: 31,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});

const KnReportDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 1135,
    height: 735,
  },
});

const KnStrengthDialog = ({
  open,
  onClose,
  inputData,
}) => {
  const {
    survey,
    endSurveyAssignment,
    hideSchedule,
  } = inputData;
  const { finalData = {}, assignedSurveys } = survey;
  const {
    surveyDates = [], takenSurveys = [], surveyID, patientId, id, surveyId,
  } = finalData;
  const { t: translate } = useTranslation();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const remainingTakenArray = _.sortBy(takenSurveys, ['id']).reverse();
  const checkComplete = useCallback((first, second) => {
    for (let index = 0; index < takenSurveys.length; index += 1) {
      const element = takenSurveys[index];
      const takenSurveyTimestamp = new Date(element.takenDate).getTime();
      const scheduledBool = (first < takenSurveyTimestamp) && (takenSurveyTimestamp < second);
      if (scheduledBool) {
        _.remove(remainingTakenArray, (n) => n.id === element.id);
        return element;
      }
    }
    return false;
  }, [remainingTakenArray, takenSurveys]);

  const [arr, setArray] = useState([]);
  const [oneTime, setOneTime] = useState(true);
  useEffect(() => {
    const array = [];
    if (oneTime) {
      for (let index = 0; index < surveyDates.length; index += 1) {
        if (index < surveyDates.length && new Date(surveyDates[index]) <= new Date()) {
          const first = surveyDates[index];
          const second = (index + 1 < surveyDates.length) ? surveyDates[index + 1] : new Date();
          const check = checkComplete(new Date(first).getTime(), new Date(second).getTime());
          if (check) {
            array.push({
              date: first,
              complete: !!(check),
              ...check,
            });
          } else {
            array.push({
              date: first,
              complete: !!(check),
            });
          }
        }
      }
      let arry = [...array, ...remainingTakenArray];
      if (arry) arry = _.sortBy(arry, ['date', 'takenDate']).reverse();
      setArray(arry);
      setOneTime(false);
    }
  }, [arr, arr.length, checkComplete, oneTime, remainingTakenArray, surveyDates]);

  const onEndSurveyAssignment = useCallback(() => {
    dialog({
      title: translate(i18nKey('removeMedicationConfirmation.title')),
      description: translate(i18nKey('removeMedicationConfirmation.content')),
      submitLabel: translate(i18nKey('removeMedicationConfirmation.deleteButton')),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      endSurveyAssignment(surveyID, patientId, surveyId);
    }).catch(() => {
      dialog({
        customDialog: KnStrengthDialog,
        inputData: {
          patientId, survey, endSurveyAssignment,
        },
      });
    });
  }, [dialog, endSurveyAssignment, patientId, survey, surveyID, surveyId, translate]);

  const findQuestion = ({ index, subIndex }) => {
    const quesObj = _.find(finalData.questions, { index });
    if (subIndex && quesObj.subQuestions && quesObj.question) {
      const subQuesObj = _.find(quesObj.subQuestions, { index: subIndex });
      return `${quesObj.question}  ${subQuesObj.question}`;
    }
    return quesObj.question;
  };

  const findAnswer = ({ answer }) => answer;

  const historyLogDiv = (val) => {
    const {
      complete = true, date = null, score,
    } = val;
    let deactivated = false;
    let canceledDate = null;
    if (surveyID) {
      const assignedObj = (assignedSurveys)
        ? _.find(assignedSurveys, { id: surveyID }) : { status: undefined };
      const { status = undefined, updatedAt } = assignedObj;
      if (status === 'deactivated') {
        deactivated = true;
        canceledDate = updatedAt;
      }
    }
    return (
      <>
        <ExpansionPanel>
          <KnMedicationPanelSummaryUpdate
            aria-controls="panel1c-content"
            id="panel1c-header"
            expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
          >
            <Box>
              <Box display="flex">
                {!complete ? (
                  <Box
                    width="9px"
                    height="2px"
                    margin="9px 10px 25px 0"
                    style={{ background: 'black' }}
                  />
                ) : (
                  <Box margin="0px 4px 8px 0">
                    <KnCheckIcon fill={green.dark} width="16px" />
                  </Box>
                )}
                <Typography
                  fontSize="15px"
                  height="14px"
                  flexGrow="0"
                  margin="0 84px 4px 0"
                >
                  {`${translate(i18nKey('survey'))} ${complete ? 'Completed' : 'not completed'}`}
                </Typography>
                {complete && (
                <Box display="flex" ml="10px">
                  <Typography>{format(new Date(val.takenDate), 'MM/dd')}</Typography>
                  <Typography style={{ marginLeft: '8px' }}>{new Date(val.takenDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}</Typography>
                </Box>
                )}
                <Box>
                  <KnTypography style={{ marginLeft: (complete) ? '100px' : '190px' }}>
                    {`${translate(i18nKey('assigned'))}: ${date ? `${format(new Date(date), 'MM/dd/yyyy')}` : '--'}`}
                  </KnTypography>
                </Box>
                { (deactivated) && (
                <Box>
                  <KnTypography style={{ marginLeft: '35px' }}>
                    {`${translate(i18nKey('canceled'))}: ${canceledDate ? `${format(new Date(canceledDate), 'MM/dd/yyyy')}` : '--'}`}
                  </KnTypography>
                </Box>
                )}
              </Box>
              <KnTypography1>
                {`${translate(i18nKey('score'))}: ${(complete && score !== null) ? `${score} out of ${finalData.totalScore}` : 'N/A'}`}
              </KnTypography1>
            </Box>
          </KnMedicationPanelSummaryUpdate>
          <ExpansionPanelDetails>
            {complete && (
            <Box>
              {val.answers.map((ques, index) => (
                <Box mb="5px">
                  <Box ml="48px" display="flex">
                    <Typography>
                      {index + 1}
                    </Typography>
                    <Typography style={{ marginRight: '6px' }}>
                      .
                    </Typography>
                    <Typography>
                      {`${translate(i18nKey('question'))}`}
                    </Typography>
                    <Typography style={{ marginRight: '8px' }}>
                      :
                    </Typography>
                    <Typography>
                      {findQuestion(ques)}
                    </Typography>
                  </Box>
                  <Box ml="67px" display="flex">
                    <Typography>
                      {translate(i18nKey('answer'))}
                    </Typography>
                    <Typography style={{ marginRight: '14px' }}>
                      :
                    </Typography>
                    <Typography>
                      {findAnswer(ques)}
                    </Typography>
                    <Typography>
                      .
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  };


  /** We have this handle for the 'Set tablet strength' button,
   * as button is outside the form component (due to the Dialog layout)
   */

  const returnFrequency = ({ frequency }) => frequency.replace(/_/g, ' ');
  const canEndSurvey = useIsUserHospitalStaff();

  const dateUpdates = () => (
    <Box width="96%" display="flex" flexDirection="column" fontWeight="500" fontSize="12px" mt="5px" mb="20px" ml="30px">
      <Box mb="3px">
        {`${translate(i18nKey('activeSurvey.startDate'))} : ${finalData.startDate ? format(new Date(finalData.startDate), 'MM/dd/yyyy') : '-'}`}
      </Box>
      <Box mb="3px">
        {`${translate(i18nKey('activeSurvey.endDate'))} :  ${finalData.endDate ? format(new Date(finalData.endDate), 'MM/dd/yyyy') : '-'}`}
      </Box>
      <Box mb="3px">
        {`${translate(i18nKey('activeSurvey.frequency'))} : ${returnFrequency(finalData)}`}
      </Box>
    </Box>
  );

  if (open) {
    return (
      <KnReportDialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        <DialogTitle data-testid="dialog-title" onClose={onClose}>
          <Box display="flex" justifyContent="space-between">
            {translate(i18nKey('title'))}
          </Box>
        </DialogTitle>
        <DialogContent data-testid="dialog-content">

          {/** We don't call handleSubmit(onSubmit) directly,
           * as we want to convert medicationStrength from string to Number first,
           * which is done by `submitStrength`
           */}
          <form>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Box fontWeight="bold" fontSize="18px" style={{ textTransform: 'capitalize' }}>
                  {`${(finalData.surveyType) ? finalData.surveyType : ''} ${translate(i18nKey('survey'))} (${surveyId})`}
                </Box>
                {finalData.surveyPdfUrl && (
                  <Box
                    fontSize="14px"
                    color={bostonBlue}
                    margin="3px 10px 3px 10px"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(patientActions.callPdfFunction(id || 1));
                    }}
                  >
                    {translate(i18nKey('surveyDetailsPDFButton'))}
                  </Box>
                )}
              </Box>
              <KnDivider />
              {!hideSchedule && (
                <>
                  <Box>
                    <Box width="100%" fontWeight="bold" fontSize="16px">
                      {translate(i18nKey('surveySchedule'))}
                    </Box>
                    {!finalData.active ? (
                      <Box width="100%" fontWeight="500" fontSize="12px" mt="5px" mb="44px" ml="30px">
                        {translate(i18nKey('noActiveSurvey'))}
                      </Box>
                    ) : (
                      <Box>
                        {canEndSurvey && (
                        <Box
                          onClick={onEndSurveyAssignment}
                          display="flex"
                          style={{ cursor: 'pointer' }}
                          justifyContent="flex-end"
                          marginTop="-20px"
                          color={primary}
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {translate(i18nKey('endSurveyAssignment'))}
                        </Box>
                        )}
                        {dateUpdates()}
                      </Box>
                    )}
                  </Box>
                  <KnDivider />
                </>
              )}
              <Box>
                {arr.map((val) => (historyLogDiv(val)))}
              </Box>
            </Box>
          </form>


        </DialogContent>
        <DialogActions data-testid="dialog-actions">
          <KnButtonsWrapper>
            <KnButtonChange
              onClick={onClose}
              data-testid="dialog-submit-button"
            >
              {translate(i18nKey('closeText'))}
            </KnButtonChange>
          </KnButtonsWrapper>
        </DialogActions>
      </KnReportDialog>
    );
  }

  return null;
};

KnStrengthDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputData: PropTypes.shape({
    medicationStrength: PropTypes.number,
    medicationType: PropTypes.string,
    medicationTypeUnit: PropTypes.string,
    survey: PropTypes.shape(),
    endSurveyAssignment: PropTypes.shape(),
    hideSchedule: PropTypes.bool,
  }),
};

KnStrengthDialog.defaultProps = {
  inputData: {
    medicationStrength: undefined,
    medicationType: undefined,
    medicationTypeUnit: undefined,
    survey: undefined,
    endSurveyAssignment: () => {},
    hideSchedule: false,
  },
};

export default KnStrengthDialog;
