/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import { detectIEEdge } from 'utils/utils';

const htmlEULA = require('assets/templates/EULA');

const template = { __html: htmlEULA };

const KnBoldSectionHeaderSignUp = styled(KnBoldSectionHeader)({
  marginBottom: 24,
});

const EULAWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '75vh',
});

const EULAStep = ({ onSuccess, eulaHandleBack }) => {
  const { t: translate } = useTranslation();
  const [isScrollEnd, setIsScrollEnd] = useState(false);

  const onSubmit = () => {
    onSuccess({});
  };

  const handleScroll = ({ target }) => {
    if (detectIEEdge()) {
      const oneTest = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
      const secondTest = (
        window.innerHeight + Math.ceil(window.scrollY)
      ) >= document.body.offsetHeight;
      setIsScrollEnd(oneTest || secondTest);
      return;
    }
    setIsScrollEnd(Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight);
  };

  return (
    <EULAWrapper>
      <Typography variant="h6" component={KnBoldSectionHeaderSignUp}>
        {translate('REGISTER.eulaTitle')}
      </Typography>
      <Box>
        <p>
          <strong>Nile AI, Inc. Platform End User License Agreement</strong>
        </p>
        <p>
          Last Updated March 14, 2021.
        </p>
      </Box>
      <Box style={{ overflow: 'auto' }} onScroll={handleScroll}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={template} />
      </Box>
      <Box
        pt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <KnButton
          variant="text"
          type="button"
          onClick={eulaHandleBack}
          data-testid="eula-login-link-button"
        >
          {translate('REGISTER.backToLogin')}
        </KnButton>
        <KnButton
          data-testid="eula-agree-button"
          onClick={onSubmit}
          disabled={!isScrollEnd}
        >
          {translate('REGISTER.agree')}
        </KnButton>
      </Box>
    </EULAWrapper>
  );
};

EULAStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  eulaHandleBack: PropTypes.func.isRequired,
};

export default EULAStep;
