/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';

export default {
  MuiPickersBasePicker: {
    root: {
      backgroundColor: palette.white.white2,
    },
  },
  MuiPickersDesktopDateRangeCalendar: {
    root: {
      backgroundColor: palette.white.white2,
      '& .MuiPickersDay-root': {
        borderRadius: '50%',
      },
    },
  },
  MuiPickersDateRangePickerInput: {
    root: {
      '& .MuiFormHelperText-root': {
        minHeight: 0,
      },
    },
  },
  MuiPickersDay: {
    root: {
      borderRadius: 0,
      color: palette.brownishGrey,
      MuiSelected: {
        color: palette.white.white2,
      },
      '&.Mui-disabled': {
        color: palette.white.white3,
      },
    },
  },
};
