/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnPreviousIcon = ({ ...rest }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" {...rest}>
    <path
      d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
      fill="black"
    />
  </svg>
);

export default KnPreviousIcon;
