/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { appInsights } from 'appInsights';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { KnHighlightText, KnLightText } from 'components/Typography';
import KnButton from 'components/Button';
import { KnActionLink } from 'components/Link';
import KnClearIcon from 'components/icons/ClearIcon';
import KnPlusIcon from 'components/icons/PlusIcon';
import KnPageContent from 'components/Content';
import KnBrightSheet from 'components/BrightSheet';
import { KnUnlabeledTextField, KnContrastTextField } from 'components/TextField';
import KnTitrationItem from 'components/titrations/TitrationItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import KnValidatedTextField from 'components/ValidatedTextField';
import KnSuccessConfirmationCard from 'components/SuccessConfirmationCard';
import patientActions from 'redux/actions/patientActions';
import thresholdActions from 'redux/actions/thresholdActions';
import { v4 as uuidv4 } from 'uuid';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import { getDangerousMedicationsSymptomPresets, flatSymptomsPresetsBySeverity } from 'utils/thresholds';
import { withKeyNamespace } from 'utils/utils';
import MenuItem from '@material-ui/core/MenuItem';
import {
  APP_PAGE_URLS,
  INTEGER_POSITIVE_VALUE_FORMAT,
  TITRATIONS_CONTEXT,
  SYMPTOM_MAX_SEVERITY,
  TRACKING_EVENTS,
} from 'Constants';
import { KnElevatedBrightBox } from 'styles/common';
import {
  KnThresholdButtonsBox,
  KnSecondaryActionButton,
  KnSeizureThresholdFieldsBox,
  KnSymptomsThresholdFieldsBox,
} from './styles';

const i18nKey = withKeyNamespace('THRESHOLD');

const symptoms = refDataService.getList(REFERENCE_DATA_TYPES.sideEffect);
const severities = _.range(1, SYMPTOM_MAX_SEVERITY + 1);

const KnSeizureThreshold = (props) => {
  const { formControls, onRemove, seizure } = props;
  const { t: translate } = useTranslation();

  /** This adds the seizures word at the end of the text field. */
  const SeizureInputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        {translate(i18nKey('seizure.suffix.seizure_plural'))}
      </InputAdornment>
    ),
  }), [translate]);

  /** This adds the days word at the end of the text field. */
  const DaysInputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        {translate(i18nKey('seizure.suffix.day_plural'))}
      </InputAdornment>
    ),
  }), [translate]);

  return (
    <>
      <Typography component={KnLightText}>
        {translate(i18nKey('hint'))}
      </Typography>
      <Box display="flex" pt={2}>
        <KnSeizureThresholdFieldsBox display="flex">
          <KnValidatedTextField
            required
            disableLabel
            trimSpaces
            name="seizures"
            defaultValue={_.get(seizure, 'count', '')}
            format={INTEGER_POSITIVE_VALUE_FORMAT}
            Component={KnUnlabeledTextField}
            InputProps={SeizureInputProps}
            control={formControls.control}
            errors={formControls.errors}
            placeholder={translate(i18nKey('seizure.seizurePlaceholder'))}
          />
          <KnValidatedTextField
            required
            disableLabel
            trimSpaces
            name="days"
            defaultValue={_.get(seizure, 'days', '')}
            format={INTEGER_POSITIVE_VALUE_FORMAT}
            Component={KnUnlabeledTextField}
            InputProps={DaysInputProps}
            control={formControls.control}
            errors={formControls.errors}
            placeholder={translate(i18nKey('seizure.dayPlaceholder'))}
          />
        </KnSeizureThresholdFieldsBox>
        <Box alignSelf="flex-start" pt={2}>
          <KnActionLink
            LhsIcon={KnClearIcon}
            onClick={onRemove}
            data-testid="remove-seizure-threshold-link"
          >
            {translate('GENERAL.remove')}
          </KnActionLink>
        </Box>
      </Box>
    </>
  );
};

const KnSymptomThreshold = (props) => {
  const {
    formControls,
    onSettingsChange,
    onRemove,
    symptom,
    index,
  } = props;
  const { t: translate } = useTranslation();

  const symptomFieldName = useRef(`symptomName-${symptom.keyId}`);

  const onFieldsChange = useCallback(({ target: { value } }, fieldName) => {
    onSettingsChange(symptom.keyId, { [fieldName]: value });
    return value;
  }, [onSettingsChange, symptom.keyId]);

  const validateSymptomsUniqueness = useRef((value) => {
    const symptomFieldValues = _.filter(
      formControls.control.getValues(),
      (fieldValue, fieldName) => (fieldValue && _.startsWith(fieldName, 'symptomName-')),
    );

    return (_.filter(symptomFieldValues, (symptomId) => (symptomId === value)).length === 1);
  });

  const reValidateOtherSymptomFields = useCallback(() => {
    /**
     * On symptom field blur or symptom remove, re-validate the other symptom fields
     * which are populated in case the user tries to fix a duplicated
     * error.
     */
    const otherSymptomFieldNames = Object.keys(_.pickBy(
      formControls.control.getValues(),
      (fieldValue, fieldName) => (fieldValue && _.startsWith(fieldName, 'symptomName-')
        && (fieldName !== symptomFieldName.current)),
    ));

    if (otherSymptomFieldNames.length) {
      formControls.triggerValidation(otherSymptomFieldNames);
    }
  }, [formControls]);

  const onRemoveHandler = useCallback(() => {
    onRemove(symptom.keyId);
    reValidateOtherSymptomFields();
  }, [onRemove, reValidateOtherSymptomFields, symptom.keyId]);

  const symptomsRules = useRef({
    validate: {
      uniqueSymptoms: validateSymptomsUniqueness.current,
    },
  });

  return (
    <Box mb={1}>
      <Box display="flex">
        <KnSymptomsThresholdFieldsBox display="flex">
          <KnValidatedTextField
            required
            select
            label={translate('FIELD_LABELS.sideEffect')}
            disableErrorMessage
            name={symptomFieldName.current}
            defaultValue={symptom.symptomId}
            onBlur={reValidateOtherSymptomFields}
            onChange={([e]) => onFieldsChange(e, 'symptomId')}
            Component={KnContrastTextField}
            rules={symptomsRules.current}
            dataTestId={`symptom-input-field-${index}`}
            control={formControls.control}
            errors={formControls.errors}
          >
            {symptoms.map(({ id, title }, optionIndex) => (
              <MenuItem key={id} value={id} data-testid={`symptom-option-${optionIndex + 1}`}>
                {title}
              </MenuItem>
            ))}
          </KnValidatedTextField>
          <KnValidatedTextField
            required
            select
            label={translate('FIELD_LABELS.severity')}
            disableErrorMessage
            name={`symptomSeverity-${symptom.keyId}`}
            defaultValue={symptom.severity}
            onChange={([e]) => onFieldsChange(e, 'severity')}
            Component={KnContrastTextField}
            dataTestId={`severity-input-field-${index}`}
            control={formControls.control}
            errors={formControls.errors}
          >
            {severities.map((value, optionIndex) => (
              <MenuItem key={value} value={value} data-testid={`severity-option-${optionIndex + 1}`}>
                {value}
              </MenuItem>
            ))}
          </KnValidatedTextField>
        </KnSymptomsThresholdFieldsBox>
        <Box alignSelf="flex-start" pt={2}>
          <KnActionLink
            LhsIcon={KnClearIcon}
            onClick={onRemoveHandler}
            data-testid={`remove-symptom-threshold-link-${index}`}
          >
            {translate('GENERAL.remove')}
          </KnActionLink>
        </Box>
      </Box>
      {(_.get(formControls.errors, `${symptomFieldName.current}.type`) === 'uniqueSymptoms') && (
        <FormHelperText error>{translate('FIELD_VALIDATION_MESSAGES.uniqueSymptoms')}</FormHelperText>
      )}
    </Box>
  );
};

const ThresholdSetupPage = (props) => {
  const { match, location, history } = props;

  /**
   * Default the router parameters in case the user
   * will navigate directly to this page.
   */
  const patientId = _.get(match, 'params.patientId', '');
  const activeRegimen = _.get(location, 'state.activeRegimen', { medications: [] });
  const patientName = _.get(location, 'state.patientName', '');
  const thresholds = _.get(location, 'state.thresholds', {});

  const { t: translate } = useTranslation();
  const [successfulSave, setSuccessfulSave] = useState(false);
  const [hasSeizureSettings, setHasSeizureSettings] = useState(!!_.get(thresholds, 'seizure'));
  /** Presets are needed when, there's an active regimen assigned and
   * a) there were no thresholds set yet OR
   * b) we just assigned the active regimen
   */
  const needsSideEffectPresets = location.state
    && activeRegimen.medications.length
    && (_.isEmpty(thresholds) || !activeRegimen.assignedAt);
  /** Logical flag reference for one-time fetching data */
  const shouldFetchPresets = useRef(needsSideEffectPresets);
  const { sideEffectPresets } = useSelector((state) => state.thresholds);
  const { isAdmin: admin, isPro } = useSelector((state) => state.user.currentUser);
  /** If this is a new regimen assignment
   * (meaning that 'medications' is not empty, but assignedAt is null)
   * clear the symptoms thresholds, otherwise keep what we have (if any)
   */
  const [symptomsSettings, setSymptomsSettings] = useState(
    activeRegimen.medications.length && !activeRegimen.assignedAt ? [] : thresholds.symptoms || [],
  );
  const {
    control,
    errors,
    triggerValidation,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(thresholdActions.clearThresholdPresets());
  }, [dispatch]);

  useEffect(() => {
    /**
     * This page depends on router parameters data to work as expected.
     * If these are missing, it means the page was accessed directly.
     * Redirect the user to the patient record page which is the
     * starting point of all patient related pages.
     */
    if ((!location.state && patientId) || admin) {
      history.replace(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
    }
  }, [admin, history, location, match, patientId]);

  useEffect(() => {
    /**
     * Only make the request if it's a legit access of the page
     * and the patient has an active regimen, but no thresholds.
     */
    if (shouldFetchPresets.current) {
      dispatch(thresholdActions.fetchThresholdPresets());
    }
    shouldFetchPresets.current = false;
  }, [dispatch, thresholds, location.state, activeRegimen.medications]);

  useEffect(() => {
    if (needsSideEffectPresets) {
      if (!sideEffectPresets.loading && sideEffectPresets.data) {
        const dangerousPresets = getDangerousMedicationsSymptomPresets(
          activeRegimen.medications,
          sideEffectPresets.data,
        );
        if (dangerousPresets.length) {
          setSymptomsSettings(flatSymptomsPresetsBySeverity(dangerousPresets));
        }
      }
    }
  }, [
    sideEffectPresets.loading,
    sideEffectPresets.data,
    setSymptomsSettings,
    activeRegimen.medications,
    needsSideEffectPresets,
  ]);

  const patientRecordAppPageUrl = APP_PAGE_URLS.patientRecord.replace(':patientId', patientId);

  const formControls = useMemo(() => ({
    control, errors, triggerValidation,
  }), [control, errors, triggerValidation]);

  const onSeizureThresholdRemove = useCallback(() => {
    setHasSeizureSettings(false);
    reset({
      seizures: '',
      days: '',
    });
  }, [reset]);

  const onAddSymptom = useCallback(() => {
    setSymptomsSettings((settings) => (
      settings.concat({
        keyId: uuidv4(),
        symptomId: '',
        severity: '',
      })
    ));
  }, [setSymptomsSettings]);

  const onRemoveSymptom = useCallback((symptomKeyId) => {
    setSymptomsSettings((settings) => (
      _.filter(settings, (symptom) => (symptom.keyId !== symptomKeyId))
    ));
  }, [setSymptomsSettings]);

  const onSymptomSettingsChange = useCallback((symptomKeyId, updates) => {
    setSymptomsSettings((settings) => (
      settings.map((symptom) => {
        if (symptom.keyId === symptomKeyId) {
          return { ...symptom, ...updates };
        }
        return symptom;
      })
    ));
  }, []);

  const onSubmit = useCallback(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.clickThresholdSave });
    triggerValidation().then((isValid) => {
      if (isValid) {
        const formData = control.getValues();
        const seizure = hasSeizureSettings
          ? {
            seizure: {
              count: formData.seizures, days: formData.days,
            },
          }
          : {};
        const sideEffects = { sideEffects: symptomsSettings };
        const data = { ...seizure, ...sideEffects };

        dispatch(patientActions.setThresholds(patientId, data)).then(() => {
          setSuccessfulSave(true);
        });
      }
    });
  }, [control, dispatch, hasSeizureSettings, patientId, symptomsSettings, triggerValidation]);

  return (
    <KnPageContent>
      {!successfulSave && (
        <>
          <KnBrightSheet
            title={translate(i18nKey('title'))}
            subTitle={translate(i18nKey('subtitle'))}
          >
            {(activeRegimen.medications.length > 0) && (
              <KnTitrationItem
                context={TITRATIONS_CONTEXT.assignedRegimen}
                titrationData={{
                  medications: activeRegimen.medications,
                  assignedAt: activeRegimen.assignedAt || new Date(),
                }}
              />
            )}

            <KnElevatedBrightBox pt={3} pb={3} pl={4} pr={4} mb={3}>
              <Typography variant="h6" component={KnHighlightText}>
                {translate(i18nKey('seizure.title'))}
              </Typography>

              <Box pt={1}>
                {hasSeizureSettings && (
                  <KnSeizureThreshold
                    formControls={formControls}
                    onRemove={onSeizureThresholdRemove}
                    seizure={_.get(thresholds, 'seizure', {})}
                  />
                )}

                {!hasSeizureSettings && (
                  <KnButton
                    type="button"
                    onClick={() => setHasSeizureSettings(true)}
                    data-testid="add-seizure-threshold-button"
                  >
                    <span>
                      <big>+</big>
                      {' '}
                      {translate(i18nKey('seizure.addThresholdButton'))}
                    </span>
                  </KnButton>
                )}
              </Box>
            </KnElevatedBrightBox>

            <KnElevatedBrightBox pt={3} pb={3} pl={4} pr={4} mb={3}>
              <Typography variant="h6" component={KnHighlightText}>
                {translate(i18nKey('symptoms.title'))}
              </Typography>

              <Box pt={1}>
                {(symptomsSettings.length > 0) && (
                  <>
                    <Typography component={KnLightText}>
                      {translate(i18nKey('hint'))}
                    </Typography>

                    <Box display="flex" pt={2} flexDirection="column" alignItems="flex-start">
                      {symptomsSettings.map((symptom, index) => (
                        <KnSymptomThreshold
                          formControls={formControls}
                          key={symptom.keyId}
                          onRemove={onRemoveSymptom}
                          onSettingsChange={onSymptomSettingsChange}
                          symptom={symptom}
                          index={index + 1}
                        />
                      ))}

                      <KnActionLink
                        LhsIcon={KnPlusIcon}
                        onClick={onAddSymptom}
                        data-testid="add-another-side-effect-button"
                        disabled={symptomsSettings.length === symptoms.length}
                      >
                        {translate(i18nKey('symptoms.addSideEffect'))}
                      </KnActionLink>
                    </Box>
                  </>
                )}

                {!symptomsSettings.length && (
                  <KnButton
                    type="button"
                    onClick={onAddSymptom}
                    disabled={sideEffectPresets.loading}
                    data-testid="add-symptoms-threshold-button"
                  >
                    <span>
                      <big>+</big>
                      {' '}
                      {translate(i18nKey('symptoms.addThresholdButton'))}
                    </span>
                  </KnButton>
                )}
              </Box>

            </KnElevatedBrightBox>
          </KnBrightSheet>

          <KnThresholdButtonsBox position="sticky" bottom="0" display="flex">
            <KnSecondaryActionButton type="button" route={patientRecordAppPageUrl}>
              {translate(i18nKey('backToPatientRecord'), { name: patientName })}
            </KnSecondaryActionButton>
            <KnButton type="button" onClick={onSubmit} data-testid="save-threshold-button" requiresPro={!isPro}>
              {translate(i18nKey('saveThreshold'))}
            </KnButton>
          </KnThresholdButtonsBox>
        </>
      )}

      {successfulSave && (
        <KnSuccessConfirmationCard
          message={translate(i18nKey('successfulSaveMessage'))}
          buttonLabel={translate(i18nKey('viewPatientRecord'), { name: patientName })}
          buttonProps={{
            route: patientRecordAppPageUrl,
            'data-testid': 'view-patient-record-button',
          }}
          data-testid="threshold-saved-confirmation-card"
        />
      )}
    </KnPageContent>
  );
};

KnSeizureThreshold.propTypes = {
  formControls: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
  seizure: PropTypes.shape().isRequired,
};

KnSymptomThreshold.propTypes = {
  formControls: PropTypes.shape().isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  symptom: PropTypes.shape({
    keyId: PropTypes.string.isRequired,
    symptomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    severity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

ThresholdSetupPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeRegimen: PropTypes.shape(),
      patientName: PropTypes.string,
      thresholds: PropTypes.shape(),
    }),
  }).isRequired,
};

export default ThresholdSetupPage;
