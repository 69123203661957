/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { translate } from 'i18n/i18n';
import { styled } from '@material-ui/core/styles';
import { downloadPDF, toDataURL, withKeyNamespace } from 'utils/utils';
import calendarActions from 'redux/actions/calendarActions';
import KnButton from 'components/Button';
import patientActions from 'redux/actions/patientActions';
import { KnDarkGreyText } from 'components/Typography';
import KnPageContent from '../../../components/Content';
import { KnPatientEmrRecordBox } from '../styles';
import logo from '../../../assets/images/nileLogo84x48.png';
import {
  KnInsuranceHTMLArea, KnLogo, StyledBox, StyledPre,
} from './PatientEmrExport.styles';
import usePatientText from './usePatientText';


export const Title = styled(KnDarkGreyText)({
  textTransform: 'uppercase',
  fontFamily: 'monospace',
  display: 'block',
});

export const SubTitle = styled(KnDarkGreyText)({
  fontFamily: 'monospace',
  display: 'block',
});

export const InfoBox = styled(Box)({
  paddingLeft: 16,
});

export const CardImg = styled('img')({
  width: '100%',
  marginTop: 8,
});

/**
 * Page used for exporig emr report
 */
const i18nKey = withKeyNamespace('PATIENT_RECORD.patientReports');
const ConsentExport = () => {
  const dispatch = useDispatch();
  const [frontCard, setFrontCard] = useState('');
  const [backCard, setBackCard] = useState('');
  const patientText = usePatientText(undefined, true, true);
  const params = useParams();

  const insurance = useSelector((state) => state.calendar.insurance.data);

  useEffect(() => {
    if (params) {
      dispatch(patientActions.fetchPatientInfo(params.patientId));
      dispatch(calendarActions.getPatientInsurance(params.patientId));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const run = async () => {
      const front = await toDataURL(insurance.frontImage && insurance.frontImage.url);
      setFrontCard(front);
      const back = await toDataURL(insurance.backImage && insurance.backImage.url);
      setBackCard(back);
    };

    if (insurance) {
      dispatch(calendarActions.updateInsuranceFlag(insurance.id));
      run();
    }
  }, [dispatch, insurance]);

  const getTextInt = useCallback((str, options) => translate(i18nKey(`insuranceReport.${str}`), options), []);

  const body = useMemo(() => {
    if (!insurance) return null;
    return (
      <>
        <Box mb={3}>
          <Title>
            {getTextInt('infoTitle')}
            :
          </Title>
          <Box mt={2}>
            <SubTitle>{getTextInt('patientUpdated', { date: new Date(insurance.updatedAt) })}</SubTitle>
          </Box>
          <Box mt={3} className={insurance.hasInsurance && (insurance.frontImage || insurance.backImage) ? 'beforeClass' : null}>
            <SubTitle>
              {getTextInt('insurance')}
              :
            </SubTitle>
            {!insurance.hasInsurance && (
              <InfoBox pb={2}>
                <SubTitle>
                  {getTextInt('noInsurance')}
                </SubTitle>
              </InfoBox>
            )}
            {(insurance.frontImage || insurance.backImage) && (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Box width="50%">
                  <Box mr={1}>
                    <SubTitle>
                      {getTextInt('cardFront')}
                    </SubTitle>
                    {(insurance.frontImage && frontCard) && <CardImg src={frontCard} alt="Front Card" />}
                  </Box>
                </Box>
                <Box width="50%">
                  <Box ml={1}>
                    <SubTitle>
                      {getTextInt('backFront')}
                    </SubTitle>
                    {(insurance.backImage && backCard) && <CardImg src={backCard} alt="Front Card" />}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box mb={3}>
          <Title>
            {getTextInt('secondaryInsuranceTitle')}
            :
          </Title>
          {!insurance.hasSecondaryInsurance && (
            <InfoBox>
              <SubTitle>{getTextInt('noSecondaryInsurance')}</SubTitle>
            </InfoBox>
          )}
          {insurance.hasSecondaryInsurance && (
            <InfoBox>
              <SubTitle>{getTextInt('secondaryInsurance')}</SubTitle>
            </InfoBox>
          )}
        </Box>
        <Box mb={3}>
          <Title>
            {getTextInt('relation')}
            :
          </Title>
          <InfoBox>
            {!insurance.hasInsurance ? (
              <SubTitle>No Insurance</SubTitle>
            ) : (
              <SubTitle>{get(insurance, 'realtionShip[0].title.en')}</SubTitle>
            )}
          </InfoBox>
        </Box>

        <Box mb={3}>
          <Title>
            {getTextInt('addressTitle')}
            :
          </Title>
          <InfoBox>
            <SubTitle>
              Address:
              {' '}
              {insurance.street}
            </SubTitle>
            <SubTitle>
              Address:
              {' '}
              {insurance.addressExtra}
            </SubTitle>
            <SubTitle>
              City:
              {' '}
              {insurance.city}
            </SubTitle>
            <SubTitle>
              Zip Code:
              {' '}
              {insurance.zipCode}
            </SubTitle>
            <SubTitle>Country: US</SubTitle>
          </InfoBox>
        </Box>
      </>
    );
  }, [getTextInt, insurance, frontCard, backCard]);


  return (
    <KnPageContent>
      <KnPatientEmrRecordBox mb={2} padding={3} pb={0} id="divToPrint">
        <Box display="flex" height="calc(100vh - 20px)" minHeight="890px">
          <Box flex="3 1 0">
            <Box position="relative">
              <div>
                <StyledPre style={{ paddingLeft: 16 }}>{patientText}</StyledPre>
                <KnInsuranceHTMLArea style={{ height: '645px !important' }}>
                  {body}
                </KnInsuranceHTMLArea>
              </div>
              <div style={{ display: 'none' }}>
                <div id="reportPDF" style={{ width: 1000 }}>
                  <>
                    <div style={{ textAlign: 'right' }}><KnLogo src={logo} alt="" /></div>
                    <StyledPre style={{ paddingLeft: 16 }}>{patientText}</StyledPre>
                  </>
                  <StyledBox style={{ padding: 16 }}>
                    {body}
                  </StyledBox>
                </div>
              </div>
              <Box display="flex" mt={2} mb={0} justifyContent="end">
                <Box display="flex" alignItems="center">
                  <KnButton
                    data-testid="download-pdf-button"
                    onClick={() => downloadPDF(
                      { before: '.beforeClass', after: '.afterClass', avoid: ['p'] }, 'insurance',
                    )}
                  >
                    {translate(i18nKey('downloadPDF'))}
                  </KnButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </KnPatientEmrRecordBox>
    </KnPageContent>
  );
};

export default ConsentExport;
