/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KnPageContent from 'components/Content';
import { titrationMedsMigrate, withKeyNamespace } from 'utils/utils';
import { TITRATIONS_CONTEXT, APP_PAGE_URLS } from 'Constants';
import titrationActions from 'redux/actions/titrationActions';
import patientActions from 'redux/actions/patientActions';
import KnTitrationForm from 'components/titrations/TitrationForm';
import KnAssignRegimenConfirmationCard from './AssignRegimenConfirmationCard';
import KnPatientInfomation from './PatientInformation';
import { KnMedicationWrapper } from './styles';

const i18nKey = withKeyNamespace('ASSIGN_REGIMEN');

/** Page used for asigning a regimen,
 * or updating (editing) an assigned regimen
 */
const AssignRegimenPage = (props) => {
  const { location, match, history } = props;
  const dispatch = useDispatch();
  const { isAdmin: admin } = useSelector((state) => state.user.currentUser);

  /**
   * Default the router parameters in case the user
   * will navigate directly to the titration page.
   */
  const patientId = _.get(match, 'params.patientId', '');
  const presetId = _.get(match, 'params.presetId', '');
  const regimenId = _.get(match, 'params.regimenId', '');
  const patientName = _.get(location, 'state.patientName', '');
  const patientType = _.get(location, 'state.patientType', '');
  const medications = titrationMedsMigrate(_.get(location, 'state.medications', []));
  const fromPage = _.get(location, 'state.from', '');
  const edit = _.get(location, 'state.edit', false);
  const note = _.get(location, 'state.note', '');
  const status = _.get(location, 'state.status', '');
  const hcpNote = _.get(location, 'state.hcpNote', '');
  const patientNames = (patientName) ? `${patientName}'s` : '';

  const { t: translate } = useTranslation();
  const [successfulSave, setSuccessfulSave] = useState(false);
  const assignedMedications = useRef(null);

  const {
    regimenList,
    titrations,
  } = useSelector((state) => ({
    regimenList: state.patientRecord.regimens,
    titrations: state.titrationsList,
  }));

  const { data: titrationsList } = titrations;
  const { data: regimens } = regimenList;

  useEffect(() => {
    /**
     * This page depends on router parameters data to work as expected.
     * If these are missing, it means the page was accessed directly.
     * Redirect the user to the patient record page which is the
     * starting point of all patient related pages.
     */
    if ((!location.state && patientId) || admin) {
      history.replace(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
    }
  }, [admin, history, location, match, patientId]);

  useEffect(() => {
    dispatch(titrationActions.fetchTitrations(true));
    if (patientType) {
      dispatch(patientActions.fetchMedicationRegimenDataInvitedPatient(patientId));
    } else dispatch(patientActions.fetchMedicationRegimenData(patientId));
  }, [dispatch, patientId, patientType]);

  const title = translate(i18nKey('patientMedicationRegimen'), { name: (patientNames) || '' });

  /** Called both on new assignment and editing an assignment */
  const submitRegimen = useCallback((data) => {
    /** We need a copy of the medications to pass to the confirmation card through props. */
    assignedMedications.current = data.medications;
    if (patientType) {
      return dispatch(patientActions
        .assignInvitedRegimen(data.medications, patientId, regimenId, data.note, data.hcpNote))
        .then(() => { setSuccessfulSave(true); });
    }
    return dispatch(
      patientActions.assignRegimen(data.medications, patientId, regimenId, data.note, data.hcpNote),
    )
      .then(() => { setSuccessfulSave(true); });
  }, [dispatch, patientId, regimenId, patientType]);

  return (
    <KnMedicationWrapper>
      <KnPageContent>
        {!successfulSave && (
          <>
            <KnPatientInfomation patientId={patientId} patientType={patientType} hideEdit={false} />

            <KnTitrationForm
              context={TITRATIONS_CONTEXT.assignRegimen}
              submitAction={submitRegimen}
              submitButtonLabel={translate(i18nKey('assignRegimenButton'))}
              title={title}
              defaultMedications={medications}
              preventCleanSubmit={!!regimenId}
              titrationsList={titrationsList || []}
              fromPage={fromPage}
              regimens={regimens || []}
              edit={edit}
              note={note}
              status={status}
              hcpNote={hcpNote}
            />
          </>
        )}


        {/* <KnAssignRegimentdBox mb={2} mt={2} height="175px">
          <Typography variant="h5" component={KnBoldSectionHeader}>
            {translate('TITRATIONS.notesToPatient')}
          </Typography> */}
        {/* <KnValidatedTextField
            Component={KnContrastTextField}
            name="addNotes"
            control={control}
            errors={errors}
            required
            trimSpaces
            // format={EMAIL_FORMAT}
            defaultValue=""
            maxLength={128}
          /> */}
        {/* </KnAssignRegimentdBox> */}


        {successfulSave && (
          <KnAssignRegimenConfirmationCard
            patientName={patientName}
            patientId={patientId}
            medications={assignedMedications.current}
            presetId={presetId}
            patientType={patientType}
          // assignedAt={assignedAt}
          />
        )}

      </KnPageContent>
    </KnMedicationWrapper>
  );
};

AssignRegimenPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientName: PropTypes.string.isRequired,
      medications: PropTypes.arrayOf(PropTypes.shape()),
      assignedAt: PropTypes.instanceOf(Date),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
      regimenId: PropTypes.string,
      presetId: PropTypes.string,
    }),
  }).isRequired,
};

export default AssignRegimenPage;
