/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import { translate } from 'i18n/i18n';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  APP_PAGE_URLS,
  TRACKING_EVENTS,
  PATIENTS_LIST_TYPES, REGIMEN_STATUS_LIST,
} from 'Constants';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnTable from 'components/Table';
import patientActions from 'redux/actions/patientActions';
import ACTION_TYPES from 'redux/actionTypes';
import InvitePatientToolbar from './InvitePatientToolbar';
import KnTableColumnsSwitcher from './TableColumnsSwitcher';
import { KnBrightBox } from '../../components/Content';
import userActions from '../../redux/actions/userActions';
import patientsListActions from '../../redux/actions/patientsListActions';
import { getTableColumns, KnSearchInput, LinkButton } from './PatientsListHelper';

const KnPatientsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    listType,
    list,
    total,
    params,
    physiciansList,
    healthSystemsList,
    showInviteToolbar,
  } = useSelector((state) => state.patientsList);
  const {
    sortBy,
    sortDir,
    page,
    limit,
    regimenStatus,
    physician,
    healthsystem,
    search,
  } = params;
  const {
    currentUser,
  } = useSelector((state) => state.user);

  // const USER_ID = currentUser.userId;
  const USER_IS_ADMIN = currentUser.isAdmin;
  // const USER_IS_PHYSICIAN = (currentUser.role
  //   && currentUser.role.id === HCP_USER_ROLES.physician && !USER_IS_ADMIN
  // );

  const fetchPatientsList = useCallback(() => {
    dispatch(patientsListActions.fetch(params, listType));
  }, [dispatch, params, listType]);

  useEffect(() => {
    dispatch(patientsListActions.fetchPhysicians());
    if (USER_IS_ADMIN) {
      dispatch(patientsListActions.fetchHealthSystems());
    }
  }, [USER_IS_ADMIN, dispatch]);

  useEffect(() => {
    fetchPatientsList();
  }, [fetchPatientsList, listType, params]);

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientList });
  }, []);

  const onTableRowClick = useCallback((row) => {
    if (listType === PATIENTS_LIST_TYPES.UNLINKED) return;
    dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_RECORD });
    dispatch(patientActions.setPatientInfo(row));
    history.push(
      APP_PAGE_URLS.patientRecord.replace(':patientId', row.patientId),
      {
        patientType: listType === PATIENTS_LIST_TYPES.INVITED,
        fromRefractory: listType === PATIENTS_LIST_TYPES.REFRACTORY,
        isNeverLinked: listType === PATIENTS_LIST_TYPES.NEVER_LINKED,
      },
    );
  }, [dispatch, history, listType]);

  const onTabChange = useCallback((e, value) => {
    dispatch(patientsListActions.setListType(value));
  }, [dispatch]);

  const searchTypingTimeout = useRef(0);
  const onSearchTermChange = useCallback(({ target: { value } }) => {
    clearTimeout(searchTypingTimeout.current);
    searchTypingTimeout.current = setTimeout(() => {
      dispatch(patientsListActions.search(value));
    }, 300);
  }, [dispatch]);

  const onReviewStatusClick = useCallback((linkId, newStatus) => {
    dispatch(patientsListActions.setReviewStatus(linkId, newStatus));
  }, [dispatch]);

  const commonTableColumns = ['lastName', 'firstName', 'dob', 'email', 'accountNumber'];
  if (USER_IS_ADMIN && listType !== PATIENTS_LIST_TYPES.NEVER_LINKED) {
    commonTableColumns.push('healthSystemName');
  }
  const tableColumns = useMemo(() => {
    switch (listType) {
      case PATIENTS_LIST_TYPES.VERIFIED:
        return getTableColumns([...commonTableColumns, 'physician', 'latestThresholdDate', 'latestRegimenStatus']);
      case PATIENTS_LIST_TYPES.UNLINKED:
        return getTableColumns([...commonTableColumns, 'dateOfDeactivation', 'isDeleted']);
      case PATIENTS_LIST_TYPES.INVITED:
        return getTableColumns([...commonTableColumns, 'physician', 'dateInvited', 'latestRegimenStatus']);
      case PATIENTS_LIST_TYPES.REFRACTORY:
        return getTableColumns([...commonTableColumns, 'physician', 'status'], onReviewStatusClick);
      case PATIENTS_LIST_TYPES.NEVER_LINKED:
        return getTableColumns([...commonTableColumns, 'lastActive']);
      default:
        return [];
    }
  }, [commonTableColumns, listType, onReviewStatusClick]);

  const onRequestSort = useCallback((sortKey) => {
    dispatch(patientsListActions.sort(sortKey));
  }, [dispatch]);

  const onInvitePatientClick = useCallback(() => {
    dispatch(patientsListActions.setShowInviteToolbar());
  }, [dispatch]);

  const defaultRefractoryColumns = ['dob', 'email', 'accountNumber'];
  const refractoryTableColumns = (currentUser.userPreferences
      && currentUser.userPreferences.refractoryTableColumns) || defaultRefractoryColumns;
  const [selectedTableColumns, setSelectedTableColumns] = useState(new Set(refractoryTableColumns));
  const handleTableColumnsSelect = useCallback((ids) => {
    setSelectedTableColumns(ids);
    dispatch(userActions.userPrefernce({
      userPreferences: [{
        key: 'refractoryTableColumns',
        value: [...ids],
      }],
    }));
  }, [dispatch]);

  return (
    <>
      <Typography variant="h4" component={KnBoldSectionHeader}>
        {translate('HOME.mainTitle')}
      </Typography>

      <Box pt={1} height={50}>
        <Typography variant="body2" data-testid="table-hint-message">{translate(`HOME.tableHintMessage.${listType}`)}</Typography>
      </Box>

      <Tabs value={listType} onChange={onTabChange} textColor="primary" indicatorColor="primary">
        {Object.values(PATIENTS_LIST_TYPES).filter((lType) => USER_IS_ADMIN || lType !== 'never_linked').map((lType) => (
          <Tab key={lType} label={translate(`HOME.tabs.${lType}`)} data-testid={`${lType}-patients-tab`} value={lType} />
        ))}
        {(process.env.REACT_APP_ALLOW_INVITE === 'true' && !showInviteToolbar)
        && (<LinkButton color="primary" onClick={onInvitePatientClick} data-testid="invite-patient-button">{translate('HOME.invitePatient')}</LinkButton>
        )}
      </Tabs>

      {showInviteToolbar && <InvitePatientToolbar />}

      <KnBrightBox
        display="flex"
        justifyContent="space-between"
        py={1}
        px={2}
        mt={4}
      >
        <KnSearchInput
          key={listType}
          onChange={onSearchTermChange}
          label={translate('GENERAL.search')}
          inputProps={{ 'data-testid': 'patients-search-input-field' }}
          defaultValue={search}
        />
        <KnTableColumnsSwitcher
          columns={
            tableColumns.filter((c) => c.switchable).map((c) => ({ id: c.sortKey, title: c.name }))
          }
          selected={selectedTableColumns}
          onSelect={handleTableColumnsSelect}
        />
      </KnBrightBox>

      <KnTable
        rowKeyAccessor="linkId"
        columns={tableColumns.filter((c) => (!c.switchable || selectedTableColumns.has(c.sortKey)))}
        data={list}
        emptyDataMessage={translate('HOME.patientTables.emptySearchResults')}
        onRequestSort={onRequestSort}
        sortBy={sortBy}
        sortDirection={sortDir}
        onRowClick={onTableRowClick}
        filters={{
          physician: {
            options: physiciansList.filter((p) => healthsystem.includes(p.healthSystemId) || !healthsystem.length).map((p) => ({ title: `${p.firstName} ${p.lastName}`, id: p.id })),
            onChange: (selected) => dispatch(patientsListActions.filterByPhysician(selected)),
            checked: physician,
          },
          healthSystemName: {
            options: healthSystemsList.map((h) => ({ title: h.name, id: h.id })),
            onChange: (selected) => dispatch(patientsListActions.filterByHealthSystem(selected)),
            checked: healthsystem,
          },
          latestRegimenStatus: {
            options: Object.entries(REGIMEN_STATUS_LIST).map(([id, title]) => ({ title, id })),
            onChange: (selected) => dispatch(patientsListActions.filterByRegimenStatus(selected)),
            checked: regimenStatus,
          },
        }}
        pagination={{
          rowsPerPage: limit,
          page,
          total,
          handleChangePage: (e, newPage) => dispatch(patientsListActions.setPage(newPage)),
          handleChangeRowsPerPage: (e) => dispatch(patientsListActions.setLimit(e.target.value)),
        }}
      />
    </>
  );
};

export default KnPatientsList;
