/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import KnPageContent, { KnBrightBox } from 'components/Content';
import { KnBoldSectionHeader } from 'components/Typography';
import { withKeyNamespace } from 'utils/utils';
import KnProfile from './Profile';
import KnPassword from './Password';

/**
 * Tabs are identified by index. Use these named constants
 * to demistify numeric values. If the tabs order changes,
 * these should be updated also.
 */
const PROFILE_TAB = 0;
const PASSWORD_TAB = 1;

/** References by tab index the component to display for each tab. */
const SettingsTabs = {
  [PROFILE_TAB]: KnProfile,
  [PASSWORD_TAB]: KnPassword,
};

const i18nKey = withKeyNamespace('ACCOUNT_SETTINGS');

const SettingsPage = () => {
  const { t: translate } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = useCallback((...args) => {
    /** First parameter is event; we only need the second parameter (tabIndex). */
    const [, tabIndex] = args;
    setSelectedTab(tabIndex);
  }, []);

  const KnSettingsTabContent = SettingsTabs[selectedTab];

  return (
    <KnPageContent>
      <Typography gutterBottom variant="h4" component={KnBoldSectionHeader}>
        {translate(i18nKey('title'))}
      </Typography>
      <Tabs value={selectedTab} onChange={handleChange} textColor="primary" indicatorColor="primary">
        <Tab
          data-testid="settings-tab"
          label={translate(i18nKey('tabs.profile'))}
        />
        <Tab
          data-testid="password-tab"
          label={translate(i18nKey('tabs.password'))}
        />
      </Tabs>

      <KnBrightBox display="flex" flexDirection="column" flex="1" p={2}>
        <KnSettingsTabContent />
      </KnBrightBox>

    </KnPageContent>
  );
};

export default SettingsPage;
