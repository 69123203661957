import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { styled } from '@material-ui/core/styles';
import { Box, Input, MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import palette from '../styles/colors';
import FilterIcon from './icons/FilterIcon';
import KnSearchIcon from './icons/SearchIcon';
import { translate } from '../i18n/i18n';

const KnCheckbox = styled(Checkbox)({
  transform: 'scale(1.3)',
  color: palette.primary,
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: palette.white.white2,
});

const StyledLi = styled('li')({
  backgroundColor: palette.white.white2,
  padding: 7,
  borderBottom: '1px solid #d4d5da',
});

const StyledInput = styled(Input)({
  padding: '0px 10px',
  backgroundColor: palette.white.white1,
});

const StyledMenuList = styled(MenuList)({
  maxHeight: 200,
  overflow: 'auto',
});

export default function KnTableColumnFilter({ options, onChange, checked }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selected, setSelected] = useState(new Set(checked));
  const isAllSelected = selected.size === options.length;
  const hasSelected = selected.size > 0;

  const handleToggleItem = (id) => {
    const newSelected = new Set(selected);
    if (newSelected.has(id)) newSelected.delete(id);
    else newSelected.add(id);
    setSelected(newSelected);
    onChange([...newSelected]);
  };

  const handleToggleAll = () => {
    const newSelected = new Set(hasSelected ? [] : options.map((o) => o.id));
    setSelected(newSelected);
    onChange([...newSelected]);
  };

  const filteredOptions = options.filter((o) => o.title.toLowerCase()
    .includes(searchTerm.toLowerCase()));

  const getCheckboxLabel = () => {
    if (!hasSelected) return 'selectAll';
    return isAllSelected ? 'deselectAll' : 'deselect';
  };

  if (options.length === 0) return null;

  return (
    <>
      <IconButton data-testid="physician-filter-button" onClick={handleClick}>
        <FilterIcon color={selected.size > 0 ? palette.primary : palette.paleGrey.paleGrey4} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <StyledLi onKeyDown={(e) => e.stopPropagation()}>
          <StyledInput
            placeholder={translate('HOME.tableFilter.search')}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            inputProps={{ 'data-testid': 'physician-filter-search-input' }}
            endAdornment={(
              <InputAdornment position="end">
                <KnSearchIcon fontSize="small" />
              </InputAdornment>
            )}
          />
        </StyledLi>
        {filteredOptions.length > 0 ? (
          [
            <StyledMenuItem data-testid="physician-filter-option-select-all-deselect" onClick={handleToggleAll}>
              <KnCheckbox checked={isAllSelected} indeterminate={hasSelected && !isAllSelected} color="primary" />
              <strong>{translate(`HOME.tableFilter.${getCheckboxLabel()}`)}</strong>
            </StyledMenuItem>,
            <StyledMenuList>
              {filteredOptions.map(({ id, title }, index) => (
                <StyledMenuItem key={id} data-testid={`physician-filter-option-${index + 1}`} onClick={() => handleToggleItem(id)}>
                  <KnCheckbox checked={selected.has(id)} color="primary" />
                  {title}
                </StyledMenuItem>
              ))}
            </StyledMenuList>,
          ]
        ) : (
          <StyledLi>
            <Box p={3} color={palette.paleGrey.paleGrey4} data-testid="no-filter-results">{translate('HOME.tableFilter.noEntries')}</Box>
          </StyledLi>
        )}
      </Menu>
    </>
  );
}

KnTableColumnFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.bool),
};

KnTableColumnFilter.defaultProps = {
  checked: [],
};
