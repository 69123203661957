/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';
import { API_REQUEST_METHODS, API_URLS } from 'Constants';
import thresholdsMappings from './mappingFunctions/thresholdsMappings';
import appActions from './appActions';
import apiRequest from './apiActions';

/**
 * Action to fetch the thresold presets list. Used to prepopulate the
 * side effect thresholds when certain medications are assigned to the patient.
 *
 */
const fetchThresholdPresets = () => {
  const request = () => ({ type: ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_SUCCESS, data });
  const error = () => ({ type: ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_ERROR });

  return async (dispatch) => {
    dispatch(request());

    return dispatch(
      apiRequest(
        API_REQUEST_METHODS.GET,
        API_URLS.thresholdPresets,
      ),
    ).then((response) => {
      dispatch(success(
        thresholdsMappings.mapServerThresholdPresetsToLocal(response.data),
      ));
    }).catch(() => {
      dispatch(error());
      dispatch(appActions.appPushNotification('THRESHOLD.ERROR_MESSAGES.thresholdPresetsFetchError'));
    });
  };
};

const clearThresholdPresets = () => ({ type: ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_CLEAR });

export default { clearThresholdPresets, fetchThresholdPresets };
