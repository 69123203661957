import React from 'react';
import {
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KnTextCopyIcon from 'components/icons/TextCopy';
import { KnContrastTextField } from 'components/TextField';
import { KnActionLink } from 'components/Link';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const KnContrastText = styled(KnContrastTextField)({
  '& .MuiFormLabel-root': {
    opacity: 0.5,
  },
});

const PatientTextCopy = ({ label, value }) => {
  const { t: translate } = useTranslation();

  const copyToClipboard = async (e) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(value);
  };

  return (
    <KnContrastText
      label={label}
      value={value}
      disabled
      disableErrorMessage
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <KnActionLink
              onClick={copyToClipboard}
              LhsIcon={() => <KnTextCopyIcon color={palette.black.black1} />}
              tooltip
              tooltipText={translate('ICON_TOOLTIP.copyText')}
              data-testid="copy-patient-email"
              style={{ marginLeft: -4 }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

PatientTextCopy.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

PatientTextCopy.defaultProps = {
};

export default PatientTextCopy;
