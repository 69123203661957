/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import arrowRight from 'assets/images/04062021_arrow_right_140px.svg';
import arrowDown from 'assets/images/04062021_arrow_down_140px.svg';
import { Trans } from 'react-i18next';
import palette from 'styles/colors';

const mailLinkColor = palette.teal;

const KnSearchResultText = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  fontSize: '18px',
});

const KnInLine = styled('div')({
  display: 'flex',
});

const Kndisplay = styled('p')({
  marginTop: '-36px',
  marginLeft: '36px',
});

/**
* Customizable drop down component
*/
const KnDisplayContent = (props) => {
  const {
    label,
    items,
    mailed,
  } = props;
  const [focused, setFocused] = useState(false);

  return (
    <Box>
      <KnInLine
        onClick={() => setFocused(!focused)}
      >
        <img src={(focused) ? arrowDown : arrowRight} marginLeft="4" width="17" height="25" alt="" />
        <KnSearchResultText
          paragraph
        >
          {label}
        </KnSearchResultText>
      </KnInLine>
      {focused && (
        <Kndisplay>
          {items.map((item) => (
            <p key={item}>
              -
              {item}
            </p>
          ))}
          <p>
            -
            <Trans
              i18nKey={mailed === 'mobile' ? 'REGISTER.cannotFindCode_array_phone_mail' : 'REGISTER.cannotFindCode_array_email_mail'}
              components={<a style={{ color: mailLinkColor }} href="mailto:support@nile.ai" title="mailto:support@nile.ai">univers</a>}
            />
          </p>
        </Kndisplay>
      )}
    </Box>
  );
};

KnDisplayContent.propTypes = {
  /** Placeholder and label for the drop down input field */
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  mailed: PropTypes.string.isRequired,
};

export default KnDisplayContent;
