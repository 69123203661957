import {
  useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { capFirstCharOnly, undisclosed } from 'utils/utils';
import { get } from 'lodash';
import { format } from 'date-fns';

const patient = 'PATIENT_RECORD.patientReports.patientReport';

const usePatientText = (isMedicalHistory, currentPatient, insuraceData) => {
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();
  const {
    emrReport,
    medicalHistoryStatus,
    patientInfo,
  } = useSelector((state) => state.patientRecord);
  const { patientUser = {} } = (
    currentPatient ? { patientUser: patientInfo.data || {} } : emrReport.data
  ) || {};
  const insurance = useSelector((state) => state.calendar.insurance.data);
  const diagnosedSince = capFirstCharOnly(
    get(patientUser, 'diagnosedWithEpilepsyDuration.value'),
  );

  const defaultText = useMemo(() => {
    let text = '';
    if (currentPatient) {
      const info = `${patient}.info`;
      text += `${translate(info)}:`.toUpperCase();
    } else {
      text += isMedicalHistory ? `${translate(`${patient}.medicalHistory`)}`.toUpperCase() : `${translate(`${patient}.title`)}`;
    }
    text += isMedicalHistory || currentPatient ? '\n' : '\n\n';
    if (!patientUser.lastActive) {
      return '';
    }
    text += `${patientUser.firstName} ${patientUser.lastName}\n`;
    if (!isMedicalHistory) {
      text += `${translate(`${patient}.gender`, { gender: undisclosed(patientUser.gender) })}\n`;
      text += `${translate(`${patient}.diagnose`, {
        title: undisclosed(insuraceData ? get(insurance, 'medicalHistory.diagnosed.answer') || diagnosedSince : diagnosedSince),
      })}\n`;
      text += `${translate(`${patient}.email`, { email: patientUser.email })}\n`;
      text += `${translate(`${patient}.phone`, {
        phone: undisclosed(insuraceData ? get(insurance, 'patientUser.phoneNumber') || patientUser.phoneNumber : patientUser.phoneNumber),
      })}`;
    }

    if (isMedicalHistory) {
      text += `${translate(`${patient}.date`, { date: get(medicalHistoryStatus, 'data.date') ? format(new Date(medicalHistoryStatus.data.date), 'MM-dd-yyyy') : '' })}\n`;
    }

    return text;
  }, [
    patientUser,
    translate,
    diagnosedSince,
    isMedicalHistory,
    medicalHistoryStatus,
    currentPatient,
    insurance,
    insuraceData,
  ]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText]);


  return inputText;
};

export default usePatientText;
