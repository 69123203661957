import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

const TimeDropDown = ({
  anchorEl, handleClose, handlChange, timeOptions,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'bottom',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: '30ch',
        },
      }}
    >
      {timeOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => handlChange(option)}
          style={{ border: 0 }}
        >
          <span style={{ width: 50 }}>{option.key}</span>
          <span style={{ paddingLeft: 30 }}>
            (
            <>{option.doctor}</>
            )
          </span>
        </MenuItem>
      ))}
    </Menu>
  );
};

TimeDropDown.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlChange: PropTypes.func.isRequired,
  timeOptions: PropTypes.instanceOf(PropTypes.array).isRequired,
};

export default TimeDropDown;
