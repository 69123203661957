module.exports = `<!DOCTYPE html>
<html>
<body>
<div class="WordSection1">
    <p>
        This End User License Agreement ("<strong>Agreement</strong>") sets forth the terms
        and conditions upon which you may download and use the Nile AI, Inc.
        ("Nile," "us," "our," or "we") platform, software application, related
        services, and all updates, enhancements, and upgrades provided to you
        (collectively, <strong>the "Platform</strong>") provided by Nile and/or its
        authorized distributors. "You", "your", "yourself" means you, as an
        individual, and the legal entity on behalf of which you are acting as the
        authorized agent or legal representative as identified by you during
        registration with the Platform, if applicable.
    </p>
    <p>
        PLEASE READ THIS AGREEMENT CAREFULLY IN ITS ENTIRETY BEFORE USING THE
        PLATFORM. IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, PLEASE DO NOT
        USE THE PLATFORM. THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER
        PROVISIONS THAT LIMIT NILE'S LIABILITY TO YOU. BY USING THE PLATFORM, YOU
        ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT, THE
        PROVISIONS, DISCLOSURES, AND DISCLAIMERS SET FORTH IN THIS AGREEMENT ARE
        FAIR AND REASONABLE, AND YOUR AGREEMENT TO FOLLOW AND BE BOUND BY THIS
        AGREEMENT IS VOLUNTARY AND IS NOT THE RESULT OF FRAUD, DURESS, OR UNDUE
        INFLUENCE EXERCISED UPON YOU BY ANY PERSON OR ENTITY.
    </p>
    <p>
        1. <strong><u>In General</u></strong><strong>.</strong> The terms of this Agreement will govern
        the Platform and any software updates, enhancements, and upgrades that
        replace and/or supplement the original Platform, unless such upgrade is
        accompanied by a separate license in which case the terms of that license
        will govern. You agree to comply with all terms, conditions, and
        restrictions set forth in this Agreement. You acknowledge that any use of
        the Platform not in compliance with this Agreement invalidates this license
        and may be prosecuted to the full extent of the law. By downloading and
        using this Platform, you represent and warrant that you are at least 18
        years old. Nile may in its sole discretion, without prior notice to you,
        revise the terms of this Agreement at any time. Upon amending the terms of
        this Agreement, Nile will present the new terms on the Platform and you
        will need to acknowledge your agreement to the amended terms prior to being
        able to continue to use the Platform. If you do not agree to the amended
        terms, your sole and exclusive remedy is to discontinue your use of the
        Platform and you will be deemed to have terminated these terms. Amended
        terms of this Agreement will be effective as of the Effective Date unless
        otherwise stated. By accessing or using the Platform after such changes are
        posted you agree and consent to all such changes.
    </p>
    <p>
        2. <strong><u>Permitted Use and Restrictions</u></strong><strong>.</strong>
    </p>
    <p>
        a. <strong><u>Grant of License and Reservations</u></strong><strong>.</strong> Subject to the
        terms of this Agreement, Nile grants you a limited, non-exclusive,
        revocable, non-transferable license to install and use the Platform and any
        future fixes, updates and upgrades provided to you, solely for use in
        connection with your provision of medical care to patients, including
        transmitting, accessing, managing, collecting, and displaying Patient
        Information (defined below). This Platform is licensed, not sold, to you by
        Nile for use only under the terms of this Agreement. Nile reserves all
        rights not expressly granted to you. The rights granted in this Agreement
        are limited to Nile's intellectual property rights in the Platform and do
        not include any other patents or intellectual property rights. You may own
        the media on which the Platform is stored, but Nile retains ownership of
        the Platform itself.
    </p>
    <p>
        b. <strong>Eligibility. </strong>By requesting to use, registering to use, and/or
        using the Platform, you represent and warrant that you have the right,
        authority, and capacity to enter into this Agreement, and you commit to
        abide by all of the terms hereof. You also represent and warrant that you
        are a healthcare provider or are affiliated with a healthcare provider in
        the provision of care to epilepsy patients (each a "Healthcare Provider"),
        and must agree to comply with all applicable laws, medical and professional
        board rules, and other rules and regulations applicable to you as a
        Healthcare Provider or otherwise.
    </p>
    <p>
        You should only use the Platform if you: (1) recommend Nile Services to
        epilepsy patients in your capacity as a person responsible for the medical
        care and treatment of such patients, and with the permission from such
        patient to monitor seizure activity and other medical data as they may
        submit to the Platform in order to inform their care pre- and
        post-treatment; or (2) as a person administering a research study involving
        consenting Platform users for medical and device research purposes. In
        addition, you must agree to use the Platform in accordance with these Terms
        and Conditions.
    </p>
    <p>
        You also represent and warrant that you meet the following eligibility
        criteria:
    </p>
    <ul>
        <li>
            You must be 18 years of age or over to register with us or use the
            Platform.
        </li>
        <li>
            Nile Services are currently available only to individuals who are
            legally in the United States or the territory of Puerto Rico. If you
            reside outside the United States or Puerto Rico, do not register to use
            Nile Services.
        </li>
        <li>
            You must not be a competitor intending to use the Nile Services for
            reasons that are in competition with Nile.
        </li>
    </ul>
    <p>
        If you do not qualify to use the Platform under these terms, do not use the
        Platform. By using the Platform, you represent and warrant that you have
        the right, authority, and capacity to enter into this Agreement and to
        abide by all of the terms and conditions set forth herein.
    </p>
    <p>
        c. <strong><u>Reverse Engineering</u></strong><strong>.</strong> Except as expressly set forth
        in this Agreement, you agree not to reverse engineer, de-compile,
        disassemble, alter, duplicate, modify, rent, lease, loan, sublicense, make
        copies, create derivative works from, distribute or provide others with the
        Platform, in whole or part, or transmit or communicate the Platform over a
        network or to any third party. Further, you agree not to develop, sell, or
        distribute applications that are capable of launching, being launched from,
        or are otherwise integrated with, the Platform or Content accessed through
        the Platform without Nile 's express written permission.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        d. <strong><u>Further Restrictions</u></strong><strong>.</strong> You further agree not to use
        the Platform to do the following:
    </p>
    <p>
        i. Transmit, access, or communicate any data that you do not have the right
        to transmit under applicable Law (defined below), including the Health
        Insurance Portability and Accountability Act of 1996 as modified by the
        Health Information Technology for Economic and Clinical Health Act and all
        rules, regulations, and related laws and acts promulgated under and in
        connection therewith (collectively, "<strong>HIPAA</strong>"), under similar more
        stringent state laws, or under a contractual or fiduciary relationship;
    </p>
    <p>
        ii. Send or otherwise transmit to or through the Platform any unlawful,
        infringing, harmful, harassing, defamatory, threatening, hateful or
        otherwise objectionable material of any kind, any material that can cause
        harm or delay to the Platform or computers of any kind, and any unsolicited
        advertising, solicitation or promotional materials;
    </p>
    <p>
        iii. Misrepresent your identity or affiliation in any way;
    </p>
    <p>
        iv. Restrict or inhibit any person from using the Platform, disclose
        personal information obtained from the Platform or collect information
        about users of the Platform;
    </p>
    <p>
        v. Gain unauthorized access to the Platform, to other users' accounts,
        names, personally identifiable information or other information, or to
        other computers or websites connected or linked to the Platform;
    </p>
    <p>
        vi. Launch or use any automated system, including without limitation,
        "robots," "spiders," or "offline readers," that access the Platform in a
        manner that sends more request messages to our servers in a given period of
        time than a human can reasonably produce in the same period by using a
        conventional web browser;
    </p>
    <p>
        vii. Send or otherwise transmit to or through the Platform chain letters,
        unsolicited messages, so-called "spamming" or "phishing" messages, or
        messages marketing or advertising goods and services;
    </p>
    <p>
        viii. Post, transmit or otherwise make available any virus, worm, spyware
        or any other computer code, file or program that may or is intended to
        damage or hijack the operation of any hardware, software or
        telecommunications equipment;
    </p>
    <p>
        ix. Violate any applicable laws or regulations in any way;
    </p>
    <p>
        x. Alter or modify any part of the content or services offered on or
        through the Platform;
    </p>
    <p>
        xi. Allow any other person to use the Platform with your registration or
        login information;
    </p>
    <p>
        xii. Breach or otherwise circumvent Nile's security or authentication
        measures; and
    </p>
    <p>
        xiii. Transmit, access or communicate any data that infringes any patent,
        trademark, trade secret, copyright or other proprietary rights of any
        party;
    </p>
    <p>
        xiv. Interfere with, disrupt, or circumvent the Platform;
    </p>
    <p>
        xv. Intentionally or unintentionally violate any applicable local, state,
        national or international law, including securities exchange commission
        regulations, and any regulations, requirements, procedures or policies in
        force from time to time relating to the Platform; or
    </p>
    <p>
        xvi. Assist or permit any persons in engaging in any of the activities
        described above.
    </p>
    <p>
        3. <strong><u>Patient Information</u></strong><strong>.</strong> You acknowledge and agree that
        the Platform may be used to transmit, collect, access, manage, and display
        Patient Information. You acknowledge and agree that Patient Information
        related to your treatment of patients may be stored by Nile and/or its
        licensees and service providers in connection with providing the Platform
        and its related services, as well as shared among other users of the
        Platform and its related services. You acknowledge and agree that the
        Patient Information stored by Nile shall not serve as the system of record
        for any patient, personal representative of a patient, health care
        provider, any business associate of a health care provider, or any
        affiliates of the foregoing. You further acknowledge that the Platform may
        be used by you to share information with patients and their designated
        personal representatives, and for patients and their designated personal
        representatives to share information with other users of the Platform based
        submission of valid authorizations of recipients to Nile. Nile will rely on
        valid authorizations to share information with approved recipients. You
        agree that Nile does not have any liability to you for any user errors,
        mistakes, or omissions in the recipient authorization process, whether
        intentional or unintentional, and that it is the obligation of the patient
        or their designated personal representative to prepare, submit and update
        valid authorizations. "Patient Information" means, collectively,
        information and data related to the provision of health care to patients,
        their health status, medical records, and related information and
        documents, including consent to treatment forms, authorization to disclose
        medical information forms, Medicare forms, Medicaid forms, living wills,
        Directives to Physicians and Family or Surrogates, Medical Powers of
        Attorney, Out-of-Hospital Do-Not-Resuscitate Orders, Declarations of Mental
        Health Treatment, images, reports, and lab and test results, medical
        treatments performed by you and/or other Healthcare Providers, and other
        "protected health information," as defined under HIPAA and similar terms as
        defined by state law. You agree that your provision and use of all Patient
        Information will be in compliance with HIPAA and all other applicable law.
        Further, with respect to all Patient Information, you agree to abide by the
        terms and conditions of any Business Associate Agreement between Nile and
        you or the entity or organization with which you are employed or otherwise
        affiliated ("Your Organization").
    </p>
    <p>
        4. <strong><u>Medical Advice and Treatment</u></strong><strong>.</strong> Nile does not provide
        medical advice, diagnosis, or treatment. You acknowledge and agree that the
        Platform is merely a tool and conduit of information related to patients
        and the provision of healthcare to patients by independent third party
        Healthcare Providers. You acknowledge and agree that Healthcare Providers,
        including you, are solely responsible for and will have complete authority,
        responsibility, supervision, and control over the provision of all medical
        services, advice, instructions, treatment decisions, and other professional
        health care services performed, and that all diagnoses, treatments,
        procedures, and other professional health care services will be provided
        and performed exclusively by or under the supervision of Healthcare
        Providers as they, in their sole discretion, deem appropriate. You further
        acknowledge and agree that Nile does not provide or endorse any medical
        advice on or through the Platform, and no information obtained through the
        Platform can be so construed or used. Nile will have and exercise
        absolutely no control, authority, or supervision over the provision of any
        medical services or other professional health care services. The use of all
        text, graphics, images, audio content, audiovisual content, other materials
        and any other information provided on or made available through the
        Platform, including all healthcare related information, instructions,
        advice, or other guidance, whether provided by you, Your Organization,
        other Healthcare Providers, or other third parties (collectively, "<strong>Content</strong>") is solely your responsibility. Nile will make all
        reasonable efforts in accordance with applicable laws and agreements to
        safeguard the integrity and availability of the Content. Further, when
        using the Platform, information may be transmitted over a medium that may
        be beyond the control and jurisdiction of Nile and its suppliers.
        Accordingly, Nile assumes no liability for or relating to the delay,
        failure, interruption, or corruption of any data or other information
        transmitted in connection with use of the Platform. Nile does not recommend
        or endorse any specific therapies, treatments, tests, health care
        providers, procedures, opinions, or other information that may be
        transmitted through the Platform. Accordingly, you acknowledge and agree
        that you are solely responsible for all medical services, advice,
        instructions, diagnoses, treatments, procedures, and other services you
        provide in connection with using the Platform or otherwise.
    </p>
    <p>
        5. <strong><u>Registration</u></strong><strong>.</strong> Upon registration, you agree to
        provide current, accurate, and complete information about you and Your
        Organization as is required to register to use the Platform and at other
        points as may be required in the course of using the Platform, including
        your complete legal name, street address, phone number(s), email address,
        and such other information as may be requested by Nile ("<strong>Registration Data</strong>"). Further, you agree to maintain and update your
        Registration Data as required to keep it current, accurate, and complete.
        You agree that Nile may store and use the Registration Data you provide in
        connection with your use of the Platform in accordance with the Privacy
        Policy (which is incorporated into this Agreement by reference). Nile has
        the right, but not the duty, to confirm or otherwise verify or check, in
        its sole discretion, the truth and accuracy of any registration information
        at any time. Verification of your registration information, specifically,
        your name, address and/or tax identification number, against a third party
        database may be considered to constitute a "credit check" under certain
        laws. Nile is not making, and will not otherwise make, any type of inquiry
        to any third party regarding any individual's credit history and personal
        financial information without first obtaining such individual's express
        authorization to do so. Nile may terminate your rights to the entire
        Platform if any information you provide is false, incomplete or inaccurate.
    </p>
    <p>
        6. <strong><u>Third Party Technology</u></strong><strong>.</strong> Any and all third party
        technology provided, made available, linked to, or otherwise accessible
        through the Platform ("<strong>Third Party Technology</strong>") is provided solely
        as a convenience to you and is not under the control of Nile. Nile does not
        endorse, recommend, or otherwise make any representations or warranties
        with respect to any Third Party Technology. Nile does not have any
        responsibility or liability to you for any Third Party Technology which you
        access and you use at your own risk. Further, you agree to comply with any
        and all terms and conditions applicable to the use of Third Party
        Technology and otherwise ensure that you have obtained all rights,
        licenses, and clearances that may be necessary to use such Third Party
        Technology.
    </p>
    <p>
        7. <strong><u>Third Party Websites</u></strong><strong>.</strong> This Platform may contain
        links to other independent third party websites ("<strong>Linked Websites</strong>").
        These Linked Websites are provided solely as a convenience to our visitors.
        Such Linked Websites are not under the control of Nile, and Nile is not
        responsible for and does not endorse the content of such Linked Websites,
        including any information or materials contained on such Linked Websites.
        Nile does not have any responsibility or liability for any information,
        data, communications or materials available on such third-party sites. You
        therefore access these Linked Websites at your own risk.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        8. <strong><u>Collection of Information</u></strong><strong>.</strong> You grant Nile the
        perpetual, non-exclusive, worldwide, royalty-free irrevocable license to
        use, copy, print, display, reproduce, modify, publish, post, transmit,
        distribute, and preserve any transmittal, communication, or other Content
        provided by you through the Platform, or any other service offered on or
        through the Platform, and data related to your use of the Platform,
        including your name, profile, and biography. Nile may disclose any such
        information and Content to other users of the Platform, Nile licensees,
        service providers, clients, researchers, and other third parties in
        accordance with applicable Law, agreements and the Privacy Policy. Further,
        Nile may also disclose such data if required to do so by Law or Nile
        determines that such preservation or disclosure is reasonably necessary to
        (1) comply with legal process, (2) enforce this Agreement, (3) respond to
        claims that any such data violates the rights of others, or (4) protect the
        rights, property, or personal safety of Nile, its employees, and users of
        the Platform. For further information regarding your privacy, please review
        our Privacy Policy, which is incorporated into this Agreement by reference.
        Notwithstanding the foregoing, the Privacy Policy may be changed from time
        to time without amending this Agreement. To the extent that there is a
        conflict between the Privacy Policy and this Agreement, this Agreement will
        control with respect to your use of the Platform; the Privacy Policy will
        control with respect to the collection, use, disclosure, and disposal of
        your information.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        9. <strong><u>Authorized Use</u></strong><strong>. </strong>Except as expressly set forth in
        this Agreement, you will protect the confidentiality of the Platform, and
        will not distribute or otherwise make available the Platform, or any
        portion of the Platform, in any form to any third party. Any rights you may
        possess in the Platform expire upon expiration or termination of this
        Agreement. You will employ the security measures necessary to prevent
        unauthorized users from accessing the Platform and your user ID(s) and
        password(s) ("<strong>Login Information</strong>"). You are solely responsible for
        the maintenance and protection of your Login Information. You accept
        responsibility for, and will be liable for all access to the Platform in
        connection with your Login Information. Without the prior written consent
        of Nile, you will not utilize the services of any third party to assist you
        in using the Platform. Further, you will be responsible for all activities
        that occur under or in connection with your account and your use of the
        Platform and you agree to notify Nile in the event your password has been
        hacked or stolen.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        10. <strong><u>Export Law Assurances</u></strong><strong>.</strong> You will not use or
        otherwise export or re-export this Platform except as authorized by United
        States law and the laws of the jurisdiction in which the Platform was
        obtained. In particular, but without limitation, the Platform will not be
        exported or re-exported (1) into (or to a national or resident of) any U.S.
        embargoed countries, or (2) to anyone on the U.S. Treasury Department's
        list of Specially Designated Nationals or the U.S. Department of Commerce
        Denied Persons' List or Entity List. By using the Platform, you represent
        and warrant that you are not located in, under control of, or a national or
        resident of any such country or on any such list.
    </p>
    <p>
        11. <strong><u>Territory</u></strong><strong>.</strong> Presently, the Platform is available to
        residents of the United States only. You understand and acknowledge that
        you may not sign up for, access or attempt to access or use the Platform
        from countries outside of the United States. Nile may use technologies to
        verify your compliance. You agree to abide by United States and other
        applicable export control laws and not to transfer, by electronic
        transmission or otherwise, any content or software subject to restrictions
        under such laws to a national destination or person prohibited under such
        laws.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        12. <strong><u>Trademarks</u></strong><strong>.</strong> Trademarks, service marks, graphics
        and logos used in connection with the Platform are the trademarks of their
        respective owners. You are not granted any right or license with respect to
        any of the trademarks mentioned above and any use of such trademarks.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        13. <strong><u>Copyright Infringement</u></strong><strong>. </strong>Nile respects copyright
        and other laws. Nile requires all Platform users to comply with copyright
        and other laws. Nile does not, by the supply of the Platform, authorize you
        to infringe the copyright or other rights of third parties. As a condition
        to use the Platform, you agree that you must not use the Platform to
        infringe upon the intellectual property or other rights of others in any
        way. The unauthorized reproduction, distribution, modification, public
        display, communication to the public or public performance of copyrighted
        works is an infringement of copyright. You are entirely responsible for
        your conduct and for ensuring that it complies with all applicable
        copyright and data-protection laws. In the event you fail to comply with
        laws regarding copyrights or other intellectual property rights, data
        protection and privacy, you may be exposed to civil and criminal liability,
        including possible fines and jail time.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        14. <strong><u>Ownership and Title</u></strong><strong>.</strong> All title to and the rights
        in the Platform, including ownership rights to patents (registrations,
        renewals, and pending applications), copyrights, trademarks, service marks,
        domain names, trade dress, trade secrets, Nile's or third party other
        technology, any derivatives of and all goodwill associated with the
        foregoing is the exclusive property of Nile and/or third parties.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        15. <strong><u>Representations &amp; Warranties</u></strong><strong>.</strong> In addition to
        the other representations and warranties contained in this Agreement, you
        further represent, warrant, and covenant to Nile the following:
    </p>
    <p>
        a. All information you provide to Nile as part of the registration process
        or otherwise will be truthful, accurate and complete, irrespective of any
        independent verification or other determination made by Nile;
    </p>
    <p>
        b. You, your practice, and all goods and services provided in connection
        your use of the Platform will comply with all applicable national, federal,
        state, and local laws, regulations, ordinances, and judicial decisions in
        courts and tribunals of competent jurisdiction within the United States
        (collectively, "<strong>Law</strong>");
    </p>
    <p>
        c. You are legally authorized in accordance with applicable Law to provide
        any and all Patient Information that you provide to the Platform for all
        uses contemplated under this Agreement;
    </p>
    <p>
        d. This Agreement has been duly and validly authorized, accepted, agreed
        to, and delivered by you (or your authorized representative) and
        constitutes your legal, valid, and binding obligation, enforceable against
        you in accordance with this Agreement. You represent that you have full
        power, capacity and authority to enter into this Agreement. If you are
        accepting on behalf of your employer or an entity, you represent that you
        have full legal authority to bind your employer or such entity to this
        Agreement; and
    </p>
    <p>
        e. The performance by you of this Agreement and your use of the Platform
        does not and will not conflict with or violate (1) any law, rule,
        regulation, order, judgment, decree, agreement, instrument, or obligation
        applicable to you, or (2) if you are an entity, any provision of your
        organizational or governing documents.
    </p>
    <p>
        16. <strong><u>Disclaimer of Warranties</u></strong><strong>. </strong>THE PLATFORM, CONTENT,
        AND ANY THIRD PARTY TECHNOLOGY ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING WARRANTIES OF TITLE, NON-INFRINGEMENT, AND IMPLIED WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE
        GENERALITY OF THE FOREGOING, NILE, ITS LICENSORS, AND SUPPLIERS MAKE NO
        WARRANTY, REPRESENTATION, OR GUARANTY:
    </p>
    <p>
        (1) AS TO THE CONTENT, SEQUENCE, ACCURACY, TIMELINESS, RELEVANCE, OR
        COMPLETENESS OF ANY CONTENT;
    </p>
    <p>
        (2) AS TO ANY CONTENT OFFERED OR PROVIDED WITHIN OR THROUGH THE PLATFORM
        REGARDING TREATMENT OF MEDICAL CONDITIONS, ACTIONS, DIAGNOSES, PROCEDURES,
        APPLICATION OF MEDICATION, OR OTHER PROVISION OF HEALTHCARE SERVICES;
    </p>
    <p>
        (3) AS TO THE SATISFACTION OF ANY GOVERNMENT REGULATIONS OR LAW REQUIRING
        DISCLOSURE OF CONTENT ON PRESCRIPTION DRUG PRODUCTS OR THE APPROVAL OR
        COMPLIANCE OF ANY SOFTWARE TOOLS WITH REGARD TO THE CONTENT OR PLATFORM; OR
    </p>
    <p>
        (4) THAT THE PLATFORM OR CONTENT MAY BE RELIED UPON FOR ANY REASON, THAT
        THE PLATFORM OR CONTENT WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT ANY
        DEFECTS CAN OR WILL BE CORRECTED;
    </p>
    <p>
        FURTHER, YOUR USE OF THE PLATFORM, CONTENT, AND ANY THIRD PARTY TECHNOLOGY
        IS AT YOUR OWN RISK. NILE DOES NOT WARRANT THAT THE PLATFORM, CONTENT, OR
        THIRD PARTY TECHNOLOGY WILL MEET YOUR SPECIFIC REQUIREMENTS. TO THE EXTENT
        THAT NILE MAY NOT DISCLAIM ANY WARRANTY AS A MATTER OF APPLICABLE LAW, THE
        SCOPE AND DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER
        SUCH LAW.
    </p>
    <p>
        17. <strong><u>Limitation of Liability</u></strong><strong>.</strong> EXCEPT WHERE PROHIBITED
        BY LAW, UNDER NO CIRCUMSTANCES WILL NILE BE LIABLE TO YOU OR ANY OTHER
        PERSON FOR DIRECT, CONSEQUENTIAL, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
        OR EXEMPLARY DAMAGES, COSTS, EXPENSES OR LOSSES OR LOST PROFITS IN
        CONNECTION WITH THE PLATFORM OR OTHERWISE RELATED TO THIS AGREEMENT. THE
        PROVISIONS OF THIS SECTION WILL APPLY REGARDLESS OF THE FORM OF ACTION,
        DAMAGE, CLAIM, LIABILITY, COST, EXPENSE, OR LOSS, WHETHER IN CONTRACT,
        STATUTE, TORT (INCLUDING NEGLIGENCE), FAILURE OF ESSENTIAL PURPOSE OR
        OTHERWISE, AND EVEN IF ADVISED OF THE LIKELIHOOD OF SUCH DAMAGES. NILE WILL
        NOT BE LIABLE FOR ANY FAILURE TO PERFORM ITS OBLIGATIONS UNDER THIS
        AGREEMENT BECAUSE OF CIRCUMSTANCES BEYOND ITS CONTROL, WHICH CIRCUMSTANCES
        INCLUDE NATURAL DISASTER, TERRORISM, THIRD PARTY LABOR DISPUTES, WAR,
        DECLARATIONS OF GOVERNMENTS, TRANSPORTATION DELAYS, FAILURE OF HARDWARE,
        EQUIPMENT, OR TELECOMMUNICATIONS FAILURE. NILE WILL NOT BE LIABLE FOR ANY
        FAILURE TO PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT BECAUSE OF MISUSE
        OF THE PLATFORM BY YOU OR BY ANY OTHER PERSON. THIS SECTION WILL BE GIVEN
        FULL EFFECT EVEN IN THE EVENT THAT ANY EXCLUSIVE REMEDY PROVIDED HAS FAILED
        OF ITS ESSENTIAL PURPOSE. FURTHER, IN NO EVENT WILL NILE BE LIABLE TO YOU
        OR ANYONE ELSE FOR ANY CLAIMS, LOSSES, OR DAMAGES ARISING OUT OF ANY USE OR
        MISUSE OF ANY THIRD PARTY TECHNOLOGY. THE PARTIES ACKNOWLEDGE THAT THE
        TERMS OF THIS SECTION REFLECT THE ALLOCATION OF RISK SET FORTH IN THIS
        AGREEMENT AND THAT THE PARTIES WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT
        THESE LIMITATIONS OF LIABILITY. IN NO EVENT SHALL THE LIABILITY OF NILE FOR
        ANY LOSS RELATED TO USE OR INABILITY TO USE THE PLATFORM EXCEED $5.00 U.S.
    </p>
    <p>
        18. <strong><u>Indemnification</u></strong><strong>.</strong> YOU WILL INDEMNIFY, DEFEND, AND
        HOLD INDEMNITEE (AS DEFINED BELOW) HARMLESS FROM AND AGAINST ANY AND ALL
        CLAIMS AND LOSSES ARISING FROM OR ATTRIBUTABLE TO (1) YOUR PROVISION OF
        MEDICAL SERVICES AND TREATMENT OF PATIENTS IN CONNECTION WITH YOUR USE OF
        THE PLATFORM, ANY CONTENT, OR OTHERWISE; (2) YOUR BREACH OF ANY OF YOUR
        REPRESENTATIONS, WARRANTIES, COVENANTS, OR OTHER AGREEMENTS MADE UNDER THIS
        AGREEMENT; (3) ANY CLAIMS BY OR DISPUTES RELATED TO YOUR USE OF THE
        PLATFORM; (4) ANY CONTENT YOU PROVIDE TO THE PLATFORM OR OTHERWISE TRANSMIT
        USING THE PLATFORM; AND (5) ANY BREACH OF CONFIDENTIALITY RELATED TO YOUR
        USE OF THE PLATFORM.
    </p>
    <p>
        a. <strong><u>Procedures</u></strong><strong>.</strong> The Indemnitee will give you written
        notice of any Claim for which indemnification is sought. However, failure
        to provide such notice will not relieve you from your liability or
        obligations under this Agreement, except to the extent you are materially
        prejudiced as a direct result of such failure. The Indemnitee will
        cooperate with you at your expense in connection with the defense and
        settlement of the Claim. You may not settle any indemnified Claim in a
        manner that adversely affects the Indemnitee without its prior written
        consent. Further, the Indemnitee may participate in the defense of the
        Claim through counsel of its own choosing at its own cost and expense. If
        you fail to promptly assume the defense and employ counsel reasonably
        satisfactory to Indemnitee, or the Indemnitee has been advised by counsel
        that there exist actual or potential conflicting interests between you or
        your counsel and such Indemnitee, the Indemnitee may employ separate
        counsel, in addition to local counsel, to represent or defend such
        Indemnitee in such action or proceeding, and you agree to pay the fees and
        disbursements of such separate counsel as incurred. To the extent
        indemnification requires the payment of monies owed, such indemnification
        will occur as soon as reasonably possible after the determination of monies
        owed, and payment to the Indemnitee will be made within 30 days of a final
        determination of monies owed. Your obligations under this Section are in
        addition to any rights that any Indemnitee may have at common law or
        otherwise.
    </p>
    <p>
        b. <strong><u>Defined Terms</u></strong><strong>.</strong> "<strong>Claim</strong>" means each and every
        claim, request, accusation, allegation, assertion, complaint, petition,
        demand, suit, action, proceeding, and cause of action of every kind and
        description. "<strong>Indemnitee</strong>" means Nile, its affiliates, and its and
        their respective officers, directors, shareholders, managers, members,
        agents, employees, representatives, successors, and assigns. "<strong>Loss</strong>"
        means each and every liability, loss, damage, and injury (including injury
        or damage to any property right, and injury, damage, or death to any
        Person), wound, wrong, hurt, harm, expense, deficiency, diminution in
        value, obligation, expenditure and disbursement of any kind or nature
        (including all fees, costs, and expenses of investigation, travel expenses,
        and value of time expended by personnel), settlement, fine, fee, cost, cost
        of court, and all expenses of litigation (including reasonable attorneys'
        fees) incident to any of the foregoing.
    </p>
    <p>
        19. <strong><u>Release</u></strong><strong>.</strong> IN THE EVENT THAT YOU HAVE A DISPUTE WITH
        ONE OR MORE USERS OF THE PLATFORM FOR ANY REASON, YOU RELEASE NILE (AND ITS
        OFFICERS, DIRECTORS, MANAGERS, MEMBERS, AGENTS, SUBSIDIARIES,
        REPRESENTATIVES, AND EMPLOYEES) FROM ALL CLAIMS, DEMANDS AND DAMAGES
        (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
        SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN
        ANY WAY CONNECTED WITH SUCH DISPUTES. IF YOU ARE A CALIFORNIA RESIDENT, YOU
        WAIVE CALIFORNIA CIVIL CODE &#167;1542, WHICH PROVIDES: "A GENERAL RELEASE
        DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
        EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
        HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
    </p>
    <p>
        20. <strong><u>Term</u></strong><strong>. </strong>Unless otherwise terminated by Nile as
        provided for in this Agreement, this Agreement will remain in effect for so
        long as Nile licenses the use of the Platform to you.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        21. <strong><u>Termination</u></strong><strong>.</strong> Nile may, in its sole discretion,
        terminate your access to the Platform, or any portion thereof, for any
        reason whatsoever without prior notice. These actions are in addition to
        any other right or remedy Nile may have available at law. You may terminate
        these Terms and Conditions by ceasing to access and use the Platform.
        Further, Your Organization may terminate, or request that Nile terminate
        your access to the Platform. In any such event, you must destroy all copies
        of the Platform and all of its component parts. Failure to abide by this
        Agreement is a material breach of this Agreement for which Nile may pursue
        all rights and remedies it has pursuant to this Agreement, and any other
        rights and remedies it may have at law or in equity. You also agree that
        any violation by you of this Agreement will constitute an unlawful and
        unfair business practice and will cause irreparable harm to Nile, for which
        monetary damages would be inadequate, and you consent to Nile obtaining any
        injunctive or equitable relief that Nile deems necessary or appropriate in
        such circumstances without the need to post a bond or satisfy any similar
        requirements. These remedies are in addition to any other remedies Nile may
        have at law or in equity. Nile shall not be liable to you or any third
        party for any such termination or discontinuance. Upon any termination of
        these Terms and Conditions you must immediately cease use of the Platform.
        To the extent permitted by applicable law, the disclaimers, limitations on
        liability, termination and your warranties and indemnities shall survive
        any termination of these Terms and Conditions.
    </p>
    <p>
        22. <strong><u>U. S. Government End Users</u></strong><strong>.</strong> The Platform is a
        "commercial item" as that term is defined at FAR 2.101, consisting of
        "commercial computer software" and "commercial computer software
        documentation" as such terms are used in 48 C.F.R. 12.212 and is provided
        to the U.S. Government only as a commercial end item. Consistent with FAR
        12.212 and DFARS 227.7202, all U.S. Government End Users acquire the
        Platform with only those rights expressly set forth in this Agreement.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        23. <strong><u>General Provisions</u></strong><strong>.</strong>
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        a. <strong><u>Entire Agreement</u></strong><strong>.</strong> This Agreement, the Privacy
        Policy, and any other terms of use, or other guidelines (collectively, "<strong>Other Terms</strong>") provided by Nile through or in connection with the
        Platform contain the entire understanding of the parties with respect to
        the subject matter of this Agreement and supersede all previous verbal and
        written agreements between the parties concerning the subject matter of
        this Agreement. To the extent that any Other Terms conflict with any
        provision of this Agreement, this Agreement will control. The Platform is
        the property of Nile. Nile reserves the right to change, add or remove
        portions of this Agreement or the Platform at any time and at its sole
        discretion. Your continued use of the Platform following the posting or
        delivery to you of any changes means that you accept and agree to such
        changes.
    </p>
    <p>
        b. <strong><u>Assignment</u></strong><strong>.</strong> This Agreement, and any rights or
        obligations in this Agreement will not be assigned by you without the prior
        written consent of Nile. Any attempt to assign or transfer this Agreement
        other than in accordance with this provision will be null and void. Subject
        to the forgoing, this Agreement and its terms and provisions inure to the
        benefit of and are binding upon the parties and their respective
        successors, heirs, personal representatives, and assigns.
    </p>
    <p>
        c. <strong><u>Governing Law/Waiver of Trial by Jury</u></strong><strong>.</strong>
    </p>
    <p>
        i. You agree that all matters relating to your access to or use of the
        Platform, including all disputes, will be governed by the laws of the
        United States and by the laws of Delaware without regard to its conflicts
        of laws provisions. You agree to the personal jurisdiction by and venue in
        the state and federal courts in Delaware, and waive any objection to such
        jurisdiction or venue. This Agreement will not be governed by the United
        Nations Convention on Contracts for the International Sale of Goods, the
        application of which is expressly excluded. If for any reason a court of
        competent jurisdiction finds any provision, or portion of any provision, to
        be unenforceable, the remainder of this Agreement will continue in full
        force and effect.
    </p>
    <p>
        ii. EXCEPT WHERE PROHIBITED BY LAW, THE PARTIES EXPRESSLY WAIVE TRIAL BY
        JURY IN ANY JUDICIAL PROCEEDING INVOLVING ANY DISPUTE, CONTROVERSY, OR
        CLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT.
    </p>
    <p>
        d. <strong><u>Notices</u></strong><strong>.</strong> All notices, requests, or consents sent to
        Nile that are required or permitted under this Agreement must be in writing
        (including electronic form) and must be delivered to the address designated
        below in a notice served in the manner provided for below. Each notice,
        request, consent, or other communication will be given and will be
        effective: (1) if delivered by hand, when so delivered; (2) if delivered by
        nationally recognized overnight courier service or sent by United States
        Express Mail, upon confirmation of delivery; (3) if delivered by certified
        or registered mail, on the third following day after deposit with the
        United States Postal Service; or (4) if delivered by facsimile, upon
        confirmation of successful transmission, and if delivered by email, upon
        confirmation of receipt by the other party in writing by return email.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        <strong>Nile</strong><br>
        15260 Ventura Blvd<br>
        Suite 1410<br>
        Sherman Oaks, CA 91403
        818.351.9744<br>
        support@nile.ai<br>
    </p>
    <p>
        e. <strong><u>Severability</u></strong><strong>.</strong> The provisions of this Agreement are
        severable. The invalidity, in whole or in part, of any provision of this
        Agreement will not affect the validity or enforceability of any other of
        its provisions. If one or more provisions of this Agreement are declared
        invalid or unenforceable, the remaining provisions will remain in full
        force and effect and will be construed in the broadest possible manner to
        effectuate the purposes of this Agreement. The parties further agree to
        replace such void or unenforceable provisions of this Agreement with valid
        and enforceable provisions that will achieve, to the extent possible, the
        economic, business, and other purposes of the void or unenforceable
        provisions.
    </p>
    <p>
        f. <strong><u>Captions</u></strong><strong>.</strong> The headings and captions of this
        Agreement are inserted for reference convenience and do not define, limit,
        or describe the scope or intent of this Agreement or any particular
        section, paragraph, or provision of this Agreement. Unless otherwise
        expressly provided, the words "include(s)," "included," or "including" do
        not limit the preceding words or terms. Pronouns will refer to the
        masculine, feminine, neuter, singular, or plural as the context will
        require.
    </p>
    <p>
        g. <strong><u>Waiver</u></strong><strong>.</strong> The failure or delay of Nile to exercise or
        enforce any rights or provision of this Agreement does not constitute a
        waiver of such right or provision.
    </p>
    <p>
        <strong><u> </u></strong>
    </p>
    <p>
        h. <strong><u>Survival</u></strong><strong>.</strong> All provisions which must survive in
        order to give effect to their meaning will survive any expiration or
        termination of this Agreement, including without limitation Sections 2(c),
        16, 17, 18, and 19 and all of your representations, warranties and
        indemnification obligations, which will survive any expiration or
        termination of this Agreement indefinitely.
    </p>
</div>
</body>
</html>`;
