/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import Box from '@material-ui/core/Box';
import KnPageContent from 'components/Content';
import patientActions from 'redux/actions/patientActions';
import {
  TRACKING_EVENTS, API_REQUEST_ERROR_CODES, API_URLS,
} from 'Constants';
import KnPatientReports from './PatientReports';
import KnPatientInfomation from './PatientInformation';
import KnInsightsMetrics from './InsightsMetrics';
import KnPatientRegimen from './MedicationRegimen';
import KnAddRegiment from './AddRegiment';
import { KnMedicationWrapper } from './styles';

const PatientRecordPage = (props) => {
  const { match: { params: { patientId } } } = props;
  const { location } = props;
  let patientType = _.get(location, 'state.patientType', '');
  const isNeverLinked = _.get(location, 'state.isNeverLinked', '');
  const pathname = _.get(location, 'pathname', '');
  if (pathname.indexOf(API_URLS.inviteStudyPatient) > -1) {
    patientType = true;
  }
  const dispatch = useDispatch();
  const { isAdmin: admin } = useSelector((state) => state.user.currentUser);
  const { patientRefractory } = useSelector((state) => state.patientRecord);
  const {
    data: patientData,
    error: patientInfoError,
  } = useSelector((state) => state.patientRecord.patientInfo);
  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientRecord });
  }, []);

  React.useLayoutEffect(() => {
    if (
      !admin
      && location.state
      && location.state.fromRefractory
      && _.get(patientRefractory, 'id')
    ) {
      setTimeout(() => {
        dispatch(patientActions.setPatientReviewStatus(
          _.get(patientRefractory, 'id'), 'reviewed',
        ));
      });
    }
  }, [dispatch, admin, location.state, patientRefractory]);

  const patientDataLoading = useMemo(
    () => !patientData && !patientInfoError,
    [patientData, patientInfoError],
  );

  return (
    <KnMedicationWrapper>
      <KnPageContent>
        <KnPatientInfomation
          patientId={patientId}
          patientType={patientType}
          isNeverLinked={isNeverLinked}
        />
        <KnAddRegiment patientId={patientId} patientType={patientType} admin={admin} />
        {!patientDataLoading && patientInfoError !== API_REQUEST_ERROR_CODES.USER_NOT_FOUND
          && (
            <>
              <Box display="flex" flex="1">
                <KnPatientReports
                  patientId={patientId}
                  patientType={patientType}
                  isNeverLinked={isNeverLinked}
                />
                <KnInsightsMetrics patientId={patientId} patientType={patientType} />
              </Box>
              <KnPatientRegimen patientId={patientId} patientType={patientType} />
            </>
          )}
      </KnPageContent>
    </KnMedicationWrapper>
  );
};

PatientRecordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientType: PropTypes.string,
      fromRefractory: PropTypes.bool,
    }),
  }).isRequired,
};

export default PatientRecordPage;
