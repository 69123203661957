import React, { useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import appActions from 'redux/actions/appActions';

const LoginSsoPage = () => {
  const dispatch = useDispatch();
  async function getUser() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (e) {
      // TODO: Error handling
    }

    return null;
  }

  useEffect(() => {
    dispatch(appActions.appPushBusy());
    return () => {
      dispatch(appActions.appPopBusy());
    };
  }, [dispatch]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser();
          break;
        case 'signOut':
          getUser();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          // TODO: Handle failure scenario here
          break;
        default:
        // TODO: Handle default scenario here
      }
    });
  }, []);

  return <></>;
};

export default LoginSsoPage;
