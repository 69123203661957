/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { DesktopDateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { KnContrastTextField } from './TextField';
import CalendarIcon from './icons/CalendarIcon';

const KnDateRangePicker = ({ ...rest }) => {
  const CalendarIconProps = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end" onClick={(e) => e.currentTarget.parentNode.click()}>
          <IconButton><CalendarIcon /></IconButton>
        </InputAdornment>
      ),
    },
  };
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DesktopDateRangePicker
        startText="Date range"
        inputFormat="MMM dd, yyyy"
        disableHighlightToday
        renderInput={(startProps, endProps) => (
          <Box position="relative">
            <KnContrastTextField
              {...startProps}
              variant="filled"
              helperText=""
              pb={0}
              inputProps={{
                ...startProps.inputProps,
                value: `${startProps.inputProps.value} - ${endProps.inputProps.value}`,
                readOnly: true,
              }}
              style={{ width: rest.width || 250, cursor: 'pointer' }}
              {...CalendarIconProps}
            />
          </Box>
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default KnDateRangePicker;
