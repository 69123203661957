/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import palette from 'styles/colors';
import KnTextBox from 'components/TextBox';
import KnButton from 'components/Button';
import { ExpansionPanelSummary } from '@material-ui/core';

const secondaryActionButton = palette.bostonBlue;

const KnPatientRecordBox = styled(Box)(({ theme, extrapadding }) => ({
  backgroundColor: palette.white.white2,
  padding: extrapadding ? theme.spacing(4) : theme.spacing(2),
}));


export const KnAssignRegimentdBox = styled(Box)(({ theme, disabled }) => ({
  backgroundColor: palette.white.white2,
  padding: theme.spacing(2),
  opacity: disabled ? 0.5 : 1,
}));

export const KnPatientReportsBox = styled(KnPatientRecordBox)({
  width: 354,
  '@media (max-width: 1440px)': {
    width: 254,
  },
});

export const KnPatientInsightsBox = styled(KnPatientRecordBox)({
  minWidth: 900,
});

export const KnInsightsMetricsHeaderBox = styled(Box)({
  width: 'calc(100vw - 492px)px', /** Same width as KnInsightsMetricsChartContainer */
});

export const KnPatientInsightsSubtitleBox = styled(Box)({
  width: 318,
});

export const KnPatientInsightsRangeBox = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginBottom: theme.spacing(-2.5),
  marginRight: theme.spacing(2),
}));

export const KnPatientNameBox = styled(Box)({
  width: 338,
});

export const KnPatientInfoBox = styled(Box)(({ theme }) => ({
  width: 140,
  paddingLeft: theme.spacing(1),
}));

export const KnPatientInfoWideBox = styled(KnPatientInfoBox)({
  width: 232,
});

/** Styles for thresholds setup */
export const KnThresholdButtonsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    flex: 1,
  },
  '& > *:first-child': {
    marginRight: theme.spacing(1),
  },
}));

export const KnSecondaryActionButton = styled(KnButton)({
  backgroundColor: secondaryActionButton,
  '&:hover': {
    backgroundColor: darken(secondaryActionButton, 0.3),
  },
});

export const KnSeizureThresholdFieldsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    width: 191,
    marginRight: theme.spacing(1),
    paddinBottom: 0,
  },
}));

export const KnSymptomsThresholdFieldsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    marginRight: theme.spacing(1),
    paddingBottom: 0,
  },
  '& > *:nth-child(1)': {
    width: 274,
  },
  '& > *:nth-child(2)': {
    width: 108,
  },
}));

/** Styles for thresholds display */
export const KnThresholdDetailsBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export const KnSeverityTextBox = styled(KnTextBox)({
  height: 'auto',
});

export const KnSymptomsTextBox = styled(KnTextBox)({
  width: 421,
  whiteSpace: 'break-spaces',
});

export const KnThresholdEditBox = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 25,
  alignItems: 'flex-start',
});

export const KnPatientInfoField = styled('span')({
  display: 'flex',
  fontSize: 14,
  lineHeight: 1.8,
  overflow: 'hidden',
});

export const KnMetricsLabel = styled('div')({
  fontSize: 13,
  color: palette.black.black3,
});

export const KnMetricsSmallLabel = styled(KnMetricsLabel)({
  fontSize: 12,
});

export const KnMetricsTitle = styled(KnMetricsLabel)({
  fontWeight: 500,
});

export const KnMetricsExtraSmallLabel = styled('div')({
  fontSize: 11,
  color: palette.lightGrey,
});

export const KnMetricsMediumLabel = styled('div')({
  fontSize: 15,
  color: palette.lightGrey,
});

export const KnSymptomLabel = styled('span')({
  fontSize: 12,
  fontWeight: 500,
  color: palette.black.black3,
  width: 125,
});

export const KnSymptomFrequency = styled('span')(({ theme }) => ({
  fontSize: 11,
  color: palette.black.black3,
  paddingLeft: theme.spacing(2),
}));

export const KnCommonSymptomsWrapper = styled(Box)({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: palette.paleGrey.paleGrey2,
});

export const KnLegendText = styled('span')({
  fontSize: 15,
  fontWeight: 600,
  color: palette.lightGrey,
});

export const KnTitrationDateText = styled('span')({
  color: palette.paleGrey.paleGrey4,
  fontWeight: 500,
});

export const KnMedicationPanelSummaryUpdate = styled(ExpansionPanelSummary)({
  border: 'none',
  '& .MuiExpansionPanelSummary-expandIcon': {
    position: 'absolute',
    top: 15,
    right: 31,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});

export const KnMedicationWrapper = styled('div')({
  width: '100%',
});

export const KnAuraTitle = styled('span')({
  fontWeight: 300,
});

export const KnWidgetBox = styled(Box)({
  backgroundColor: palette.white.white2,
  boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.1)',
});

export const KnPatientEmrRecordBox = styled(Box)({
  backgroundColor: palette.white.white2,
});

export const KnCalenarBox = styled(Box)({
  backgroundColor: palette.white.white2,
});


export default KnPatientRecordBox;
