/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from 'styles/colors';
import KnImprovementIcon from './ImprovementIcon';
import KnDeteriorationIcon from './DeteriorationIcon';
import KnStableIcon from './StableIcon';

/**
 * Explicitly create a React component to prevent React from passing down the
 * backgroundColor prop and throwing an error.
 */
const KnStateIconWrapperComponent = ({ backgroundColor, opacity, ...rest }) => (<div {...rest} />);

const KnStateIconWrapper = styled(KnStateIconWrapperComponent)(({ backgroundColor, opacity }) => ({
  alignItems: 'center',
  backgroundColor: fade(backgroundColor, opacity),
  borderRadius: '50%',
  display: 'flex',
  fontSize: 18, /** the icon will inherit this */
  height: 26,
  justifyContent: 'center',
  width: 26,
}));

const svgProps = {
  fontSize: 'inherit',
  viewBox: '0 0 18 18', /** align viewbox with the font size */
};

export const KnStateImprovedIcon = () => (
  <KnStateIconWrapper
    backgroundColor={palette.green.dark}
    opacity={0.2}
    data-testid="patient-state-improved-icon"
  >
    <KnImprovementIcon {...svgProps} />
  </KnStateIconWrapper>
);

export const KnStateDeterioratedIcon = () => (
  <KnStateIconWrapper
    backgroundColor={palette.copper}
    opacity={0.2}
    data-testid="patient-state-deteriorated-icon"
  >
    <KnDeteriorationIcon {...svgProps} />
  </KnStateIconWrapper>
);

export const KnStateStableIcon = () => (
  <KnStateIconWrapper
    backgroundColor={palette.paleGrey.paleGrey2}
    opacity={1}
    data-testid="patient-state-stable-icon"
  >
    <KnStableIcon {...svgProps} />
  </KnStateIconWrapper>
);

KnStateIconWrapperComponent.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
};
