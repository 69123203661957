import { format } from 'date-fns';
import { countBy, get, max } from 'lodash';
import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countSeizureTypes } from 'utils/metrics';

const seizureEmr = 'PATIENT_RECORD.patientReports.seizureEMR';

const useSeizureText = (addNotes) => {
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();
  const emrReport = useSelector((state) => state.patientRecord.emrReport);
  const { patientSeizures = [] } = emrReport.data || {};

  const getSeizureById = useCallback((ids) => patientSeizures.find((s) => s.id === max(ids)), [
    patientSeizures,
  ]);

  const defaultText = useMemo(() => {
    let text = '\n';
    const auraCount = countBy(patientSeizures, (s) => (s.eventType === 'aura' ? 'aura' : 'seizure'));

    if (patientSeizures.length > 1) {
      text += `${translate(`${seizureEmr}.title_plural`, { count: patientSeizures.length })}`;
    } else if (patientSeizures.length === 1) {
      text += `${translate(`${seizureEmr}.title`, { count: patientSeizures.length })}`;
    }

    if (auraCount.aura > 1) {
      text += `${translate(`${seizureEmr}.auraTitle_plural`, { count: auraCount.aura })}`;
    } else {
      text += `${translate(`${seizureEmr}.auraTitle`, { count: auraCount.aura })}`;
    }
    text += '\n';

    text += `   ${translate(`${seizureEmr}.averageTitle`)}\n`;

    const getDateText = (ids) => {
      const latestSeizure = getSeizureById(ids);
      return `   ${translate(`${seizureEmr}.recoredAt`, { date: latestSeizure.date })}`;
    };

    const seizureTypes = countSeizureTypes(patientSeizures);
    ['aura', 'cna', 'ca', 'cu', 'nca', 'ncna', 'ncu', 'ua', 'una', 'ucua'].forEach((key) => {
      text += `   ${translate(`${seizureEmr}.seizureTypes.${key}`, { count: get(seizureTypes, `${key}.count`, 0) })}`;
      if (seizureTypes[`${key}`]) {
        text += getDateText(seizureTypes[`${key}`].ids);
      }
      text += '\n';
    });

    text += '\n';

    patientSeizures.forEach((seizure) => {
      const timePeriod = get(seizure, 'timePeriod.value', '');
      const duration = get(seizure, 'duration.value', '');
      text += `-  ${format(seizure.date, 'MM/dd/yyyy')}:${timePeriod ? ` ${timePeriod}` : ''}${duration ? `, ${duration}` : ''};`;
      const {
        recoveryTime, didGoToER, didUseRescueMedication, triggers, preset,
      } = seizure;
      const seizureType = Object.keys(countSeizureTypes([seizure]));
      if ((seizureType && seizureType[0]) || preset) {
        text += `\n   ${seizureType && seizureType[0] ? translate(`${seizureEmr}.seizureTitleTypes.${seizureType && seizureType[0]}`) : ''}${preset ? `${seizureType && seizureType[0] ? ', ' : ''}${get(preset, 'name')}` : ''};`;
      }

      if (triggers && triggers.length) {
        text += `\n   ${translate(`${seizureEmr}.triggers`, { value: triggers.map((t) => t || 'Other').join(', ') })}`;
      }

      if (recoveryTime || didGoToER || didUseRescueMedication) {
        text += `\n   ${translate(`${seizureEmr}.recovery`)}${recoveryTime || ''}${didGoToER ? `, ${translate(`${seizureEmr}.wentToER`)}` : ''}${didUseRescueMedication ? `, ${translate(`${seizureEmr}.rescue`)}` : ''};`;
      }

      if (seizure.notes && addNotes) {
        text += `\n   ${translate('PATIENT_RECORD.patientReports.sideEffectEMR.note')} ${seizure.notes};`;
      }
      text += '\n\n';
    });
    return text;
  }, [
    translate, patientSeizures, getSeizureById, addNotes,
  ]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText]);

  if (patientSeizures && !patientSeizures.length) {
    return `\n${translate(`${seizureEmr}.seizures`)}\n`;
  }

  return inputText;
};

export default useSeizureText;
