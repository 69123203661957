/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import appBarStyles from './components/appBar';
import typographyStyles from './components/typography';
import buttonStyles from './components/button';
import linkStyles from './components/link';
import menuStyles from './components/menu';
import textFieldStyles from './components/textField';
import tabStyles from './components/tabs';
import tablesStyles from './components/table';
import cardStyles from './components/card';
import dialogStyles from './components/dialog';
import paperStyles from './components/paper';
import expansionPanelStyles from './components/expansionPanel';
import checkboxStyles from './components/checkbox';
import pickerStyles from './components/picker';
import palette from './colors';

const text = {
  primary: palette.black.black1,
  secondary: palette.gunmetal,
  disabled: palette.paleGrey.paleGrey1,
};

const theme = createTheme({
  /** Base spacing value.
   * Any white-spacing dimensions
   * should be multiple of this.
   */
  spacing: 8,

  /** app color palette */
  palette: {
    type: 'light',
    error: palette.error,
    primary: {
      contrastText: palette.white.white2,
      main: palette.primary,
    },
    secondary: {
      main: palette.secondary,
    },
    text,
  },

  /** typography definitions */
  typography: {
    fontFamily: 'DIN Next LT W01 Regular',
    button: {
      fontWeight: 'bold',
      textTransform: 'none',
    },
    body1: {
      color: text.primary,
    },
  },

  /** components customizations */
  overrides: {
    ...appBarStyles,
    ...typographyStyles,
    ...buttonStyles,
    ...linkStyles,
    ...cardStyles,
    ...dialogStyles,
    ...paperStyles,
    ...expansionPanelStyles,
    ...menuStyles,
    ...textFieldStyles,
    ...tabStyles,
    ...checkboxStyles,
    ...tablesStyles,
    ...pickerStyles,
  },
});

export default responsiveFontSizes(theme);
