/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { KnBrightBox } from 'components/Content';
import { KnContrastTextField } from 'components/TextField';
import { orderBy } from 'lodash';
import KnButton from 'components/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withKeyNamespace, composeFullName } from 'utils/utils';
import { EMAIL_FORMAT } from 'Constants';
import patientsListActions from '../../redux/actions/patientsListActions';
import { formatValidator } from '../../utils/customFieldValidators';


const i18nKey = withKeyNamespace('STUDY_PATIENTS');

const KnStyledTextField = styled(KnContrastTextField)({
  width: 300,
  marginRight: 20,
});

const KnStyledButton = styled(KnButton)({
  width: 200,
  height: 56,
});

const KnInLine = styled('div')({
  display: 'inline-flex',
});

const sortAlphabetically = (list, key = 'lastName') => orderBy(list, [(p) => (p[key] || '').toLowerCase()], ['asc']);

const InvitePatientToolbar = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { isAdmin } = useSelector((state) => state.user.currentUser);
  const { physiciansList, healthSystemsList } = useSelector((state) => state.patientsList);

  const [physician, setPhysician] = useState();
  const [email, setEmail] = useState();
  const [healthSystem, setHealthSystem] = useState();
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(() => {
    dispatch(patientsListActions.invitePatient(email, physician));
  }, [dispatch, email, physician]);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
    setError(!formatValidator(value, EMAIL_FORMAT, false));
  }, []);

  const filteredPhysicians = useMemo(() => {
    if (!healthSystem) {
      return sortAlphabetically(physiciansList);
    }
    return sortAlphabetically(physiciansList.filter((p) => p.healthSystemId === healthSystem));
  }, [physiciansList, healthSystem]);

  const isValid = email && !error && physician;

  return (
    <KnBrightBox
      display="flex"
      flexDirection="row"
      p={2}
    >
      <KnInLine>
        <KnStyledTextField
          label={translate('FIELD_LABELS.patientEmail')}
          error={{
            type: error,
            message: translate('FIELD_VALIDATION_MESSAGES.patientEmail.format'),
          }}
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          type="email"
        />

        {isAdmin && (
        <KnStyledTextField
          label={translate('HOME.filterByHealthSystem')}
          select
          onChange={(e) => {
            setHealthSystem(e.target.value);
            setPhysician();
          }}
        >
          {sortAlphabetically(healthSystemsList, 'name').map((option, index) => (
            <MenuItem
              key={option.id}
              value={option.id}
              data-testid={`health-system-option-${index + 1}`}
            >
              {option.name}
            </MenuItem>
          ))}
        </KnStyledTextField>
        )}

        <KnStyledTextField
          select
          disabled={isAdmin && !healthSystem}
          label={translate('HOME.filterByPhysician')}
          data-testid="physician-filter-dropdown"
          onChange={(e) => setPhysician(e.target.value)}
        >
          {filteredPhysicians.map((p, index) => (
            <MenuItem
              key={p.id}
              value={p.id}
              data-testid={`physician-filter-option-${index + 1}`}
            >
              {composeFullName(p.firstName, p.lastName)}
            </MenuItem>
          ))}
        </KnStyledTextField>
        <KnStyledButton data-testid="send-invite-button" onClick={handleSubmit} disabled={!isValid}>
          {translate(i18nKey('submitButton'))}
        </KnStyledButton>
      </KnInLine>
    </KnBrightBox>
  );
};

export default InvitePatientToolbar;
