/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import startOfDay from 'date-fns/startOfDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import sub from 'date-fns/sub';
import {
  getMetricsTimeRange,
  countSeizureMetricsByType,
  aggregateLongTermSymptoms,
  aggregateLongTermMedicationAdherence,
  getCombinedTimeRange,
} from 'utils/metrics';
import { withKeyNamespace } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import patientActions from 'redux/actions/patientActions';
import Typography from '@material-ui/core/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import Box from '@material-ui/core/Box';
import { KnSectionHeader, KnSubtitleText } from 'components/Typography';
import {
  INSIGHTS_METRICS_PERIOD,
  SEIZURE_TYPES, MEDICATION_ADHERENCE_STATUS,
  SYMPTOM_TYPES,
  ADHERENCE_TYPES, REGIMEN_STATUS,
} from 'Constants';
import KnInsightsMetricsChart from './charts/InsightsMetricsChart';
import KnInsightsMetricsLegend from './charts/InsightsMetricsLegend';
import {
  KnPatientInsightsBox, KnInsightsMetricsHeaderBox,
  KnPatientInsightsSubtitleBox, KnAuraTitle,
} from './styles';
import GanttCalendarChart from './charts/GanttCalendarChart';

const i18nKey = withKeyNamespace('PATIENT_RECORD');

const getSeizureFilterData = (aggregatedData, TYPES, startDay, endDay) => {
  const rangeData = _.filter(
    aggregatedData,
    (item) => isWithinInterval(item.date, { start: startDay, end: endDay }),
  );
  const counts = {};
  const seizureTypes = Object.values(TYPES);
  seizureTypes.forEach((type) => {
    counts[type] = _.sumBy(rangeData, type) || 0;
  });
  return counts;
};

const getSymptomFilterData = (aggregatedData, TYPES, startDay, endDay) => {
  const rangeData = _.filter(
    aggregatedData,
    (item) => isWithinInterval(item.date, { start: startDay, end: endDay }),
  );
  const counts = {};
  const symptomsTypes = Object.values(TYPES);
  symptomsTypes.forEach((type) => {
    const data = _.groupBy(rangeData, 'type')[type] || [];
    counts[type] = data.length || 0;
  });
  return counts;
};


const KnInsightsMetrics = ({ patientId, patientType }) => {
  const dispatch = useDispatch();
  const [seizuresCounts, setSeizuresCounts] = useState({});
  const [symptomsCounts, setSymptomsCounts] = useState({});
  const [symptomsFilters, setSymptomsFilters] = useState([]);
  const [regimentFilters, setRegimentFilters] = useState([]);
  const [symptomsManuallyUnchecked, setSymptomsManuallyUnchecked] = useState({
    severe: null,
    medium: null,
    mild: null,
  });
  const [regimenManuallyUnchecked, setRegimenManuallyUnchecked] = useState({
    started: null,
    updated: null,
    missed: true,
    logged: true,
  });
  const [adheranceCounts, setAdheranceCounts] = useState({});
  const [toggledType, setToggledType] = useState({ type: '' });
  // const [toggledAdherenceType, setToggledAdherenceType] = useState({ type: '' });
  const [metricsRange, setMetricsRange] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const { t: translate } = useTranslation();
  const {
    regimenList,
    patientInfo,
    longTermSeizureMetrics,
    longTermSymptomsMetrics,
    longTermMedicationsMetrics,
  } = useSelector((state) => ({
    ...state.patientRecord,
    regimenList: state.patientRecord.regimens,
  }));
  const seizureData = longTermSeizureMetrics.data;
  const symptomsData = longTermSymptomsMetrics.data;
  const medicationsData = longTermMedicationsMetrics.data;
  const aggregatedSeizureData = useMemo(() => (
    seizureData ? countSeizureMetricsByType(seizureData) : []
  ), [seizureData]);

  const aggregatedSymptomsData = useMemo(() => (
    (symptomsData && symptomsData.length)
      ? aggregateLongTermSymptoms(symptomsData)
      : []
  ), [symptomsData]);

  const aggregatedMedicationAdherenceData = useMemo(() => (
    (medicationsData)
      ? aggregateLongTermMedicationAdherence(medicationsData, regimenList)
      : []
  ), [medicationsData, regimenList]);

  const timeZone = _.get(patientInfo, 'data.timeZone');

  /** Currently assigned regimen (started or not) */
  const regimens = regimenList.data;
  const assignedRegimen = useMemo(() => {
    if (regimens && regimens.length) {
      return _.first(_.filter(regimens, (regimen) => (
        regimen.status !== REGIMEN_STATUS.deactivated
        && (regimen.status !== REGIMEN_STATUS.completed)
        && (regimen.status !== REGIMEN_STATUS.updated)
      ))) || {};
    }
    return {};
  }, [regimens]);

  const isMedicationStarted = assignedRegimen.status === REGIMEN_STATUS.active;

  const pastRegimens = useMemo(() => (regimens && regimens.length ? _.filter(regimens,
    (regimen) => regimen && (
      regimen.status === REGIMEN_STATUS.deactivated
      || regimen.status === REGIMEN_STATUS.completed
      || regimen.status === REGIMEN_STATUS.updated
    )) : []), [regimens]);

  useEffect(() => {
    /** We need patient timezone loaded, to decide the time range for the symptoms metrics */
    if (patientType) {
      const endDate = new Date();
      const startDate = sub(endDate, { days: (INSIGHTS_METRICS_PERIOD.days - 1) });
      setMetricsRange({ startDate, endDate });
    }
    if (timeZone) {
      const { startDate, endDate } = isMedicationStarted
        ? getCombinedTimeRange()
        : getMetricsTimeRange({
          timezone: timeZone,
          offset: INSIGHTS_METRICS_PERIOD.days,
        });

      setMetricsRange({ startDate, endDate });
      if (!patientType) {
        dispatch(patientActions.fetchLongTermSeizureData(
          patientId,
          startDate,
          endDate,
        ));
        dispatch(patientActions.fetchLongTermSymptomData(
          patientId,
          startDate,
          endDate,
        ));
        dispatch(patientActions.fetchLongTermMedicationAdherenceData(
          patientId,
          sub(new Date(), { days: (INSIGHTS_METRICS_PERIOD.days - 1) }),
          sub(new Date(), { days: 1 }),
          MEDICATION_ADHERENCE_STATUS.all,
        ));
      }
    }
  }, [dispatch, isMedicationStarted, patientId, timeZone, patientType]);

  const timeDelay = useRef(null);
  const updateRanges = useCallback((start, end) => {
    setSelectedRange({ startDay: start, endDay: end });
    /**
     * The chart dates use the start of the day; we need to transform
     * the interval limits so no seizures are left uncounted.
     */
    clearTimeout(timeDelay.current);
    timeDelay.current = setTimeout(() => {
      const startDay = startOfDay(start);
      const endDay = startOfDay(end);

      const seizuresRangeData = getSeizureFilterData(
        aggregatedSeizureData, SEIZURE_TYPES, startDay, endDay,
      );
      const symptomsRangeData = getSymptomFilterData(
        aggregatedSymptomsData, SYMPTOM_TYPES, startDay, endDay,
      );

      const adherenceRangeData = getSymptomFilterData(
        aggregatedMedicationAdherenceData, ADHERENCE_TYPES, startDay, endDay,
      );

      setSeizuresCounts(seizuresRangeData);
      setSymptomsCounts(symptomsRangeData);
      setAdheranceCounts(adherenceRangeData);
      if (!Object.keys(adheranceCounts).length) {
        setRegimentFilters(Object.keys(adherenceRangeData).filter((s) => !!adherenceRangeData[s]));
      }

      if (!Object.keys(symptomsCounts).length) {
        setSymptomsFilters(Object.keys(symptomsRangeData).filter((s) => !!symptomsRangeData[s]));
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregatedSeizureData,
    aggregatedSymptomsData,
    aggregatedMedicationAdherenceData,
  ]);

  const onLegendFilterSelected = useCallback((type) => {
    setToggledType({ type });
  }, []);

  const onLegendSymptomsFilterSelected = useCallback((type, checked) => {
    setSymptomsManuallyUnchecked({ ...symptomsManuallyUnchecked, [type]: !checked });
    if (checked) {
      setSymptomsFilters([...symptomsFilters, type]);
    } else {
      setSymptomsFilters(symptomsFilters.filter((s) => s !== type));
    }
  }, [symptomsFilters, symptomsManuallyUnchecked]);

  const onLegendRegimenFilterSelected = useCallback((type, checked) => {
    setRegimenManuallyUnchecked({ ...regimenManuallyUnchecked, [type]: !checked });
    if (checked) {
      setRegimentFilters([...regimentFilters, type]);
    } else {
      setRegimentFilters(regimentFilters.filter((s) => s !== type));
    }
  }, [regimentFilters, regimenManuallyUnchecked]);


  /** Retry handlers for error cases */
  const redoFetchSeizures = useCallback(() => {
    if (!patientType) {
      dispatch(patientActions.fetchLongTermSeizureData(
        patientId,
        metricsRange.startDate,
        metricsRange.endDate,
      ));
    }
  }, [dispatch, patientId, metricsRange, patientType]);

  const redoFetchSymptoms = useCallback(() => {
    if (!patientType) {
      dispatch(patientActions.fetchLongTermSymptomData(
        patientId,
        metricsRange.startDate,
        metricsRange.endDate,
      ));
    }
  }, [dispatch, patientId, metricsRange, patientType]);

  const redoFetchMedicationAdherence = useCallback(() => {
    if (!patientType) {
      dispatch(patientActions.fetchLongTermMedicationAdherenceData(
        patientId,
        metricsRange.startDate,
        metricsRange.endDate,
        MEDICATION_ADHERENCE_STATUS.all,
      ));
    }
  }, [dispatch, patientId, metricsRange, patientType]);

  return (
    <KnPatientInsightsBox mb={2} flex="1">
      <KnErrorMessage
        error={longTermSeizureMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.seizuresFetchError')}
        onRetry={redoFetchSeizures}
        centered={false}
        mb={2}
      />

      <KnErrorMessage
        error={longTermSymptomsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.symptomsFetchError')}
        onRetry={redoFetchSymptoms}
        centered={false}
        mb={2}
      />

      <KnErrorMessage
        error={longTermMedicationsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.medicationAdherenceFetchError')}
        onRetry={redoFetchMedicationAdherence}
        centered={false}
        mb={2}
      />

      {(seizureData || patientType) && (
        <>
          <KnInsightsMetricsHeaderBox>
            <Typography variant="h6" component={KnSectionHeader}>
              {translate(
                i18nKey('seizureInsights.title'),
                { count: _.sum(Object.values(seizuresCounts)) },
              )}
              {!patientType && seizureData.some((s) => s.eventType === 'aura') && (
                <KnAuraTitle>
                  {translate(
                    i18nKey('seizureInsights.auraTitle'),
                    { count: seizuresCounts.aura },
                  )}
                </KnAuraTitle>
              )}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <KnPatientInsightsSubtitleBox>
                <Typography component={KnSubtitleText}>
                  {translate(i18nKey('seizureInsights.subtitle'))}
                </Typography>
              </KnPatientInsightsSubtitleBox>
            </Box>
          </KnInsightsMetricsHeaderBox>
          {metricsRange && (
            <KnInsightsMetricsChart
              seizureData={aggregatedSeizureData}
              symptomsData={aggregatedSymptomsData}
              medicationsData={aggregatedMedicationAdherenceData}
              updateRanges={updateRanges}
              titrationData={
                aggregatedMedicationAdherenceData.filter(
                  (s) => s.type === ADHERENCE_TYPES.updated || s.type === ADHERENCE_TYPES.started,
                )
              }
              toggledSeizure={toggledType}
              // toogleAdherece={toggledAdherenceType}
              symptomsFilters={symptomsFilters}
              symptomsManuallyUnchecked={symptomsManuallyUnchecked}
              regimenManuallyUnchecked={regimenManuallyUnchecked}
              regimentFilters={
                regimentFilters.filter((s) => ![
                  ADHERENCE_TYPES.missed, ADHERENCE_TYPES.logged,
                ].includes(s))
              }
              adherenceFilters={
                regimentFilters.filter((s) => [
                  ADHERENCE_TYPES.missed, ADHERENCE_TYPES.logged,
                ].includes(s))
              }
              startDate={metricsRange.startDate}
              endDate={metricsRange.endDate}
              hideDateLabels={isMedicationStarted}
            />
          )}
          {regimens && regimens.length && isMedicationStarted && selectedRange && (
            <GanttCalendarChart
              key="gantt-chart-calender"
              chartId="gantt-chart-calender"
              titration={assignedRegimen}
              pastRegimens={pastRegimens}
              hasDateSelector={false}
              selectedRange={selectedRange}
            />
          )}
          <KnInsightsMetricsLegend
            seizuresCounts={seizuresCounts}
            symptomsCounts={symptomsCounts}
            adheranceCounts={adheranceCounts}
            onLegendFilterSelected={onLegendFilterSelected}
            onLegendSymptomsFilterSelected={onLegendSymptomsFilterSelected}
            onLegendRegimenFilterSelected={onLegendRegimenFilterSelected}
            hideSymptomsLegend={!aggregatedSymptomsData}
            hideMedicationsLegend={!aggregatedMedicationAdherenceData}
          />
        </>
      )}
    </KnPatientInsightsBox>
  );
};

KnInsightsMetrics.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientType: PropTypes.bool.isRequired,
};

export default KnInsightsMetrics;
