/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

/**
 * List of all action types used in the app
 * Add your new action types here.
 */
const ACTION_TYPES = {
  /** Login actions deal with the logging in and out of the server. */
  /** Login action types are commonly used by both steps of the login flow */
  LOGIN_REQUEST: 'LOGIN.LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN.LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN.LOGIN_ERROR',
  LOGIN_CLEAR_ERROR: 'LOGIN.CLEAR_ERROR',
  /** Used when user successfully logged out */
  LOGGED_OUT: 'LOGGED_OUT',

  /** Health system actions */
  HEALTH_SYSTEM_REQUEST: 'HEALTH_SYSTEM.REQUEST',
  HEALTH_SYSTEM_SUCCESS: 'HEALTH_SYSTEM.SUCCESS',
  HEALTH_SYSTEM_ERROR: 'HEALTH_SYSTEM.ERROR',

  /** User role actions */
  USER_ROLE_REQUEST: 'USER_ROLE.REQUEST',
  USER_ROLE_SUCCESS: 'USER_ROLE.SUCCESS',
  USER_ROLE_ERROR: 'USER_ROLE.ERROR',

  /** Register actions deal with new user sign up */
  /** Register action types are commonly used by all steps of the sign up flow */
  REGISTER_REQUEST: 'REGISTER.REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER.REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER.REGISTER_ERROR',
  REGISTER_CLEAR_ERROR: 'REGISTER.CLEAR_ERROR',
  REGISTER_RESET: 'REGISTER.RESET',

  /** Patient list related actions */
  PATIENT_LIST_REQUEST: 'PATIENT_LIST.REQUEST',
  PATIENT_LIST_SUCCESS: 'PATIENT_LIST.SUCCESS',
  PATIENT_LIST_ERROR: 'PATIENT_LIST.ERROR',
  PATIENT_LIST_SORT: 'PATIENT_LIST.SORT',
  PATIENT_LIST_SEARCH: 'PATIENT_LIST.SEARCH',
  PATIENT_LIST_TYPE: 'PATIENT_LIST.TYPE',
  PATIENT_LIST_PAGE: 'PATIENT_LIST.PAGE',
  PATIENT_LIST_LIMIT: 'PATIENT_LIST.LIMIT',
  PATIENT_LIST_FILTER_BY_PHYSICIAN: 'PATIENT_LIST.FILTER_BY_PHYSICIAN',
  PATIENT_LIST_FILTER_BY_HS: 'PATIENT_LIST.FILTER_BY_HS',
  PATIENT_LIST_FILTER_BY_RS: 'PATIENT_LIST.FILTER_BY_RS',
  SHOW_INVITE_TOOLBAR: 'SHOW_INVITE_TOOLBAR',

  /** Patient related actions */
  PATIENT_SET_INFO: 'PATIENT.SET_INFO',
  PATIENT_INVITE_SET_INFO: 'PATIENT_INVITE.SET_INFO',
  PATIENT_ERROR: 'PATIENT.ERROR',
  PATIENT_CLEAR_ERROR: 'PATIENT.CLEAR_ERROR',
  PATIENT_CLEAR_RECORD: 'PATIENT.CELAR_RECORD',
  PATIENT_TOPLINE_SEIZURES_REQUEST: 'PATIENT.TOPLINE_SEIZURES_REQUEST',
  PATIENT_TOPLINE_SEIZURES_SUCCESS: 'PATIENT.TOPLINE_SEIZURES_SUCCESS',
  PATIENT_TOPLINE_SEIZURES_ERROR: 'PATIENT.TOPLINE_SEIZURES_ERROR',
  PATIENT_TOPLINE_SYMPTOMS_REQUEST: 'PATIENT.TOPLINE_SYMPTOMS_REQUEST',
  PATIENT_TOPLINE_SYMPTOMS_SUCCESS: 'PATIENT.TOPLINE_SYMPTOMS_SUCCESS',
  PATIENT_TOPLINE_SYMPTOMS_ERROR: 'PATIENT.TOPLINE_SYMPTOMS_ERROR',
  PATIENT_LONG_TERM_SYMPTOMS_REQUEST: 'PATIENT.LONG_TERM_SYMPTOMS_REQUEST',
  PATIENT_LONG_TERM_SYMPTOMS_SUCCESS: 'PATIENT.LONG_TERM_SYMPTOMS_SUCCESS',
  PATIENT_LONG_TERM_SYMPTOMS_ERROR: 'PATIENT.LONG_TERM_SYMPTOMS_ERROR',
  PATIENT_LONG_TERM_SEIZURES_SUCCESS: 'PATIENT_LONG_TERM_SEIZURES.SUCCESS',
  PATIENT_LONG_TERM_SEIZURES_REQUEST: 'PATIENT_LONG_TERM_SEIZURES.REQUEST',
  PATIENT_LONG_TERM_SEIZURES_ERROR: 'PATIENT_LONG_TERM_SEIZURES.ERROR',
  PATIENT_LONG_TERM_MEDICATION_ADHERENCE_SUCCESS: 'PATIENT_LONG_TERM_MEDICATION_ADHERENCE.SUCCESS',
  PATIENT_LONG_TERM_MEDICATION_ADHERENCE_REQUEST: 'PATIENT_LONG_TERM_MEDICATION_ADHERENCE.REQUEST',
  PATIENT_LONG_TERM_MEDICATION_ADHERENCE_ERROR: 'PATIENT_LONG_TERM_MEDICATION_ADHERENCE.ERROR',
  PATIENT_REGIMEN_ASSIGNED: 'PATIENT.REGIMEN_ASSIGNED',
  PATIENT_MEDICATION_REGIMENS_SUCCESS: 'PATIENT_MEDICATION_REGIMENS.SUCCESS',
  PATIENT_MEDICATION_REGIMENS_REQUEST: 'PATIENT_MEDICATION_REGIMENS.REQUEST',
  PATIENT_MEDICATION_REGIMENS_ERROR: 'PATIENT_MEDICATION_REGIMENS.ERROR',
  PATIENT_MEDICATION_REGIMEN_DEACTIVATED: 'PATIENT_MEDICATION_REGIMEN.DEACTIVATED',
  PATIENT_THRESHOLDS_REQUEST: 'PATIENT_THRESHOLDS.REQUEST',
  PATIENT_THRESHOLDS_ERROR: 'PATIENT_THRESHOLDS.ERROR',
  PATIENT_THRESHOLDS_SUCCESS: 'PATIENT_THRESHOLDS.SUCCESS',
  PATIENT_SURVEYS_REQUEST: 'PATIENT_SURVEYS.REQUEST',
  PATIENT_SURVEYS_ERROR: 'PATIENT_SURVEYS.ERROR',
  PATIENT_SURVEYS_SUCCESS: 'PATIENT_SURVEYS.SUCCESS',
  PATIENT_ALL_SURVEYS_REQUEST: 'PATIENT_ALL_SURVEYS.REQUEST',
  PATIENT_ALL_SURVEYS_ERROR: 'PATIENT_ALL_SURVEYS.ERROR',
  PATIENT_ALL_SURVEYS_SUCCESS: 'PATIENT_ALL_SURVEYS.SUCCESS',
  PATIENT_ALL_SURVEYS_DEACTIVATED: 'PATIENT_ALL_SURVEYS.DEACTIVATED',
  PATIENT_THRESHOLD_EVENTS_REQUEST: 'PATIENT_THRESHOLD_EVENTS.REQUEST',
  PATIENT_THRESHOLD_EVENTS_ERROR: 'PATIENT_THRESHOLD_EVENTS.ERROR',
  PATIENT_THRESHOLD_EVENTS_SUCCESS: 'PATIENT_THRESHOLD_EVENTS.SUCCESS',
  PATIENT_THRESHOLD_SEEN_SUCCESS: 'PATIENT_THRESHOLD_SEEN_SUCCESS',
  PATIENT_UNLINK_SUCCESS: 'PATIENT_UNLINK.SUCCESS',
  PATIENT_REVIEW_STATUS_SUCCESS: 'PATIENT_REVIEW_STATUS_SUCCESS',
  PATIENT_REFRACTORY_SUCCESS: 'PATIENT_REFRACTORY_SUCCESS',
  PATIENT_CLEAR_REFRACATORY: 'PATIENT_CLEAR_REFRACATORY',
  PATIENT_REFRACTORY_FAILURE: 'PATIENT_REFRACTORY_FAILURE',
  PATIENT_ATTACHMENTS_SUCCESS: 'PATIENT_ATTACHMENTS_SUCCESS',
  PATIENT_EMR_REPORT_SUCCESS: 'PATIENT_EMR_REPORT_SUCCESS.SUCCESS',
  PATIENT_EMR_REPORT_REQUEST: 'PATIENT_EMR_REPORT_REQUEST.REQUEST',
  PATIENT_EMR_REPORT_ERROR: 'PATIENT_EMR_REPORT_ERROR.ERROR',
  PATIENT_MEDICAL_HISTORY_SUCCESS: 'PATIENT_MEDICAL_HISTORY_SUCCESS',
  PATIENT_MEDICAL_HISTORY_REQUEST: 'PATIENT_MEDICAL_HISTORY_REQUEST.REQUEST',
  PATIENT_MEDICAL_HISTORY_ERROR: 'PATIENT_MEDICAL_HISTORY_ERROR.ERROR',

  INVITE_STUDY_PATIENT: 'INVITE_STUDY_PATIENT',
  INVITE_PATIENT_SUCCESS: 'INVITE_PATIENT_SUCCESS',

  /** Thresholds related actions */
  THRESHOLD_SYMPTOM_PRESETS_REQUEST: 'THRESHOLD_SYMPTOM_PRESETS.REQUEST',
  THRESHOLD_SYMPTOM_PRESETS_SUCCESS: 'THRESHOLD_SYMPTOM_PRESETS.SUCCESS',
  THRESHOLD_SYMPTOM_PRESETS_ERROR: 'THRESHOLD_SYMPTOM_PRESETS.ERROR',
  THRESHOLD_SYMPTOM_PRESETS_CLEAR: 'THRESHOLD_SYMPTOM_PRESETS.CLEAR',

  /** HCP users related actions */
  CURRENT_USER_SUCCESS: 'CURRENT_USER.SUCCESS',
  CURRENT_USER_PREFERENCE: 'CURRENT_PREFERENCE.SUCCESS',
  CURRENT_USER_ERROR: 'CURRENT_USER.ERROR',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE.SUCCESS',
  PHYSICIANS_LIST_SUCCESS: 'PHYSICIANS_LIST.SUCCESS',
  PHYSICIANS_LIST_ERROR: 'PHYSICIANS_LIST.ERROR',
  NILE_NEXT_PHYSICIANS_LIST_SUCCESS: 'NILE_NEXT_PHYSICIANS_LIST_SUCCESS.SUCCESS',
  NILE_NEXT_PHYSICIANS_LIST_ERROR: 'NILE_NEXT_PHYSICIANS_LIST_ERROR.ERROR',

  /** Titrations related actions  */
  TITRATION_DELETED: 'TITRATION.DELETED',
  TITRATION_CREATED: 'TITRATION.CREATED',
  TITRATION_LIST_REQUEST: 'TITRATION_LIST.REQUEST',
  TITRATION_LIST_SUCCESS: 'TITRATION_LIST.SUCCESS',
  TITRATION_LIST_ERROR: 'TITRATION_LIST.ERROR',

  /** App related actions */
  APP_PUSH_BUSY: 'APP.PUSH_BUSY',
  APP_POP_BUSY: 'APP.POP_BUSY',
  APP_PUSH_NOTIFICATION: 'APP.PUSH_NOTIFICATION',
  APP_POP_NOTIFICATION: 'APP.POP_NOTIFICATION',
  APP_SET_API_VERSION: 'APP.SET_API_VERSION',

  /** FAQ related actions */
  FAQ_FETCH_SUCCESS: 'FAQ_FETCH.SUCCESS',
  FAQ_FETCH_ERROR: 'FAQ_FETCH.ERROR',

  /** Survey related actions */
  SURVEY_LIST_REQUEST: 'SURVEY_LIST.REQUEST',
  SURVEY_LIST_SUCCESS: 'SURVEY_LIST.SUCCESS',
  SURVEY_LIST_ERROR: 'SURVEY_LIST.ERROR',
  SURVEY_LIST_SORT: 'SURVEY_LIST.SORT',
  SURVEY_ASSIGN_REQUEST: 'SURVEY_ASSIGN.REQUEST',
  SURVEY_ASSIGN_SUCCESS: 'SURVEY_ASSIGN.SUCCESS',
  SURVEY_ASSIGN_ERROR: 'SURVEY_ASSIGN.ERROR',

  /** Calenndar related actions */
  PHYSICIAN_OPEN_SLOT_REQUEST: 'PHYSICIAN_OPEN_SLOT.REQUEST',
  PHYSICIAN_OPEN_SLOT_SUCCESS: 'PHYSICIAN_OPEN_SLOT.SUCCESS',
  PHYSICIAN_OPEN_CALENDAR_SLOT_SUCCESS: 'PHYSICIAN_OPEN_CALENDAR_SLOT_SUCCESS.SUCCESS',
  PHYSICIAN_OPEN_CALENDAR_SLOT_REQUEST: 'PHYSICIAN_OPEN_CALENDAR_SLOT_REQUEST.SUCCESS',
  PHYSICIAN_OPEN_CALENDAR_SLOT_ERROR: 'PHYSICIAN_OPEN_CALENDAR_SLOT_ERROR.SUCCESS',
  PHYSICIAN_OPEN_SLOT_ERROR: 'PHYSICIAN_OPEN_SLOT.ERROR',
  PHYSICIAN_OPEN_SLOT_DELETE_SUCCESS: 'PHYSICIAN_OPEN_SLOT_DELETE.SUCCESS',
  PHYSICIAN_OPEN_SLOT_CREATE_SUCCESS: 'PHYSICIAN_OPEN_SLOT_CREATE.SUCCESS',
  PHYSICIAN_APPOINTMENT_REQUEST: 'PHYSICIAN_APPOINTMENT_REQUEST.REQUEST',
  PHYSICIAN_APPOINTMENT_SUCCESS: 'PHYSICIAN_APPOINTMENT_SUCCESS.SUCCESS',
  PHYSICIAN_APPOINTMENT_ERROR: 'PHYSICIAN_APPOINTMENT_ERROR.ERROR',
  PHYSICIAN_APPOINTMENT_CANCEL_REQUEST: 'PHYSICIAN_APPOINTMENT_CANCEL_REQUEST.REQUEST',
  PHYSICIAN_APPOINTMENT_CANCEL_SUCCESS: 'PHYSICIAN_APPOINTMENT_CANCEL_SUCCESS.SUCCESS',
  PHYSICIAN_APPOINTMENT_CANCEL_ERROR: 'PHYSICIAN_APPOINTMENT_CANCEL_ERROR.ERROR',
  PATIENT_APPOINTMENT_REQUEST: 'PATIENT_APPOINTMENT_REQUEST.REQUEST',
  PATIENT_APPOINTMENT_SUCCESS: 'PATIENT_APPOINTMENT_SUCCESS.SUCCESS',
  PATIENT_APPOINTMENT_ERROR: 'PATIENT_APPOINTMENT_ERROR.ERROR',
  PATIENT_CONSENT_REQUEST: 'PATIENT_CONSENT_REQUEST.REQUEST',
  PATIENT_CONSENT_SUCCESS: 'PATIENT_CONSENT_SUCCESS.SUCCESS',
  PATIENT_CONSENT_ERROR: 'PATIENT_CONSENT_ERROR.ERROR',
  PATIENT_INSURANCE_REQUEST: 'PATIENT_INSURANCE_REQUEST.REQUEST',
  PATIENT_INSURANCE_SUCCESS: 'PATIENT_INSURANCE_SUCCESS.SUCCESS',
  PATIENT_INSURANCE_ERROR: 'PATIENT_INSURANCE_ERROR.ERROR',
  PATIENT_INSURANCE_COMPLETED_REQUEST: 'PATIENT_INSURANCE_COMPLETED_REQUEST.REQUEST',
  PATIENT_INSURANCE_COMPLETED_SUCCESS: 'PATIENT_INSURANCE_COMPLETED_SUCCESS.SUCCESS',
  PATIENT_INSURANCE_COMPLETED_ERROR: 'PATIENT_INSURANCE_COMPLETED_ERROR.ERROR',
  PATIENT_INSURANCE_UPDATED_REQUEST: 'PATIENT_INSURANCE_UPDATED_REQUEST.REQUEST',
  PATIENT_INSURANCE_UPDATED_SUCCESS: 'PATIENT_INSURANCE_UPDATED_SUCCESS.SUCCESS',
  PATIENT_INSURANCE_UPDATED_ERROR: 'PATIENT_INSURANCE_UPDATED_ERROR.ERROR',
  PATIENT_MEDICAL_UPDATED_REQUEST: 'PATIENT_MEDICAL_UPDATED_REQUEST.REQUEST',
  PATIENT_MEDICAL_UPDATED_SUCCESS: 'PATIENT_MEDICAL_UPDATED_SUCCESS.SUCCESS',
  PATIENT_MEDICAL_UPDATED_ERROR: 'PATIENT_MEDICAL_UPDATED_ERROR.ERROR',
  PATIENT_INSURANCE_CLEAR_INFO: 'PATIENT_INSURANCE_CLEAR_INFO',
  PATIENT_APPOINTMENT_FLAG_VIEWED: 'PATIENT_APPOINTMENT_FLAG_VIEWED',
};

export default ACTION_TYPES;
