/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

export default {
  MuiTypography: {
    paragraph: {
      marginBottom: 32,
    },
  },
};
