/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnArrowUpIcon = () => (
  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path fill="#666" d="M17 14l-5-5-5 5h10z" />
  </svg>
);


export default KnArrowUpIcon;
