
import _ from 'lodash';
import React, {
  useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import {
  TITRATION_OFFSET_FORMAT,
} from 'Constants';
import { KnContrastTextField } from 'components/TextField';
import PropTypes from 'prop-types';

const KnOffsetBoxChange = (props) => {
  const {
    medication, medications, setMedications, dosagesUpdated, setDosagesUpdated,
    control,
    errors,
    setError,
    clearError,
    handleOnChange,
  } = props;
  const { t: translate } = useTranslation();
  const [offsetValue, setOffsetValue] = useState(medication.offset || null);
  const [style, setStyle] = useState('');
  const medName = `offset-add-${medication.id}`;
  const customeStyle = `MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorSecondary MuiFilledInput-colorSecondary MuiInputBase-fullWidth ${style} MuiInputBase-formControl`;

  const addMedicationOffset = useCallback((val) => {
    if (!RegExp(TITRATION_OFFSET_FORMAT).test(val)) {
      setStyle('Mui-error Mui-error');
      setError(medName, 'valueFormat');
    } else {
      setStyle('Mui-focused Mui-focused');
      clearError(medName, '');
    }
    setMedications(medications.map((m) => {
      if (medication.id === m.id) {
        return { ...m, offset: val === '' ? null : Number(val) };
      }
      return m;
    }));
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [
    clearError,
    dosagesUpdated,
    medName, medication.id,
    medications, setDosagesUpdated,
    setError, setMedications]);

  const handleOffset = (e) => {
    const val = e.target.value;
    const lastText = (val[val.length - 1]) ? val[val.length - 1] : '';
    if (Number.isInteger(+lastText) || lastText === '') {
      setStyle('Mui-focused Mui-focused');
      setOffsetValue(val);
      addMedicationOffset(val);
    }
  };
  const handleOnBlur = () => {
    if (_.get(errors, `${medName}.type`) === 'valueFormat') {
      setStyle('Mui-error Mui-error');
    } else {
      setStyle('');
      handleOnChange(offsetValue);
    }
  };
  return (
    <Box>
      <KnContrastTextField
        className={customeStyle}
        disableLabel
        name={medName}
        onChange={handleOffset}
        onBlur={handleOnBlur}
        control={control}
        errors={errors}
        type="number"
        disableErrorMessage
        format={TITRATION_OFFSET_FORMAT}
        notEqualTo={0}
        value={offsetValue}
        label={translate('TITRATIONS.titrationBuilder.addOffset')}
        onWheel={(event) => event.target.blur()}
      />
      {/* <FormHelperText
        error
        data-testid="strength-validation-error"
        style={{
          marginTop: '-20px',
          marginLeft: '0px',
        }}
      >
        {
              (_.get(errors, `${medName}.type`) === 'valueFormat')
              && translate('FIELD_VALIDATION_MESSAGES.offsetLimit')
            }
      </FormHelperText> */}
    </Box>
  );
};

KnOffsetBoxChange.defaultProps = {
  medication: null,
  medications: [],
  dosagesUpdated: false,
  control: null,
  errors: null,
  handleOnChange: () => {},
};

KnOffsetBoxChange.propTypes = {
  medication: PropTypes.shape(),
  setMedications: PropTypes.func.isRequired,
  setDosagesUpdated: PropTypes.func.isRequired,
  dosagesUpdated: PropTypes.bool,
  medications: PropTypes.arrayOf(PropTypes.shape()),
  control: PropTypes.shape(),
  errors: PropTypes.shape(),
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func,
};
export default KnOffsetBoxChange;
