/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import KnDialog from './Dialog';
import useCognitoUser from '../../utils/cognitoUser';

const KnDialogServiceContext = createContext(Promise.reject);

export const KnDialogServiceProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState(null);
  const awaitingPromiseRef = useRef();
  const cognitoUser = useCognitoUser();

  const handleClose = () => {
    /**
     * Note: we don't reject the promise on cancel as we usually
     * don't want to do on cancel anything more than closing
     * the modal. To be revisited if we ever need to subscribe
     * to promise rejection.
     */
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject(null);
    }
    setDialogState(null);
  };

  /**
   * Custom dialogs might submit a value.
   * forwarding it with the promise resolve to the dialog caller
   * @param {any} value Optional value from the dialog
   */
  const handleSubmit = (value) => {
    /**
     * On submit, we resolve the promise so that any subscriber will
     * be notified and can handle dialog submition.
     */
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }

    setDialogState(null);
  };

  const openDialog = (options) => {
    setDialogState(options);
    return new Promise((resolve, reject) => {
      /** Save the promise result to the ref. */
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  return (
    <KnDialogServiceContext.Provider
      value={openDialog}
    >
      {children}
      {!!dialogState && !dialogState.customDialog && (
        <KnDialog
          open={!!dialogState}
          onClose={handleClose}
          onSubmit={handleSubmit}
          {...dialogState}
        />
      )}
      {!!dialogState && dialogState.customDialog && (
        <dialogState.customDialog
          open={!!dialogState && !!cognitoUser}
          onClose={handleClose}
          onSubmit={handleSubmit}
          inputData={dialogState.inputData}
        />
      )}
    </KnDialogServiceContext.Provider>
  );
};

KnDialogServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDialog = () => React.useContext(KnDialogServiceContext);
export default useDialog;
