/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from 'styles/colors';

const fieldColor = palette.black.black1;
const labelColor = fade(palette.black.black1, 0.5);
const labelErrorColor = palette.error.main;
const textFieldBackgroundColor = palette.white.white2;
const alignmentOffset = 8; /** offset alignment of input and label */

/** styles of the label when input is focused
 * OR when there is a value in the input - in both cases
 * the label will be floating.
 */
const floatingLabelStyles = {
  textTransform: 'uppercase',
  color: fieldColor,
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: 0.5,
};

const underlineStyles = {
  '&::before': {
    borderColor: 'transparent',
  },
  '&.Mui-disabled::before': {
    borderColor: 'transparent',
  },
  '&:hover:not(.Mui-disabled)::before': {
    borderColor: 'transparent',
  },
};

export default {
  MuiTextField: {
    root: {
      borderRadius: 0,
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: textFieldBackgroundColor,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      '&:hover': {
        backgroundColor: textFieldBackgroundColor,
      },
      '&.Mui-focused': {
        backgroundColor: textFieldBackgroundColor,
      },
    },

    input: {
      color: fieldColor,
      paddingLeft: alignmentOffset,
      paddingRight: alignmentOffset,
    },

    underline: underlineStyles,
  },

  MuiInput: {
    underline: underlineStyles,
  },

  MuiInputLabel: {
    root: {
      color: labelColor,

      '&.Mui-focused': {
        ...floatingLabelStyles,
        '&.Mui-error': {
          color: labelErrorColor,
        },
      },
      '&.MuiFormLabel-filled': {
        ...floatingLabelStyles,
        '&.Mui-error': {
          color: labelErrorColor,
        },
      },
      '&.Mui-error:not(.Mui-focused):not(.MuiFormLabel-filled)': {
        /** On error we want the label to have the same color */
        color: labelColor,
      },
      '&.Mui-disabled': {
        color: fieldColor,
      },
    },

    filled: {
      '&.MuiInputLabel-shrink': {
        /** override the horizontal translate value to match the alignment of the label */
        transform: `translate(${alignmentOffset}px, 10px) scale(0.75)`,
      },
    },
  },

  MuiFormHelperText: {
    root: {
      fontSize: 11,
      lineHeight: 2,
      marginTop: 0,
      marginLeft: alignmentOffset,
      marginRight: alignmentOffset,

      '&.Mui-error': {
        /** Should be enough for one line of text.
          * We set it so it will ocuppy space even if the element is empty. */
        minHeight: 22,
      },
    },
  },
};
