/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const highlightText = palette.primary;
const darkerText = palette.black.black3;
const blackText = palette.black.black2;
const lightText = palette.steel;
const noteText = palette.lightGrey;

const KnBaseTypographyComponent = ({ marginBottom, ...rest }) => <div {...rest} />;

const KnSectionHeader = styled(KnBaseTypographyComponent)({
  textTransform: 'uppercase',
});

const KnBoldSectionHeader = styled(KnSectionHeader)(({ marginBottom }) => ({
  fontWeight: 'bold',
  marginBottom: marginBottom ? 32 : 0,
}));

const KnSubtitleText = styled('span')({
  fontSize: 14,
  lineHeight: 1.3,
  color: darkerText,
});

const KnSmallLabel = styled('span')({
  fontSize: 12,
  letterSpacing: 0.5,
  lineHeight: 1.6,
});

const KnSmallBlackLabel = styled('span')({
  fontSize: 12,
  color: blackText,
});

const KnSubtleText = styled('span')({
  color: palette.brownishGrey,
});

const KnCardsSubtitle = styled('span')({
  fontSize: 12,
  color: noteText,
});

const KnHighlightText = styled('span')({
  color: highlightText,
});

const KnHighlightTextDosage = styled('span')({
  color: darkerText,
});

const KnDarkerText = styled('span')({
  color: darkerText,
});

const KnLightText = styled('span')({
  color: lightText,
});

const KnColoredText = styled('span')(({ color }) => ({
  color,
}));

const KnSectionHeaderCapatalize = styled(KnBaseTypographyComponent)({
  textTransform: 'capatalize',
});

const KnBold = styled('span')({
  fontWeight: 500,
});

const KnSmall = styled('p')({
  fontSize: 14,
});

const KnDarkGreyText = styled('span')({
  fontSize: 14,
  lineHeight: 1.3,
  color: palette.darkGrey,
});

KnBaseTypographyComponent.propTypes = {
  marginBottom: PropTypes.bool,
};

KnBaseTypographyComponent.defaultProps = {
  marginBottom: false,
};

export {
  KnSectionHeader,
  KnBoldSectionHeader,
  KnSubtitleText,
  KnSmallLabel,
  KnSubtleText,
  KnCardsSubtitle,
  KnHighlightText,
  KnHighlightTextDosage,
  KnDarkerText,
  KnLightText,
  KnColoredText,
  KnSectionHeaderCapatalize,
  KnSmallBlackLabel,
  KnBold,
  KnSmall,
  KnDarkGreyText,
};
