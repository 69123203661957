/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnCheckIcon = (props) => {
  const { fill = '#6FCF97', width } = props;
  return (
    <SvgIcon {...props} style={{ width }}>
      <path fill={fill} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
    </SvgIcon>
  );
};

KnCheckIcon.defaultProps = {
  fill: '#6FCF97',
  width: '22px',
};

KnCheckIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
};

export default KnCheckIcon;
