/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useState, useCallback, useLayoutEffect } from 'react';
import PropTypes, { func, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  API_REQUEST_ERROR_CODES,
  APP_PAGE_URLS,
  TITRATION_NAME_FORMAT,
} from 'Constants';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import KnCheckOutlineIcon from 'components/icons/CheckOutlineIcon';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import palette from 'styles/colors';
import titrationActions from 'redux/actions/titrationActions';

const iconColor = palette.lemonYellow;
const successBox = palette.primary;
const boxContrast = palette.white.white2;
const { teal } = palette;

const KnSuccessBox = styled(Box)(({ theme }) => ({
  minHeight: 682,
  minWidth: 817,
  backgroundColor: successBox,

  '& > *': {
    marginBottom: theme.spacing(5),
  },
}));

const KnSuccessActionButton = styled(KnButton)({
  backgroundColor: boxContrast,
  color: teal,
  width: 275,

  '&:hover': {
    backgroundColor: darken(boxContrast, 0.1),
  },
});

const KnCardCheckOutlineIcon = styled(KnCheckOutlineIcon)({
  postion: 'absolute', fontSize: '97px', marginTop: '-120px', width: '97px', height: '97px',
});

const KnCardTypography = styled(Typography)({ marginTop: '-25px', marginLeft: '30px' });

const KnCardTypography1 = styled(Typography)(({ checkBox }) => ({
  opacity: checkBox ? 1 : 0, marginTop: '-20px', marginLeft: '90px', cursor: checkBox ? 'pointer' : 'default',
}));

const KnCardDiv = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  top: '45px',
  marginBottom: '-26px',
});

const KnContrastTextFieldCard = styled(KnContrastTextField)({
  '& .MuiFilledInput-root': {
    borderRadius: '0px !important',
  },
});

const KnValidatedTextFieldChange = styled(KnValidatedTextField)(({ checkBox }) => ({
  width: '300px',
  opacity: checkBox ? 1 : 0,
  cursor: checkBox ? 'text' : 'default',
}));

const KnSuccessConfirmationCard = ({
  message,
  buttonLabel,
  buttonProps,
  secondaryButtonLabel,
  secondaryButtonProps,
  setCheckBox,
  primaryButtonLabelFirst,
  primaryButtonLabelSecond,
  checkBox,
  checkBoxLabel,
  submitTitration,
  templateName,
  settemplateName,
  control,
  errors,
  setValue,
  handleSubmit,
  register,
  patientType,
  getValues,
  patientId,
  ...rest
}) => {
  const history = useHistory();
  return (
    <KnSuccessBox
      alignItems="center"
      alignSelf="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      color={boxContrast}
      {...rest}
    >
      <KnCardCheckOutlineIcon color={iconColor} />

      <Typography variant="h5" component={KnBoldSectionHeader} position="absolute">
        {message}
      </Typography>
      <Box position="relative" bottom="-30px">
        <Checkbox
          checked={checkBox}
          onChange={(e, checked) => {
            setCheckBox(checked);
          }}
          style={{ transform: 'scale(1.5)' }}
          color="white"
          inputProps={{ 'data-testid': `custom-dosage-checkbox-${1 + 1}` }}
        />
        <KnCardTypography style={{ fontSize: '18px' }} variant="h7" component={KnBoldSectionHeader}>
          {checkBoxLabel}
        </KnCardTypography>
      </Box>
      <form>
        <KnValidatedTextFieldChange
          Component={KnContrastTextFieldCard}
          name="templateName"
          label="Enter Template Name"
          disabled={!checkBox}
          disableErrorMessage={!checkBox}
          control={control}
          errors={errors}
          required
          trimSpaces
          format={TITRATION_NAME_FORMAT}
          defaultValue=""
          maxLength={128}
          checkBox={checkBox}
        />
        <KnCardTypography1
          checkBox={checkBox}
          variant="h7"
          onClick={() => { if (checkBox) handleSubmit(); }}
          component={KnBoldSectionHeader}
        >
          {secondaryButtonLabel}
        </KnCardTypography1>
      </form>
      <KnCardDiv>
        <KnSuccessActionButton
          onClick={() => {
            if (patientType) {
              history.push(
                APP_PAGE_URLS.invitedPatientRecord.replace(':patientId', patientId),
                { patientType: true },
              );
            } else history.push(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
          }}
          data-testid="view-patient-record-button"
        >
          <Typography variant="h7" component={KnBoldSectionHeader}>
            {primaryButtonLabelSecond}
          </Typography>
        </KnSuccessActionButton>
        {(!patientType) && (
          <KnSuccessActionButton {...secondaryButtonProps}>
            <Typography variant="h7" component={KnBoldSectionHeader}>
              {primaryButtonLabelFirst}
            </Typography>
          </KnSuccessActionButton>
        )}
      </KnCardDiv>

    </KnSuccessBox>
  );
};

const KnAssignRegimenConfirmationCard = (props) => {
  /** 'assignedAt' is null when it was a new regimen asignment */
  const {
    patientId,
    patientName,
    medications,
    assignedAt,
    presetId,
    patientType,
  } = props;
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [templateName, settemplateName] = useState('');
  const history = useHistory();
  const { stat = {} } = history.location;
  const { redirectPath } = stat;
  const patientNames = (patientName) ? `${patientName}'s` : '';


  /**
   * Take the thresholds from the patient record. In normal flow,
   * a user shouldn't be able to reach this step other than coming
   * from the patient record page. We fetched the thresholds there
   * therefore these should be available in the store.
   */

  const {
    control,
    errors,
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: thresholds,
  } = useSelector((state) => state.patientRecord.thresholds);

  const submitTitration = useCallback((data) => (
    dispatch(titrationActions.saveTitration(data, presetId))
      .then(() => {
        if (patientType) {
          history.push(
            APP_PAGE_URLS.invitedPatientRecord.replace(':patientId', patientId),
            { patientType: true },
          );
        } else history.push(redirectPath || APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
      }, (errorCode) => {
        if (errorCode === API_REQUEST_ERROR_CODES.TITRATION_PRESET_EXISTS) {
          setError('templateName', 'uniquePreset');
        }
      })
  ), [dispatch, presetId, history, redirectPath, patientId, setError, patientType]);

  const onSubmit = (formData) => {
    const data = { medications, name: formData.templateName };
    submitTitration(data);
  };

  return (
    <KnSuccessConfirmationCard
      message={translate('ASSIGN_REGIMEN.successfulAssignMessage', { name: patientNames })}
      buttonLabel={translate('ASSIGN_REGIMEN.viewPatientRegimen', { name: patientNames })}
      setCheckBox={setCheckBox}
      templateName={templateName}
      getValues={getValues}
      setValue={setValue}
      register={register}
      handleSubmit={handleSubmit(onSubmit)}
      settemplateName={settemplateName}
      submitTitration={() => {
        const data = { medications, name: templateName };
        submitTitration(data);
      }}
      checkBox={checkBox}
      buttonProps={{
        route: (patientType) ? APP_PAGE_URLS.invitedPatientRecord.replace(':patientId', patientId)
          : APP_PAGE_URLS.patientRecord.replace(':patientId', patientId),
        'data-testid': 'view-patient-record-button',
      }}
      checkBoxLabel={translate('ASSIGN_REGIMEN.saveRegimenTemplate')}
      primaryButtonLabelFirst={translate('ASSIGN_REGIMEN.continueToThreshold')}
      primaryButtonLabelSecond={translate('ASSIGN_REGIMEN.returnToPatient')}
      secondaryButtonLabel={translate('ASSIGN_REGIMEN.saveTemplate', { name: patientName })}
      control={control}
      errors={errors}
      secondaryButtonProps={{
        route: APP_PAGE_URLS.threshold.replace(':patientId', patientId),
        routeParams: {
          activeRegimen: {
            medications,
            assignedAt,
          },
          patientName,
          thresholds,
        },
      }}
      patientType={patientType}
      data-testid="titration-saved-confirmation-card"
      patientId={patientId}
    />
  );
};

KnAssignRegimenConfirmationCard.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  medications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assignedAt: PropTypes.instanceOf(Date),
  presetId: string,
  patientType: PropTypes.bool,
};

KnAssignRegimenConfirmationCard.defaultProps = {
  assignedAt: undefined,
  presetId: PropTypes.string,
  patientType: false,
};

KnSuccessConfirmationCard.defaultProps = {
  secondaryButtonLabel: null,
  secondaryButtonProps: {},
  setCheckBox: func,
  submitTitration: func,
  settemplateName: func,
  templateName: string,
  getValues: func,
  setValue: func,
  register: func,
  handleSubmit: func,
  patientType: false,
};

KnSuccessConfirmationCard.propTypes = {
  patientId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape().isRequired,
  checkBox: PropTypes.bool.isRequired,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonProps: PropTypes.shape(),
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  setCheckBox: PropTypes.func,
  settemplateName: PropTypes.func,
  submitTitration: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  handleSubmit: PropTypes.func,
  templateName: PropTypes.string,
  patientType: PropTypes.bool,
  checkBoxLabel: PropTypes.string.isRequired,
  primaryButtonLabelFirst: PropTypes.string.isRequired,
  primaryButtonLabelSecond: PropTypes.string.isRequired,
};

export default KnAssignRegimenConfirmationCard;
