import { differenceInHours } from 'date-fns';

export const SLOT_STATUS = {
  BOOKED: 'booked',
  NO_SHOW: 'noShow',
  OPEN: 'open',
  RESERVED: 'reserved',
};

export const slotTotalHours = (dateRange) => differenceInHours(
  new Date(dateRange.end), new Date(dateRange.start),
);
