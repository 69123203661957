/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KnActionLink } from 'components/Link';
import KnPlusIcon from 'components/icons/PlusIcon';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import { BUILDER_INITAL_STATE } from 'Constants';
import {
  withKeyNamespace,
} from 'utils/utils';
import { Box } from '@material-ui/core';
import KnTitrationFormDosage from './TitrationFormDosage';

import KnTitrationBuilder from './TitrationBuilder/TitrationBuilder';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');


/**
 * Component used by the KnTitrationForm,
 * for editing one medication of the titration
 */
const KnTitrationFormMedication = (props) => {
  const { t: translate } = useTranslation();
  const {
    index: medIndex,
    context,
    medication,
    onAddDosage,
    onRemoveMediction,
    onMedicationChange,
    onMedicationDosageRemove,
    dataTestId,
    onChangeDosage,
    showDeleteMedication,
    medications,
    setDosagesUpdated,
    dosagesUpdated,
    setMedications,
    onTitrationBuilderAddDosage,
    onSmartTitrationAddDosage,
    ...rest
  } = props;
  const {
    dosages,
    id: medId,
    unit,
    medicationStrength,
    medicationType,
  } = medication;
  const [lastDosageDeleted, setLastDosageDeleted] = useState(false);
  const [isSmartTitration, setIsSmartTitration] = useState(false);
  const [titrationState, setTitrationState] = useState(BUILDER_INITAL_STATE);

  /**
   * Droppable and draggable must share the same type
   * to prevent other type draggables to be dropped.
   */
  const droppableType = `med-${medId}`;

  const onMedicationChangeHandler = useCallback((dosageId, updates) => {
    onMedicationChange(medId, dosageId, updates, isSmartTitration);
  }, [medId, onMedicationChange, isSmartTitration]);

  const onMedicationDosageRemoveHandler = useCallback((dosageId, lastDosage) => {
    onMedicationDosageRemove(medId, dosageId);
    if (lastDosage) {
      setLastDosageDeleted(true);
    }
  }, [medId, onMedicationDosageRemove]);

  return (
    <Droppable droppableId={medId.toString()} type={droppableType} direction="vertical">
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} data-testid={dataTestId}>
          <Box pb={5}>
            <KnTitrationBuilder
              medication={medication}
              medications={medications}
              setMedications={setMedications}
              dosagesUpdated={dosagesUpdated}
              setDosagesUpdated={setDosagesUpdated}
              onTitrationBuilderAddDosage={onTitrationBuilderAddDosage}
              onSmartTitrationAddDosage={onSmartTitrationAddDosage}
              lastDosageDeleted={lastDosageDeleted}
              setLastDosageDeleted={setLastDosageDeleted}
              isSmartTitration={isSmartTitration}
              setIsSmartTitration={setIsSmartTitration}
              onChangeDosage={onChangeDosage}
              onMedicationChange={onMedicationChange}
              medIndex={medIndex}
              setTitrationState={setTitrationState}
              titrationState={titrationState}
            />
            {dosages.map((dosage, index) => (
              <Draggable
                key={dosage.keyId}
                draggableId={dosage.keyId}
                index={index}
                type={droppableType}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    data-testid={`dosage-wrapper-${index + 1}`}
                    {...draggableProvided.draggableProps}
                  >
                    <KnTitrationFormDosage
                      dosage={dosage}
                      unit={unit}
                      medicationStrength={medicationStrength}
                      medicationType={medicationType}
                      medicationStrengthSet={!!medicationStrength}
                      index={index}
                      onChange={onMedicationChangeHandler}
                      onRemove={onMedicationDosageRemoveHandler}
                      dragHandleProps={draggableProvided.dragHandleProps}
                      lastDosage={dosages.length === index + 1}
                      isSmartTitration={isSmartTitration}
                      titrationState={titrationState}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            <div>
              {!snapshot.isDraggingOver && !snapshot.draggingFromThisWith && (
                <>
                  <KnActionLink
                    onClick={() => onAddDosage(medId)}
                    LhsIcon={KnPlusIcon}
                    style={{ float: 'left' }}
                    data-testid="add-dosage-link"
                  >
                    {translate(i18nKey('addDosage'))}
                  </KnActionLink>
                  {showDeleteMedication && (
                    <KnActionLink
                      onClick={() => onRemoveMediction(medId)}
                      LhsIcon={KnDeleteIcon}
                      tooltip
                      tooltipText={translate('ICON_TOOLTIP.delete')}
                      style={{ float: 'right' }}
                      data-testid="delete-medication-link"
                    />
                  )}
                </>
              )}
            </div>
          </Box>
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
KnTitrationFormMedication.defaultProps = {
  onRemoveMediction: () => { },
  context: '',
};
KnTitrationFormMedication.propTypes = {
  index: PropTypes.number.isRequired,
  medication: PropTypes.shape({
    id: PropTypes.number.isRequired,
    activeIngredient: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    medicationStrength: PropTypes.number,
    medicationType: PropTypes.string,
    medicationTypeUnit: PropTypes.string,
    dosages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  setMedications: PropTypes.func.isRequired,
  setDosagesUpdated: PropTypes.func.isRequired,
  dosagesUpdated: PropTypes.bool.isRequired,
  medications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddDosage: PropTypes.func.isRequired,
  onChangeDosage: PropTypes.func.isRequired,
  onMedicationChange: PropTypes.func.isRequired,
  onMedicationDosageRemove: PropTypes.func.isRequired,
  onTitrationBuilderAddDosage: PropTypes.func.isRequired,
  onSmartTitrationAddDosage: PropTypes.func.isRequired,
  onRemoveMediction: PropTypes.func,
  dataTestId: PropTypes.string.isRequired,
  showDeleteMedication: PropTypes.bool.isRequired,
  context: PropTypes.string,
};
export default KnTitrationFormMedication;
