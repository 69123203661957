/* eslint-disable jsx-a11y/img-redundant-alt */
/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { styled, Typography } from '@material-ui/core';
import {
  KnHighlightTextDosage,
  KnSmallBlackLabel,
  KnSubtitleText,
  KnSubtleText,
} from 'components/Typography';
import { numberWithCommas } from 'utils/utils';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import TargetDoseBarChart from '../../charts/TargetDoseBarChart';

const KnYLabel = styled(Typography)({
  position: 'absolute',
  transform: 'rotate(-90deg)',
  bottom: 18,
  left: 35,
  top: 15,
});

const KnXLabel = styled(Typography)({
  marginLeft: 60,
  position: 'absolute',
  top: -5,
});

const KnFDALabel = styled(Typography)({
  color: palette.error.main,
  position: 'absolute',
  left: 58,
  bottom: -6,
});

const KnFDALabelRight = styled(Typography)({
  color: palette.error.main,
  position: 'absolute',
  right: 16,
  bottom: -4,
});

const KnTypography = styled(Typography)({
  fontSize: 14,
  color: palette.plainGrey,
});

const KnBoxLink = styled(Typography)({
  fontSize: 14,
  color: palette.primary,
  fontWeight: 500,
  cursor: 'pointer',
});

const BorderRightBox = styled(Box)({
  borderRight: `1px solid ${palette.silver.silver3}`,
});


const KnTargetDosageInfo = ({
  chartId, stats, uptodate,
}) => {
  const { t: translate } = useTranslation();

  const handleClick = useCallback(() => {
    window.open(stats.fdaLink, '_blank');
  }, [stats]);

  const handleLinkClick = useCallback(() => {
    window.open(uptodate, '_blank');
  }, [uptodate]);

  return (
    <Box ml={7} mr={7}>
      <Typography variant="h6" component={KnHighlightTextDosage}>
        {translate('TITRATION_BUILDER.TARGET_DOSE.title')}
      </Typography>
      <Box display="flex">
        <BorderRightBox width="50%" pt={3}>
          <Box position="relative" maxWidth="90%">
            {stats.doses ? (
              <>
                <KnXLabel variant="caption" component={KnSmallBlackLabel}>
                  {translate('TITRATION_BUILDER.TARGET_DOSE.xLabel', { unit: stats.unit })}
                </KnXLabel>
                <KnYLabel variant="caption" component={KnSmallBlackLabel}>
                  {translate('TITRATION_BUILDER.TARGET_DOSE.yLabel')}
                </KnYLabel>
                <TargetDoseBarChart
                  chartId={chartId}
                  unit={stats.unit}
                  doses={stats.doses}
                />
              </>
            ) : (
              <Box height={240} textAlign="center" lineHeight={14}>
                <Typography style={{ fontSize: 14 }} component={KnSubtleText}>
                  {translate('TITRATION_BUILDER.TARGET_DOSE.noStats')}
                </Typography>
              </Box>
            )}
            {stats.doses && (
              <>
                <KnFDALabel variant="caption" component={KnSmallBlackLabel}>
                  Maximum dose:
                  {' '}
                  {stats.maxDose ? (
                    <>
                      {numberWithCommas(stats.maxDose)}
                      {' '}
                      {stats.unit}
                    </>
                  ) : <>Not available</>}
                </KnFDALabel>
                <KnFDALabelRight variant="caption" component={KnSmallBlackLabel}>
                  <Box
                    component="span"
                    style={{
                      textDecoration: stats.fdaLink ? 'underline' : '',
                      cursor: 'pointer',
                    }}
                    onClick={handleClick}
                  >
                    Med Info
                  </Box>
                </KnFDALabelRight>
              </>
            )}
          </Box>
        </BorderRightBox>
        <Box width="50%" pt={3} pl={4}>
          <KnTypography>
            The Smart Titration autofills the most commonly prescribed dosage
            for the selected medication. Click individual dosage to modify.
          </KnTypography>
          <KnTypography style={{ marginTop: 24, marginBottom: 24 }}>
            Histogram is populated based on the most common prescriptions for the active
            ingredient (see FAQ for population study details).
            Additional dosing resources can be found at
            {' '}
            <KnBoxLink
              component="span"
              onClick={handleLinkClick}
            >
              Uptodate.com
            </KnBoxLink>
          </KnTypography>

          <Typography variant="subtitle1" component={KnSubtitleText}>
            Disclaimer:
          </Typography>
          <KnTypography>
            Recommendations on the use of this prescription drug are consistent with
            FDA-required labeling which is available via the link to the left.
            Please verify dosages are appropriate for your patient prior to prescribing.
          </KnTypography>
        </Box>
      </Box>
    </Box>
  );
};

KnTargetDosageInfo.propTypes = {
  chartId: PropTypes.string.isRequired,
  stats: PropTypes.shape().isRequired,
  uptodate: PropTypes.string.isRequired,
};
export default KnTargetDosageInfo;
