/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { KnElevatedColorBox } from 'styles/common';
import { KnDarkerText, KnLightText } from 'components/Typography';
import { padIfDigit, withKeyNamespace } from 'utils/utils';
import { THRESHOLD_EVENT_TYPES } from 'Constants';
import palette from 'styles/colors';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { KnMetricsLabel } from './styles';
import KnEyeIcon from '../../components/icons/EyeIcon';

const thresholdMetColor = palette.error.main;
const i18nKey = withKeyNamespace('PATIENT_RECORD.achievementsCard');

const KnAchievementsCard = ({ data, handleSeenClick }) => {
  const { t: translate } = useTranslation();
  const isThresholdType = data.type === THRESHOLD_EVENT_TYPES.seizure
      || data.type === THRESHOLD_EVENT_TYPES.side_effect;

  const cardContent = useMemo(() => (isThresholdType
    ? translate(i18nKey(`content.${data.type}`))
    : translate(
      i18nKey('surveyResultsScore'),
      { value: padIfDigit(data.score), maxScore: padIfDigit(data.maxScore) },
    )), [isThresholdType, data, translate]);

  /** Some cards show date only, others time as well */
  const dateDisplayKey = useMemo(() => (isThresholdType ? 'resultDate' : 'resultDateTime'), [isThresholdType]);

  return (
    <KnElevatedColorBox
      p={2}
      pb={1.5}
      mb={1}
      display="flex"
      justifyContent="space-between"
      data-testid={`achievement-card-${data.type}`}
      borderColor={(isThresholdType && !data.isSeen) ? thresholdMetColor : 'white'}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" component={KnMetricsLabel}>
          {translate(i18nKey(`title.${data.type}`))}
        </Typography>
        <Typography component={KnDarkerText}>
          {cardContent}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" component={KnLightText}>
          {translate(i18nKey(dateDisplayKey), { date: data.date })}
        </Typography>
        {(isThresholdType && !data.isSeen) && (
        <Tooltip title={translate(i18nKey('markAsSeen'))} placement="top" arrow>
          <IconButton onClick={() => handleSeenClick(data.id)}>
            <KnEyeIcon />
          </IconButton>
        </Tooltip>
        )}
      </Box>
    </KnElevatedColorBox>
  );
};

const KnPatientAchievementsReports = ({ surveys, events, handleSeenClick }) => {
  const [reportCards, setReportCards] = useState([]);
  const { t: translate } = useTranslation();

  useEffect(() => {
    let achievements = [];
    if (surveys) {
      achievements = achievements.concat(surveys);
    }
    if (events) {
      achievements = achievements.concat(events);
    }

    let cards = [];
    if (achievements.length) {
      cards = cards.concat(achievements.map((achievementData) => ({
        date: achievementData.date,
        Component: <KnAchievementsCard
          handleSeenClick={handleSeenClick}
          key={`${achievementData.type}-${achievementData.id}`}
          data={achievementData}
        />,
      })));
    }
    setReportCards(_.orderBy(cards, ['date'], ['desc']));
  }, [setReportCards, surveys, events, handleSeenClick, translate]);

  if (reportCards.length) {
    return <>{reportCards.map(({ Component }) => Component)}</>;
  }

  return null;
};

KnAchievementsCard.propTypes = {
  data: PropTypes.shape().isRequired,
  handleSeenClick: PropTypes.func.isRequired,
};

KnPatientAchievementsReports.defaultProps = {
  surveys: [],
  events: [],
};

KnPatientAchievementsReports.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape()),
  surveys: PropTypes.arrayOf(PropTypes.shape()),
  handleSeenClick: PropTypes.func.isRequired,
};

export default KnPatientAchievementsReports;
