/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { useIsUserActive, useIsUserPending } from 'utils/utils';

import KnPageContent, { KnBox } from 'components/Content';
import KnUserPendingCard from './UserPendingCard';
import KnPatientsList from './PatientsList';

const HomePage = () => {
  const isUserActive = useIsUserActive();
  const isUserPending = useIsUserPending();

  return (
    <KnPageContent dark={!isUserActive}>
      {isUserPending && <KnUserPendingCard />}
      {isUserActive && <KnPatientsList />}
      {!isUserPending && <KnBox />}
    </KnPageContent>
  );
};

export default HomePage;
