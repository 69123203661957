/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _, {
  ceil, get, sortBy, times,
} from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import React, {
  useEffect, useState, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import useDialog from 'components/dialog/DialogService';
import appActions from 'redux/actions/appActions';
import userActions from 'redux/actions/userActions';
import KnValidatedTextField from 'components/ValidatedTextField';
import {
  ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, MenuItem, Typography,
} from '@material-ui/core';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import {
  composeMedicationLabel,
  medicationSearchMatch,
  withKeyNamespace,
  unitAllowsStrength,
  computeTotalQuantity,
  preFillTimesOnSelection,
  getTitrationBuilderDuration,
  titrationMedsMigrate,
} from 'utils/utils';
import {
  API_REQUEST_ERROR_CODES,
  TITRATIONS_CONTEXT,
  TITRATION_NAME_FORMAT,
  DEFAULT_MEDICATION_UNIT,
  MedicationColors,
  MAX_MEDICATION_LIMIT,
  REGIMEN_STATUS,
  APP_PAGE_URLS,
} from 'Constants';
import { v4 as uuidv4 } from 'uuid';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import KnSearchDropDown from 'components/SearchDropDown';
import KnMedicationSheet from 'components/TitrationSheet';
import { KnContrastTextField } from 'components/TextField';
import { KnBoldSectionHeader, KnSectionHeader } from 'components/Typography';
import KnBrightSheet from 'components/BrightSheet';
import palette from 'styles/colors';
import { KnActionLink } from 'components/Link';
import KnPlusIcon from 'components/icons/PlusIcon';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import KnButton from 'components/Button';
import KnAddMedication from 'features/patient/AddMedication';
import GanttDayChart from 'features/patient/charts/GanttDayChart';
import { styled } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import GanttCalendarChart from 'features/patient/charts/GanttCalendarChart';
import TextField from '@material-ui/core/TextField';
import { KnBrightBox } from 'components/Content';
import KnTitrationFormMedication from './form-components/TitrationFormMedication';
import {
  KnMedicationIndexBox,
  KnMedicationPanelSummary,
  KnTitrationBuilderFieldsBox,
  KnTitrationNameInput,
  KnTitrationORText,
  KnTitrationIndexBorder,
} from './styles';
import KnCollaspedTable from './CollaspedTable';
import KnDisclaimer from './Disclaimer';


const expandIconColor = palette.primary;
const { plainGrey } = palette;

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

const initialPresetInfo = {
  presetName: '',
  presetMedication: '',
};

const KnFormHelperText = styled(FormHelperText)({
  marginTop: -40,
  marginBottom: 30,
});

const KnCollaspedTableView = styled(Typography)({
  marginLeft: '65px',
  marginTop: '-5px',
  color: plainGrey,
});

const KnNoteToPatientLable = styled(Typography)({
  marginTop: '20px',
  fontWeight: 'bold',
  marginLeft: '-25px',
  fontSize: '20px',
});

const KnMedicationPanelSummaryUpdate = styled(ExpansionPanelSummary)({
  border: 'none',
  '& .MuiExpansionPanelSummary-expandIcon': {
    position: 'absolute',
    top: 15,
    right: 31,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
});

const KnMediactionActionButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

/**
 * Generic component used to create/edit a titration,
 * in different contexts (presets, assigned regimens, etc)
 */
const KnTitrationForm = (props) => {
  const {
    context,
    headerActionComponent,
    submitAction,
    titrationsList: titrationsListRespone,
    submitButtonLabel,
    title,
    disclaimer,
    defaultMedications,
    preventCleanSubmit,
    fromPage,
    regimens,
    leaveTemplate,
    presetName,
    showChart,
    edit,
    note,
    hcpNote,
  } = props;
  const { t: translate } = useTranslation();
  const currentUserData = useSelector((state) => state.user.currentUser);
  const { userPreferences, isPro } = currentUserData;
  const disclaimr = userPreferences ? userPreferences.disclaimer : false;
  const [medications, setMedications] = useState(defaultMedications);
  const [showAddMedicaion, setShowAddMedicaion] = useState(!medications.length);
  const [dosagesUpdated, setDosagesUpdated] = useState(false);
  const [deletedMedications, setDeletedMedications] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(disclaimr || false);
  const [inputText, setInputText] = useState(note);
  const [inputTextRegimenUpdate, setInputTextRegimenUpdate] = useState(hcpNote);
  const successfulSave = useRef(false);
  const dialog = useDialog();
  const unblockLeave = useRef(() => { });
  const confirmedLeave = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    errors,
    register,
    triggerValidation,
    getValues,
    setError,
    clearError,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialPresetInfo,
    reValidateMode: 'onChange',
  });

  const formControls = useMemo(() => ({
    control,
    errors,
    triggerValidation,
    setValue,
    setError,
  }), [control, errors, triggerValidation, setValue, setError]);

  const titrationsList = useMemo(() => {
    const ownTemplates = titrationsListRespone.filter((t) => get(t, 'hcpUser.id') === currentUserData.userId);
    const otherTemplates = titrationsListRespone.filter((t) => get(t, 'hcpUser.id') !== currentUserData.userId);
    return [
      ...sortBy(ownTemplates, (i) => (i.name || '').toLowerCase()),
      ...sortBy(otherTemplates, (i) => get(i, 'hcpUser.name', '').toLowerCase()),
    ];
  }, [titrationsListRespone, currentUserData]);

  const beforePageLeave = useCallback((e) => {
    /**
     * Display the prompt on browser/window close or page reload
     * only if we haven't already saved the titration.
     */
    if (!successfulSave.current) {
      /** Cancel the event as stated by the standard. */
      e.preventDefault();
      /** Chrome requires returnValue to be set. */
      e.returnValue = '';
    }
  }, []);

  useEffect(() => {
    if (!successfulSave.current && dosagesUpdated && !confirmedLeave.current) {
      /** Unblock handler to allow transitioning. */
      unblockLeave.current = history.block((nextLocation) => {
        dialog({
          title: translate(i18nKey('confirmationLeaveDialog.title')),
          description: (context === TITRATIONS_CONTEXT.assignRegimen) ? translate(i18nKey('confirmationLeaveDialog.content')) : translate(i18nKey('confirmationLeaveDialog.contentTemplate')),
          submitLabel: translate('GENERAL.okButton'),
          closeLabel: translate('GENERAL.cancelButton'),
        }).then(() => {
          /**
           * Set the logical flag, unblock navigation and navigate
           * to the requested page on modal confirmation.
           */
          confirmedLeave.current = true;
          unblockLeave.current();
          history.push(nextLocation);
        });

        return !dosagesUpdated || confirmedLeave.current;
      });
    }

    return unblockLeave.current;
  }, [history, dialog, dosagesUpdated, translate, context]);


  useEffect(() => {
    window.addEventListener('beforeunload', beforePageLeave, false);

    return () => {
      window.removeEventListener('beforeunload', beforePageLeave, false);
    };
  }, [beforePageLeave]);

  useEffect(() => {
    medications.forEach((medication, i) => {
      if (medication.expand === undefined) medications[i] = { ...medications[i], expand: true };
    });
  }, [medications]);

  const allMedications = useMemo(() => _.sortBy(
    refDataService.getList(REFERENCE_DATA_TYPES.medications),
    ['activeIngredient', 'name'],
  ), []);

  const takeToError = () => setTimeout(() => {
    if (Object.keys(formControls.errors).length) {
      const errorsStrings = Object.keys(formControls.errors);
      const element = document.querySelector(`[name='${errorsStrings[0]}']`);
      try {
        if (element && element.parentNode) {
          element.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } catch (error) {
        /** Do nothing, version will not be displayed */
      }
    }
  });

  const actionSubmit = (datas) => {
    const data = datas;
    if (context === TITRATIONS_CONTEXT.newTitration) {
      data.name = getValues('presetName');
    }
    submitAction(data).then(() => {
      successfulSave.current = true;
    },
    (errorCode) => {
      if (errorCode === API_REQUEST_ERROR_CODES.TITRATION_PRESET_EXISTS) {
        setError('presetName', 'uniquePreset');
        takeToError();
      }
    });
  };

  const checkMedicationError = (noMedicationError, isValid) => {
    if (!noMedicationError && isValid) {
      const meds = [];
      medications.map((med) => {
        const dosages = [];
        med.dosages.map((dose) => {
          if (dose.complete) {
            return dose;
          }
          dosages.push(dose);
          return dose;
        });
        if (dosages.length) {
          meds.push({
            ...med,
            dosages,
          });
        }
        return med;
      });
      const data = {
        medications: meds, note: inputText, hcpNote: inputTextRegimenUpdate,
      };
      actionSubmit(data);
    }
  };

  const onSubmit = () => {
    /** Check if there's medication added */
    const noMedicationError = medications.length === 0;
    takeToError();
    if (noMedicationError) {
      setError('medicationSelect', 'emptyTitration');
    }

    triggerValidation().then((isValid) => {
      checkMedicationError(noMedicationError, isValid);
    });
  };

  const isMedicationAdded = useCallback((medicationItem) => _.find(
    medications,
    { activeIngredient: medicationItem.activeIngredient },
  ), [medications]);

  /**
   * A medication will be added to the titration only if the medication wasn't added already.
   */
  const addMedicationIfNeeded = useCallback((medicationItem, index) => {
    if (!isMedicationAdded(medicationItem)) {
      const medicationsClone = [...medications];
      medicationsClone[index] = {
        ...medicationItem,
        index: medications.length + 1,
        unit: DEFAULT_MEDICATION_UNIT,
        medicationStrength: undefined, /** by default, no tablet strength is set */
        dosages: [
          {
            keyId: uuidv4(),
            index: 1,
            quantity: 0,
            duration: '',
            frequency: '',
            times: [],
            individualQuantities: [],
            hasCustomDosage: false,
            new: true,
          },
        ],
      };
      setMedications(medicationsClone);
      setDeletedMedications(
        deletedMedications.filter((d) => !medicationsClone.map((m) => m.id).includes(d)),
      );
      setShowAddMedicaion(false);
      if (!dosagesUpdated) {
        setDosagesUpdated(true);
      }
      clearError('medicationSelect', 'emptyTitration');
    }
  }, [
    isMedicationAdded,
    setMedications,
    medications,
    setShowAddMedicaion,
    dosagesUpdated,
    clearError,
    setDeletedMedications,
    deletedMedications,
  ]);

  const onRemoveMediction = useCallback((medId) => {
    dialog({
      title: translate(i18nKey('removeMedicationConfirmation.title')),
      description: translate(i18nKey('removeMedicationConfirmation.content')),
      submitLabel: translate(i18nKey('removeMedicationConfirmation.deleteButton')),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      const med = _.find(medications, { id: medId });
      if (med) {
        const filteredMeds = _.filter(medications, (item) => item.id !== medId);
        setDeletedMedications([...deletedMedications, medId]);
        setMedications(filteredMeds);
        if (!dosagesUpdated) {
          setDosagesUpdated(true);
        }
        if (!filteredMeds.length) {
          setShowAddMedicaion(true);
        }
      }
    });
  }, [
    dialog, medications, translate, setShowAddMedicaion, setMedications,
    dosagesUpdated, setDeletedMedications, deletedMedications,
  ]);

  const getDosages = useCallback((item, medId, key, builderState, dosages) => {
    if (item.id === medId) {
      if (key === 'maintenanceDose') {
        return {
          ...item,
          dosages: item.dosages.map((d, index) => {
            if (item.dosages.length === index + 1) {
              return {
                ...d,
                duration: 365,
              };
            }
            return d;
          }),
        };
      }

      return {
        ...item,
        unit: builderState.unit,
        dosages,
      };
    }
    return item;
  }, []);

  const onTitrationBuilderAddDosage = useCallback((
    medId, builderState, resetDuration = false, key = '',
  ) => {
    const med = _.find(medications, { id: medId });
    let dosageStep = 0;

    if (builderState.doses > 1) {
      dosageStep = (builderState.targetDose - builderState.startDose) / (builderState.doses - 1);
    }

    if (med) {
      const { medicationStrength } = med;
      const dosages = times(builderState.doses).map((d, index) => {
        const individualQuantities = times(builderState.frequency).map(
          () => ceil((builderState.startDose + dosageStep * index) / builderState.frequency, 2),
        );
        return {
          keyId: uuidv4(),
          index,
          complete: false,
          quantity: ceil(builderState.startDose + dosageStep * index, 2),
          duration: resetDuration ? '' : getTitrationBuilderDuration(builderState, index),
          frequency: builderState.frequency,
          times: preFillTimesOnSelection(builderState.frequency),
          individualQuantities,
          hasCustomDosage: !!medicationStrength,
        };
      });
      setMedications((meds) => _.map(
        meds, (item) => getDosages(item, medId, key, builderState, key === 'unit' ? med.dosages : dosages),
      ));
    }
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [medications, setMedications, dosagesUpdated, getDosages]);

  const onSmartTitrationAddDosage = useCallback((
    medId, smartTitration, key = '',
  ) => {
    const med = _.find(medications, { id: medId });
    if (med && !key) {
      const dosages = smartTitration.dosages.map((d, index) => ({
        keyId: uuidv4(),
        index,
        complete: false,
        quantity: ceil(d.quantity, 2),
        duration: key === 'maintenanceDose' && (smartTitration.dosages.length - 1 === index) ? 365 : d.durationInDays,
        frequency: d.frequencyPerDay,
        times: get(d, 'timePeriodDosages', []).map((s, i) => ({ ...s, index: i })),
        individualQuantities: get(d, 'timePeriodDosages', []).map((s) => s.quantity),
        hasCustomDosage: !!d.isCustom,
      }));
      setMedications((meds) => _.map(
        meds, (item) => {
          if (item.id === medId) {
            return {
              ...item,
              unit: smartTitration.unit,
              dosages,
            };
          }
          return item;
        },
      ));
    } else if (med && key) {
      setMedications((meds) => _.map(
        meds, (item) => {
          if (item.id === medId) {
            return {
              ...item,
              dosages: item.dosages.map((d, index) => {
                if (item.dosages.length === index + 1) {
                  return {
                    ...d,
                    duration: 365,
                  };
                }
                return d;
              }),
            };
          }
          return item;
        },
      ));
    }
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [medications, setMedications, dosagesUpdated]);

  /**
   * When a new dosage is added, we copy all the values, except the quantity,
   * from the previous dosage.
   */
  const onAddDosage = useCallback((medId) => {
    const med = _.find(medications, { id: medId });
    if (med) {
      const lastDosage = _.last(med.dosages);
      const { medicationStrength } = med;
      const dosages = med.dosages.concat({
        ...lastDosage,
        keyId: uuidv4(),
        index: med.dosages.length + 1,
        complete: false,
        quantity: 0,
        duration: '',
        frequency: '',
        times: [],
        individualQuantities: [],
        hasCustomDosage: !!medicationStrength,
        new: true,
      });

      setMedications((meds) => _.map(meds, (item) => {
        if (item.id === medId) {
          return {
            ...item,
            dosages,
          };
        }
        return item;
      }));
    }
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [medications, setMedications, dosagesUpdated]);

  const onChangeDosage = useCallback((medId, unit) => {
    const med = _.find(medications, { id: medId });
    if (med) {
      const dosages = med.dosages.map((e) => ({
        ...e,
        quantity: ceil(_.sum(e.individualQuantities.map((i) => +i)), 2),
        hasCustomDosage: true,
        individualQuantities: e.individualQuantities,
      }));
      setMedications((meds) => _.map(meds, (item) => {
        if (item.id === medId) {
          return {
            ...item,
            dosages,
            unit: unit || DEFAULT_MEDICATION_UNIT,
          };
        }
        return item;
      }));
    }
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [medications, setMedications, dosagesUpdated]);

  // const computeQuantities = useCallback((total, frequency, updates) => {
  //   if (_.every(updates.individualQuantities, (v) => v === 0) && updates.quantity === 0) {
  //     return new Array(frequency).fill(0);
  //   }
  //   if (updates.hasCustomDosage) {
  //     return updates.individualQuantities;
  //   }
  //   const eachQuantity = Number((parseFloat(total) || 0).toFixed(2));
  //   return new Array(frequency).fill(eachQuantity);
  // }, []);

  /**
   * Called when unit changes for a medication, or a medication is removed.
   * Compares starting unit value of each medication with it's current value,
   * and shows/hides the unit switch notification based on that
   * @param {number} changedMedId - id of med that just changed, or got removed
   * @param {string} changedMedUnit - new unit value for the above med, 'undefined' if med removed
   */
  const updateUnitSwitchNotification = useCallback((changedMedId, changedMedUnit) => {
    let anyUnitSwitched = false;
    /** Check each medication, that's currently on the form */
    medications.forEach((med) => {
      if (med.id === changedMedId && changedMedUnit === undefined) {
        /** Skip checking this medication, if it just got removed */
        return;
      }
      /** Search it in the initial list of added meds */
      const startingMed = _.find(defaultMedications, { id: med.id });
      /** In case this medication was not added initially,
       * we need to compare it's current unit to the default unit
       * (as new meds are added with default unit value)
       */
      const startingUnit = _.get(startingMed, 'unit', DEFAULT_MEDICATION_UNIT);
      /** If we're checking the medication that just changed,
       * take the new value of the unit (as 'medications' state is not yet updated),
       * otherwise take the unit from the 'medications' state
       */
      const currentUnit = med.id === changedMedId ? changedMedUnit : med.unit;
      if (currentUnit !== startingUnit) {
        anyUnitSwitched = true;
      }
    });
    if (anyUnitSwitched) {
      /** Unit is changed for at least one medication */
      dispatch(appActions.appPushNotification('TITRATIONS.WARNING_MESSAGES.unitSwitched'));
      setTimeout(() => {
        dispatch(appActions.appPopNotification('TITRATIONS.WARNING_MESSAGES.unitSwitched'));
      }, 10000);
    } else {
      /** There are no more meds with changed unit value */
      dispatch(appActions.appPopNotification('TITRATIONS.WARNING_MESSAGES.unitSwitched'));
    }
  }, [dispatch, defaultMedications, medications]);

  /**
   * Updates a dosage for a medication in the list. The function receives
   * only the field value which has updated in the updates object.
   */
  const onTitrationChange = useCallback((medId, dosageId, updates) => {
    setMedications((meds) => meds.map((med) => {
      if (med.id === medId) {
        if (_.get(updates, 'unit')) {
          /** It's a unit change, dosages remain unchanged */
          updateUnitSwitchNotification(medId, updates.unit);

          const strengthUpdate = { medicationStrength: med.medicationStrength };
          /** We might have to clear medicationStrength */
          if (!unitAllowsStrength(updates.unit)) {
            strengthUpdate.medicationStrength = undefined;
          }
          return { ...med, ...updates, ...strengthUpdate };
        }

        if (_.has(updates, 'medicationStrength')) {
          /** It's a strength change, dosages remain unchanged
           * Note: using _.has(), as updates.medicationStrength might be undefined,
           * in case of value removal
           */
          return { ...med, ...updates };
        }

        const updatedDosages = med.dosages.map((dosage) => {
          if (dosage.keyId === dosageId) {
            /**
             * The individual quantities will be computed when:
             *   - custom dosage is unchecked and either the quantity or the frequency have changed
             *   - custom dosage has just been unchecked: the quantity will be evenly distributed
             */
            if (!updates.hasCustomDosage) {
              if (updates.individualQuantities) {
                const updatedQuantity = ceil(computeTotalQuantity(updates.individualQuantities), 2);
                return {
                  ...dosage, ...updates, quantity: updatedQuantity, new: true,
                };
              }
              if (updates.frequency) {
                return {
                  ...dosage,
                  ...updates,
                  new: true,
                  individualQuantities: times(updates.frequency).map(
                    () => ceil(dosage.quantity / updates.frequency, 2),
                  ),
                  times: preFillTimesOnSelection(updates.frequency),
                };
              }

              // if (updates.quantity || updates.frequency) {
              //   const individualQuantities = computeQuantities(
              //     updates.quantity || dosage.quantity || 0,
              //     updates.frequency || dosage.frequency, updates,
              //   );
              //   if (isSmartTitration) {
              //     return {
              //       ...dosage, ...updates, new: true,
              //     };
              //   }
              //   return {
              //     ...dosage, ...updates, individualQuantities, new: true,
              //   };
              // }
              // if (updates.hasCustomDosage === false) {
              //   const individualQuantities = computeQuantities(
              //     dosage.quantity, dosage.frequency, updates,
              //   );
              //   return {
              //     ...dosage, ...updates, individualQuantities, new: true,
              //   };
              // }
              // if (updates.quantity === '') {
              //   const individualQuantities = computeQuantities(
              //     updates.quantity || 0,
              //     updates.frequency || dosage.frequency, updates,
              //   );
              //   if (isSmartTitration) {
              //     return {
              //       ...dosage, ...updates, new: true,
              //     };
              //   }
              //   return {
              //     ...dosage, ...updates, individualQuantities, new: true,
              //   };
              // }
            }
            /**
             * If custom dosage is enabled:
             *  - if the individual quantities have changed, the total quantity will be updated with
             *    the sum of the individual ones.
             *  - if frequency has changed, the total quantity will be updated with the sum of the
             *    the remaining frequencies dosages.
             */
            // if (dosage.hasCustomDosage) {
            //   if (updates.individualQuantities) {
            //     const updatedQuantity = computeTotalQuantity(updates.individualQuantities);
            //     return {
            //       ...dosage, ...updates, quantity: updatedQuantity, new: true,
            //     };
            //   }
            //   if (updates.frequency) {
            //     const individualQuantities = dosage.individualQuantities.slice(
            //       0,
            //       updates.frequency,
            //     );
            //     const updatedQuantity = _.sumBy(individualQuantities, (q) => (Number(q) || 0));
            //     const hasCustomDosage = true;
            //     return {
            //       ...dosage,
            //       ...updates,
            //       individualQuantities,
            //       hasCustomDosage,
            //       quantity: updatedQuantity,
            //       new: true,
            //     };
            //   }
            // }
            /** If neither of the above, then these are other dosage updates. */
            return { ...dosage, ...updates, new: true };
          }
          return dosage;
        });
        return { ...med, dosages: updatedDosages };
      }
      return med;
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [dosagesUpdated, updateUnitSwitchNotification, setMedications]);

  const onTitrationDosageRemove = useCallback((medId, dosageId) => {
    setMedications((meds) => _.flatMap(meds, (med) => {
      if (med.id === medId) {
        const filteredDosages = _.filter(med.dosages, (dosage) => dosage.keyId !== dosageId);

        /** When we delete the last dosage, the medication must also be deleted. */
        if (!filteredDosages.length) {
          updateUnitSwitchNotification(medId, undefined);
          return [];
        }
        return [{ ...med, dosages: filteredDosages }];
      }
      return [med];
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [dosagesUpdated, updateUnitSwitchNotification, setMedications]);

  const onTitrationDosageReorder = useCallback((result) => {
    /**
     * If the dosage is dragged outside the medication it belongs to
     * destination will be null. We should do nothing in this case OR
     * if the dosage was dropped in the same place.
     */
    if (!result.destination
      || (result.destination.index === result.source.index)) {
      return;
    }

    const medId = parseInt(result.source.droppableId, 10);
    const from = result.source.index;
    const to = result.destination.index;

    setMedications((meds) => meds.map((med) => {
      if (med.id === medId) {
        const dosages = [...med.dosages];
        if (!dosages[to].complete) {
          const [moved] = dosages.splice(from, 1);
          dosages.splice(to, 0, moved);

          return { ...med, dosages };
        }
      }
      return med;
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [dosagesUpdated, setMedications]);

  const medicationType = useCallback((index, type, id, tMedications) => {
    if (type === 'new' && tMedications.length) {
      const medicationsClone = [...medications, ...tMedications].slice(0, MAX_MEDICATION_LIMIT);
      setMedications(medicationsClone);
      setDeletedMedications(
        deletedMedications.filter((d) => !medicationsClone.map((m) => m.id).includes(d)),
      );
      if (!dosagesUpdated) {
        setDosagesUpdated(true);
      }
    } else if (tMedications.length) {
      const beforeMeds = medications.slice(0, index);
      const afterMeds = medications.slice(index + 1, medications.length);
      const medicationsClone = [...beforeMeds, ...tMedications, ...afterMeds];
      setMedications(medicationsClone);
      setDeletedMedications(
        deletedMedications.filter((d) => !medicationsClone.map((m) => m.id).includes(d)),
      );
      if (!dosagesUpdated) {
        setDosagesUpdated(true);
      }
    } else if (tMedications.length === 0) {
      setError(`medication-${id}`, {
        type: 'manual',
        message: '',
      });
    }
  }, [dosagesUpdated, medications, setError, deletedMedications]);

  const onTemplateSelect = useCallback((event, index, type, id) => {
    if (event.target.value) {
      const titration = titrationsList.find((t) => t.id === event.target.value);

      titration.medications = titrationMedsMigrate(titration.medications);

      const tMedications = _.filter(titration.medications.map((t) => ({
        ...t, isTemplate: true, templateId: event.target.value,
      })), (med) => !isMedicationAdded(med));

      if (type !== 'new') {
        const medAtIndex = titration.medications.find((m) => m.id === medications[index].id) || {};
        if (Object.keys(medAtIndex).length) {
          tMedications.unshift({
            ...medications[index], isTemplate: true, templateId: event.target.value,
          });
        }
      }
      medicationType(index, type, id, tMedications);

      // If there is no medication to add keep Add Medication open
      if (tMedications.length) {
        clearError();
        setShowAddMedicaion(false);
      }
    }
  }, [
    titrationsList, medications,
    setShowAddMedicaion, isMedicationAdded, clearError,
    medicationType,
  ]);

  const onExpanndClick = (index, expanded) => {
    const medicationsClone = [...medications];
    medicationsClone[index] = { ...medications[index], expand: expanded };
    setMedications(medicationsClone);
  };

  const templateName = (medication) => (titrationsList
    .find((t) => t.id === medication.templateId) || {})
    .name;


  const assignedRegimen = useMemo(() => {
    const currentRegimens = _.filter(
      regimens,
      (regimen) => (
        (regimen.status !== REGIMEN_STATUS.deactivated)
        && (regimen.status !== REGIMEN_STATUS.completed)
        && (regimen.status !== REGIMEN_STATUS.updated)
      ),
    );
    return _.first(currentRegimens) || {};
  }, [regimens]);

  const pastRegimens = useMemo(() => _.filter(
    regimens,
    (regimen) => (
      regimen.status === REGIMEN_STATUS.deactivated
      || regimen.status === REGIMEN_STATUS.completed
      || regimen.status === REGIMEN_STATUS.updated
    ),
  ), [regimens]);

  const getChart = () => {
    if (!showChart && showChart !== undefined) {
      return null;
    }
    if ((assignedRegimen.status === REGIMEN_STATUS.notStarted
      && _.size(assignedRegimen.parentTitrations))
      || assignedRegimen.status === REGIMEN_STATUS.active
    ) {
      return (
        <GanttCalendarChart
          key="gantt-chart-calender"
          chartId="gantt-chart-calender"
          titration={{ ...assignedRegimen, medications }}
          pastRegimens={pastRegimens}
          assignedRegimen={assignedRegimen}
          deletedMedications={
            deletedMedications.filter((d) => !medications.map((m) => m.id).includes(d))
          }
          isEdit
        />
      );
    }
    return (
      <GanttDayChart
        key="gantt-chart-day"
        chartId="gantt-chart-day"
        medications={medications}
        showAddMedicaion={showAddMedicaion}
      />
    );
  };

  const ExpensionGraph = (text) => (
    <ExpansionPanel defaultExpanded>
      <KnMedicationPanelSummaryUpdate
        aria-controls="panel1c-content"
        id="panel1c-header"
        expandIcon={<div><KnArrowDownIcon color={expandIconColor} /></div>}
      >
        <Box ml={2} pb={1} pt={2}>
          <Typography variant="h5" component={KnBoldSectionHeader}>
            {text}
          </Typography>
        </Box>
      </KnMedicationPanelSummaryUpdate>
      <ExpansionPanelDetails>
        {getChart()}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  const KnMedicationPanelSummarydub = (medication, index) => (
    <KnMedicationPanelSummary
      aria-controls="panel1c-content"
      id="panel1c-header"
      expandIcon={(context === TITRATIONS_CONTEXT.assignRegimen)
        && <div><KnArrowDownIcon color={expandIconColor} /></div>}
    >
      <KnMedicationIndexBox display="flex" flexDirection="column" color={MedicationColors[index]}>
        <Typography variant="h5" component={KnBoldSectionHeader}>
          {index + 1}
        </Typography>
        <KnTitrationIndexBorder color={MedicationColors[index]} />
      </KnMedicationIndexBox>
      <Box display="flex" flexDirection="column" pt={2} pl={8} pr={6}>
        <Typography variant="h6" component={KnBoldSectionHeader}>
          {`${medication.activeIngredient} (${medication.name})`}
        </Typography>
      </Box>
      {medication.expand !== undefined && !medication.expand && (
        <Box>
          <KnCollaspedTableView>
            {templateName(medication)
              ? templateName(medication) : medication.activeIngredient}
          </KnCollaspedTableView>
          <Box pt={2}>
            <KnCollaspedTable medication={medication} />
            <Box style={{ margin: '35px' }}>
              <KnActionLink
                onClick={() => {
                  setTimeout(() => {
                    onAddDosage(medication.id);
                  });
                }}
                LhsIcon={KnPlusIcon}
                style={{ float: 'left' }}
                data-testid="add-dosage-link"
              >
                {translate(i18nKey('addDosage'))}
              </KnActionLink>
              <KnActionLink
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveMediction(medication.id);
                }}
                tooltip
                tooltipText={translate('ICON_TOOLTIP.delete')}
                LhsIcon={KnDeleteIcon}
                style={{ float: 'right' }}
                data-testid="delete-medication-link"
              />
            </Box>
          </Box>
        </Box>
      )}
    </KnMedicationPanelSummary>
  );
  const KnSearchDropDownChange = (medication, index) => (
    <KnSearchDropDown
      key={medication.id}
      label={translate('FIELD_LABELS.presetMedication')}
      items={allMedications}
      disabled={medication.fromPrev}
      isItemDisabled={isMedicationAdded}
      itemDisplay={composeMedicationLabel}
      onItemSelect={addMedicationIfNeeded}
      searchMatch={medicationSearchMatch}
      error={errors.medicationSelect}
      medication={medication.isTemplate ? {} : medication}
      medicationIndex={index}
    />
  );

  const handleChange = useCallback((e) => {
    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
    setInputText(e.target.value);
  }, [dosagesUpdated, setDosagesUpdated, setInputText]);

  const ShowMedication = (medication, index) => (
    <KnTitrationFormMedication
      context={context}
      key={medication.id}
      index={index}
      medication={medication}
      medications={medications}
      setMedications={setMedications}
      dosagesUpdated={dosagesUpdated}
      setDosagesUpdated={setDosagesUpdated}
      onChangeDosage={onChangeDosage}
      onAddDosage={onAddDosage}
      onRemoveMediction={onRemoveMediction}
      onMedicationChange={onTitrationChange}
      onMedicationDosageRemove={onTitrationDosageRemove}
      onTitrationBuilderAddDosage={onTitrationBuilderAddDosage}
      onSmartTitrationAddDosage={onSmartTitrationAddDosage}
      dataTestId={`medication-wrapper-${index + 1}`}
      showDeleteMedication
      {...formControls}
    />
  );

  if (context === TITRATIONS_CONTEXT.assignRegimen) {
    return (
      <>
        {ExpensionGraph(translate(i18nKey('medicationRegimen')))}
        {fromPage && (
          <KnDisclaimer
            showDisclaimer={showDisclaimer}
            setShowDisclaimer={(e) => {
              dispatch(userActions.userPrefernce({
                userPreferences: [{
                  key: 'disclaimer',
                  value: e,
                }],
              })).then(() => {
                setShowDisclaimer(e);
                dispatch(userActions.getCurrentUser(true));
              });
            }}
          />
        )}
        {medications.map((medication, index) => (

          <ExpansionPanel
            defaultExpanded
            key={medication.id}
            expanded={medication.expand}
            onChange={(event, expanded) => onExpanndClick(index, expanded)}
          >
            {KnMedicationPanelSummarydub(medication, index)}
            <ExpansionPanelDetails>
              <KnMedicationSheet
                key={medication.id}
                ActionComponent={headerActionComponent}
                dosages={(
                  <DragDropContext onDragEnd={onTitrationDosageReorder}>
                    {ShowMedication(medication, index)}
                  </DragDropContext>
                )}
              >
                <Box flex="1">
                  <KnTitrationBuilderFieldsBox display="flex" pt={2}>
                    {(context === TITRATIONS_CONTEXT.newTitration) && (
                      <KnValidatedTextField
                        name="presetName"
                        Component={KnTitrationNameInput}
                        control={control}
                        errors={errors}
                        format={TITRATION_NAME_FORMAT}
                        required
                        trimSpaces
                        maxLength={80}
                      />
                    )}
                    <>
                      {' '}
                      <Box
                        style={{ opacity: !medication.fromPrev ? 1 : 0 }}
                        disabled={medication.fromPrev}
                      >
                        {KnSearchDropDownChange(medication, index)}
                      </Box>
                      <KnTitrationORText
                        style={{ opacity: !medication.fromPrev ? 1 : 0 }}
                        variant="h5"
                        component={KnBoldSectionHeader}
                      >
                        {translate('TITRATIONS.titrationBuilder.or')}
                      </KnTitrationORText>
                      <Box>
                        <KnContrastTextField
                          style={{ opacity: !medication.fromPrev ? 1 : 0 }}
                          disabled={medication.fromPrev}
                          name={`medication-${medication.id}`}
                          select
                          value={medication.templateId}
                          label={translate('TITRATIONS.titrationBuilder.medicationTemplate')}
                          onChange={(e) => onTemplateSelect(e, index, 'replace', medication.id)}
                          {...register(`medication-${medication.id}`)}
                        >
                          {titrationsList.map((titration, unitIndex) => (
                            <MenuItem
                              key={titration.id}
                              value={titration.id}
                              data-testid={`medication-template-option-${unitIndex + 1}`}
                            >
                              {titration.name}
                              {
                                get(titration, 'hcpUser.id') !== currentUserData.userId && (
                                  <>
                                    {'  '}
                                    (
                                    {get(titration, 'hcpUser.name')}
                                    )
                                  </>
                                )
                              }
                            </MenuItem>
                          ))}
                        </KnContrastTextField>
                        {errors[`medication-${medication.id}`] && (
                          <KnFormHelperText error data-testid="times-validation-error">
                            {translate('TITRATIONS.titrationBuilder.errorMessage')}
                          </KnFormHelperText>
                        )}
                      </Box>

                    </>
                  </KnTitrationBuilderFieldsBox>
                </Box>

              </KnMedicationSheet>
            </ExpansionPanelDetails>
          </ExpansionPanel>


        ))}
        {(!medications.length || showAddMedicaion) && (
          <KnMedicationSheet
            title={`${translate('TITRATIONS.titrationBuilder.addMedicationOrTemplate')}`}
            index={medications.length}
            hideIndexAndTitle={false}
            color={MedicationColors[medications.length]}
          >
            <Box flex="1">
              <KnTitrationBuilderFieldsBox display="flex" pt={2}>
                <KnSearchDropDown
                  label={translate('FIELD_LABELS.presetMedication')}
                  items={allMedications}
                  isItemDisabled={isMedicationAdded}
                  itemDisplay={composeMedicationLabel}
                  onItemSelect={addMedicationIfNeeded}
                  searchMatch={medicationSearchMatch}
                  error={errors.medicationSelect}
                  medicationIndex={medications.length}
                />
                <KnTitrationORText variant="h6" component={KnBoldSectionHeader}>
                  {translate('TITRATIONS.titrationBuilder.or')}
                </KnTitrationORText>
                <Box>
                  <KnContrastTextField
                    name="medication-add"
                    select
                    value=""
                    label={translate('TITRATIONS.titrationBuilder.medicationTemplate')}
                    onChange={(e) => onTemplateSelect(e, medications.length, 'new', 'add')}
                  >
                    {titrationsList.map((titration, unitIndex) => (
                      <MenuItem
                        key={titration.id}
                        value={titration.id}
                        data-testid={`medication-template-option-${unitIndex + 1}`}
                      >
                        {titration.name}
                        {
                          get(titration, 'hcpUser.id') !== currentUserData.userId && (
                            <>
                              {'  '}
                              (
                              {get(titration, 'hcpUser.name')}
                              )
                            </>
                          )
                        }
                      </MenuItem>
                    ))}
                  </KnContrastTextField>
                  {errors['medication-add'] && (
                    <KnFormHelperText error data-testid="times-validation-error">
                      {translate('TITRATIONS.titrationBuilder.errorMessage')}
                    </KnFormHelperText>
                  )}
                </Box>
              </KnTitrationBuilderFieldsBox>
            </Box>
          </KnMedicationSheet>
        )}

        <KnAddMedication
          setShowAddMedicaion={setShowAddMedicaion}
          showAddMedicaion={showAddMedicaion || _.size(medications) >= MAX_MEDICATION_LIMIT}
        />

        <Box mb={5} mt={-5}>
          <KnMedicationSheet>
            <Box ml={-4} mb={-2}>
              <KnNoteToPatientLable component={KnSectionHeader}>
                {translate('TITRATIONS.notesToPatient')}
              </KnNoteToPatientLable>
              <KnContrastTextField
                rowsMin={15}
                variant="filled"
                multiline
                size="medium"
                inputProps={{
                  maxLength: 500,
                }}
                onChange={handleChange}
                label={translate('TITRATIONS.maxNodeLength')}
                type="string"
                value={inputText}
              />
            </Box>
          </KnMedicationSheet>
        </Box>
        {(edit) && (
        <Box mb={5} mt={-5}>
          <KnMedicationSheet>
            <Box ml={-4} mb={-2}>
              <KnNoteToPatientLable component={KnSectionHeader}>
                {translate('TITRATIONS.notesToDoctor')}
                <span style={{ fontWeight: 500 }}>{translate('TITRATIONS.doctorUse')}</span>
              </KnNoteToPatientLable>
              <KnContrastTextField
                rowsMin={15}
                variant="filled"
                multiline
                size="medium"
                inputProps={{
                  maxLength: 500,
                }}
                onChange={(e) => { setInputTextRegimenUpdate(e.target.value); }}
                label={translate('TITRATIONS.maxNodeLength')}
                type="string"
                value={inputTextRegimenUpdate}
              />
            </Box>
          </KnMedicationSheet>
        </Box>
        )}
        <KnMediactionActionButtonBox>
          <KnButton
            style={{ width: '250px' }}
            onClick={history.goBack}
            data-testid="leave-regimen-button"
          >
            {translate('TITRATIONS.leaveMedication')}
          </KnButton>
          <KnButton
            style={{ width: '250px' }}
            onClick={onSubmit}
            data-testid="save-and-send-to-patient-button"
            disabled={edit ? ((preventCleanSubmit && !dosagesUpdated) || _.size(medications) < 1)
              : (showAddMedicaion && (_.size(medications) < 1))}
            requiresPro={!isPro}
          >
            {translate('TITRATIONS.saveAndSendToPatient')}
          </KnButton>
        </KnMediactionActionButtonBox>
      </>
    );
  }

  return (
    <>
      <KnBrightSheet
        title={title}
        subTitle={disclaimer}
        padding={5}
      >
        <Box flex="1">
          <KnTitrationBuilderFieldsBox display="flex" pt={2}>
            {(context === TITRATIONS_CONTEXT.newTitration) && (
              <>
                <KnValidatedTextField
                  name="presetName"
                  Component={KnTitrationNameInput}
                  control={control}
                  errors={errors}
                  format={TITRATION_NAME_FORMAT}
                  required
                  trimSpaces
                  maxLength={80}
                />
              </>
            )}
            {(context === TITRATIONS_CONTEXT.editTitration) && (
              <>
                <TextField
                  maxLength={80}
                  disabled
                  value={presetName}
                  id="filled-basic"
                  color="secondary"
                  label={translate('TITRATIONS.titrationBuilder.medicationTemplate')}
                  variant="filled"
                />
              </>
            )}
          </KnTitrationBuilderFieldsBox>
        </Box>
      </KnBrightSheet>

      <KnBrightBox mt={2} mb={2}>
        {ExpensionGraph(translate(i18nKey('medicationTemplate')))}
      </KnBrightBox>

      {medications.map((medication, index) => (
        <KnBrightBox mb={2} key={medication.id}>
          {KnMedicationPanelSummarydub(medication, index)}
          <ExpansionPanelDetails>
            <KnMedicationSheet
              key={medication.id}
              dosages={(
                <DragDropContext onDragEnd={onTitrationDosageReorder}>
                  {ShowMedication(medication, index)}
                </DragDropContext>
              )}
            >
              <Box flex="1">
                <KnTitrationBuilderFieldsBox display="flex" pt={2} width="80%">
                  <>
                    <Box>
                      {KnSearchDropDownChange(medication, index)}
                    </Box>
                  </>
                  {/* <KnOffsetBoxChange
                    medication={medication}
                    medications={medications}
                    setMedications={setMedications}
                    dosagesUpdated={dosagesUpdated}
                    setDosagesUpdated={setDosagesUpdated}
                    control={control}
                    errors={errors}
                    setError={setError}
                    clearError={clearError}
                  /> */}
                </KnTitrationBuilderFieldsBox>
              </Box>

            </KnMedicationSheet>
          </ExpansionPanelDetails>
        </KnBrightBox>

      ))}

      {(!medications.length || showAddMedicaion) && (
        <KnMedicationSheet
          title={translate('TITRATIONS.titrationBuilder.selectMedication')}
          index={medications.length}
          hideIndexAndTitle={false}
          color={MedicationColors[medications.length]}
        >
          <Box flex="1">
            <KnTitrationBuilderFieldsBox display="flex" pt={2}>
              <KnSearchDropDown
                label={translate('FIELD_LABELS.presetMedication')}
                items={allMedications}
                isItemDisabled={isMedicationAdded}
                itemDisplay={composeMedicationLabel}
                onItemSelect={addMedicationIfNeeded}
                searchMatch={medicationSearchMatch}
                error={errors.medicationSelect}
                medicationIndex={medications.length}
              />
            </KnTitrationBuilderFieldsBox>
          </Box>
        </KnMedicationSheet>
      )}

      <KnAddMedication
        setShowAddMedicaion={setShowAddMedicaion}
        showAddMedicaion={showAddMedicaion || _.size(medications) >= MAX_MEDICATION_LIMIT}
      />

      <KnMediactionActionButtonBox>
        <KnButton
          route={APP_PAGE_URLS.titrationsList}
          style={{ width: '300px' }}
        >
          {leaveTemplate}
        </KnButton>
        <KnButton
          onClick={onSubmit}
          data-testid="save-titration-button"
          style={{ width: '300px' }}
          disabled={(_.size(medications) > 0) ? preventCleanSubmit && !dosagesUpdated : true}
        >
          {submitButtonLabel}
        </KnButton>
      </KnMediactionActionButtonBox>
    </>
  );
};

KnTitrationForm.defaultProps = {
  title: '',
  disclaimer: null,
  leaveTemplate: '',
  headerActionComponent: null,
  defaultMedications: [],
  preventCleanSubmit: false,
  titrationsList: [],
  fromPage: '',
  presetName: null,
  regimens: [],
  showChart: true,
  edit: false,
  note: '',
  hcpNote: '',
  status: '',
};

KnTitrationForm.propTypes = {
  title: PropTypes.string,
  disclaimer: PropTypes.string,
  leaveTemplate: PropTypes.string,
  presetName: PropTypes.string,
  headerActionComponent: PropTypes.shape(),
  submitAction: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  context: PropTypes.oneOf([
    TITRATIONS_CONTEXT.newTitration,
    TITRATIONS_CONTEXT.editTitration,
    TITRATIONS_CONTEXT.assignRegimen,
  ]).isRequired,
  preventCleanSubmit: PropTypes.bool,
  fromPage: PropTypes.string,
  defaultMedications: PropTypes.arrayOf(PropTypes.shape()),
  titrationsList: PropTypes.arrayOf(PropTypes.shape()),
  regimens: PropTypes.arrayOf(PropTypes.shape()),
  showChart: PropTypes.bool,
  edit: PropTypes.bool,
  note: PropTypes.string,
  hcpNote: PropTypes.string,
  status: PropTypes.string,
};

export default KnTitrationForm;
