/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnReportIcon = () => (
  <svg width="28" height="28" fill="none" viewBox="0 0 28 28">
    <path fill="#6CB7C6" fillRule="evenodd" d="M22.167 3.5H17.29C16.8 2.145 15.517 1.165 14 1.165c-1.517 0-2.8.98-3.29 2.333H5.833C4.55 3.5 3.5 4.55 3.5 5.833v16.333c0 1.283 1.05 2.333 2.333 2.333h16.334c1.283 0 2.333-1.05 2.333-2.333V5.833c0-1.284-1.05-2.334-2.333-2.334zM14 3.5c.642 0 1.167.524 1.167 1.166 0 .642-.525 1.167-1.167 1.167s-1.167-.525-1.167-1.167.525-1.167 1.167-1.167zM8.167 19.832h8.166v-2.334H8.167v2.334zm11.666-4.667H8.167v-2.333h11.666v2.333zM8.167 10.499h11.666V8.166H8.167v2.333z" clipRule="evenodd" />
  </svg>
);

export default KnReportIcon;
