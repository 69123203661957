/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import React, {
  useRef, useLayoutEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { create, color, useTheme } from '@amcharts/amcharts4/core';
import {
  XYChart, ValueAxis, CategoryAxis, ColumnSeries, LabelBullet,
} from '@amcharts/amcharts4/charts';
import palette from 'styles/colors';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { styled } from '@material-ui/core';
import { sortBy } from 'lodash';
import { numberWithCommas } from 'utils/utils';

const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

export const KnGanttChartContainer = styled('div')({
  height: 240,
  width: '100%',
  '& g > g:last-child': {
    shapeRendering: 'auto', // crispEdges
  },

  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
});

const TargetDoseBarChart = ({
  chartId, unit, doses,
}) => {
  const chartRef = useRef(null);

  const ganttChartData = useMemo(() => sortBy(doses, (s) => parseFloat(s.percent))
    .map((d, index) => ({
      ...d,
      color: index + 1 === doses.length ? palette.primary : palette.green.light2,
      textColor: index + 1 === doses.length ? palette.primary : palette.bostonBlue,
      fontWeight: index + 1 === doses.length ? 'bold' : 'normal',
      labelValue: numberWithCommas(d.dose),
    })), [doses]);

  useTheme(am4themesAnimated);


  const createChart = () => {
    const chart = create(chartId, XYChart);
    chart.plotContainer.background.strokeWidth = 1;
    chart.plotContainer.background.strokeOpacity = 1;
    chart.plotContainer.background.stroke = color('#CDD8DA');

    chart.data = ganttChartData;

    const valueAxis = chart.xAxes.push(new ValueAxis());
    const categoryAxis = chart.yAxes.push(new CategoryAxis());
    const series = chart.series.push(new ColumnSeries());

    categoryAxis.dataFields.category = 'percent';
    categoryAxis.renderer.grid.template.location = 0;
    // disable vertical lines
    categoryAxis.renderer.grid.template.disabled = true;

    series.columns.template.maxWidth = 20;
    series.columns.template.height = 20;
    series.columns.template.column.cornerRadiusBottomRight = 4;
    series.columns.template.column.cornerRadiusTopRight = 4;
    series.dataFields.valueX = 'dose';
    series.dataFields.categoryY = 'percent';
    series.columns.template.propertyFields.fill = 'color';
    series.columns.template.propertyFields.stroke = 'color';
    valueAxis.renderer.labels.template.disabled = true;
    // disable horizontal lines
    valueAxis.renderer.grid.template.disabled = true;

    const valueLabel = series.bullets.push(new LabelBullet());
    valueLabel.label.text = `{labelValue} ${unit}`;
    valueLabel.label.fontSize = 14;
    valueLabel.label.propertyFields.fontWeight = 'fontWeight';
    valueLabel.label.propertyFields.fill = 'textColor';
    valueLabel.label.truncate = false;
    valueLabel.label.hideOversized = false;
    valueLabel.label.horizontalCenter = 'left';
    valueLabel.label.dx = 10;
  };

  useLayoutEffect(() => {
    if (!chartRef.current && ganttChartData.length) {
      createChart();
    }
    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartRef.current.dispose();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ganttChartData]);

  return (
    <>
      <KnGanttChartContainer id={chartId} />
    </>
  );
};

TargetDoseBarChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  doses: PropTypes.shape().isRequired,
};

export default TargetDoseBarChart;
