/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { TITRATIONS_CONTEXT } from 'Constants';
import {
  KnTitrationSubtleText,
} from '../styles';

const TitrationDateInfo = (props) => {
  const { titration, context, component } = props;
  const { t: translate } = useTranslation();

  const titrationDate = useMemo(() => {
    switch (context) {
      case TITRATIONS_CONTEXT.assignedRegimen:
      case TITRATIONS_CONTEXT.pastRegimen:
        if (titration.assignedAt) {
          return translate(
            'PATIENT_RECORD.medicationRegimen.assignedAt',
            { date: titration.assignedAt },
          );
        }
        /** When started and deactivated/completed, show no date, as title holds both dates */
        return '';
      default:
        return translate('TITRATIONS.lastUpdated', { date: titration.lastUpdated });
    }
  }, [titration, context, translate]);

  return (
    <Typography variant="body2" component={component}>
      {titrationDate}
    </Typography>
  );
};


TitrationDateInfo.defaultProps = {
  component: KnTitrationSubtleText,
};
TitrationDateInfo.propTypes = {
  titration: PropTypes.shape().isRequired,
  context: PropTypes.oneOf(
    [TITRATIONS_CONTEXT.assignPreset, TITRATIONS_CONTEXT.titrationsManagement,
      TITRATIONS_CONTEXT.assignedRegimen, TITRATIONS_CONTEXT.pastRegimen],
  ).isRequired,
  component: PropTypes.shape(),
};

export default TitrationDateInfo;
