import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import {
  getCompletedDosages, capFirstCharOnly, tabletCountDisplay,
  calculateTabletCount, getCurrentRegiment,
} from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import filterPastRegiment from './util';

const useRegimenText = (props) => {
  const {
    showTitle, extraSpace, dateRange, notStarted,
  } = props;
  const [inputText, setInputText] = useState({});
  const { t: translate } = useTranslation();
  const {
    data: regimens,
  } = useSelector((state) => state.patientRecord.regimens);

  const titration = useMemo(() => getCurrentRegiment(regimens, notStarted), [regimens, notStarted]);

  const pastRegimens = useMemo(() => filterPastRegiment(regimens, dateRange),
    [regimens, dateRange]);

  const getDuration = useCallback((duration, endDate) => {
    const date = `${(endDate && showTitle) ? translate('PATIENT_RECORD.patientReports.currentDate', { date: endDate }) : ''}`;
    if (duration % 7 === 0) {
      return `${duration / 7}w${date};`;
    }
    return `${duration}d${date};`;
  }, [translate, showTitle]);

  const strengthValue = (d, t, medicationStrength, medicationType) => `(${tabletCountDisplay(calculateTabletCount(
    d.individualQuantities[t.index],
    medicationStrength,
    {},
  ), medicationType).replace(/ /g, '')})`;

  const getMedsText = useCallback((currentTitration) => {
    let text = '';
    const {
      medications = [], startedAt, parentTitrationId, note, hcpNote,
    } = currentTitration || {};
    const parentTitration = (regimens || []).find((r) => r.id === parentTitrationId);
    const { medics } = getCompletedDosages(medications, parentTitration, startedAt);
    medics.forEach((m) => {
      const {
        medicationStrength, medicationType, medicationTypeUnit,
      } = m;
      text += `${extraSpace ? '  ' : ''}Take ${m.activeIngredient}(${m.name}) from day ${m.offset ? m.offset + 1 : 1}:\n`;
      if (medicationStrength) text += `${capFirstCharOnly(medicationType)}${translate('FIELD_LABELS.suspensionStrength')}${medicationStrength}${medicationTypeUnit}\n`;
      m.dosages.forEach((d) => {
        d.times.forEach((t) => {
          const customDosageString = `${d.individualQuantities[t.index]}${m.unit}${medicationStrength ? strengthValue(d, t, medicationStrength, medicationType) : ' '}${t.timePeriod.value}`;
          if (t.index === 0 && d.times.length === t.index + 1) {
            text += `${''}  - ${customDosageString} ${getDuration(d.duration, d.endDate)}\n`;
          } else if (t.index === 0) {
            text += `${''}  - ${customDosageString}, `;
          } else if (d.times.length === t.index + 1) {
            text += `${customDosageString} ${getDuration(d.duration, d.endDate)}\n`;
          } else {
            text += `${customDosageString}, `;
          }
        });
      });
      text += '\n';
    });
    if (note) {
      text += `${extraSpace ? '   ' : ''}Notes To Patient: ${note}`;
      text += '\n\n';
    }
    if (hcpNote) {
      text += `${extraSpace ? '   ' : ''}Regimen Update Notes (HCP/Dr use): ${hcpNote}`;
      text += '\n\n';
    }
    return text;
  }, [
    regimens,
    translate,
    extraSpace,
    getDuration,
  ]);

  const getTitrationText = useCallback((currentTitration) => {
    const { startedAt } = currentTitration || {};

    let currentMedsText = '\n';
    let pastMedsText = '';

    if (showTitle) {
      currentMedsText += `${translate('PATIENT_RECORD.patientReports.medicationReport')}`;
      if (startedAt) {
        currentMedsText += ` ${translate('PATIENT_RECORD.patientReports.startedAt', { date: startedAt })}`;
      }
      currentMedsText += '\n\n';

      pastMedsText += `\n\n${translate('PATIENT_RECORD.patientReports.pastMedTitle')}\n\n`;
    }

    currentMedsText += getMedsText(currentTitration);

    pastRegimens.forEach((regimen) => {
      if (regimen.startedAt) {
        pastMedsText += translate(
          'PATIENT_RECORD.medicationRegimen.pastRegimenTitle',
          {
            startDate: regimen.startedAt,
            endDate: regimen.titrationUpdateDate ? format(new Date(regimen.titrationUpdateDate), 'MM/dd/yyyy') : regimen.deactivatedAt || regimen.completedAt,
          },
        );
        pastMedsText += `, ${capitalize(regimen.status)};`;
      } else {
        pastMedsText += translate('PATIENT_RECORD.medicationRegimen.neverStartedRegimenTitle');
      }
      pastMedsText += '\n';
      pastMedsText += getMedsText(regimen);
    });

    return {
      currentMedsText: titration ? currentMedsText : `\n${translate('PATIENT_RECORD.patientReports.medicationReport')}\n`,
      pastMedsText: pastRegimens.length ? pastMedsText : `\n${translate('PATIENT_RECORD.patientReports.pastMedTitle')}\n`,
    };
  }, [
    showTitle,
    translate,
    getMedsText,
    pastRegimens,
    titration,
  ]);

  useEffect(() => {
    setInputText(getTitrationText(titration));
  }, [getTitrationText, setInputText, titration]);

  if (regimens && !regimens.length) {
    return '';
  }

  return inputText;
};

export default useRegimenText;
