import React from 'react';
import {
  RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import palette from 'styles/colors';
import { BoxBottomBorder } from './styles';
import { SLOT_STATUS } from './CalendarConstant';

const KnFormControlLabel = styled(FormControlLabel)({
  '& .Mui-disabled': {
    color: palette.black.black1,
  },
});

const EventStatus = ({
  value, initialValue,
  handleStatusChange, isPastAppintment,
}) => (
  <BoxBottomBorder pt={2} pb={2}>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={value}
      onChange={handleStatusChange}
    >
      <KnFormControlLabel disabled={[SLOT_STATUS.NO_SHOW].includes(initialValue) || (isPastAppintment && initialValue === SLOT_STATUS.BOOKED)} value="reserved" control={<Radio />} label="Reserved" />
      <KnFormControlLabel value="booked" control={<Radio />} label="Booked" disabled={isPastAppintment} />
      <KnFormControlLabel disabled={initialValue === SLOT_STATUS.RESERVED || !isPastAppintment} value="noShow" control={<Radio />} label="No Show" />
    </RadioGroup>
  </BoxBottomBorder>
);

EventStatus.propTypes = {
  value: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  isPastAppintment: PropTypes.bool.isRequired,
};

EventStatus.defaultProps = {
};

export default EventStatus;
