/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnCopyIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM15.5 5L21.5 11V21C21.5 22.1 20.6 23 19.5 23H8.49C7.39 23 6.5 22.1 6.5 21L6.51 7C6.51 5.9 7.4 5 8.5 5H15.5ZM14.5 12H20L14.5 6.5V12Z" fill="#0A87A0" />
  </svg>
);

export default KnCopyIcon;
