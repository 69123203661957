/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import forgotPasswordActions from 'redux/actions/forgotPasswordActions';
import KnValidatedTextField from 'components/ValidatedTextField';
import { EMAIL_FORMAT } from 'Constants';

const initialLookupInfo = {
  email: '',
};

const EmailLookupStep = ({ submitDisabled, onSuccess }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialLookupInfo,
    reValidateMode: 'onChange',
  });

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the forgot password call now.
   */
  const onSubmit = async (formData, e) => {
    e.preventDefault();
    // const res = await dispatch(patientActions.checkUserRegisration({ email: formData.email }));
    // if (!res.isFound) {
    //   dispatch(appActions.appPushNotification('FORGOT_PASSWORD.ERROR_MESSAGES.emailNotExists'));
    //   return false;
    // }
    // if (res.registrationType === 'sso') {
    //   dispatch(appActions.appPushNotification('FORGOT_PASSWORD.ERROR_MESSAGES.ssoUserError'));
    //   return false;
    // }
    dispatch(forgotPasswordActions.forgotPassword(formData.email))
      .then((response) => {
        if (response && !response.error) {
          /** Fogot password call was successful, send response to step 2 of the flow */
          onSuccess({ username: response });
        }
      });
    return true;
  };

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('FORGOT_PASSWORD.emailLookupTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <KnValidatedTextField
          Component={KnTextField}
          name="email"
          control={control}
          errors={errors}
          format={EMAIL_FORMAT}
          required
          trimSpaces
          maxLength={128}
        />
        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            route="/"
            data-testid="login-link"
          >
            {translate('FORGOT_PASSWORD.backToLogin')}
          </KnButton>
          <KnButton
            disabled={submitDisabled}
            data-testid="send-code-button"
          >
            {translate('FORGOT_PASSWORD.sendCode')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

EmailLookupStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default EmailLookupStep;
